import { NextRouter } from 'next/router'
import { ITaxonomyWithoutChildren } from '@core/api/Categories/types'
import { RegistrationTypes } from '@core/api/Channel/types'
import {
  IProductsQueryFilter,
  IProductsQueryFilterRange,
  IProductsQueryOrder,
} from '@core/api/Products/types'
import { ICategoryPageProps } from '@core/pages/c/[...slugSlashId]'
import { getUserCart } from '@core/store/cart/selectors'
import { ShopStore } from '@core/store/configureStore'
import { getAuthenticatedUser } from '@core/store/user/selectors'
import { Environment } from '@core/utils/Environment'
import { logging } from '@core/utils/logging'
import { IGenericPageContext } from '@core/utils/pages/PageContext'
import { PageType } from '@core/utils/pages/types'
import trackingConfig from './config'

// CUSTOM LOGGING
export const log = (
  message: string,
  data?: {
    [key: string]: any
  }
) => {
  if (!Environment.default.isDevelopment) return
  logging.info(message, data)
}

export const getRouteProperty = (router: NextRouter, property: string) => {
  let item = trackingConfig.routeMap[router.route]
  if (!item)
    item = trackingConfig.routeMap[trackingConfig.routeMapRoutes.default]
  return item[property](router)
}

export const getPaymentMethod = (paymentMethod: string) => {
  if (RegExp('invoice$').test(paymentMethod))
    return `de${trackingConfig.dimension4.invoice}`
  if (RegExp('credit_card$').test(paymentMethod))
    return `de${trackingConfig.dimension4.creditCard}`
  if (RegExp('paypal$').test(paymentMethod))
    return `de${trackingConfig.dimension4.paypal}`
  if (RegExp('payment_in_advance$').test(paymentMethod))
    return `de${trackingConfig.dimension4.advancePayment}`
  if (RegExp('bank_transfer$').test(paymentMethod))
    return `de${trackingConfig.dimension4.bankTransfer}`
  return `de${trackingConfig.dimension4.unknown}`
}

export const normalizeText = (text: string) => {
  return text.replace(/\s+/g, '-').toLowerCase()
}

export const createBreadcrumbs = (
  path: ITaxonomyWithoutChildren[] | undefined
) => {
  return path?.map((t) => t.name).join('_') || ''
}

export const getList = (pageContext: IGenericPageContext) => {
  switch (pageContext.props.pageType) {
    case PageType.category: {
      const categoryPageProps = pageContext.props as ICategoryPageProps
      const categoryPath = createBreadcrumbs(
        categoryPageProps.category?.breadcrumbs.hierarchy
      )
      return categoryPath ? categoryPath.split('_').slice(1).join('_') : ''
    }
    case PageType.product:
      return trackingConfig.defaultListValue
    default:
      return trackingConfig.defaultListValue
  }
}

export const getCurrency = (store: ShopStore) => {
  const cart = getUserCart(store.getState())
  if (cart) return cart.price.total.currency
  return trackingConfig.fallbackCurrency
}

export const getCheckoutStep = (router: NextRouter) => {
  if (router.route === (trackingConfig.routeMapRoutes.checkoutCart as string))
    return trackingConfig.checkoutSteps.checkoutCart as number
  if (
    router.route === (trackingConfig.routeMapRoutes.checkoutAddress as string)
  )
    return trackingConfig.checkoutSteps.checkoutAddress as number
  if (
    router.route === (trackingConfig.routeMapRoutes.checkoutShipping as string)
  )
    return trackingConfig.checkoutSteps.checkoutShipping as number
  if (
    router.route === (trackingConfig.routeMapRoutes.checkoutPayment as string)
  )
    return trackingConfig.checkoutSteps.checkoutPayment as number
  if (
    router.route === (trackingConfig.routeMapRoutes.checkoutOverview as string)
  )
    return trackingConfig.checkoutSteps.checkoutOverview as number
  if (
    router.route ===
    (trackingConfig.routeMapRoutes.checkoutConfirmation as string)
  )
    return trackingConfig.checkoutSteps.checkoutConfirmation as number
  return 0
}

export const getCheckoutOption = (store: ShopStore) => {
  const user = getAuthenticatedUser(store.getState())
  if (user) {
    return user.channel.registrationType === RegistrationTypes.closed
      ? trackingConfig.dimension7.closedShop
      : trackingConfig.dimension7.openShop
  }
  return trackingConfig.dimension7.openShop
}

export const getSortingCategory = (sort?: IProductsQueryOrder) => {
  if (!sort) return trackingConfig.customEventCategories.sorting
  const key = Object.keys(sort)[0]
  return `${trackingConfig.customEventCategories.sorting}-${sort[key]}`
}
export const getSortingAction = (sort?: IProductsQueryOrder) => {
  if (!sort) return trackingConfig.customEventActions.reset
  return Object.keys(sort)[0]
}

export const getFilterCategory = (_?: IProductsQueryFilter) => {
  return trackingConfig.customEventCategories.filter
}
export const getFilterAction = (filter?: IProductsQueryFilter) => {
  return filter
    ? trackingConfig.customEventActions.list
    : trackingConfig.customEventActions.reset
}

export const getFilterLabel = (filter?: IProductsQueryFilter) => {
  if (!filter) return ''
  const keys = Object.keys(filter)
  const filterStringArray = keys.map((key) => {
    switch (typeof filter[key]) {
      case 'object': {
        if (Array.isArray(filter[key])) {
          const array = filter[key] as Array<string>
          return `${key}.${array.map((item) => normalizeText(item)).join('/')}`
        } else {
          const object = filter[key] as IProductsQueryFilterRange
          return `price.${object.gte}-${object.lte}`
        }
      }
      case 'string': {
        const string = filter[key] as string
        return `${key}.${normalizeText(string)}`
      }
      default:
        return ''
    }
  })

  return filterStringArray.join('_')
}
