import { CustomerTypes } from '../Channel/types'
import { IClientFields } from '../types'

export interface ICustomerSelfUpdate {
  companyName?: string
  type: CustomerTypes
  channel?: string
  clientFields?: IClientFields
}

export interface IShippingAddressCollection {
  total: number
  addresses: IShippingAddress[]
}

export interface IBillingInformationCollection {
  total: number
  addresses: IBillingInformation[]
}

export enum ShippingAddressStatus {
  ACTIVE = 'active',
  INACTIVE = 'inactive',
}

export interface IShippingAddress {
  id: string
  /**
   * General display name of the address
   */
  label?: string
  status: ShippingAddressStatus
  firstName?: string
  lastName?: string
  gender?: GenderTypes
  company?: string
  street: string
  postalCode: string
  city: string
  region?: string
  country: string
  phone?: string
  notes?: string
  isDefault?: boolean
  contactName?: string
  isEditableByCustomer?: boolean
  createdAt?: string
  clientFields: { [key: string]: any }
}

export interface IBillingInformation {
  id: string
  /**
   * General display name of the address
   */
  label?: string
  /**
   * B2B: Company name
   */
  firstName: string
  lastName: string
  gender: GenderTypes
  company?: string
  street: string
  postalCode: string
  city: string
  region?: string
  country: string
  postOfficeBoxNumber?: string
  email?: string
  phone?: string
  vatId?: string
  notes?: string
  isDefault?: boolean
  clientFields: { [key: string]: any }
}

export interface IAddressFormBase {
  label?: string
  firstName?: string
  lastName?: string
  gender?: GenderTypes
  company?: string
  email?: string
  street: string
  postalCode: string
  city: string
  region?: string
  country: string
  phone?: string
  vatId?: string
  postOfficeBoxNumber?: string
  notes?: string
  clientFields?: {
    [key: string]: any
  }
}

export interface IRegisterAccount {
  type: string
  gender: GenderTypes
  firstName: string
  lastName: string
  company?: string
  email: string
  channel?: string
  clientFields?: IRegisterClientFieldCollection
}

export interface IRegisterClientFieldCollection {
  phone?: string
  mobile?: string
  fax?: string
  website?: string
  billingEmail?: string
  chamberOfCommerce?: string
}

export enum GenderTypes {
  Male = 'male',
  Female = 'female',
  Diverse = 'diverse',
  NotAvailable = 'n/a',
}

export enum GenderContext {
  form = 'form',
  sentence = 'sentence',
}

export enum AddressTypes {
  Billing = 'billing',
  Shipping = 'shipping',
}

export interface ICustomerTeam {
  teamMembers: ICustomerTeamMember[]
}

export interface ICustomerTeamMember {
  user: {
    id: string
    firstName: string
    lastName: string
    gender: GenderTypes
    email: string
  }
  role: 'owner' | 'member'
  lastLogin: string
  createdAt: string
  status: 'created' | 'requested' | 'invited' | 'enabled' | 'locked' | 'pending'
  lastOrderAmount: number
  lastOrderDate: string
  totalOrderValue: number
}

export interface IInviteTeamMemberPayload {
  email: string
  firstName: string
  lastName: string
  gender: GenderTypes
}
