import React, { CSSProperties } from 'react'
import cn from 'classnames'
import style from './LYSIcon.module.less'

type IconSizes = 'xs' | 'sm' | 'md' | 'xl'

interface IconComponentProps {
  className?: string
  style?: CSSProperties
  title?: string
}

export type IconComponent =
  | React.FunctionComponent<IconComponentProps>
  | React.ComponentClass<IconComponentProps>
  | React.ForwardRefExoticComponent<IconComponentProps>
  | any

export interface Props {
  component: IconComponent
  className?: string
  size?: IconSizes
  style?: CSSProperties
  title?: string
}

const LYSIcon: React.FC<Props> = (props) => {
  const { className, component, size, ...rest } = props
  const extendedClassName = cn(size && style[size], className)
  return React.createElement(component, {
    ...rest,
    className: extendedClassName,
  })
}
export default LYSIcon
