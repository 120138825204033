import * as React from 'react'
import { Input } from 'antd'
import { InputProps, InputRef, SearchProps } from 'antd/lib/input'
import { EyeInvisibleOutlined, EyeTwoTone } from '@ant-design/icons'

export type LYSInputProps = InputProps
export type LYSInputSearchProps = SearchProps

const LYSInput = React.forwardRef<InputRef, LYSInputProps>((props, ref?) => (
  <Input ref={ref} {...props} />
))

export const LYSInputPassword = (props: any) => {
  return (
    <Input.Password
      {...props}
      iconRender={(visible) =>
        visible ? (
          <EyeTwoTone rev={undefined} />
        ) : (
          <EyeInvisibleOutlined rev={undefined} />
        )
      }
    />
  )
}

export type LYSInputType = InputRef

export const LYSInputTextArea = Input.TextArea
export const LYSInputSearch = Input.Search
export const LYSInputGroup = Input.Group

export default LYSInput
