import React from 'react'
import { Tabs } from 'antd'
import { TabPaneProps, TabsProps } from 'antd/lib/tabs'

const TabPane = Tabs.TabPane

const LYSTabs: React.FC<TabsProps> = (props) => {
  return <Tabs {...props} />
}
const LYSTabPane: React.FC<TabPaneProps> = (props) => {
  return <TabPane {...props} />
}

export { LYSTabs, LYSTabPane }
