import BaseAPI from '@core/api/Platform/BaseAPI'
import { ITaxonomy } from './types'

class Categories extends BaseAPI {
  public async fetchCategoryTree(
    depth?: number
  ): Promise<ITaxonomy | undefined> {
    try {
      const response = await this.api.get<ITaxonomy>('taxonomies/tree', {
        params: { depth },
        useCache: true,
      })
      const { data } = response
      return data
    } catch (e) {
      if (e.response?.status === 404) return undefined
      throw e
    }
  }

  public async fetchCategory(id: string): Promise<ITaxonomy | undefined> {
    try {
      const response = await this.api.get<ITaxonomy>(`taxonomies/${id}`, {
        params: {
          depth: 1,
        },
        useCache: true,
        genericUrl: 'taxonomies/{id}',
      })

      const { data } = response
      return data
    } catch (e) {
      if (e.response?.status === 404) return undefined
      throw e
    }
  }
}

export default Categories
