import { AppState } from '@core/store/app/types'
import { cartProcessing } from '@core/store/cart/actions'

const initialState: AppState = {
  blockedCartInteraction: false,
}

const app = (state: AppState = initialState, action: any): AppState => {
  if (!state || !action) {
    return initialState
  }
  if (cartProcessing && action.type === cartProcessing.started.type) {
    return {
      ...state,
      blockedCartInteraction: true,
    }
  }

  if (
    cartProcessing &&
    (action.type === cartProcessing.failed.type ||
      action.type === cartProcessing.done.type)
  ) {
    return {
      ...state,
      blockedCartInteraction: false,
    }
  }

  return state
}

export default app
