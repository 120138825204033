import * as React from 'react'
import cn from 'classnames'
import Footer from '@core/components/Footer'
import {
  LYSCol,
  LYSLayout,
  LYSRow,
  LYSBackTopButton,
} from '@core/components/Primitives'
import Header from '@core/components/header/Header'
import { PageOverlays } from '@core/components/layouts/DefaultPage/PageOverlays'
import config from '@core/config/config'
import { useServices } from '@core/utils/ioc'
import style from './index.module.less'

export interface DefaultPageProps {
  className?: string
}

const DefaultPage: React.FC<DefaultPageProps> = ({ children, className }) => {
  const { router } = useServices()
  const blacklistedURLs = [
    '/cart',
    '/checkout/address',
    '/checkout/select-shipping',
    '/checkout/select-payment',
    '/checkout/overview',
    '/checkout/result',
  ]

  return (
    <>
      <LYSLayout className={cn(style.layout, className)}>
        <Header />
        <LYSLayout.Content>
          <LYSRow justify="center" align="top">
            <LYSCol span={24}>{children}</LYSCol>
          </LYSRow>
        </LYSLayout.Content>
        {!blacklistedURLs.includes(router.pathname) &&
          config.features.scrollToTopButton.enabled && <LYSBackTopButton />}
        <Footer />
      </LYSLayout>

      <PageOverlays />
    </>
  )
}

export default DefaultPage
