import React from 'react'
import { ICartItem } from '@core/api/Cart/types'
import { getImagePlaceholder } from '@core/api/Products/types'
import { LYSRow, LYSList, LYSTypography } from '@core/components/Primitives'
import ProductDetailLink from '@core/components/ProductCollection/ProductDetailLink'
import useCartEvents from '@core/hooks/cart/useCartEvents'
import style from './index.module.less'

interface Props {
  cartItem: ICartItem
  hideCartItemEventHint?: boolean
}

const UnavailableCartItem: React.FC<Props> = ({
  cartItem,
  hideCartItemEventHint,
}) => {
  const { getUnavailableMessage } = useCartEvents()
  const { product } = cartItem
  const { variant } = product
  const image = variant.imageUrl || product.imageUrl || getImagePlaceholder()
  const warningMessage = getUnavailableMessage(cartItem)
  return (
    <LYSList.Item key={product.variant.id} className={style.listItem}>
      <LYSRow className={style.row} justify="space-between" align="middle">
        <ProductDetailLink slug={variant.slug} id={variant.id}>
          <img
            className={style.image}
            src={image}
            alt={product.name}
            loading="lazy"
          />
        </ProductDetailLink>

        <div>
          {!hideCartItemEventHint && (
            <>
              <LYSTypography.Text className={style.cartEventWarning}>
                {warningMessage}
              </LYSTypography.Text>
              <br />
            </>
          )}
          <div className={style.infoColumn}>
            <div className={style.productName}>
              <ProductDetailLink slug={variant.slug} id={variant.id}>
                <LYSTypography.Text strong={true}>
                  {product.name}
                </LYSTypography.Text>
                <br />
              </ProductDetailLink>
            </div>
            <div>
              <LYSTypography.Text>{product.variant.name}</LYSTypography.Text>
            </div>
          </div>
        </div>
      </LYSRow>
    </LYSList.Item>
  )
}

export default UnavailableCartItem
