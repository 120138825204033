import { BackTop } from 'antd'
import { UpOutlined } from '@ant-design/icons'
import config from '@core/config/config'
import { useTranslation } from '@core/i18n/i18n'
import style from './LYSBackTopButton.module.less'
import LYSButton from '../LYSButton'
import { usePageContext } from '@core/utils/pages/PageContext'

const LYSBackTopButton: React.FC = (props) => {
  const { t } = useTranslation()
  const {
    props: { isMobile },
  } = usePageContext()

  return (
    <BackTop {...props}>
      <LYSButton
        size="small"
        className={style.button}
        shape="round"
        icon={<UpOutlined rev={undefined} />}
      >
        {config.features.scrollToTopButton.withText &&
          !isMobile &&
          t('scrollToTopButtonText')}
      </LYSButton>
    </BackTop>
  )
}

export default LYSBackTopButton
