import cn from 'classnames'
import style from './LYSSpacer.module.less'
interface Props {
  className?: string
}

const LYSSpacer: React.FC<Props> = ({ className }) => {
  return <div className={cn(style.spacer, className)} />
}

export default LYSSpacer
