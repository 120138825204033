import { INewsletterSubscription } from '@core/api/NewsletterSubscription/types'
import {
  showErrorNotification,
  showNotification,
} from '@core/components/Notification'
import { i18n } from '@core/i18n/i18n'
import thunk from '@core/store/thunk'
import { sanitizeModel } from '@core/utils/models/sanitizeModel'

export const subscribeNewsletter = (
  data: INewsletterSubscription,
  disableNotification?: boolean
) =>
  thunk(async (_dispatch, _getState, dependencies) => {
    try {
      data = sanitizeModel(data)
      await dependencies.api.newsletterSubscription.subscribe(data)
      if (!disableNotification) {
        showNotification(
          i18n.t('notification.newsletter.subscribe.success'),
          'success'
        )
      }
    } catch (error) {
      if (error.response?.status === 409) {
        showErrorNotification(
          error,
          i18n.t('notification.newsletter.subscribe.warning')
        )
      } else {
        showErrorNotification(error, 'notification.newsletter.subscribe.error')
      }
    }
  })

export const unsubscribeNewsletter = (id: string) =>
  thunk(async (_dispatch, _getState, dependencies) => {
    try {
      await dependencies.api.newsletterSubscription.unsubscribe(id)

      showNotification(
        i18n.t('notification.newsletter.unsubscribe.success'),
        'success'
      )
    } catch (error) {
      showErrorNotification(error, 'notification.newsletter.unsubscribe.error')
    }
  })
