import * as React from 'react'
import { Spin } from 'antd'
import cn from 'classnames'
import style from './index.module.less'

interface Props {
  loading?: boolean
  className?: string
}

const LYSLoading: React.FC<Props> = ({ loading, className, children }) => {
  return (
    <Spin
      spinning={loading !== undefined ? loading : true}
      className={cn(style.loading, className)}
    >
      {children}
    </Spin>
  )
}

export default LYSLoading
