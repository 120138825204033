import * as React from 'react'
import { useState } from 'react'
import { CloseOutlined } from '@ant-design/icons'
import { LYSButton } from '@core/components/Primitives'
import RichTextElement from '@core/prismic/components/RichTextElement'
import { IInfoBannerContent } from '@core/prismic/types'
import { resolvePrismicLink } from '@core/prismic/util'
import style from './index.module.less'

interface Props {
  infoBanner?: IInfoBannerContent
}

const PrismicInfoBanner: React.FC<Props> = ({ infoBanner }) => {
  const [showBanner, setshowBanner] = useState(true)
  if (!infoBanner) return null
  const { url, target } = resolvePrismicLink(infoBanner.data.button_link)

  const bannerStyle = {
    backgroundColor: `${infoBanner.data.background_color}`,
    color: `${infoBanner.data.text_color}`,
  }

  const buttonStyle = {
    backgroundColor: `${infoBanner.data.button_background_color}`,
    color: `${infoBanner.data.button_text_color}`,
  }

  const hideBanner = () => {
    setshowBanner(false)
  }

  const hasButton = infoBanner.data.button_text.length > 0

  return showBanner ? (
    <div style={bannerStyle} className={style.bannerWrapper}>
      <div className={style.contentWrapper}>
        <RichTextElement element={infoBanner.data.info_text} />
        {hasButton && (
          <a href={url} target={target}>
            <LYSButton
              size="small"
              style={buttonStyle}
              className={style.button}
            >
              <RichTextElement element={infoBanner.data.button_text} />
            </LYSButton>
          </a>
        )}
      </div>
      <LYSButton
        style={{ color: `${infoBanner.data.text_color}` }}
        className={style.closeIcon}
        icon={<CloseOutlined rev={undefined} />}
        size="small"
        type={'link'}
        onClick={hideBanner}
      />
    </div>
  ) : null
}

export default PrismicInfoBanner
