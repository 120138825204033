import React from 'react'
import { Radio } from 'antd'
import { RadioProps } from 'antd/lib/radio'

export type Props = RadioProps

const LYSRadio: React.FC<Props> = React.forwardRef<HTMLElement, Props>(
  (props, ref?) => <Radio ref={ref} {...props} />
)

export default LYSRadio
