export enum PageType {
  generic = 'generic',
  category = 'core/category',
  product = 'core/product',
  brand = 'core/brand',
  award = 'core/award',
  cart = 'core/cart',
  checkoutAddress = 'core/checkout/address',
  checkoutShipping = 'core/checkout/shipping',
  checkoutPayment = 'core/checkout/payment',
  checkoutOverview = 'core/checkout/overview',
  checkoutResult = 'core/checkout/result',
}

export type AsyncProps<Type> = Type extends Promise<infer X> ? X : never
