import * as React from 'react'
import { Radio } from 'antd'
import { RadioGroupProps } from 'antd/lib/radio'

export type Props = RadioGroupProps

const LYSRadioGroup: React.FC<Props> = React.forwardRef<HTMLDivElement, Props>(
  (props, ref?) => {
    return <Radio.Group ref={ref} {...props} />
  }
)

export default LYSRadioGroup
