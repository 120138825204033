import * as React from 'react'
import { Badge } from 'antd'
import { ShoppingCartOutlined } from '@ant-design/icons'
import { LYSButton } from '@core/components/Primitives'
import { LYSButtonProps } from '@core/components/Primitives/LYSButton'
import style from './index.module.less'
import config from '@core/config/config'
import { ButtonType } from 'antd/lib/button'
import { usePageContext } from '@core/utils/pages/PageContext'

export interface QuickCartButtonProps {
  onClick: () => void
  className?: string
  itemsInCart: number
}
export const QuickCartButton = ({
  onClick,
  itemsInCart,
  ...props
}: LYSButtonProps & QuickCartButtonProps) => {
  const {
    props: { isMobile },
  } = usePageContext()
  return (
    <Badge
      count={itemsInCart}
      offset={
        config.features.header.buttonType === 'link' ? [-10, 10] : [-4, 4]
      }
      className={style.badge}
    >
      <LYSButton
        data-testid="cart_button"
        type={config.features.header.buttonType as ButtonType}
        size={isMobile ? 'middle' : 'large'}
        ghost
        onClick={onClick}
        icon={<ShoppingCartOutlined rev={undefined} />}
        {...props}
      />
    </Badge>
  )
}
