import React from 'react'
import { LYSRow } from '@core/components/Primitives'
import style from './index.module.less'

interface Props {
  justify: 'start' | 'end' | 'center' | 'space-around' | 'space-between'
}

const ButtonLayout: React.FC<Props> = (props) => {
  const { justify, children } = props

  return (
    <LYSRow className={style.buttonContainer} justify={justify}>
      {children}
    </LYSRow>
  )
}

export default ButtonLayout
