import { CookieSerializeOptions } from 'cookie'
import { NextPageContext } from 'next'
import { destroyCookie, parseCookies, setCookie } from 'nookies'

export const AUTH_TOKEN = 'jwt'
export const AUTH_REFRESH_TOKEN = 'refresh_token'
export const CART_ID = 'cart_id'
export const PR_MODAL_ACKNOWLEDGED = 'pr_modal_hidden'

interface CookieCache {
  [key: string]: string | undefined
}

export default class CookiesFacade {
  // The cache saves some performance but is also required for SSR:
  // If we need to change or delete a cookie during SSR the nookie library will
  // only manipulate the `Set-Cookie` header in the response, but not destroy the
  // cookie in the request header which it reads its cookies from. So this is kind
  // of a temporary cookie storage for the lifetime of a request or a browser
  // session
  private _cache: CookieCache = {}

  constructor(private _ctx?: NextPageContext) {
    this._cache = parseCookies(_ctx)
  }

  public get(key: string) {
    return this._cache[key]
  }

  public set(key: string, value: string, options?: CookieSerializeOptions) {
    // location is not defined on server
    if (this._ctx && !this._ctx.isServer) {
      const domain =
        location.hostname === 'localhost' ? undefined : location.hostname

      options = {
        ...options,
        domain,
      }
    }
    // Path in browser is always root, however on the server-side it becomes
    // the current URL. Since we don't need this behaviour we can set it to
    // root always unless it was explicitly overridden
    options = {
      ...options,
      path: options?.path || '/',
    }

    setCookie(this._ctx, key, value, options)
    this._cache[key] = value
  }

  public remove(key: string, options?: CookieSerializeOptions) {
    options = {
      ...options,
      path: options?.path || '/',
    }
    destroyCookie(this._ctx, key, options)
    delete this._cache[key]
  }
}
