import React, { useContext } from 'react'
import Link from 'next/link'
import { IProduct, IProductSearch } from '@core/api/Products/types'
import PromotionContext from '@core/components/shared/PromotionContext'
import routes from '@core/config/routes'
import { productClickEvent } from '@core/events/product'
import { useServices } from '@core/utils/ioc'
import { usePageContext } from '@core/utils/pages/PageContext'

interface Props {
  slug: string
  id: string
  product?: IProduct | IProductSearch
}

const ProductDetailLink: React.FC<Props> = ({
  slug,
  id,
  children,
  product,
}) => {
  const productRoute = routes.productDetail(slug, id)
  const promotionContext = useContext(PromotionContext)
  const pageContext = usePageContext()
  const { eventBus } = useServices()

  const handleClick = () => {
    if (product && promotionContext) {
      eventBus.publish(
        productClickEvent(product, { ...promotionContext }, pageContext)
      )
    }
  }

  return (
    <Link href={productRoute.href} as={productRoute.as}>
      <span onClick={handleClick}>{children}</span>
    </Link>
  )
}

export default ProductDetailLink
