import { ITaxonomy } from '@core/api/Categories/types'
import config from '@core/config/config'
import thunk from '@core/store/thunk'

export enum ActionTypes {
  SET_CATEGORY_NAVIGATION = 'SET_CATEGORY_NAVIGATION',
}

export interface ISetCategoryNavigationAction {
  type: ActionTypes.SET_CATEGORY_NAVIGATION
  payload: ITaxonomy
}

export const fetchCategoryNavigation = () =>
  thunk(
    async (
      dispatch,
      _getState,
      dependencies
    ): Promise<ITaxonomy | undefined> => {
      const rootCategory = await dependencies.api.categories.fetchCategoryTree(
        config.categoryNavigation.depth
      )
      if (rootCategory) {
        dispatch(setCategoryNavigation(rootCategory))
        return rootCategory
      }
    }
  )

export function setCategoryNavigation(
  categories: ITaxonomy
): ISetCategoryNavigationAction {
  return {
    type: ActionTypes.SET_CATEGORY_NAVIGATION,
    payload: categories,
  }
}

export type Action = ISetCategoryNavigationAction
