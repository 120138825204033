import * as React from 'react'
import cn from 'classnames'
import style from './index.module.less'

export enum ContainerWidth {
  Default = 'default',
  Narrow = 'narrow',
  UltraNarrow = 'ultra-narrow',
}

interface OwnProps {
  id?: string
  padded?: boolean | { x?: boolean; y?: boolean }
  background?: string
  width?: ContainerWidth
  stretch?: boolean
  className?: string
}

const LYSContainer: React.FC<OwnProps> = ({
  id,
  padded,
  children,
  className,
  width,
  background,
  stretch,
}) => {
  return (
    <div
      id={id}
      style={{
        backgroundColor: background,
      }}
      className={cn(
        style.container,
        className,
        width ? style[width] : '',
        stretch && style.stretch
      )}
    >
      {padded ? (
        <div
          className={cn(
            typeof padded === 'boolean' && style.padding,
            typeof padded !== 'boolean' && !!padded.x && style.paddingX,
            typeof padded !== 'boolean' && !!padded.y && style.paddingY
          )}
        >
          {children}
        </div>
      ) : (
        children
      )}
    </div>
  )
}

export default LYSContainer
