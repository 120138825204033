import { AppContext } from 'next/app'
import { NextRouter } from 'next/router'
import Api from '@core/api'
import ProductQueryService from '@core/components/ProductCollection/ProductQueryService'
import PrismicApi from '@core/prismic/PrismicApi'
import { init } from '@core/store/app/actions'
import configureStore, { ShopStore } from '@core/store/configureStore'
import { IAppState } from '@core/store/types'
import { getUserChannelId } from '@core/store/user/selectors'
import { Environment } from '@core/utils/Environment'
import { Cache } from '@core/utils/caching/Cache'
import { ConsentService } from '@core/utils/consent'
import CookiesFacade, { AUTH_TOKEN } from '@core/utils/cookies'
import EventBus from '@core/utils/eventbus'
import { ProductContextService } from '@core/utils/models/product/ProductContextService'
import { PreferencesService } from '@core/utils/preferences'
import { createShopRouter, ShopRouter } from '@core/utils/routing/ShopRouter'
import config from './config'

export interface IAppProps {
  initialState?: IAppState
  [key: string]: any
}

export interface IShopServices {
  cookies: CookiesFacade
  api: Api
  store: ShopStore
  eventBus: EventBus
  router: ShopRouter
  environment: Environment
  consent: ConsentService
  prismicApi: PrismicApi
  productQueryService: ProductQueryService
  preferences: PreferencesService
  productContextService: ProductContextService
}

export const setupServices = (
  isServer: boolean,
  ctx: AppContext | undefined,
  appProps: IAppProps | undefined,
  nextRouter: NextRouter
): IShopServices => {
  const cookies = new CookiesFacade(ctx?.ctx)
  const environment = Environment.default
  const cache = isServer
    ? getServerCache(cookies, nextRouter)
    : createClientCache(() => store)
  const api = new Api({
    cookies,
    apiKey: environment.get('API_KEY')!,
    apiUrl: environment.get('API_URL')! + config.shopApi,
    apiTimeout: config.apiTimeout,
    cache: cache,
  })
  const eventBus = new EventBus()
  const router = createShopRouter(nextRouter)
  const consent = new ConsentService(eventBus)

  const storeDependencies = {
    environment,
    cookies,
    api,
    router,
    eventBus,
  }

  const initialState = appProps?.initialState
  const store = configureStore(initialState, storeDependencies)

  const prismicApi = new PrismicApi(store, cache, cookies)
  const preferences = new PreferencesService(cookies)
  const productQueryService = new ProductQueryService(
    store,
    router,
    api,
    preferences
  )
  const productContextService = new ProductContextService()

  // Run init action only in browser
  if (!isServer) {
    store.dispatch(init())
  }

  return {
    cookies,
    api,
    store,
    eventBus,
    router,
    environment,
    consent,
    prismicApi,
    productQueryService,
    preferences,
    productContextService,
  }
}

/**
 * Creates a channel aware cache implementation
 */
const createClientCache = (storeProvider: () => ShopStore | undefined) => {
  return new Cache({
    keyFunction(key: string) {
      const store = storeProvider()
      const channelId =
        (store && getUserChannelId(store.getState())) || 'default'
      return `${key}|${channelId}`
    },
  })
}

const serverCache = new Cache({
  maxTtl: 1000 * 60 * 15,
  maxEntries: 100,
})

const getServerCache = (cookies: CookiesFacade, nextRouter: NextRouter) => {
  // As soon as the user has a JWT they might be in a different channel or
  // could be shown user-specific content - in that case do not apply caching
  if (cookies.get(AUTH_TOKEN)) return

  // Adding a query param `nocache` disables the server cache
  // e.g. for testing changes to Prismic content immediately
  if ('nocache' in nextRouter.query) return

  return serverCache
}
