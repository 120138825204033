import React from 'react'
import Link from 'next/link'
import routes from '@core/config/routes'
import { useServices } from '@core/utils/ioc'

const LoginLink: React.FC = (props) => {
  const { children } = props
  const { router } = useServices()

  return (
    <Link {...routes.login(router.asPath)}>
      <a rel="nofollow">{children}</a>
    </Link>
  )
}

export default LoginLink
