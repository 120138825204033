import { IModalsState } from '@core/store/modals/types'
import { Action, ActionTypes, ModalType } from './actions'

const initialState: IModalsState = {
  modalQueue: [],
  activeModal: undefined,
}

export const MODAL_PRIORITY = [
  ModalType.PRICE_REQUEST,
  ModalType.PRICE_REQUEST_PHONE_NUMBER,
  ModalType.CART_UPDATE,
]

function sortQueueByPriority(a: ModalType, b: ModalType) {
  return MODAL_PRIORITY.indexOf(a) - MODAL_PRIORITY.indexOf(b)
}

export default function modalsReducer(
  state: IModalsState = initialState,
  action: Action
) {
  switch (action.type) {
    case ActionTypes.SHOW_MODAL: {
      if (state.activeModal) {
        return {
          ...state,
          modalQueue: [...state.modalQueue, action.payload].sort(
            sortQueueByPriority
          ),
        }
      } else {
        return {
          ...state,
          activeModal: action.payload,
        }
      }
    }

    case ActionTypes.DISMISS_MODAL: {
      const { payload: modalToHide } = action
      const tmpmodalQueue = [...state.modalQueue]

      if (modalToHide) {
        if (tmpmodalQueue.includes(modalToHide)) {
          tmpmodalQueue.splice(
            tmpmodalQueue.findIndex((el) => el === modalToHide)
          )
        } else if (state.activeModal !== modalToHide) {
          return state
        }
      }

      const [next, ...rest] = tmpmodalQueue

      return {
        activeModal: next,
        modalQueue: rest,
      }
    }

    case ActionTypes.CLEAR_QUEUE: {
      return {
        ...state,
        modalQueue: [],
      }
    }

    default:
      return state
  }
}
