import dayjs from 'dayjs'
import 'dayjs/locale/de'
import 'dayjs/locale/nl'
import 'dayjs/locale/en'
import i18nConfig from '@core/config/i18n'

const locale = i18nConfig.language

export const formatLongDay = (date: string): string =>
  dayjs(date).locale(locale).format('dddd')

export const formatDateMonth = (date: string): string =>
  dayjs(date).locale(locale).format('DD. MMMM')

export const formatDateTime = (date: string): string =>
  dayjs(date).locale(locale).format('DD. MMMM YY HH:mm')

export const formatDate = (date: string): string =>
  dayjs(date).locale(locale).format('DD. MMMM YYYY')

export const formatDateLongMonth = (date: string): string =>
  dayjs(date).locale(locale).format('dd DD. MMMM')

export const isPastDate = (date: string): boolean =>
  dayjs(date).diff(dayjs(), 'day') < 0

export const formatAccountDate = (date: string): string =>
  dayjs(date).locale(locale).format('DD. MMMM YYYY')

export const formatDateMonthYearRegular = (date: string): string =>
  dayjs(date).locale(locale).format('DD.MM.YYYY')
