/* eslint-disable react-hooks/exhaustive-deps */
import * as React from 'react'
import { ReactNode, useContext, useMemo } from 'react'

export type RenderProp<TProps> = (
  originalChildren: ReactNode,
  passedProps?: TProps,
  drilledProps?: any
) => ReactNode

export type TypedChildrenRenderFunction<TProps> = (
  passedProps?: TProps,
  drilledProps?: any
) => React.ReactElement | null

interface SlotProps<TProps = void> {
  children?: ReactNode | TypedChildrenRenderFunction<TProps>
  render: RenderProp<TProps> | undefined
  props?: TProps
}

export const Slot = <TProps,>(props: SlotProps<TProps>) => {
  const { render, children, props: passedProps, ...drilledProps } = props

  return render !== undefined
    ? render(children, passedProps, drilledProps)
    : typeof children === 'function'
    ? children(passedProps, drilledProps)
    : // Relay HOC props from Slot parent to single child if possible
    React.Children.count(children) === 1 && React.isValidElement(children)
    ? React.cloneElement(children as JSX.Element, {
        ...(children as JSX.Element).props,
        ...drilledProps,
      })
    : children || null
}

type ComponentsProvider<TComponents> = (defaults: TComponents) => TComponents

type PublicTemplateProps<TProps, TSlots, TComponents> = TProps & {
  slots?: Partial<TSlots>
  components?: Partial<TComponents>
}

type PrivateTemplateProps<TProps, TSlots, TComponents> = PublicTemplateProps<
  TProps,
  TSlots,
  TComponents
> & {
  getComponents: ComponentsProvider<TComponents>
}

type PublicTemplateComponent<TProps, TSlots, TComponents = any> = React.FC<
  PublicTemplateProps<TProps, TSlots, TComponents>
>

type PrivateTemplateComponent<TProps, TSlots, TComponents = any> = React.FC<
  PrivateTemplateProps<TProps, TSlots, TComponents>
>

interface TemplateExtension {
  slots: { [key: string]: any }
  components: { [key: string]: any }
}

type TemplateExtensionMap = Map<
  PublicTemplateComponent<any, any>,
  TemplateExtension
>

interface TemplateContextData {
  extensions: TemplateExtensionMap
}

const TemplateContextObject = React.createContext<TemplateContextData>({
  extensions: new Map(),
})

export const useTemplateContext = () => useContext(TemplateContextObject)

export class TemplateContext {
  private extensions: TemplateExtensionMap = new Map()

  extend<TProps, TSlots, TComponents>(
    component: PublicTemplateComponent<TProps, TSlots, TComponents>,
    extension: {
      slots?: Partial<TSlots>
      components?: Partial<TComponents>
    }
  ): TemplateContext {
    this.extensions.set(component, {
      slots: extension.slots || {},
      components: extension.components || {},
    })
    return this
  }

  Provider: React.FC = ({ children }) => {
    return (
      <TemplateContextObject.Provider value={{ extensions: this.extensions }}>
        {children}
      </TemplateContextObject.Provider>
    )
  }
}

export const template = <TProps, TSlots, TComponents = any>(
  Component: PrivateTemplateComponent<TProps, TSlots, TComponents>
): PublicTemplateComponent<TProps, TSlots, TComponents> => {
  const TemplateWrapper: PublicTemplateComponent<TProps, TSlots, TComponents> =
    (props) => {
      const context = useTemplateContext()
      const extension = context.extensions.get(TemplateWrapper)
      const propSlots = Object.values<any>(props.slots || {})
      const propComponents = Object.values<any>(props.components || {})

      const slots = useMemo(
        () => ({ ...extension?.slots, ...props.slots }),
        [extension, ...propSlots]
      )

      const components = useMemo(
        () => ({ ...extension?.components, ...props.components }),
        [extension, ...propComponents]
      )

      const getComponents = useMemo(
        () => (defaults: TComponents) => ({
          ...defaults,
          ...components,
        }),
        [...propComponents]
      )

      const enhancedProps = { ...props, slots, components, getComponents }

      return <Component {...enhancedProps} />
    }
  return TemplateWrapper
}
