import * as React from 'react'
import { ICartItem } from '@core/api/Cart/types'
import { LYSTypography, LYSRow, LYSCol } from '@core/components/Primitives'
import ProductDetailLink from '@core/components/ProductCollection/ProductDetailLink'
import { getGhsClassImage } from '@core/config/config.helper'
import { useTranslation } from '@core/i18n/i18n'
import style from './index.module.less'

interface Props {
  cartItem: ICartItem
}

const CartItemHazardWarning: React.FC<Props> = ({ cartItem }) => {
  const { t } = useTranslation()

  return (
    <LYSRow className={style.hazardHint}>
      <LYSCol xs={24}>
        <LYSTypography.Text type={'secondary'}>
          {t('cart.item.hazardHint')}{' '}
          <ProductDetailLink
            slug={cartItem.product.variant.slug}
            id={cartItem.product.variant.id}
          >
            {t('general.link.pleaseClickHere')}
          </ProductDetailLink>
        </LYSTypography.Text>
      </LYSCol>

      <LYSCol xs={24} className={style.ghsImageWrap}>
        {cartItem.product.ghsClasses.map((ghsClass) => {
          const image = getGhsClassImage(ghsClass.code)
          if (image) {
            return (
              <img
                className={style.ghsImage}
                alt={ghsClass.label[0]}
                key={ghsClass.code}
                src={image}
                loading="lazy"
              />
            )
          }
        })}
      </LYSCol>
    </LYSRow>
  )
}

export default CartItemHazardWarning
