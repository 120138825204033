import * as React from 'react'
import Link from 'next/link'

interface Props {
  textLabel?: string
  href: string
  as?: string
  newTab?: boolean
  children?: React.ReactChild
  nofollow?: boolean
  dataTestId?: string
}

const FooterLink: React.FC<Props> = (props) => {
  const { textLabel, href, as, newTab, children, nofollow, dataTestId } = props

  return (
    <div>
      {href.indexOf('http') === -1 && href.indexOf('www') === -1 ? (
        <Link href={href} as={as} prefetch={false}>
          <a
            target={newTab ? 'blank' : undefined}
            rel={nofollow ? 'nofollow' : undefined}
            data-testid={dataTestId}
          >
            {children ? children : textLabel}
          </a>
        </Link>
      ) : (
        <a
          href={href}
          target={newTab ? 'blank' : undefined}
          rel={
            newTab ? 'noopener noreferrer' : nofollow ? 'nofollow' : undefined
          }
          data-testid={dataTestId}
        >
          {children ? children : textLabel}
        </a>
      )}
    </div>
  )
}

export default FooterLink
