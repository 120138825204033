import Link from 'next/link'
import { RightOutlined } from '@ant-design/icons'
import { LYSButton, LYSIcon, LYSTypography } from '@core/components/Primitives'
import { useTranslation } from '@core/i18n/i18n'
import style from './index.module.less'
import useChannel from '@core/hooks/channel/useChannel'
import routes from '@core/config/routes'
import { WarningOutlined } from '@ant-design/icons'
const CartMenuButtons: React.FC = () => {
  const { t } = useTranslation()
  const { isOpenChannel } = useChannel()

  return (
    <div className={style.wrap}>
      <div className={style.button}>
        {isOpenChannel ? (
          <>
            <div className={style.openShopWarning}>
              <LYSIcon component={WarningOutlined} className={style.icon} />
              <LYSTypography.Text className={style.warningText}>
                <Link {...routes.registerClosedShop}>
                  Jetzt Servicepartner werden oder einloggen.
                </Link>{' '}
                Um Bestellung abzuschließen.
              </LYSTypography.Text>
            </div>
          </>
        ) : (
          <Link href="/cart" prefetch={false}>
            <LYSButton
              type="primary"
              block={true}
              className={style.shoppingButton}
              data-testid="edit-cart"
            >
              {t('cart.menu.editCartText')}

              <LYSIcon component={RightOutlined} />
            </LYSButton>
          </Link>
        )}
      </div>
    </div>
  )
}

export default CartMenuButtons
