import qs from 'qs'
import Api from '@core/api'
import {
  IProductsQuery,
  HAS_PRICE_KEY,
  IN_STOCK_KEY,
  OWN_BRANDS_KEY,
  HAS_AWARDS_KEY,
  ONLY_PURCHASED_KEY,
  ONLY_RECENTLY_PURCHASED_KEY,
  ON_PRODUCT_LIST_KEY,
} from '@core/api/Products/types'
import parseProductCollectionQuery from '@core/components/ProductCollection/parseProductCollectionQuery'
import config from '@core/config/config'
import { getCategoryNavigation } from '@core/store/categories/selectors'
import { ShopStore } from '@core/store/configureStore'
import { PreferencesService, Preferences } from '@core/utils/preferences'
import { ShopRouter } from '@core/utils/routing/ShopRouter'

interface IDefaultFilters {
  [key: string]: any
}

class ProductQueryService {
  constructor(
    protected store: ShopStore,
    protected router: ShopRouter,
    protected api: Api,
    protected preferences: PreferencesService
  ) {}

  private get defaultFilters() {
    const hasPriceFilter =
      config.features.filters.showOnlyWithPriceFilter &&
      this.preferences.get(Preferences.ONLY_WITH_PRICE)
    const hasInStockFilter =
      config.features.filters.onStockFilter &&
      this.preferences.get(Preferences.IN_STOCK_KEY)
    const hasOwnBrandsFilter =
      config.features.filters.ownBrandsFilter &&
      this.preferences.get(Preferences.OWN_BRANDS_KEY)
    const hasAwardsFilter =
      config.features.filters.awardsFilter.active &&
      this.preferences.get(Preferences.HAS_AWARDS_KEY)
    const hasOnlyPurchasedProductsFilter =
      config.features.filters.showOnlyPurchasedProducts &&
      this.preferences.get(Preferences.ONLY_PURCHASED_KEY)
    const hasOnlyRecentlyPurchasedProductsFilter =
      config.features.filters.showOnlyRecentlyPurchasedProducts &&
      this.preferences.get(Preferences.ONLY_RECENTLY_PURCHASED_KEY)
    const hasOnProductListFilter =
      config.features.filters.showOnProductListFilter &&
      this.preferences.get(Preferences.ON_PRODUCT_LIST_KEY)

    const defaultFilterObject: IDefaultFilters = {}

    if (hasPriceFilter) defaultFilterObject[HAS_PRICE_KEY] = '1'
    if (hasInStockFilter) defaultFilterObject[IN_STOCK_KEY] = '1'
    if (hasOwnBrandsFilter) defaultFilterObject[OWN_BRANDS_KEY] = '1'
    if (hasAwardsFilter) defaultFilterObject[HAS_AWARDS_KEY] = '1'
    if (hasOnlyPurchasedProductsFilter)
      defaultFilterObject[ONLY_PURCHASED_KEY] = '1'
    if (hasOnlyRecentlyPurchasedProductsFilter)
      defaultFilterObject[ONLY_RECENTLY_PURCHASED_KEY] = '1'
    if (hasOnProductListFilter) defaultFilterObject[ON_PRODUCT_LIST_KEY] = '1'

    return defaultFilterObject
  }

  parseQuery(url?: string) {
    url = url || this.router.asPath
    return parseProductCollectionQuery(url, this.defaultFilters)
  }

  parseQueryWithTaxonomy(taxonomyId: string, url?: string) {
    const query = this.parseQuery(url)
    return {
      ...query,
      filter: {
        ...query.filter,
        taxonomy: taxonomyId,
      },
    }
  }

  parseQueryWithBrand(brandId: string, url?: string) {
    const query = this.parseQuery(url)
    return {
      ...query,
      filter: {
        ...query.filter,
        brandId,
      },
    }
  }

  parseQueryWithAward(awardId: string, url?: string) {
    const query = this.parseQuery(url)
    return {
      ...query,
      filter: {
        ...query.filter,
        awardId,
      },
    }
  }

  async runProductsQuery(query: IProductsQuery = this.parseQuery()) {
    const preparedQuery = this.prepareApiQuery(query)

    const data = await this.api.products.fetchProducts({
      ...preparedQuery,
      requiresAggregations: 0,
      track: 1,
    })
    return data
  }

  async runAggregationsQuery(query: IProductsQuery = this.parseQuery()) {
    const preparedQuery = this.prepareApiQuery(query)

    /**
     * resetting limit and page because:
     * - aggregations are not meant to change if limit or page changes. Due to our axios caching
     *   this results in using the cached version on page change and not request the aggregations
     * - limit 0 to really only fetch the aggregations and not trigger the products operation
     */
    const data = await this.api.products.fetchProducts({
      ...preparedQuery,
      limit: 0,
      page: 1,
      requiresAggregations: 1,
    })
    return data
  }

  setQuery(newQuery: IProductsQuery) {
    const router = this.router
    const newFullQuery = {
      ...router.query,
      ...{ filter: undefined },
      ...{ order: undefined },
      ...newQuery,
    }
    const stringifiedBuiltQuery = qs.stringify(newQuery)
    const stringifiedFullQuery = qs.stringify(newFullQuery)
    router.pushAndScrollTop(
      `${router.pathname}?${stringifiedFullQuery}`,
      // replace the query in the asPath
      `${router.asPath.split('?')[0]}?${stringifiedBuiltQuery}`
    )
  }

  private prepareApiQuery(baseQuery: IProductsQuery) {
    const apiQuery = { ...baseQuery }
    const rootTaxonomy = getCategoryNavigation(this.store.getState())

    if (apiQuery.search && this.validExactProductSearch(apiQuery)) {
      apiQuery.filter = {
        ...(apiQuery.filter || {}),
        sku: apiQuery.search,
      }
    }

    // API needs a taxonomy set to return filter aggregations
    // If there is no filtered taxonomy we set a fallback taxonomy
    if (!apiQuery.filter?.taxonomy && rootTaxonomy) {
      apiQuery.filter = {
        ...(apiQuery.filter || {}),
        taxonomy: rootTaxonomy.id,
      }
    }

    return apiQuery
  }

  exactProductSearchEnabled(): boolean {
    return config.features.exactProductSearchEnabled
  }

  searchTextIsProductNumber(searchText: string): boolean {
    const productNumberPattern = config.features.productNumberRegex
    return productNumberPattern.test(searchText)
  }

  searchTextMightBeProductNumber(searchText: string): boolean {
    const loosePattern = config.features.looseProductNumberRegex
    return loosePattern ? loosePattern.test(searchText) : false
  }

  validExactProductSearch(query: IProductsQuery): boolean {
    return (
      query.search !== undefined &&
      this.exactProductSearchEnabled() &&
      this.searchTextIsProductNumber(query.search)
    )
  }
}

export default ProductQueryService
