import { ServerResponse } from 'http'
import { NextPageContext } from 'next'
import Router from 'next/router'
import { Route } from '@core/config/routes'
import { getCleanUrlWithoutParams, getQueryParamsString } from '@core/utils/url'

export const redirectOrPush = (ctx: NextPageContext, redirectUrl: string) => {
  if (redirectUrl) {
    if (ctx.isServer) {
      ctx.res!.writeHead(302, {
        Location: redirectUrl,
      })
      ctx.res!.end()
    } else {
      Router.push(redirectUrl)
    }
  }
}

export const externalRedirect = (url: string) => {
  window.location.assign(url)
}

export const notFound = (res?: ServerResponse) => {
  if (res) {
    res.statusCode = 404
  }
  return {}
}

/**
 * Verify that a content item (product, brand, category...) is accessed through
 * its single current URL and if not, permanently redirect to the current URL
 * @param ctx
 * @param expectedRoute
 */
export const ensureContentUrl = (
  ctx: NextPageContext,
  expectedRoute: string | Route
) => {
  if (!ctx.res) return

  const expectedPath =
    typeof expectedRoute === 'string'
      ? expectedRoute
      : expectedRoute.as || expectedRoute.href

  // Need to remove query params here
  const actualPath = getCleanUrlWithoutParams(ctx.req?.url ?? ctx.asPath ?? '')

  if (actualPath !== expectedPath) {
    // Append original query params
    const query = getQueryParamsString(ctx.asPath || '')

    ctx.res.writeHead(301, {
      Location: expectedPath + (query ? `?${query}` : ''),
    })
    ctx.res.end()
  }
}
