import {
  ICartItem,
  ICartItemProductInformation,
  ICartItemVariantInformation,
} from '@core/api/Cart/types'
import {
  IPriceRequestProduct,
  IPriceRequestVariant,
} from '@core/api/PriceRequests/types'
import {
  IProductListItem,
  IProductListItemProductInformation,
  IProductListItemVariantInformation,
} from '@core/api/ProductLists/types'
import {
  IPackagingUnit,
  IProduct,
  IProductCollection,
  IProductsCollectionQuery,
  IProductSearch,
  IStockInformation,
  ITaxonomyAggregationLeaf,
  IVariant,
  IVariantSearch,
  ProductViewType,
} from '@core/api/Products/types'

export enum ProductContextType {
  ProductDetail,
  ProductSearch,
  ProductListItem,
  ProductCartItem,
  ProductCollection,
  ProductPriceRequest,
}

export interface CommonProductRules {
  hideStockInformation: boolean
  isPurchasable: boolean
  isAllowedToAddToProductList: boolean
  displayNoPriceHint: boolean
}

export type CommonProductStockInformation = IStockInformation

export type CommonProductPackageUnitInformation = {
  packagingUnit: IPackagingUnit
  packagingUnits: IPackagingUnit[]
  setPackagingUnit?: (packagingUnit: IPackagingUnit) => void
}

export interface CommonProductContext {
  rules: CommonProductRules
  stock: CommonProductStockInformation
  packagingUnitsInformation: CommonProductPackageUnitInformation
}

export interface ProductDetailContext extends CommonProductContext {
  type: ProductContextType.ProductDetail
  product: IProduct
  variant: IVariant
}

export interface ProductSearchContext extends CommonProductContext {
  type: ProductContextType.ProductSearch
  product: IProductSearch
  variant: IVariantSearch
}
export interface ProductPriceRequestContext extends CommonProductContext {
  type: ProductContextType.ProductPriceRequest
  product: IPriceRequestProduct
  variant: IPriceRequestVariant
  createdAt: string
}

export interface ProductListItemContext extends CommonProductContext {
  type: ProductContextType.ProductListItem
  product: IProductListItemProductInformation
  variant: IProductListItemVariantInformation
  productListItem: IProductListItem
}

export interface ProductCartItemContext extends CommonProductContext {
  type: ProductContextType.ProductCartItem
  product: ICartItemProductInformation
  variant: ICartItemVariantInformation
  cartItem: ICartItem
}

export interface ProductCollectionContext {
  productCollection: IProductCollection
  queryToFetch: IProductsCollectionQuery
  setTaxonomyFilter: (taxonomyId: string) => void
  removeFilter: (key: string) => void
  taxonomyAggregationRoot?: ITaxonomyAggregationLeaf
  brandShortId?: string
  productView: ProductViewType
  showCategoryFilter?: boolean
  showSearchTermSuggestions?: boolean
  query: IProductsCollectionQuery
  hideControls?: boolean
  hidePagination?: boolean
  productCollectionProductsTotal?: number
  isLoading?: boolean
  setItemPerPageLimit: (_: any, newLimit: number) => void
  isSearchResultPage?: boolean
}

export type ProductContext =
  | ProductDetailContext
  | ProductSearchContext
  | ProductListItemContext
  | ProductCartItemContext
  | ProductPriceRequestContext
