import BaseAPI from '../Platform/BaseAPI'
import { IRecommendation, RecommendationTypes } from './types'

class Recommendations extends BaseAPI {
  public async fetchRecommendations(
    limit: number,
    variantIds?: string[]
  ): Promise<IRecommendation[]> {
    const variantIdsString =
      variantIds && variantIds?.length > 0
        ? `&variantIds=${variantIds?.join('&variantIds=')}`
        : ''

    const response = await this.api.get<IRecommendation[]>(
      `recommendations/v2?limit=${limit}${variantIdsString}`,
      {
        useCache: true,
        genericUrl: 'recommendations',
      }
    )
    const { data } = response
    return data
  }

  public async fetchRecommendationsByType(
    type: RecommendationTypes,
    limit?: number,
    variantIds?: string[]
  ): Promise<IRecommendation> {
    const variantIdsString =
      variantIds && variantIds?.length > 0
        ? `&variantIds=${variantIds?.join('&variantIds=')}`
        : ''

    const response = await this.api.get<IRecommendation>(
      `recommendations/${type}?limit=${limit || 10}${variantIdsString}`,
      {
        useCache:
          type === RecommendationTypes.LAST_VIEWED_VARIANTS ||
          type === RecommendationTypes.LAST_SEARCHED_TERMS
            ? false
            : true,
        genericUrl: 'recommendations',
      }
    )
    const { data } = response
    return data
  }
}

export default Recommendations
