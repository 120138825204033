import * as React from 'react'
import { LYSTypography } from '@core/components/Primitives'
import { useTranslation } from '@core/i18n/i18n'
import { RenderProp, Slot, template } from '@core/utils/templates'
import style from '@core/components/shared/ProductCard/index.module.less'

interface OwnProps {
  shortDescription?: string
  variationName?: string
  variantName?: string
  sku?: string
  skuProvidedBySupplier?: string
  gtin?: string
}

interface ProductShortInformationSlots {
  shortDescription?: RenderProp<void>
  variationName?: RenderProp<void>
  variantName?: RenderProp<void>
  sku?: RenderProp<void>
  gtin?: RenderProp<void>
  skuProvidedBySupplier?: RenderProp<void>
}

const ProductShortInformation = template<
  OwnProps,
  ProductShortInformationSlots
>(
  ({
    shortDescription,
    variationName,
    variantName,
    sku,
    gtin,
    skuProvidedBySupplier,
    slots,
  }) => {
    const { t } = useTranslation()

    return (
      <>
        <Slot render={slots?.shortDescription}>
          {shortDescription && (
            <LYSTypography.Paragraph className={style.paragraph} ellipsis>
              {shortDescription}
            </LYSTypography.Paragraph>
          )}
        </Slot>

        <Slot render={slots?.variationName}>
          {variationName && (
            <LYSTypography.Paragraph
              className={style.paragraph}
              type={'secondary'}
            >
              {t('product.productInformation.variationName')}: {variationName}
            </LYSTypography.Paragraph>
          )}
        </Slot>

        <Slot render={slots?.sku}>
          {sku && (
            <LYSTypography.Paragraph
              className={style.paragraph}
              type={'secondary'}
              data-testid={'product-information-sku'}
            >
              {t('product.productInformation.sku')}: {sku}
            </LYSTypography.Paragraph>
          )}
        </Slot>

        <Slot render={slots?.gtin}>
          {gtin && (
            <LYSTypography.Paragraph
              className={style.paragraph}
              type={'secondary'}
              data-testid={'product-information-gtin'}
            >
              {t('product.productInformation.gtin')}: {gtin}
            </LYSTypography.Paragraph>
          )}
        </Slot>

        <Slot render={slots?.variantName}>
          {variantName && (
            <LYSTypography.Paragraph
              className={style.paragraph}
              type={'secondary'}
              data-testid={'product-information-variantName'}
            >
              {t('product.productInformation.variantName')}: {variantName}
            </LYSTypography.Paragraph>
          )}
        </Slot>

        <Slot render={slots?.skuProvidedBySupplier}>
          {skuProvidedBySupplier && (
            <LYSTypography.Paragraph
              className={style.paragraph}
              type={'secondary'}
            >
              {t('product.productInformation.skuProvidedBySupplier')}:{' '}
              {skuProvidedBySupplier}
            </LYSTypography.Paragraph>
          )}
        </Slot>
      </>
    )
  }
)

export default ProductShortInformation
