import * as React from 'react'
import { Select } from 'antd'
import { RefSelectProps, SelectProps } from 'antd/lib/select'

// TODO: Support type hint for select options + value
export type Props = SelectProps<any>

const LYSSelect: React.FC<Props> = React.forwardRef<RefSelectProps, Props>(
  (props, ref?) => <Select ref={ref} {...props} />
)

export const LYSSelectOption = Select.Option

export default LYSSelect
