import React from 'react'
import Link from 'next/link'
import routes from '@core/config/routes'
import { LYSIcon, LYSTypography } from '../Primitives'
import styles from './AutoSuggestOptions.module.less'
import { AvailableSearchTypes } from './SearchTypeSwitch'

const TermIconOption: React.FC<{
  term: string
  icon: any
}> = ({ term, icon }) => (
  <div>
    <Link {...routes.searchResult(term, AvailableSearchTypes.PRODUCT)}>
      <a className={styles.searchIconContainer}>
        <LYSIcon className={styles.searchIcon} component={icon} />
        <LYSTypography.Text ellipsis>{term}</LYSTypography.Text>
      </a>
    </Link>
  </div>
)

export default TermIconOption
