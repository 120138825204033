import { ITaxonomy } from '@core/api/Categories/types'
import routes from '@core/config/routes'
import { useRouter } from 'next/router'

const useRedirectToCategoryPage = () => {
  const router = useRouter()

  const redirectToCategoryPage = (category: ITaxonomy) => {
    const categoryRoute = routes.taxonomy(category).as
    router.push(categoryRoute!)
  }

  return redirectToCategoryPage
}

export default useRedirectToCategoryPage
