import { useState } from 'react'
import { useDispatch, useSelector } from 'react-redux'
import { IProduct, IProductSearch } from '@core/api/Products/types'
import { showNotification } from '@core/components/Notification'
import useModals from '@core/hooks/modals/useModals'
import { useTranslation } from '@core/i18n/i18n'
import { ModalType } from '@core/store/modals/actions'
import {
  fetchPriceRequests,
  createPriceRequest,
  deletePriceRequest,
  acknowledgeAllPriceRequests,
} from '@core/store/priceRequests/actions'
import {
  getPriceRequests,
  getIsPendingPriceRequest,
  getAnsweredPriceRequests,
  getPendingPriceRequests,
  getNotificationPriceRequests,
} from '@core/store/priceRequests/selectors'
import { updateUser } from '@core/store/user/actions'
import { getHasPhoneNumber, isAuthenticated } from '@core/store/user/selectors'
import { PR_MODAL_ACKNOWLEDGED } from '@core/utils/cookies'
import { useServices } from '@core/utils/ioc'
import { usePageContext } from '@core/utils/pages/PageContext'
import { IPriceRequestProduct } from '@core/api/PriceRequests/types'

let tmpRequestedVariantId: string | null = null

const usePriceRequests = (
  product?: IProduct | IProductSearch | IPriceRequestProduct
) => {
  const {
    props: { featureFlags },
  } = usePageContext()
  const { t } = useTranslation()
  const dispatch = useDispatch()
  const { cookies } = useServices()
  const { show, hide } = useModals()
  const loggedIn = useSelector(isAuthenticated)
  const priceRequests = useSelector(getPriceRequests)
  const answeredPriceRequests = useSelector(getAnsweredPriceRequests)
  const notificationPriceRequests = useSelector(getNotificationPriceRequests)
  const pendingPriceRequests = useSelector(getPendingPriceRequests)
  const hasPhoneNumber = useSelector(getHasPhoneNumber)
  const productSku = product?.sku
  const isPendingPriceRequest = useSelector(
    getIsPendingPriceRequest(productSku || '')
  )

  const isRequestable =
    !product?.mainVariant.packagingUnits[0].price &&
    featureFlags.priceRequestsEnabled

  const showNotLoggedInMessage = () => {
    showNotification(t('priceRequest.notLoggedIn.notification'), 'warning')
  }

  const create = (providedVariantId: string) => {
    if (!loggedIn) {
      return showNotLoggedInMessage()
    }
    if (hasPhoneNumber) {
      dispatch(createPriceRequest(providedVariantId))
    } else {
      tmpRequestedVariantId = providedVariantId
      show(ModalType.PRICE_REQUEST_PHONE_NUMBER)
    }
  }

  const remove = (variantId: string) => {
    dispatch(deletePriceRequest(variantId))
  }

  const refetch = () => dispatch(fetchPriceRequests())

  // hide price request notification modal via cookie
  const hidePriceRequestModal = () => {
    hide(ModalType.PRICE_REQUEST)
    cookies.set(PR_MODAL_ACKNOWLEDGED, '1')
    setSessionPRModalHidden(true)
  }

  // api will change the price request status to `acknowledged`
  const acknowledgePriceRequests = () => {
    dispatch(acknowledgeAllPriceRequests())
  }

  const closePhoneNumberRequestModal = () => {
    tmpRequestedVariantId = null
    hide(ModalType.PRICE_REQUEST_PHONE_NUMBER)
  }

  const submitPhoneNumberAndCreateRequest = (phoneNumber: string) => {
    if (!loggedIn) {
      return showNotLoggedInMessage()
    }

    dispatch(
      updateUser({
        clientFields: {
          phone: phoneNumber,
        },
      })
    )

    if (tmpRequestedVariantId) {
      dispatch(createPriceRequest(tmpRequestedVariantId))
    }

    hide(ModalType.PRICE_REQUEST_PHONE_NUMBER)

    tmpRequestedVariantId = null
  }

  const [isSessionPRModalHidden, setSessionPRModalHidden] = useState<boolean>(
    !!cookies.get(PR_MODAL_ACKNOWLEDGED)
  )

  return {
    refetch,
    create,
    remove,
    priceRequests,
    answeredPriceRequests,
    notificationPriceRequests,
    pendingPriceRequests,
    isPendingPriceRequest,
    isRequestable,
    hidePriceRequestModal,
    acknowledgePriceRequests,
    closePhoneNumberRequestModal,
    submitPhoneNumberAndCreateRequest,
    isSessionPRModalHidden,
  }
}

export default usePriceRequests
