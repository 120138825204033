import { ITaxonomy } from '@core/api/Categories/types'
import { IProduct } from '@core/api/Products/types'
import { IGenericPageContext } from '@core/utils/pages/PageContext'
import { createAction } from '@reduxjs/toolkit'

export const viewPageEvent = createAction(
  'view/page',
  (pageContext: IGenericPageContext) => {
    return {
      payload: {
        pageContext,
      },
    }
  }
)

export const viewProductEvent = createAction(
  'view/product',
  (product: IProduct) => {
    return {
      payload: {
        product,
      },
    }
  }
)

export const viewCategoryEvent = createAction(
  'view/category',
  (category: ITaxonomy) => {
    return {
      payload: {
        category,
      },
    }
  }
)

export type IViewPageEvent = ReturnType<typeof viewPageEvent>
export type IViewProductEvent = ReturnType<typeof viewProductEvent>
export type IViewCategoryEvent = ReturnType<typeof viewCategoryEvent>

export default {
  viewPageEvent,
  viewProductEvent,
  viewCategoryEvent,
}
