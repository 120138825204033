import React from 'react'
import generatePicker from 'antd/lib/date-picker/generatePicker'
import {
  PickerProps,
  RangePickerProps,
} from 'antd/lib/date-picker/generatePicker'
import locale from 'antd/lib/date-picker/locale/de_DE'
import { Dayjs } from 'dayjs'
import dayjsGenerateConfig from 'rc-picker/lib/generate/dayjs'
import 'antd/lib/date-picker/style/index.css'

const DatePicker = generatePicker<Dayjs>(dayjsGenerateConfig)

const LYSDatePicker = React.forwardRef<any, PickerProps<Dayjs>>(
  (props, ref) => {
    return <DatePicker {...props} ref={ref} locale={locale} />
  }
)

export const LYSRangePicker = React.forwardRef<any, RangePickerProps<Dayjs>>(
  (props, ref) => {
    return <DatePicker.RangePicker {...props} ref={ref} locale={locale} />
  }
)

export default LYSDatePicker
