import { PhoneOutlined } from '@ant-design/icons'
import {
  LYSCol,
  LYSIcon,
  LYSRow,
  LYSTypography,
} from '@core/components/Primitives'
import { contactConfig } from '@core/config/contactConfig'
import { useTranslation } from '@core/i18n/i18n'
import style from './index.module.less'

const TopBar: React.FC = () => {
  const { t } = useTranslation()
  return (
    <div className={style.topbar}>
      <LYSRow justify={'space-between'} align={'middle'}>
        <LYSCol fill={true} className={style.introCol}>
          <LYSTypography.Text strong={true}>
            {t('topbar.intro')}
          </LYSTypography.Text>
        </LYSCol>
        <LYSCol className={style.contactCol}>
          <a href={contactConfig.phoneLink}>
            <LYSIcon component={PhoneOutlined} /> {contactConfig.telephone}
          </a>
        </LYSCol>
      </LYSRow>
    </div>
  )
}

export default TopBar
