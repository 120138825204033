import { Rule, RuleType } from 'rc-field-form/lib/interface'
import { CustomerTypes } from '@core/api/Channel/types'
import { i18n } from '@core/i18n/i18n'
import validators from '@core/config/validators'

export const generalValidationRules = {
  required: {
    required: true,
    get message() {
      return i18n.t('form.validation.required')
    },
  },
  blacklist: { validator: validators.validateAddressBlacklistedStrings },
  email: {
    type: 'email' as RuleType,
    get message() {
      return i18n.t('form.validation.invalidEmail')
    },
  },
  maxLength: (maxLength: number) => ({
    validator: async (_rule: any, value: any) => {
      if (typeof value === 'string' && value.length > maxLength) {
        throw new Error(
          i18n.t('form.validation.exceededMaxLength', { maxLength })
        )
      }
    },
  }),
}

const formConfig = {
  billingAddressForm(customerType: CustomerTypes, _isAuthenticated?: boolean) {
    return {
      label: {
        validateTrigger: 'onBlur',
        rules: [generalValidationRules.required],
      },
      gender: {
        validateTrigger: 'onBlur',
        rules: [
          customerType === CustomerTypes.personal
            ? generalValidationRules.required
            : undefined,
        ].filter((rule) => rule !== undefined) as Rule[],
      },
      firstName: {
        validateTrigger: 'onBlur',
        rules: [
          customerType === CustomerTypes.personal
            ? generalValidationRules.required
            : undefined,
        ].filter((rule) => rule !== undefined) as Rule[],
      },
      lastName: {
        validateTrigger: 'onBlur',
        rules: [
          customerType === CustomerTypes.personal
            ? generalValidationRules.required
            : undefined,
        ].filter((rule) => rule !== undefined) as Rule[],
      },
      company: {
        validateTrigger: 'onBlur',
        rules: [
          customerType === CustomerTypes.company
            ? generalValidationRules.required
            : undefined,
        ].filter((rule) => rule !== undefined) as Rule[],
      },
      email: {
        validateTrigger: 'onBlur',
        rules: [generalValidationRules.email],
      },
      street: {
        validateTrigger: 'onBlur',
        rules: [
          generalValidationRules.required,
          { validator: validators.validateStreet },
        ],
      },
      postalCode: {
        validateTrigger: 'onBlur',
        rules: [
          generalValidationRules.required,
          { validator: validators.validatePostalCode },
        ],
      },
      city: {
        validateTrigger: 'onBlur',
        rules: [generalValidationRules.required],
      },
      country: {
        validateTrigger: 'onBlur',
        rules: [generalValidationRules.required],
      },
      vatId: {
        validateTrigger: 'onBlur',
        rules: [{ validator: validators.validateVatId }],
      },
      additionalInfo: {},
      phone: {
        validateTrigger: 'onBlur',
        rules: [{ validator: validators.validatePhoneNumber }],
      },
    }
  },
  shippingAddressForm(customerType: CustomerTypes, _isAuthenticated?: boolean) {
    return {
      label: {
        validateTrigger: 'onBlur',
        rules: [generalValidationRules.required],
      },
      gender: {
        validateTrigger: 'onBlur',
        rules: [
          customerType === CustomerTypes.personal
            ? generalValidationRules.required
            : undefined,
        ].filter((rule) => rule !== undefined) as Rule[],
      },
      firstName: {
        validateTrigger: 'onBlur',
        rules: [
          generalValidationRules.blacklist,
          customerType === CustomerTypes.personal
            ? generalValidationRules.required
            : undefined,
        ].filter((rule) => rule !== undefined) as Rule[],
      },
      lastName: {
        validateTrigger: 'onBlur',
        rules: [
          generalValidationRules.blacklist,
          customerType === CustomerTypes.personal
            ? generalValidationRules.required
            : undefined,
        ].filter((rule) => rule !== undefined) as Rule[],
      },
      company: {
        validateTrigger: 'onBlur',
        rules: [
          generalValidationRules.blacklist,
          customerType === CustomerTypes.company
            ? generalValidationRules.required
            : undefined,
        ].filter((rule) => rule !== undefined) as Rule[],
      },
      street: {
        validateTrigger: 'onBlur',
        rules: [
          generalValidationRules.required,
          generalValidationRules.blacklist,
          { validator: validators.validateStreet },
        ],
      },
      postalCode: {
        validateTrigger: 'onBlur',
        rules: [
          generalValidationRules.required,
          generalValidationRules.blacklist,
          { validator: validators.validatePostalCode },
        ],
      },
      city: {
        validateTrigger: 'onBlur',
        rules: [
          generalValidationRules.required,
          generalValidationRules.blacklist,
        ],
      },
      country: {
        validateTrigger: 'onBlur',
        rules: [generalValidationRules.required],
      },
      additionalInfo: {},
      phone: {
        validateTrigger: 'onBlur',
        rules: [{ validator: validators.validatePhoneNumber }],
      },
    }
  },
  guestCheckoutCustomerInfoForm: {
    email: {
      validateTrigger: 'onBlur',
      rules: [generalValidationRules.required, generalValidationRules.email],
    },
  },
  changePassword: {
    currentPassword: {
      validateTrigger: 'onBlur',
      rules: [generalValidationRules.required],
    },
    newPassword: {
      validateTrigger: 'onBlur',
      rules: [
        generalValidationRules.required,
        { validator: validators.validatePassword },
      ],
    },
    newPasswordConfirm: {
      validateTrigger: 'onBlur',
      rules: [generalValidationRules.required],
    },
  },
  forgotPassword: {
    email: {
      validateTrigger: 'onBlur',
      rules: [generalValidationRules.required, generalValidationRules.email],
    },
  },
  registerForm: {
    firstName: {
      validateTrigger: 'onBlur',
      rules: [generalValidationRules.required],
    },
    lastName: {
      validateTrigger: 'onBlur',
      rules: [generalValidationRules.required],
    },
    customerType: {
      validateTrigger: 'onBlur',
      rules: [generalValidationRules.required],
    },
    channel: {
      validateTrigger: 'onBlur',
      rules: [generalValidationRules.required],
    },
    company: {
      validateTrigger: 'onBlur',
      rules: [generalValidationRules.required],
    },
    email: {
      validateTrigger: 'onBlur',
      rules: [generalValidationRules.required, generalValidationRules.email],
    },
    chamberOfCommerce: {
      rules: [
        generalValidationRules.maxLength(8),
        generalValidationRules.required,
        {
          pattern: /^[0-9]{8}$/,
          get message() {
            return i18n.t('form.register.patternHint')
          },
        },
      ],
    },
  },
  resendTokenForm: {
    email: {
      validateTrigger: 'onBlur',
      rules: [generalValidationRules.required, generalValidationRules.email],
    },
  },
  resetPassword: {
    password: {
      validateTrigger: 'onBlur',
      rules: [
        generalValidationRules.required,
        { validator: validators.validatePassword },
      ],
    },
    confirmPassword: {
      validateTrigger: 'onBlur',
      rules: [generalValidationRules.required],
    },
  },
  voucherInput: {},
  checkoutAdditionalInfo: {
    customerPurchaseOrderNumber: {
      validateTrigger: 'onBlur',
      rules: [],
    },
  },
  loginForm: {
    agentName: {
      validateTrigger: 'onBlur',
      rules: [generalValidationRules.required],
    },
    email: {
      validateTrigger: 'onBlur',
      rules: [generalValidationRules.required],
    },
    password: {
      validateTrigger: 'onBlur',
      rules: [generalValidationRules.required],
    },
  },
  invitationForm: {
    gender: {
      validateTrigger: 'onBlur',
      rules: [generalValidationRules.required],
    },
    firstName: {
      validateTrigger: 'onBlur',
      rules: [generalValidationRules.required],
    },
    lastName: {
      validateTrigger: 'onBlur',
      rules: [generalValidationRules.required],
    },
    email: {
      validateTrigger: 'onBlur',
      rules: [generalValidationRules.required, generalValidationRules.email],
    },
  },
  editUserForm: {
    gender: {
      validateTrigger: 'onBlur',
      rules: [generalValidationRules.required],
    },
    firstName: {
      validateTrigger: 'onBlur',
      rules: [generalValidationRules.required],
    },
    lastName: {
      validateTrigger: 'onBlur',
      rules: [generalValidationRules.required],
    },
    customerType: {
      validateTrigger: 'onBlur',
      rules: [generalValidationRules.required],
    },
    channel: {
      validateTrigger: 'onBlur',
      rules: [generalValidationRules.required],
    },
    company: {
      validateTrigger: 'onBlur',
      rules: [generalValidationRules.required],
    },
    phone: {},
    email: {
      validateTrigger: 'onBlur',
      rules: [generalValidationRules.required, generalValidationRules.email],
    },
  },
  productListForm: {
    label: {
      validateTrigger: 'onBlur',
      rules: [
        {
          required: true,
          get message() {
            return i18n.t('wishlist.createProductList.nameRequired')
          },
        },
      ],
    },
    description: {
      validateTrigger: 'onBlur',
      rules: [generalValidationRules.maxLength(40)],
    },
    email: {},
    phone: {},
  },
  newsletter: {
    email: {
      validateTrigger: 'onBlur',
      rules: [generalValidationRules.required, generalValidationRules.email],
    },
  },
  priceRequestModal: {
    phone: {
      rules: [generalValidationRules.required],
    },
  },
}

export default formConfig
