import CookiesFacade from '../cookies'

export const PREFERENCES_KEY = 'preferences'

export enum Preferences {
  ONLY_WITH_PRICE = 'onlyWithPrice',
  NEW_KEY = 'onlyNewProducts',
  HAS_AWARDS_KEY = 'onlyAwards',
  OWN_BRANDS_KEY = 'onlyOwnBrands',
  IN_STOCK_KEY = 'onlyInStock',
  ONLY_PURCHASED_KEY = 'onlyPurchasedProducts',
  ONLY_RECENTLY_PURCHASED_KEY = 'onlyRecentlyPurchasedProducts',
  ON_PRODUCT_LIST_KEY = 'showOnlyProductsFromProductLists'
}

export class PreferencesService {
  constructor(protected cookies: CookiesFacade) {}

  private get preferences() {
    const savedPreferences = this.cookies.get(PREFERENCES_KEY) || '{}'
    return JSON.parse(savedPreferences)
  }

  public remove() {
    this.cookies.remove(PREFERENCES_KEY)
  }

  public get(key: Preferences | string) {
    return this.preferences[key]
  }

  public set(key: Preferences | string, value: string | number | boolean) {
    const newPreferences = { ...this.preferences }
    newPreferences[key] = value
    this.cookies.set(PREFERENCES_KEY, JSON.stringify(newPreferences))
  }
}
