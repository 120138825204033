import {
  IShippingAddress as OriginalIShippingAddress,
  IAddressFormBase as OriginalIAddressFormBase,
} from '@original/api/Customer/types'

export * from '@original/api/Customer/types'

export type ISlot = string

export interface ISlots {
  slot1?: ISlot
  slot2?: ISlot
}

export enum IDeliveryWeekdays {
  MONDAY = 'monday',
  TUESDAY = 'tuesday',
  WEDNESDAY = 'wednesday',
  THURSDAY = 'thursday',
  FRIDAY = 'friday',
  SATURDAY = 'saturday',
  SUNDAY = 'sunday',
}

export type IDeliveryTimes = {
  [key in IDeliveryWeekdays]: ISlots
}

interface IAdditionalProperties {
  clientFields: {
    [key: string]: any
    costCenter?: string
    objectNumber?: string
    mobile?: string
    /** accepts time ranges with min. 3h, like ["12:00-15:00", "16:00-19:00"]  */
    deliveryTimes?: IDeliveryTimes
    name?: string
    phoneNumber?: string
  }
}

export type IWeekdays =
  | 'monday'
  | 'tuesday'
  | 'wednesday'
  | 'thursday'
  | 'friday'
  | 'saturday'
  | 'sunday'

export type IShippingAddress = OriginalIShippingAddress & IAdditionalProperties
export type IShippingAddressFormBase = OriginalIAddressFormBase &
  IAdditionalProperties

// export type IAddressFormBase = OriginalIAddressFormBase & {
//   contactName?: string
// }
