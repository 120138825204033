import PrismicDOM from 'prismic-dom'
import linkResolver from '@core/prismic/linkResolver'

export const getFirstTextValue = (property: PrismicTextElement[]) =>
  property && property[0] && property[0].text

export const convertRichTextToHtml = (text?: PrismicTextElement[]) => {
  if (!text) return
  let htmlString
  try {
    htmlString = PrismicDOM.RichText.asHtml(text, linkResolver)
  } catch (e) {
    // ignore
  }
  return htmlString
}

/**
 * Resolve any type of supported Prismic link to a url / target
 * @param linkElement
 */
export const resolvePrismicLink = (
  linkElement?: PrismicLinkElement
): { url?: string; target?: string } => {
  switch (linkElement?.link_type) {
    case 'Web':
      return linkElement
    case 'Document': {
      const url = linkResolver(linkElement)
      return {
        url,
      }
    }
    default:
      return {}
  }
}
