import {
  DispatchWithThunk,
  IAppState,
  IStoreDependencies,
} from '@core/store/types'

type Thunk<T> = (
  dispatch: DispatchWithThunk,
  getState: () => IAppState,
  dependencies: IStoreDependencies
) => T

/**
 * Utility for creating a typed thunk without explicitly specifying all types for the thunk parameters
 * @param thunk
 */
const thunk = <T>(thunk: Thunk<T>) => {
  return thunk as any
}

export default thunk
