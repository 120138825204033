import {
  IProductList,
  IProductListItemCollection,
} from '@core/api/ProductLists/types'

export enum ProductListSortOption {
  MANUAL = 'manual',
  HIGHEST_PRICE = 'highest-price',
  LOWEST_PRICE = 'lowest-price',
  PRODUCT_NAME_ASC = 'product-name-asc',
  PRODUCT_NAME_DESC = 'product-name-desc',
  PURCHASABLE = 'purchasable',
  NOT_PURCHASABLE = 'not-purchasable',
  LAST_ADDED = 'last-added',
}

export interface IProductListState {
  isLoading: boolean
  isError: boolean
  productLists: IProductList[]
  sortOrder: ProductListSortOption
  selectedList?: IProductListItemCollection
}

export interface ISortable {
  children: React.ReactNode
}

export interface ISortEnd {
  oldIndex: number
  newIndex: number
}
