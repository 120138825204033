import { Layout } from 'antd'
import { LayoutProps } from 'antd/lib/layout'
import cn from 'classnames'
import styles from './global.module.less'
import customStyles from './globalCustom.module.less'

interface StaticExports {
  Header: typeof Layout.Header
  Sider: typeof Layout.Sider
  Content: typeof Layout.Content
  Footer: typeof Layout.Footer
}

export type Props = LayoutProps

const LYSLayout: React.FC<Props> & StaticExports = (props) => {
  return (
    <Layout
      className={cn(styles.container, customStyles.container)}
      {...props}
    />
  )
}

LYSLayout.Header = Layout.Header
LYSLayout.Sider = Layout.Sider
LYSLayout.Content = Layout.Content
LYSLayout.Footer = Layout.Footer

export default LYSLayout
