import { ProductViewType } from '@core/api/Products/types'

export enum ActionTypes {
  SET_PRODUCT_VIEW = 'SET_PRODUCT_VIEW',
}

export interface ISetProductViewAction {
  type: ActionTypes.SET_PRODUCT_VIEW
  payload: ProductViewType
}

export function setProductView(
  payload: ProductViewType
): ISetProductViewAction {
  return {
    type: ActionTypes.SET_PRODUCT_VIEW,
    payload,
  }
}
export type Action = ISetProductViewAction
