import { createContext, useContext, useState } from 'react'

export interface IAppBarContext {
  autoSuggestOpen: boolean
  setAutoSuggestOpen?: (open: boolean) => void
  categoryMenuOpen: boolean
  setCategoryMenuOpen?: (open: boolean) => void
}

export const appBarContext = createContext<IAppBarContext>({
  autoSuggestOpen: false,
  categoryMenuOpen: false,
})

export const AppBarContextProvider: React.FC = ({ children }) => {
  const [autoSuggestOpen, setAutoSuggestOpen] = useState(false)
  const [categoryMenuOpen, setCategoryMenuOpen] = useState(false)
  return (
    <appBarContext.Provider
      value={{
        autoSuggestOpen,
        setAutoSuggestOpen,
        categoryMenuOpen,
        setCategoryMenuOpen,
      }}
    >
      {children}
    </appBarContext.Provider>
  )
}

export const useAppBarContext = () => {
  const {
    autoSuggestOpen,
    setAutoSuggestOpen,
    categoryMenuOpen,
    setCategoryMenuOpen,
  } = useContext(appBarContext)
  return {
    autoSuggestOpen,
    setAutoSuggestOpen,
    categoryMenuOpen,
    setCategoryMenuOpen,
  }
}
