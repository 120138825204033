import React from 'react'
import { Organization } from 'schema-dts'
import seoConfig from '@core/config/seo'
import { JsonLd } from '@core/utils/jsonld'

/**
 * sadly next/seo doesn´t support organization as JSON-Ld Schema,
 * so we use this as a manual implementation
 */
const LYSOrganizationJsonLd: React.FC = () => {
  return (
    <JsonLd<Organization>
      item={{
        '@context': 'https://schema.org',
        '@type': 'Organization',
        ...seoConfig.organizationDetails,
      }}
    />
  )
}

export default LYSOrganizationJsonLd
