import { Spin } from 'antd'
import { LoadingOutlined } from '@ant-design/icons'
import { AntdIconProps } from '@ant-design/icons/lib/components/AntdIcon'

export type Props = AntdIconProps & React.RefAttributes<HTMLSpanElement>

const LYSSpin: React.FC<Props> = (props) => {
  const antIcon = <LoadingOutlined rev={undefined} {...props} spin />
  return <Spin indicator={antIcon} />
}

export default LYSSpin
