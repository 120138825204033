type CacheKeyFunction = (url: string) => string

const DEFAULT_MAX_TTL = 1000 * 60 * 5
const DEFAULT_MAX_ENTRIES = 50

const identity = (value: any) => value

interface CacheOptions {
  // Max cache entries
  maxEntries: number
  // Max time to live in milliseconds
  maxTtl: number
  // Function to generate unique cache key for URL
  keyFunction: CacheKeyFunction
}

interface CacheEntry<T> {
  created: number
  value: T
}

type CacheMap = { [key: string]: CacheEntry<any> }

export class Cache {
  private _options: CacheOptions
  private _keyHistory: string[] = []
  private _entries: CacheMap = {}

  constructor(options?: Partial<CacheOptions>) {
    this._options = {
      maxEntries: DEFAULT_MAX_ENTRIES,
      maxTtl: DEFAULT_MAX_TTL,
      keyFunction: identity,
      ...options,
    }
  }

  get<T extends any>(key: string): T | undefined {
    return this.access(key)?.value
  }

  has(key: string): boolean {
    return !!this.access(key)
  }

  put<T extends any>(key: string, value: T) {
    const generatedKey = this._options.keyFunction(key)

    this.remove(generatedKey)

    this._entries[generatedKey] = {
      created: new Date().getTime(),
      value,
    }
    this._keyHistory.push(generatedKey)

    while (this._keyHistory.length > this._options.maxEntries) {
      const keyToRemove = this._keyHistory.shift()
      if (!keyToRemove) break
      this.remove(keyToRemove)
    }
  }

  get entries(): Readonly<CacheMap> {
    return this._entries
  }

  private access(key: string) {
    const generatedKey = this._options.keyFunction(key)
    const entry = this._entries[generatedKey]

    if (!entry) return

    const now = new Date().getTime()
    const lifetime = now - entry.created

    if (lifetime > this._options.maxTtl) {
      this.remove(generatedKey)
      return
    }

    return entry
  }

  private remove(keyToRemove: string) {
    this._keyHistory = this._keyHistory.filter((key) => key !== keyToRemove)
    delete this._entries[keyToRemove]
  }
}
