import React from 'react'
import { MailOutlined, PhoneOutlined } from '@ant-design/icons'
import { ISupportContact } from '@core/api/User/types'
import { LYSCol, LYSIcon, LYSTypography } from '@core/components/Primitives'
import style from './index.module.less'

interface IProps {
  contact: ISupportContact
  fullCol?: boolean
}

const ServiceFooterContactInfo: React.FC<IProps> = ({ contact, fullCol }) => (
  <LYSCol xs={24} md={fullCol ? 24 : 12}>
    <LYSTypography.Text visualAppearance={'h4'}>
      {contact.name}
    </LYSTypography.Text>
    <LYSTypography.Paragraph type={'secondary'}>
      {contact.clientFields.position}
    </LYSTypography.Paragraph>
    <div className={style.phoneMail}>
      <div className={style.phone}>
        <a href={`tel:${contact.clientFields.phonenumber}`}>
          <LYSIcon component={PhoneOutlined} />{' '}
          <LYSTypography.Text>
            {contact.clientFields.phonenumber}
          </LYSTypography.Text>
        </a>
      </div>
      {contact.clientFields.email && (
        <div>
          <a href={`mailto:${contact.clientFields.email}`}>
            <LYSIcon component={MailOutlined} />{' '}
            <LYSTypography.Text>
              {contact.clientFields.email}
            </LYSTypography.Text>
          </a>
        </div>
      )}
    </div>
  </LYSCol>
)

export default ServiceFooterContactInfo
