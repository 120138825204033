import { createContext, useContext } from 'react'
import { DefaultPageProps } from '@core/utils/pages/getDefaultPageProps'
import { PageType } from '@core/utils/pages/types'

export interface IPageContext<TProps = DefaultPageProps> {
  props: TProps
}

export type IGenericPageContext = IPageContext<DefaultPageProps & any>

export const PageContext = createContext<IPageContext<DefaultPageProps>>({
  props: {} as any,
})

export const usePageContext = <TPageProps>(
  expectedPageType?: PageType
): IPageContext<TPageProps & DefaultPageProps> => {
  const pageContext = useContext(PageContext) as IPageContext<
    TPageProps & DefaultPageProps
  >

  if (expectedPageType && pageContext.props.pageType !== expectedPageType) {
    console.warn(
      `usePageContext call within unexpected page context: expected=${expectedPageType}, actual=${pageContext.props.pageType}`
    )
  }

  return pageContext
}
