import React from 'react'
import { LYSButton } from '@core/components/Primitives'
import LYSStaticImage from '@core/components/Primitives/LYSStaticImage'
import config from '@core/config/config'
import usePriceRequests from '@core/hooks/priceRequest/usePriceRequests'
import { useTranslation } from '@core/i18n/i18n'
import styles from './index.module.less'
import cn from 'classnames'
import { usePageContext } from '@core/utils/pages/PageContext'

interface IProps {
  variantId: string
  smallLayout?: boolean
  disabled?: boolean
  isProductDetailPage?: boolean
}

const PriceRequestCta: React.FC<IProps> = ({
  variantId,
  smallLayout,
  disabled,
  isProductDetailPage = false,
}) => {
  const { t } = useTranslation()
  const { create } = usePriceRequests()
  const {
    props: { isMobile },
  } = usePageContext()
  return (
    <LYSButton
      size={'large'}
      type="primary"
      actionButtonStyle={true}
      onClick={() => create(variantId)}
      disabled={disabled}
      className={cn(
        styles.cta,
        smallLayout && styles.ctaSmall,
        !isProductDetailPage && styles.priceRequestButton
      )}
    >
      <LYSStaticImage
        src={config.assets.priceRequest}
        alt={'Illustration'}
        width={20}
        height={20}
      />
      {!isMobile && (
        <span className={styles.ctaLabel}>
          {smallLayout ? t`priceRequest.ctaSmall` : t`priceRequest.cta`}
        </span>
      )}
    </LYSButton>
  )
}

export default PriceRequestCta
