import { Alert } from 'antd'
import { AlertProps } from 'antd/lib/alert'

export type Props = AlertProps

const LYSAlert: React.FC<Props> = (props) => {
  return <Alert {...props} />
}

export default LYSAlert
