import cn from 'classnames'
import { StockInformationTypes } from '@core/api/Channel/types'
import { LYSTypography } from '@core/components/Primitives'
import { StockInfoLocation } from '@core/components/shared/StockInformation/types'
import useChannel from '@core/hooks/channel/useChannel'
import { useTranslation } from '@core/i18n/i18n'
import { CommonProductStockInformation } from '@core/utils/models/product/types'
import style from './index.module.less'
import { showInStock, showOutOfStock } from './helpers'
import { RenderProp, Slot, template } from '@core/utils/templates'
// eslint-disable-next-line import/no-cycle
import { useFollowUpProducts } from '@core/components/ProductDetail/ProductRelations/FollowUpProductRelations'

export interface StockInformationProps {
  stock: CommonProductStockInformation
  className?: string
  location?: StockInfoLocation
}

interface StockInformationSlots {
  inStockText?: RenderProp<void>
}

const StockInformation = template<StockInformationProps, StockInformationSlots>(
  ({ stock, className, location, slots }) => {
    const { t } = useTranslation()
    const { userChannel } = useChannel()
    const followUpProducts = useFollowUpProducts()

    const isOutOfStock = stock.isOutOfStock
    const isDiscontinued = stock.isDiscontinued

    const stockInformationType =
      userChannel?.displayStockMode || StockInformationTypes.disabled

    if (stockInformationType === StockInformationTypes.disabled) return null

    const availableStock = stock.stock?.available

    const hasFollowUpProducts = followUpProducts.length > 0
    return (
      <>
        {isOutOfStock && showOutOfStock(location!) ? (
          hasFollowUpProducts && isDiscontinued ? (
            <div className={cn(style.outOfStock, className)}>
              <LYSTypography.Text>
                {t('product.stockInformation.cannotBeOrdered')}
              </LYSTypography.Text>
            </div>
          ) : (
            <div className={cn(style.outOfStock, className)}>
              <LYSTypography.Text>
                {t('product.stockInformation.outOfStock')}
              </LYSTypography.Text>
            </div>
          )
        ) : stockInformationType === StockInformationTypes.simple ? (
          showInStock(location!) && (
            <div className={cn(style.inStock, className)}>
              <LYSTypography.Text>
                {t('product.stockInformation.inStock')}
              </LYSTypography.Text>
            </div>
          )
        ) : (
          showInStock(location!) &&
          availableStock !== undefined && (
            <div className={cn(style.inStock, className)}>
              <LYSTypography.Text>
                <Slot render={slots?.inStockText}>
                  {t('product.stockInformation.inStockExtended', {
                    stock: availableStock,
                  })}
                </Slot>
              </LYSTypography.Text>
            </div>
          )
        )}
      </>
    )
  }
)

export default StockInformation
