export function moveItem<T>(arr: T[], item: T, newIndex: number) {
  newIndex = Math.min(arr.length, newIndex)
  newIndex = Math.max(0, newIndex)
  arr.splice(arr.indexOf(item), 1)
  arr.splice(newIndex, 0, item)
}

/**
 * For some reasony the type supprt, especially for complex operations, was not
 * working properly with lodash "uniqBy" and "whithout".
 * But the manual implementation is not big at all
 */

export function removeEmpty<T>(array: Array<T | undefined | null>): T[] {
  return array.filter((e) => !!e) as T[]
}

export function removeObjectDuplicates<T>(array: T[], prop: keyof T): T[] {
  return array.filter((obj, pos, arr) => {
    return arr.map((mapObj) => mapObj[prop]).indexOf(obj[prop]) === pos
  })
}
