import { ProductViewType } from '@core/api/Products/types'
import { IProductDetailInitialState } from '@core/store/product-detail/types'
import { Action, ActionTypes } from './actions'

const initialState: IProductDetailInitialState = {
  productView: ProductViewType.GRID,
}

export default function productDetailReducer(
  state: IProductDetailInitialState = initialState,
  action: Action
) {
  switch (action.type) {
    case ActionTypes.SET_PRODUCT_VIEW: {
      const productView = action.payload
      return {
        ...state,
        productView,
      }
    }
    default:
      return state
  }
}
