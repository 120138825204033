export function flatten<T>(tree: T, key: keyof T, collection: T[] = []) {
  if (!tree[key] || !Array.isArray(tree[key])) {
    return collection
  }

  const children = tree[key] as unknown as T[]
  for (let i = 0; i < children.length; i++) {
    const child = children[i] as T
    collection.push(child)
    flatten(child, key, collection)
  }

  return collection
}
