import React, { useMemo } from 'react'
import { DeleteOutlined } from '@ant-design/icons'
import { ICartItem } from '@core/api/Cart/types'
import CartItemHazardWarning from '@core/components/Cart/CartItemHazardWarning'
import {
  LYSTypography,
  LYSButton,
  LYSCol,
  LYSRow,
} from '@core/components/Primitives'
import ProductDetailLink from '@core/components/ProductCollection/ProductDetailLink'
import EstimatedDeliveryRange from '@core/components/shared/EstimatedDeliveryRange'
import ProductPrice from '@core/components/shared/ProductPrice'
import StockInformation from '@core/components/shared/StockInformation'
import { StockInfoLocation } from '@core/components/shared/StockInformation/types'
import useCart from '@core/hooks/cart/useCart'
import useChannel from '@core/hooks/channel/useChannel'
import { useTranslation } from '@core/i18n/i18n'
import { formatChannelPrice } from '@core/utils/i18n'
import { useServices } from '@core/utils/ioc'
import { ProductContextProvider } from '@core/utils/models/product/ProductContext'
import style from './index.module.less'

interface OwnProps {
  item: ICartItem
  removeProduct: (item: ICartItem) => void
}

type Props = OwnProps

const CartProductRow: React.FC<Props> = (props: Props) => {
  const { productContextService } = useServices()
  const { userChannel } = useChannel()
  const { isCartUpdatePending } = useCart()
  const { t } = useTranslation()
  const { item, removeProduct } = props
  const { product } = item
  const { isDiscontinued } = product
  const productContext = useMemo(
    () => productContextService.getProductCartItemContext(item),
    [productContextService, item]
  )

  const removeItem = (e: React.MouseEvent<HTMLElement>) => {
    e.preventDefault()
    removeProduct(item)
  }

  const shouldShowPricePerUnit = item.quantity > 1

  return (
    <ProductContextProvider productContext={productContext}>
      <div className={style.wrapper}>
        <LYSRow
          className={style.mainContent}
          align="middle"
          justify="space-between"
        >
          <LYSCol xs={4}>
            <ProductDetailLink
              slug={product.variant.slug}
              id={product.variant.id}
            >
              <img
                className={style.productImage}
                src={`${product.variant.imageUrl}`}
                alt={product.variant.name}
                title={product.variant.name}
                loading="lazy"
              />
            </ProductDetailLink>
          </LYSCol>
          <LYSCol xs={20} className={style.infoColumn}>
            <ProductDetailLink
              slug={product.variant.slug}
              id={product.variant.id}
            >
              <LYSTypography.Text>
                <span className={style.quantity}>{item.quantity}x</span>
                {product.name}
              </LYSTypography.Text>

              <EstimatedDeliveryRange
                estimatedDeliveryDaysRange={
                  productContext.variant.estimatedDeliveryDaysRange
                }
              />
            </ProductDetailLink>

            <LYSButton
              type={'link'}
              icon={<DeleteOutlined rev={undefined} />}
              onClick={removeItem}
              size={'small'}
              className={style.deleteButton}
              disabled={isCartUpdatePending}
            />
          </LYSCol>
        </LYSRow>

        <LYSRow justify="end">
          <LYSCol className={style.quantityAndPricePerItemContainer}>
            {shouldShowPricePerUnit && item.price && (
              <LYSTypography.Text
                type={'secondary'}
                className={style.perItemPrice}
              >
                {t(`cart.perItemShort`, {
                  price: formatChannelPrice(item.price, userChannel!),
                })}
              </LYSTypography.Text>
            )}
            {item.total && (
              <LYSTypography.Text
                className={style.price}
                data-testid="price-in-quickcart"
              >
                <ProductPrice
                  price={item.total}
                  compactPrice={true}
                  strikePrice={item.totalStrikeThroughPrice?.price}
                  mainPriceHolderClass={style.priceHolder}
                />
              </LYSTypography.Text>
            )}
          </LYSCol>
        </LYSRow>

        <LYSRow justify="end">
          <LYSCol xs={20}>
            {isDiscontinued ? (
              <LYSTypography.Paragraph type={'danger'}>
                <small>{t`product.discontinued.hint`}</small>
              </LYSTypography.Paragraph>
            ) : (
              <StockInformation
                stock={productContext.stock}
                location={StockInfoLocation.CartDrawerItem}
                className={style.stockInformation}
              />
            )}

            {item.product.ghsClasses.length > 0 && (
              <CartItemHazardWarning cartItem={item} />
            )}
          </LYSCol>
        </LYSRow>
      </div>
    </ProductContextProvider>
  )
}

export default CartProductRow
