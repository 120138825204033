import React from 'react'
import { TFunction } from 'next-i18next'
import Link from 'next/link'
import { LYSButton, LYSContainer, LYSResult } from '@core/components/Primitives'
import { ContainerWidth } from '@core/components/Primitives/LYSContainer'
import { LYSResultProps } from '@core/components/Primitives/LYSResult'
import HeadTags from '@core/components/shared/HeadTags'
import { useTranslation } from '@core/i18n/i18n'
import { formatPageTitle } from '@core/utils/title'

interface Props {
  statusCode?: number | null
}

const Error: React.FC<Props> = ({ statusCode }) => {
  const { t } = useTranslation()

  const resultProps = getResultProps(statusCode, t)

  return (
    <>
      <HeadTags
        title={resultProps.title && formatPageTitle(resultProps.title)}
      />
      <LYSContainer padded={true} width={ContainerWidth.UltraNarrow}>
        <LYSResult {...resultProps} />
      </LYSContainer>
    </>
  )
}

const getResultProps = (
  statusCode: number | null | undefined,
  t: TFunction
): Partial<LYSResultProps> => {
  switch (statusCode) {
    case 500:
      return {
        status: 500,
        title: t(`errorPage.500.title`),
        subTitle: t(`errorPage.500.description`),
      }
    case 404:
    default:
      return {
        status: 404,
        title: t(`errorPage.404.title`),
        subTitle: t(`errorPage.404.description`),
        extra: (
          <Link href={'/'}>
            <LYSButton type={'primary'} ghost={true}>
              {t('errorPage.404.goBack')}
            </LYSButton>
          </Link>
        ),
      }
  }
}

export default Error
