import * as React from 'react'
import App, { AppContext } from 'next/app'
import { withRouter, NextRouter } from 'next/router'
import { Provider } from 'react-redux'
import LYSOrganizationJsonLd from '@core/components/Primitives/LYSOrganizationJsonLd'
import DefaultPage from '@core/components/layouts/DefaultPage'
import HeadTags from '@core/components/shared/HeadTags'
import config from '@core/config/config'
import init from '@core/config/init'
import seoConfig from '@core/config/seo'
import { IShopServices } from '@core/config/services'
import { viewPageEvent } from '@core/events/view'
import { appWithTranslation, i18n } from '@core/i18n/i18n'
import { fetchCategoryNavigation } from '@core/store/categories/actions'
import { fetchUser } from '@core/store/user/actions'
import { compose } from '@core/utils/compose'
import { withServices, ServiceContext } from '@core/utils/ioc'
import { logging } from '@core/utils/logging'
import { NextPageWithLayout } from '@core/utils/next'
import { PageContext } from '@core/utils/pages/PageContext'
import {
  DefaultPageProps,
  getDefaultPageProps,
} from '@core/utils/pages/getDefaultPageProps'
import { getCleanUrlWithoutParams } from '@core/utils/url'
import withErrorBoundary from '@core/utils/withErrorBoundary'

interface AppProps {
  services: IShopServices
  Component: NextPageWithLayout
  router: NextRouter
}

const enhance = compose(
  withServices,
  appWithTranslation,
  withRouter,
  withErrorBoundary
)

export class ShopApp extends App<AppProps> {
  public componentDidMount() {
    try {
      const { services, pageProps } = this.props
      init(services)
      services.eventBus.publish(viewPageEvent({ props: pageProps }))
    } catch (error) {
      logging.error('Service initialization failed', error)
    }
  }

  componentDidUpdate() {
    const { services, pageProps } = this.props
    services.eventBus.publish(viewPageEvent({ props: pageProps }))
  }

  public static async getInitialProps({ Component, ctx }: AppContext) {
    const { store } = ctx.services
    if (ctx.isServer) {
      await Promise.all([
        store.dispatch(fetchUser()),
        store.dispatch(fetchCategoryNavigation()),
      ])
    }

    const [defaultPageProps, customPageProps] = await Promise.all([
      getDefaultPageProps(ctx),
      Component.getInitialProps ? await Component.getInitialProps(ctx) : {},
    ])

    const pageProps = {
      ...defaultPageProps,
      ...(customPageProps || {}),
    }

    return { pageProps }
  }

  public render() {
    const { Component, pageProps, services, router } = this.props
    const currentPath = router.asPath
    const { store } = services

    const Layout = Component.Layout || DefaultPage
    const fullUrl = config.baseUrl + currentPath

    return (
      <ServiceContext.Provider value={services}>
        <PageContext.Provider value={{ props: pageProps as DefaultPageProps }}>
          <Provider store={store}>
            <Layout>
              <HeadTags
                title={i18n.t('seo.webTitle')}
                description={i18n.t('seo.description')}
                ogImage={seoConfig.image}
                ogType={seoConfig.defaultType}
                ogUrl={fullUrl}
                canonical={getCleanUrlWithoutParams(fullUrl)}
              />
              <Component {...pageProps} />
              <LYSOrganizationJsonLd />
            </Layout>
          </Provider>
        </PageContext.Provider>
      </ServiceContext.Provider>
    )
  }
}

export default enhance(ShopApp)
