import React from 'react'
import { LYSTypography } from '@core/components/Primitives'
import { useTranslation } from '@core/i18n/i18n'
import styles from './index.module.less'

interface Props {
  hideTitle?: boolean
}

const PriceRequestStatusInfo: React.FC<Props> = ({ hideTitle }) => {
  const { t } = useTranslation()
  return (
    <>
      {!hideTitle && (
        <LYSTypography.Text visualAppearance={'h4'} className={styles.title}>
          {t`priceRequest.status.title`}
        </LYSTypography.Text>
      )}

      <LYSTypography.Paragraph>
        {t`priceRequest.status.estimationInfo1`}
        <span
          className={styles.hours}
        >{t`priceRequest.status.estimationInfo48h`}</span>
        {t`priceRequest.status.estimationInfo2`}
      </LYSTypography.Paragraph>
    </>
  )
}

export default PriceRequestStatusInfo
