import { LYSTooltip } from '../Primitives'
import { QuestionCircleOutlined } from '@ant-design/icons'

interface HelpIconWithTooltipProps {
  title: JSX.Element
  color?: string
}

const HelpIconWithTooltip: React.FC<HelpIconWithTooltipProps> = (props) => {
  const { title, color } = props
  return (
    <LYSTooltip
      overlayInnerStyle={color === 'white' ? { color: 'black' } : undefined}
      color={color}
      title={title}
    >
      <QuestionCircleOutlined rev={undefined} />
    </LYSTooltip>
  )
}
export default HelpIconWithTooltip
