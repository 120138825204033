import { ParsedUrlQuery } from 'querystring'

export const getCleanUrlWithoutParams = (url: string): string => {
  if (url.includes('?')) {
    return url.substring(0, url.indexOf('?'))
  }

  return url
}

export const getQueryParamsString = (url: string): string => {
  return url.split('?')[1] || ''
}

export const getNumberFromQueryParamOrUndefined = (
  param: string | string[] | undefined
) =>
  param &&
  (Array.isArray(param)
    ? Number.parseInt(param[0], 10)
    : Number.parseInt(param, 10))

export const getSingleQueryParamOrUndefined = (
  query: ParsedUrlQuery,
  paramName: string
): string | undefined => {
  const param = query[paramName]

  return Array.isArray(param) ? param[0] : param
}
