import { createSelector } from 'reselect'
import { ShippingAddressStatus } from '@core/api/Customer/types'
import { IAppState } from '@core/store/types'
import { getUser } from '../user/selectors'

export const getCustomer = (state: IAppState) => state.customer

export const getBillingInformation = createSelector(
  getCustomer,
  (customer) => customer.billingInformation
)

export const getActiveShippingAddresses = createSelector(
  getCustomer,
  (customer) =>
    customer.shippingAddresses.filter(
      (address) => address.status === ShippingAddressStatus.ACTIVE
    )
)

export const getInactiveShippingAddresses = createSelector(
  getCustomer,
  (customer) =>
    customer.shippingAddresses.filter(
      (address) => address.status === ShippingAddressStatus.INACTIVE
    )
)

export const getAllShippingAddresses = createSelector(
  getCustomer,
  (customer) => customer.shippingAddresses
)

export const getShippingAddressById = (addressId: string | undefined) =>
  createSelector(getAllShippingAddresses, (addresses) => {
    return addresses && addresses.find((address) => address.id === addressId)
  })

export const getBillingInformationById = (addressId: string | undefined) =>
  createSelector(getBillingInformation, (addresses) => {
    return addresses && addresses.find((address) => address.id === addressId)
  })

export const getDefaultShippingAddress = createSelector(
  getAllShippingAddresses,
  (addresses) => {
    return addresses && addresses.find((address) => address.isDefault === true)
  }
)

export const getDefaultBillingInformation = createSelector(
  getBillingInformation,
  (billingInformation) =>
    billingInformation &&
    billingInformation.find((information) => information.isDefault === true)
)

export const getTeamMembers = createSelector(
  getCustomer,
  getUser,
  (customer, userState) => {
    const { user } = userState
    /**
     * Don´t show yourself to avoid deactivating own user account
     */
    return customer.teamMembers.filter((member) => member.user.id !== user?.id)
  }
)

export const isAccountOwner = createSelector(
  getCustomer,
  (customer) => customer.isOwner
)
