import { useCallback, useMemo } from 'react'
import {
  IProductsQuery,
  IProductsQueryOrder,
  HAS_PRICE_KEY,
  NEW_KEY,
  OWN_BRANDS_KEY,
  IN_STOCK_KEY,
  HAS_AWARDS_KEY,
  ONLY_PURCHASED_KEY,
  ONLY_RECENTLY_PURCHASED_KEY,
  ON_PRODUCT_LIST_KEY,
  IProductsQueryFilter,
} from '@core/api/Products/types'
import config from '@core/config/config'
import {
  productCollectionFilterEvent,
  productCollectionSortEvent,
} from '@core/events/product'
import { useServices } from '@core/utils/ioc'
import { Preferences } from '@core/utils/preferences'

export const extractUrlValues = (
  filterKey: string,
  filter?: IProductsQueryFilter
) => {
  const urlValues = (filter && filter[filterKey]) || []

  // only simple filters are currently supported
  return Array.isArray(urlValues) ? urlValues : []
}

const useProductCollectionQuery = () => {
  const { eventBus, productQueryService, preferences } = useServices()

  const query = productQueryService.parseQuery()

  const setNewQuery = useCallback(
    (newQuery: IProductsQuery) => {
      productQueryService.setQuery(newQuery)
    },
    [productQueryService]
  )

  const setPageNumber = (newPage: number) =>
    setNewQuery({
      ...query,
      page: newPage,
    })

  const setItemPerPageLimit = (_: any, newLimit: number) =>
    setNewQuery({
      ...query,
      page: 1,
      limit: newLimit,
    })

  const setGenericFilter = useCallback(
    (key: string, value: any) => {
      const newFilter = {
        ...query.filter,
        [key]: value,
      }
      setNewQuery({
        ...query,
        page: 1,
        filter: newFilter,
      })
      if (eventBus) eventBus.publish(productCollectionFilterEvent(newFilter))
    },
    [setNewQuery, eventBus, query]
  )

  const setRangeFilter = (key: string, [gte, lte]: [number, number]) =>
    setGenericFilter(key, { gte, lte })

  const onlyWithPriceFilterActive = useMemo(
    () =>
      config.features.filters.showOnlyWithPriceFilter &&
      query?.filter?.[HAS_PRICE_KEY] === '1',
    [query]
  )

  const setPriceRangeFilter = useCallback(
    (key: string, [gte, lte]: [number, number]) => {
      const queryCopy = { ...query }

      const newFilter = {
        ...queryCopy.filter,
        [key]: { gte, lte },
      }

      setNewQuery({
        ...queryCopy,
        filter: newFilter,
      })

      if (eventBus) eventBus.publish(productCollectionFilterEvent(newFilter))
    },
    // eslint-disable-next-line react-hooks/exhaustive-deps
    [query, eventBus, preferences, setNewQuery]
  )

  const setOnlyPriceFilter = useCallback(
    (onlyWithPrice: boolean) => {
      preferences.set(Preferences.ONLY_WITH_PRICE, false)

      const queryCopy = { ...query }
      if (!onlyWithPrice && queryCopy.filter)
        delete queryCopy.filter[HAS_PRICE_KEY]

      const newFilter = onlyWithPrice
        ? {
            ...queryCopy.filter,
            [HAS_PRICE_KEY]: '1',
          }
        : {
            ...queryCopy.filter,
          }

      setNewQuery({
        ...queryCopy,
        filter: newFilter,
      })

      if (eventBus) eventBus.publish(productCollectionFilterEvent(newFilter))
    },
    [query, eventBus, preferences, setNewQuery]
  )

  const setTermFilter = useCallback(
    (key: string, value: string[]) => {
      return setGenericFilter(key, value)
    },
    [setGenericFilter]
  )

  const setTaxonomyFilter = useCallback(
    (taxonomyId: string) => {
      setGenericFilter('taxonomy', taxonomyId)
    },
    [setGenericFilter]
  )

  const removeFilter = useCallback(
    (key: string) => {
      const newQuery = { ...query }
      if (newQuery.filter) delete newQuery.filter[key]
      setNewQuery(newQuery)
      if (eventBus)
        eventBus.publish(productCollectionFilterEvent(newQuery.filter))
    },
    [setNewQuery, eventBus, query]
  )

  const onlyOwnBrandsFilterActive =
    config.features.filters.ownBrandsFilter &&
    query?.filter?.[OWN_BRANDS_KEY] === '1'

  const setBrandsFilter = useCallback(
    (onlyOwnBrands: boolean, key: string, value: string[]) => {
      if (!onlyOwnBrands) {
        removeFilter(OWN_BRANDS_KEY)
        preferences.set(Preferences.OWN_BRANDS_KEY, false)
        setTermFilter(key, value)
      } else {
        setTermFilter(key, [])
        const newQuery = { ...query }
        if (newQuery.filter) delete newQuery.filter[key]

        const newFilter = {
          ...newQuery.filter,
          [OWN_BRANDS_KEY]: '1',
        }

        setNewQuery({
          ...query,
          filter: newFilter,
        })

        preferences.set(Preferences.OWN_BRANDS_KEY, true)
      }
    },
    [query, preferences, setNewQuery, removeFilter, setTermFilter]
  )

  const onlyAwardsFilterActive =
    config.features.filters.awardsFilter.active &&
    query?.filter?.[HAS_AWARDS_KEY] === '1'

  const setAwardsFilter = useCallback(
    (onlyAwards: boolean, key: string, value: string[]) => {
      if (!onlyAwards) {
        removeFilter(HAS_AWARDS_KEY)
        preferences.set(Preferences.HAS_AWARDS_KEY, false)
        setTermFilter(key, value)
      } else {
        setTermFilter(key, [])

        const newQuery = { ...query }
        if (newQuery.filter) delete newQuery.filter[key]

        const newFilter = {
          ...newQuery.filter,
          [HAS_AWARDS_KEY]: '1',
        }

        setNewQuery({
          ...query,
          filter: newFilter,
        })

        preferences.set(Preferences.HAS_AWARDS_KEY, true)
      }
    },
    [query, preferences, setNewQuery, removeFilter, setTermFilter]
  )
  const onlyInStockFilterActive =
    config.features.filters.onStockFilter &&
    `${query?.filter?.[IN_STOCK_KEY]}` === '1'

  const toggleOnlyInStock = useCallback(() => {
    if (onlyInStockFilterActive) {
      removeFilter(IN_STOCK_KEY)
      preferences.set(Preferences.IN_STOCK_KEY, false)
    } else {
      const newFilter = {
        ...query.filter,
        [IN_STOCK_KEY]: '1',
      }

      setNewQuery({
        ...query,
        filter: newFilter,
      })

      preferences.set(Preferences.IN_STOCK_KEY, true)
    }
  }, [onlyInStockFilterActive, removeFilter, setNewQuery, preferences, query])

  const setOrder = useCallback(
    (newOrder: IProductsQueryOrder) => {
      setNewQuery({
        ...query,
        page: 1,
        order: newOrder,
      })
      if (eventBus) eventBus.publish(productCollectionSortEvent(newOrder))
    },
    [setNewQuery, query, eventBus]
  )

  const removeKey = useCallback(
    (key: keyof IProductsQuery) => {
      const newQuery = { ...query }
      delete newQuery[key]
      setNewQuery(newQuery)
      preferences.remove()
      if (eventBus) {
        switch (key) {
          case 'order':
            eventBus.publish(productCollectionSortEvent())
            break
          case 'filter':
            eventBus.publish(productCollectionFilterEvent())
            break
          default:
            break
        }
      }
    },
    [query, setNewQuery, preferences, eventBus]
  )

  const onlyWithNewProductsFilterActive =
    config.features.newProductsFeature && `${query?.filter?.[NEW_KEY]}` === '1'

  const toggleOnlyNewProducts = useCallback(() => {
    if (onlyWithNewProductsFilterActive) {
      removeFilter(NEW_KEY)
      preferences.set(Preferences.NEW_KEY, false)
    } else {
      const newFilter = {
        ...query.filter,
        [NEW_KEY]: '1',
      }

      setNewQuery({
        ...query,
        filter: newFilter,
      })

      preferences.set(Preferences.NEW_KEY, true)
    }
  }, [
    onlyWithNewProductsFilterActive,
    removeFilter,
    preferences,
    query,
    setNewQuery,
  ])

  const onlyPurchasedProductsActive =
    config.features.filters.showOnlyPurchasedProducts &&
    `${query?.filter?.[ONLY_PURCHASED_KEY]}` === '1'

  const toggleOnlyPurchasedProducts = useCallback(() => {
    if (onlyPurchasedProductsActive) {
      removeFilter(ONLY_PURCHASED_KEY)
      preferences.set(Preferences.ONLY_PURCHASED_KEY, false)
    } else {
      const onlyPurchasedFilter = {
        ...query.filter,
        [ONLY_PURCHASED_KEY]: '1',
      }

      setNewQuery({
        ...query,
        filter: onlyPurchasedFilter,
      })

      preferences.set(Preferences.ONLY_PURCHASED_KEY, true)
    }
  }, [
    onlyPurchasedProductsActive,
    removeFilter,
    preferences,
    query,
    setNewQuery,
  ])

  const onlyRecentlyPurchasedProductsActive =
    config.features.filters.showOnlyRecentlyPurchasedProducts &&
    `${query?.filter?.[ONLY_RECENTLY_PURCHASED_KEY]}` === '1'

  const toggleOnlyRecentlyPurchasedProducts = useCallback(() => {
    if (onlyRecentlyPurchasedProductsActive) {
      removeFilter(ONLY_RECENTLY_PURCHASED_KEY)
      preferences.set(Preferences.ONLY_RECENTLY_PURCHASED_KEY, false)
    } else {
      const onlyRecentlyPurchasedFilter = {
        ...query.filter,
        [ONLY_RECENTLY_PURCHASED_KEY]: '1',
      }

      setNewQuery({
        ...query,
        filter: onlyRecentlyPurchasedFilter,
      })

      preferences.set(Preferences.ONLY_RECENTLY_PURCHASED_KEY, true)
    }
  }, [
    onlyRecentlyPurchasedProductsActive,
    removeFilter,
    preferences,
    query,
    setNewQuery,
  ])

  const onlyOnProductListActive =
    config.features.filters.showOnProductListFilter &&
    `${query?.filter?.[ON_PRODUCT_LIST_KEY]}` === '1'

  const toggleOnProductListProducts = useCallback(
    () => {
      if (onlyOnProductListActive) {
        removeFilter(ON_PRODUCT_LIST_KEY)
        preferences.set(Preferences.ON_PRODUCT_LIST_KEY, false)
      } else {
        const onlyProductListFilter = {
          ...query.filter,
          [ON_PRODUCT_LIST_KEY]: '1',
        }

        setNewQuery({
          ...query,
          filter: onlyProductListFilter,
        })

        preferences.set(Preferences.ON_PRODUCT_LIST_KEY, true)
      }
    },
    // eslint-disable-next-line react-hooks/exhaustive-deps
    [onlyPurchasedProductsActive, removeFilter, preferences, query, setNewQuery]
  )

  return {
    query,
    setPageNumber,
    setItemPerPageLimit,
    setTermFilter,
    setRangeFilter,
    setTaxonomyFilter,
    setOrder,
    removeFilter,
    removeKey,
    onlyWithPriceFilterActive,
    onlyWithNewProductsFilterActive,
    toggleOnlyNewProducts,
    onlyOwnBrandsFilterActive,
    setBrandsFilter,
    onlyInStockFilterActive,
    toggleOnlyInStock,
    setOnlyPriceFilter,
    setPriceRangeFilter,
    setAwardsFilter,
    onlyAwardsFilterActive,
    onlyPurchasedProductsActive,
    toggleOnlyPurchasedProducts,
    onlyRecentlyPurchasedProductsActive,
    toggleOnlyRecentlyPurchasedProducts,
    onlyOnProductListActive,
    toggleOnProductListProducts,
  }
}

export default useProductCollectionQuery
