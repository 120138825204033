import {
  IGrossPrice,
  IHazardStatements,
  IGhsClass,
  IStockInformation,
  IEstimatedDeliveryDaysRange,
  IStrikeThroughPrice,
  IPackagingUnit,
} from '@core/api/Products/types'
import { GenderTypes } from '../Customer/types'

interface ICartItemClientFields {
  [key: string]: any
}

export interface ICartItemVariantInformation {
  id: string
  name: string
  imageUrl: string
  slug: string
  sku: string
  clientFields: ICartItemClientFields
  estimatedDeliveryDaysRange?: IEstimatedDeliveryDaysRange
  packagingUnits: IPackagingUnit[]
}

export interface ICartItemProductInformation {
  id: string
  name: string
  sku: string
  imageUrl: string
  shortDescription?: string
  variant: ICartItemVariantInformation
  clientFields: ICartItemClientFields
  hazardStatements: IHazardStatements[]
  ghsClasses: IGhsClass[]
  isDiscontinued: boolean
  variationName?: string
  skuProvidedBySupplier?: string
}

export interface IAddCartItems {
  items: ICartItem2[]
}

export interface ICartItem2 {
  variantId: string
  quantity: number
  clientFields?: { [key: string]: any }
}

export interface ICartEvents {
  name: string
  payload: any
}

export interface ICreateCart {
  cartId?: string
  customerId?: string
  email?: string
  currency: string
}

export interface ICart {
  id: string
  customerId?: string
  estimatedDeliveryDate?: string
  userId?: string
  items: ICartItem[]
  unavailableItems?: ICartItem[]
  price: ICartPrice
  customerInformation?: IOrderCustomer
  billingInformation?: IOrderBillingInformation
  shippingAddress?: IOrderShippingAddress
  shippingMethod?: IShippingMethod
  paymentMethod?: IPaymentMethod
  voucherInformation?: IVoucherInformation
  additionalCartIds?: string[]
  wishedDeliveryDate?: {
    from: string
    to: string
  }
}

export interface IPaymentInformation {
  paymentMethodId: string
  payload: IPaymentInformationPayload
}

export interface IPaymentInformationPayload {
  [key: string]: any
}

// Temporarly has Label
export interface IOrderBillingInformation {
  referenceId?: string
  label?: string
  firstName: string
  lastName: string
  gender: GenderTypes
  company?: string
  street: string
  postalCode: string
  city: string
  region?: string
  country: string
  postOfficeBoxNumber?: string
  email?: string
  phone?: string
  vatId?: string
  notes?: string
  clientFields: { [key: string]: any }
}

export interface IOrderShippingAddress {
  referenceId?: string
  firstName?: string
  lastName?: string
  gender?: GenderTypes
  company?: string
  street: string
  postalCode: string
  city: string
  region?: string
  country: string
  phone?: string
  notes?: string
  clientFields: { [key: string]: any }
  label?: string
}

export interface ICartItem {
  product: ICartItemProductInformation
  quantity: number
  price?: IGrossPrice
  total?: IGrossPrice
  events?: ICartEvents[]
  stockInformation?: IStockInformation
  brandName?: string
  mainTaxonomyName?: string
  strikeThroughPrice?: IStrikeThroughPrice
  totalStrikeThroughPrice?: IStrikeThroughPrice
}

export interface ICartPrice {
  valueOfGoods: IGrossPrice
  valueOfGoodsWithFees: IGrossPrice
  subtotal: IGrossPrice
  shippingWithFees: IGrossPrice
  shipping: IGrossPrice
  subtotalWithFees: IGrossPrice
  total: IGrossPrice
  /**
   * @deprecated The value should not be used
   */
  fees: IFees[]
  feesV2: IFeesV2[]
  taxes: ITaxes[]
  voucherDiscount?: IGrossPrice
}

export interface IOrderCustomer {
  customerId?: string
  name: string
  email: string
  userEmail?: string
  userId?: string
  contactName?: string
}

export interface IShippingInformation {
  shippingMethodId: string
  desiredDeliveryDate?: string
  wishedDeliveryDate?: {
    from: string | null // example 2020-04-22
    to: string | null // example 2020-04-23
  }
  sendDataToThirdParties?: boolean
  payload: { [key: string]: any }
}

export function findItemInCart(cart: ICart, variantId: string) {
  return cart.items.find((item) => item.product.variant.id === variantId)
}

export function isItemInCart(cart: ICart, productId: string) {
  return findItemInCart(cart, productId) != null
}

/**
 * @deprecated The type should not be used anymoe
 */
export interface IFees {
  label: string
  amount: number
}

export interface IFeesV2 {
  label: string
  amount: IGrossPrice
}
export interface ITaxes {
  label: string
  amount: number
}

export interface IShipmentShippingMethod {
  id: string
  type: string
  label: string
  possibleDeliveryDateIsSelectable?: boolean
  possibleDeliveryDates: string[]
  sendsDataToThirdParties?: boolean
}

export const SHIPPING_PICKUP_TYPE = 'pickup_location'
export const FIXED_PRICE_TYPE = 'fixed_price_shipping'

export interface IPickupLocation {
  addition?: string
  city: string
  company?: string
  country: string
  firstName?: string
  lastName?: string
  postalCode: string
  street: string
  name?: string
}

export interface IClickAndCollectShippingMethod
  extends IShipmentShippingMethod {
  type: typeof SHIPPING_PICKUP_TYPE
  pickupLocation: IPickupLocation
}

export type IShippingMethod =
  | IShipmentShippingMethod
  | IClickAndCollectShippingMethod

export interface IShippingMethodCollection {
  total: number
  methods: IShippingMethod[]
}

export interface IPaymentMethodCollection {
  total: number
  availableMethods: IPaymentMethod[]
  unavailableMethods: IUnavailablePaymentMethod[]
}

export interface IPaymentMethod {
  id: string
  type: string
  label: string
}

export interface IUnavailablePaymentMethod {
  id: string
  type: string
  label: string
  reason: {
    code: string
    message: string
  }
}

export interface IVoucherInformation {
  id: string
  code: string
  absoluteDiscountValue: number
  percentageDiscountValue: string
}

export interface IAdditionalCheckoutInfo {
  customerPurchaseOrderNumber: string
}
