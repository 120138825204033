import BaseAPI from '@core/api/Platform/BaseAPI'
import { IDocumentSearchQuery, IDocumentSearchResponse } from './types'

class DocumentSearch extends BaseAPI {
  public async searchDocuments(
    query: IDocumentSearchQuery
  ): Promise<IDocumentSearchResponse> {
    const response = await this.api.get<IDocumentSearchResponse>(
      'document-search',
      {
        params: query,
        useCache: true,
        genericUrl: 'document-search',
      }
    )
    return response.data
  }
}

export default DocumentSearch
