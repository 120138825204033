import {
  IProduct,
  IProductSearch,
  IProductsQueryFilter,
  IProductsQueryOrder,
  ProductPromotionType,
} from '@core/api/Products/types'
import { IGenericPageContext } from '@core/utils/pages/PageContext'
import { createAction } from '@reduxjs/toolkit'

export interface IProductEventOptions {
  isPromotion?: boolean
  promotionType?: ProductPromotionType
}

export const productCollectionViewEvent = createAction(
  'product/collectionView',
  (
    productList: Array<IProductSearch | IProduct>,
    options: IProductEventOptions,
    pageContext: IGenericPageContext
  ) => {
    return {
      payload: {
        productList,
        options,
        pageContext,
      },
    }
  }
)

export const productCollectionSortEvent = createAction(
  'product/collectionSort',
  (sort?: IProductsQueryOrder) => {
    return {
      payload: {
        sort,
      },
    }
  }
)

export const productCollectionFilterEvent = createAction(
  'product/collectionFilter',
  (filter?: IProductsQueryFilter) => {
    return {
      payload: {
        filter,
      },
    }
  }
)

export const productClickEvent = createAction(
  'product/click',
  (
    product: IProductSearch | IProduct,
    options: IProductEventOptions,
    pageContext: IGenericPageContext
  ) => {
    return {
      payload: {
        product,
        options,
        pageContext,
      },
    }
  }
)

export type IProductCollectionViewEvent = ReturnType<
  typeof productCollectionViewEvent
>
export type IProductCollectionSortEvent = ReturnType<
  typeof productCollectionSortEvent
>
export type IProductCollectionFilterEvent = ReturnType<
  typeof productCollectionFilterEvent
>
export type IProductClickEvent = ReturnType<typeof productClickEvent>

export default {
  productCollectionViewEvent,
  productCollectionSortEvent,
  productCollectionFilterEvent,
  productClickEvent,
}
