import { IUserState } from '@core/store/user/types'
import { Action, ActionTypes } from './actions'

const initialState: IUserState = {}

export default function userReducer(
  state: IUserState = initialState,
  action: Action
) {
  switch (action.type) {
    case ActionTypes.SET_USER: {
      const user = action.payload
      return {
        ...state,
        user,
        anonymous: undefined,
      }
    }
    case ActionTypes.SET_ANONYMOUS_USER: {
      const user = action.payload
      return {
        ...state,
        user: undefined,
        anonymous: user,
      }
    }
    default:
      return state
  }
}
