import React from 'react'
import cn from 'classnames'
import isEmpty from 'lodash/isEmpty'
import Link from 'next/link'
import { TFunction } from 'next-i18next'
import { IDocumentSearchItem } from '@core/api/DocumentSearch/types'
import { RightCircleOutlined } from '@ant-design/icons'
import {
  IProductSearch,
  ICampaignUrl,
  ITaxonomyAggregationLeaf,
} from '@core/api/Products/types'
import { LYSButton, LYSRow, LYSTypography } from '@core/components/Primitives'
import config from '@core/config/config'
import { useTranslation } from '@core/i18n/i18n'
import { flatten } from '@core/utils/flatten'
import style from './AutoSuggestOptions.module.less'
import DocumentOption from './DocumentOption'
import ProductOption from './ProductOption'
import { AvailableSearchTypes } from './SearchTypeSwitch'
import TaxonomyOption from './TaxonomyOption'
import TermOption from './TermOption'
import { IAutoCompleteSuggestions } from './useSuggestions'
import routes from '@core/config/routes'
import TermIconOption from './TermIconOption'
import { SearchOutlined } from '@ant-design/icons'
import { ProductContextProvider } from '@core/utils/models/product/ProductContext'
import { useServices } from '@core/utils/ioc'
import { SelectOutlined } from '@ant-design/icons'
interface OptionGroup {
  label: React.ReactNode
  options: Option[]
}

interface Option {
  label: React.ReactNode
  value: string
}

const HintOption: React.FC<{ searchType: AvailableSearchTypes }> = ({
  searchType,
}) => {
  const { t } = useTranslation()
  return (
    <LYSRow>
      <LYSTypography.Text
        type={'secondary'}
        className={cn(style.option, style.plainText)}
      >
        {searchType === AvailableSearchTypes.DOCUMENT
          ? t`search.searchField.documentSearchHint`
          : t`search.searchField.productSearchHint`}
      </LYSTypography.Text>
    </LYSRow>
  )
}

const NoResultsOption: React.FC<{ searchType: AvailableSearchTypes }> = ({
  searchType,
}) => {
  const { t } = useTranslation()
  return (
    <LYSRow>
      <LYSTypography.Text
        type={'secondary'}
        className={cn(style.option, style.plainText)}
      >
        {searchType === AvailableSearchTypes.DOCUMENT
          ? t('search.searchField.documentSearchEmpty')
          : t('search.searchField.productSearchEmpty')}
      </LYSTypography.Text>
    </LYSRow>
  )
}

export const findMaxWeightedTaxonomyNodes = (
  leaf: ITaxonomyAggregationLeaf,
  amount = 5
) => {
  const flattenedLeafs = flatten(leaf, 'children', [])
  const sortedLeafs = flattenedLeafs.sort(
    (a, b) => b.productCount - a.productCount
  )
  const topSortedLeafs = sortedLeafs.splice(0, amount)
  return topSortedLeafs
}

const renderAsGroupOrEmpty = <T,>({
  optionsRender,
  data,
  label,
  appendOption,
}: {
  label: string | JSX.Element
  data?: T[]
  appendOption?: Option
  optionsRender: (data: T, i: number) => Option
}): OptionGroup | null => {
  const getOptions = () =>
    appendOption
      ? [...data!.map(optionsRender), appendOption]
      : data!.map(optionsRender)
  return isEmpty(data)
    ? null
    : {
        label,
        options: getOptions(),
      }
}

export function buildAutoSuggestOptions({
  campaignUrl,
  taxonomies,
  suggestions,
  recommendations,
  lastSearchStrings,
  searchText,
  isLoading,
  searchType,
  t,
}: {
  campaignUrl?: ICampaignUrl
  taxonomies?: ITaxonomyAggregationLeaf[]
  suggestions: IAutoCompleteSuggestions
  recommendations?: IProductSearch[]
  lastSearchStrings?: string[]
  searchText: string
  isLoading: boolean
  searchType: AvailableSearchTypes
  t: TFunction
}): Array<Option | OptionGroup> {
  const minSearchCharactersTyped = searchText.length > 2
  // eslint-disable-next-line react-hooks/rules-of-hooks
  const { productContextService } = useServices()
  const hasSuggestions = Object.keys(suggestions).some(
    (suggestionType) => !!(suggestions as any)[suggestionType]?.length
  )
  const hasNoResults =
    searchText && minSearchCharactersTyped && !hasSuggestions && !isLoading

  const isHint = !searchText || !minSearchCharactersTyped

  const showRecommendations =
    config.features.quickSearchRecommendations.enabled &&
    !hasSuggestions &&
    searchType === AvailableSearchTypes.PRODUCT

  const showLastSearches =
    config.features.quickSearchHistory.enabled &&
    lastSearchStrings &&
    lastSearchStrings?.length >= 1 &&
    !hasSuggestions &&
    searchType === AvailableSearchTypes.PRODUCT

  if (hasSuggestions || showRecommendations) {
    const groups = [
      renderAsGroupOrEmpty<string>({
        label: t`search.searchField.termResults`,
        data: suggestions.termSuggestions,
        optionsRender: (term) => ({
          value: term,
          label: <TermOption term={term} />,
        }),
      }),
      campaignUrl
        ? renderAsGroupOrEmpty<ICampaignUrl>({
            label: t`search.searchField.campaignUrl`,
            data: [campaignUrl],
            optionsRender: (campaignUrl) => ({
              value: campaignUrl.label,
              label: (
                <a href={campaignUrl.url} target="_blank" rel="noreferrer">
                  <SelectOutlined rotate={90} rev={undefined} />{' '}
                  {campaignUrl.label}
                </a>
              ),
            }),
          })
        : null,
      taxonomies
        ? renderAsGroupOrEmpty<ITaxonomyAggregationLeaf>({
            label: t`search.searchField.taxonomyResults`,
            data: taxonomies,
            optionsRender: (taxonomy) => ({
              value: `taxonomy_${taxonomy.id}`,
              label: <TaxonomyOption search={searchText} taxonomy={taxonomy} />,
            }),
          })
        : null,
      renderAsGroupOrEmpty<IProductSearch>({
        label: t`search.searchField.productResults`,
        data: suggestions.productSuggestions,
        optionsRender: (product) => ({
          value: `product_${product.id}`,
          label: (
            <ProductContextProvider
              productContext={productContextService.getProductSearchContext(
                product,
                product.mainVariant
              )}
            >
              <ProductOption product={product} />
            </ProductContextProvider>
          ),
        }),
      }),

      showLastSearches
        ? renderAsGroupOrEmpty<string>({
            label: (
              <>
                <HintOption searchType={searchType} />
                <LYSRow
                  className={cn(
                    style.recommendationTitleContainer,
                    style.option,
                    style.plainText
                  )}
                >
                  <LYSTypography.Text className={cn(style.recommendationTitle)}>
                    {t`search.searchField.lastSearches`}
                  </LYSTypography.Text>
                </LYSRow>
              </>
            ),
            data: lastSearchStrings,
            optionsRender: (term) => ({
              value: term,
              label: <TermIconOption icon={SearchOutlined} term={term} />,
            }),
          })
        : null,

      showRecommendations
        ? renderAsGroupOrEmpty<IProductSearch>({
            label: (
              <>
                <LYSRow
                  className={cn(
                    style.recommendationTitleContainer,
                    style.option,
                    style.plainText
                  )}
                >
                  <LYSTypography.Text className={cn(style.recommendationTitle)}>
                    {t`search.searchField.recommendations`}
                  </LYSTypography.Text>
                </LYSRow>
              </>
            ),
            appendOption: {
              value: t`search.searchField.recommendations`,
              label: (
                <div className={style.moreRecommendationsButtonWrap}>
                  <Link {...routes.recommendations}>
                    <LYSButton
                      className={style.moreRecommendationsButton}
                      type={'link'}
                      icon={<RightCircleOutlined rev={undefined} />}
                    >
                      {t`search.searchField.moreRecommendations`}
                    </LYSButton>
                  </Link>
                </div>
              ),
            },
            data: recommendations,
            optionsRender: (product) => ({
              value: `product_${product.id}`,
              label: (
                <ProductContextProvider
                  productContext={productContextService.getProductSearchContext(
                    product,
                    product.mainVariant
                  )}
                >
                  <ProductOption product={product} />
                </ProductContextProvider>
              ),
            }),
          })
        : null,
      renderAsGroupOrEmpty<IDocumentSearchItem>({
        label: t`search.searchField.documentResults`,
        data: suggestions.documentSuggestions,
        optionsRender: (document) => ({
          value: `document_${document.id}`,
          label: <DocumentOption document={document} />,
        }),
      }),
    ]

    return groups.filter((group) => !!group) as OptionGroup[]
  }

  if (hasNoResults) {
    return [
      {
        value: '',
        label: <NoResultsOption searchType={searchType} />,
      },
    ]
  }
  if (isHint) {
    return [
      {
        value: '',
        label: <HintOption searchType={searchType} />,
      },
    ]
  }
  return []
}
