import { MenuItem } from '@core/components/shared/menu/MenuItem'
import cn from 'classnames'
import { DownOutlined } from '@ant-design/icons'
import { LYSIcon } from '@core/components/Primitives'
import { MenuItemProps } from '@core/components/shared/menu/MenuItem'
import routes from '@core/config/routes'
import { ITaxonomy } from '@core/api/Categories/types'
import style from './index.module.less'

export interface CategoryNavItemProps extends MenuItemProps {
  category: ITaxonomy
  isVirtualMenuItem?: boolean
}

export const CategoryNavItem: React.FC<CategoryNavItemProps> = ({
  category,
  isVirtualMenuItem,
  ...props
}) => (
  <MenuItem
    {...props}
    route={isVirtualMenuItem ? undefined : routes.taxonomy(category)}
    className={cn(props.className, style.categoryNavItem)}
    activeClassName={style.active}
    title={category.name}
  >
    <span>{category.name}</span>
    <LYSIcon component={DownOutlined} className={style.categoryNavItemIcon} />
  </MenuItem>
)
