import { createSelector } from 'reselect'
import { IAppState } from '@core/store/types'
import { ModalType } from './actions'

const getModalState = (state: IAppState) => state.modals

export const getActiveModal = createSelector(
  getModalState,
  (modalState) => modalState.activeModal
)

export const isModalActive = createSelector(
  getActiveModal,
  (_: IAppState, modalType: ModalType) => modalType,
  (activeModal, modalType) => activeModal === modalType
)

export const getModalQueue = createSelector(
  getModalState,
  (modalState) => modalState.modalQueue
)

export const isModalInQueue = createSelector(
  getModalQueue,
  (_: IAppState, modalType: ModalType) => modalType,
  (modalQueue, modalType) => modalQueue.findIndex((el) => el === modalType)
)
