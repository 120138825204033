import config from '@core/config/config'
import { IShopServices } from '@core/config/services'
import {
  productCollectionSortEvent,
  IProductCollectionSortEvent,
  productCollectionFilterEvent,
  IProductCollectionFilterEvent,
} from '@core/events/product'
import {
  teaserViewEvent,
  ITeaserViewEvent,
  ITeaserClickEvent,
  teaserClickEvent,
} from '@core/events/teaser'
import { ConverlyticsQueue } from '@core/modules/converlytics-tracking/ConverlyticsQueue'
import trackingConfig from './config'
import {
  getSortingAction,
  getSortingCategory,
  normalizeText,
  getFilterCategory,
  getFilterAction,
  getFilterLabel,
} from './utils'

const setupEventTracking = (
  services: IShopServices,
  queue: ConverlyticsQueue
) => {
  const { eventBus, router } = services
  if (window && window.document) {
    window.document.addEventListener('click', (event) => {
      const data = createClickCustomEventData(services, event)

      if (data) {
        queue.push(data)
      }
    })
  }

  eventBus.subscribe<IProductCollectionSortEvent>(
    productCollectionSortEvent.type,
    ({ payload }) => {
      const data = {
        event: trackingConfig.customEventName,
        eventCategory: getSortingCategory(payload.sort),
        eventAction: getSortingAction(payload.sort),
        eventLabel: config.baseUrl + router.asPath,
      }

      queue.push(data)
    }
  )

  eventBus.subscribe<IProductCollectionFilterEvent>(
    productCollectionFilterEvent.type,
    ({ payload }) => {
      const data = {
        event: trackingConfig.customEventName,
        eventCategory: getFilterCategory(payload.filter),
        eventAction: getFilterAction(payload.filter),
        eventLabel: getFilterLabel(payload.filter),
      }

      queue.push(data)
    }
  )

  eventBus.subscribe<ITeaserViewEvent>(teaserViewEvent.type, ({ payload }) => {
    const name = payload.teaser.teaser_image.alt
      ? `${trackingConfig.customEventActions.view}-${normalizeText(
          payload.teaser.teaser_image.alt
        )}`
      : trackingConfig.customEventActions.view

    const data = {
      event: trackingConfig.eventNames.promotionView,
      eventCategory: trackingConfig.customEventCategories.teaser,
      eventAction: name,
      eventLabel: `${config.baseUrl + router.asPath}_${name}`,
    }

    queue.push(data)
  })

  eventBus.subscribe<ITeaserClickEvent>(
    teaserClickEvent.type,
    ({ payload }) => {
      const name = payload.teaser.teaser_image.alt
        ? `${trackingConfig.customEventActions.click}-${normalizeText(
            payload.teaser.teaser_image.alt
          )}`
        : trackingConfig.customEventActions.click

      const data = {
        event: trackingConfig.eventNames.promotionClick,
        eventCategory: trackingConfig.customEventCategories.teaser,
        eventAction: name,
        eventLabel: `${config.baseUrl + router.asPath}_${name}`,
      }

      queue.push(data)
    }
  )
}
export interface CustomEventData {
  event: string
  eventCategory: string
  eventAction: string
  eventLabel: any
}
const createClickCustomEventData = (
  _: IShopServices,
  event: any // must be set here to any otherwise typescript hell
): CustomEventData | undefined => {
  if (event && event.target) {
    if (event && event.target.localName) {
      switch (event.target.localName) {
        case 'a': {
          let label = ''
          const href = event.target.getAttribute('href')
          if (href)
            label = href.indexOf(`http`) > -1 ? href : config.baseUrl + href

          return {
            event: trackingConfig.customEventName,
            eventCategory: trackingConfig.customEventCategories.link,
            eventAction: trackingConfig.customEventActions.click,
            eventLabel: `${event.target.innerText}.${label}`,
          }
        }
        case 'img': {
          let label = ''
          const src = event.target.getAttribute('src')
          if (src) label = src.indexOf(`http`) > -1 ? src : config.baseUrl + src

          return {
            event: trackingConfig.customEventName,
            eventCategory: trackingConfig.customEventCategories.image,
            eventAction: trackingConfig.customEventActions.click,
            eventLabel: label,
          }
        }
        case 'button': {
          let label = event.target.innerText || ''
          if (!label) label = event.target.getAttribute('aria-label')
          if (!label) label = trackingConfig.customIconButtonLabel

          return {
            event: trackingConfig.customEventName,
            eventCategory: trackingConfig.customEventCategories.button,
            eventAction: trackingConfig.customEventActions.click,
            eventLabel: label,
          }
        }
        case 'span': {
          // we need to look at the parent element if it is anchor tag (dropdown menu)
          const parent = event.target.parentElement
          if (parent && parent.localName === 'a') {
            let label = ''
            const href = parent.getAttribute('href')
            if (href)
              label = href.indexOf(`http`) > -1 ? href : config.baseUrl + href

            return {
              event: trackingConfig.customEventName,
              eventCategory: trackingConfig.customEventCategories.link,
              eventAction: trackingConfig.customEventActions.click,
              eventLabel: `${parent.innerText}.${label}`,
            }
          }
          return undefined
        }
        default:
          return undefined
      }
    }
  }
}

export default setupEventTracking
