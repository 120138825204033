import React, { useMemo } from 'react'
import { IProduct } from '@core/api/Products/types'
import { useRelatedProducts } from '@core/components/ProductDetail/useRelatedProducts'
import { useTranslation } from '@core/i18n/i18n'
import { useProductDetailContext } from '@core/utils/models/product/ProductContext'
// eslint-disable-next-line import/no-cycle
import ProductRelationBase from '@core/components/ProductDetail/ProductRelations/ProductRelationBase'

export const ALTERNATIVE_CONTAINER_ID = 'ProductAlternatives'

export const useFollowUpProducts = (product?: IProduct) => {
  const { relatedProducts } = useRelatedProducts(product, 'followup')

  return useMemo(() => {
    // Do not display follow ups if product is not discontinued
    if (
      !product?.mainVariant.packagingUnits[0]?.stockInformation?.isDiscontinued
    )
      return []

    // Only display available products, excluding self
    return relatedProducts.filter(
      (p) => p.mainVariant.packagingUnits[0].purchasable && p.id !== product.id
    )
  }, [product, relatedProducts])
}

const FollowUpProductRelations: React.FC = () => {
  const { t } = useTranslation()
  const { product } = useProductDetailContext() || {}
  const followUpProducts = useFollowUpProducts(product)

  return (
    <div id={ALTERNATIVE_CONTAINER_ID}>
      <ProductRelationBase
        title={t('productDetail.alternatives.title')}
        products={followUpProducts}
      />
    </div>
  )
}

export default FollowUpProductRelations
