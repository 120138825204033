import Head from 'next/head'
import config from '@core/config/config'
import i18n from '@core/config/i18n'
import { useServices } from '@core/utils/ioc'

export type HeadTagsProps = {
  title?: string
  ogTitle?: string
  description?: string
  ogDescription?: string
  ogImage?: string
  ogUrl?: string
  ogType?: string
  canonical?: string
  index?: boolean
  follow?: boolean
  keywords?: string
}

const conditionallyRenderTag = (
  identifier: string,
  type: 'property' | 'name',
  content?: string
) => {
  const identifierProp = { [type]: identifier }
  return (
    content && <meta key={identifier} {...identifierProp} content={content} />
  )
}

// this component needs to include <Head> as title & meta tags
// need to be either direct descendants or at most inside one fragment
// https://github.com/zeit/next.js/issues/8384
const HeadTags: React.FC<HeadTagsProps> = ({
  title,
  ogTitle,
  description,
  ogDescription,
  ogUrl,
  ogImage,
  ogType,
  canonical,
  index,
  follow,
  keywords,
}) => {
  const { environment } = useServices()

  const robots = [
    index === true ? 'index' : index === false ? 'noindex' : undefined,
    follow === true ? 'follow' : follow === false ? 'nofollow' : undefined,
  ]
    .filter((item) => !!item)
    .join(',')

  return (
    <Head>
      {title && <title key={'title'}>{title}</title>}
      {description && (
        <meta key="description" name="description" content={description} />
      )}

      <link rel="icon" type="image/x-icon" href={config.assets.favicon} />

      {/* Twitter card data  */}
      <meta name="twitter:card" content="summary" key="twitter:card" />

      <link
        key={'api-dns-prefetch'}
        rel="dns-prefetch"
        href={environment?.get('API_URL')}
      />

      <meta
        key={'content:language'}
        httpEquiv={'content-language'}
        content={i18n.language}
      />

      {/* Open Graph data */}
      {conditionallyRenderTag('og:title', 'property', ogTitle || title)}
      {conditionallyRenderTag(
        'og:description',
        'property',
        ogDescription || description
      )}
      {conditionallyRenderTag('og:url', 'property', ogUrl)}
      {conditionallyRenderTag('og:image', 'property', ogImage)}
      {conditionallyRenderTag('og:type', 'property', ogType)}
      {conditionallyRenderTag('keywords', 'name', keywords)}

      {canonical !== undefined && (
        <link rel="canonical" href={canonical} key="canonical" />
      )}

      {robots.length !== 0 && (
        <meta name="robots" content={robots} key="robots" />
      )}
    </Head>
  )
}

export default HeadTags
