import { ITaxonomyWithoutChildren } from '@core/api/Categories/types'
import { IClientFields } from '@core/api/types'
import config from '@core/config/config'

export const PRICE_KEY = 'price'
export const HAS_PRICE_KEY = 'hasPrice'
export const NEW_KEY = 'isNew'
export const HAS_AWARDS_KEY = 'hasAwards'
export const OWN_BRANDS_KEY = 'isOwnBrand'
export const IN_STOCK_KEY = 'isOnStock'
export const ONLY_PURCHASED_KEY = 'showOnlyPurchasedProducts'
export const ONLY_RECENTLY_PURCHASED_KEY = 'showOnlyRecentlyPurchasedProducts'
export const ON_PRODUCT_LIST_KEY = 'showOnlyProductsFromProductLists'

export enum AggregationsTypes {
  RANGE = 'range',
  TERM = 'bucket',
}

export interface ISearchTermSuggestions {
  searchTerm: string
  suggestions: string[]
}

export interface ISuggestions {
  searchTermSuggestions?: ISearchTermSuggestions
}

export interface IProductCollection {
  total: number
  maxScore: number
  hits: IProductSearch[]
  aggregations: IProductAggregation[]
  suggestions?: ISuggestions
  taxonomyAggregationRoot?: ITaxonomyAggregationLeaf
  campaignUrl?: ICampaignUrl
}

export interface ICampaignUrl {
  url: string
  label: string
}

export interface IProductSearch {
  id: string
  score: number
  name: string
  sku: string
  isActive: boolean
  mainVariant: IVariantSearch
  variants: IVariantSearch[]
  description: string
  shortDescription: string
  clientFields: IProductClientFields
  attachments: IAttachments[]
  brand?: IBrand
  mainTaxonomy: ITaxonomyWithoutChildren
  taxonomies: ITaxonomyWithoutChildren[]
  hazardStatements: IHazardStatements[]
  ghsClasses: IGhsClass[]
  isDiscontinued: boolean
  variationName?: string
  skuProvidedBySupplier?: string
  assortments: Assortment[]
  isNew: boolean
}

export interface Assortment {
  id: string
  label: string
  clientFields: any[]
  purchasable: boolean
}

export interface IVariantSearch {
  id: string
  isActive: boolean
  name: string
  description: string
  shortDescription: string
  clientFields: IVariantClientFields
  sku: string
  gtin?: string
  slug: string
  price?: IGrossPrice
  images?: IVariantImage[]
  attachments: IAttachments[]
  outOfStock: boolean
  isDiscontinued: boolean
  purchasable: boolean
  unitPrice: IUnitPrice
  strikeThroughPrice?: IStrikeThroughPrice
  estimatedDeliveryDaysRange?: IEstimatedDeliveryDaysRange
  packagingUnits: IPackagingUnit[]
}

export interface IProduct {
  clientFields: IProductClientFields
  variants: IVariant[]
  sku: string
  mainVariant: IVariant
  description: string
  shortDescription: string
  id: string
  mainTaxonomy: ITaxonomyWithoutChildren
  taxonomies: ITaxonomyWithoutChildren[]
  name: string
  attachments: IAttachments[]
  relatedProducts: IRelatedProducts
  brand?: IBrand
  hazardStatements: IHazardStatements[]
  ghsClasses: IGhsClass[]
  precautionaryStatements: IPrecautionaryStatement[]
  biocideProductTypes: IBiocideProductType[]
  awards: IAward[]
  isDiscontinued: boolean
  breadcrumbs: {
    hierarchy: ITaxonomyWithoutChildren[]
  }
  variationName?: string
  skuProvidedBySupplier?: string
  assortments: Assortment[]
  isNew: boolean
}

export interface IRelatedProducts {
  // in theory there are more types (like has_accessories which is the same as bundle and has_addition which is the
  // the same as crossSell), but api exports both, so we only have to check for the types that are mentioned here
  variations?: string[]
  bundle?: string[]
  followup?: string[]
  crossSell?: string[]
  sparePart?: string[]
}

export type IProductRelationType = keyof IRelatedProducts

export interface IAward {
  id: string
  label: string
  image: string
  slug: string
  url: string
}
export interface IAwardCollection {
  total: 1
  awards: Array<Omit<IAward, 'slug'>>
}

export interface IAwardsQuery {
  page?: number
  offset?: number
  limit?: number
  'order[label]'?: string
}
export interface IBrand {
  id: string
  name: string
  image?: string
  slug: string
}

export interface IAttachments {
  clientFields: IClientFields[]
  filename: string
  filesize: number
  id: string
  mime: string
  name: string
  tags: string[]
  url: string
}

export interface IWarehouse {
  id: string
  channelIds: string[]
  clientFields: IClientFields
}

export interface IProductClientFields extends IClientFields {
  attributes: IAttribute[]
  masterName: string
  masterNumber: string
  weight: {
    gross: number
    net: number
    unit: string
  }
  dimension: {
    height: number
    length: number
    unit: string
    width: number
  }
  biocidal: boolean
  meta?: IPageMetadata
}

export interface IHazardStatements {
  code: string
  phrase: string
}

export interface IPrecautionaryStatement {
  /**
   * Reference code for the precautionary statement, as classified by GHS.      *      *     Please note that this value is mostly displayed for reference and ease of translation,      *     but the what is required by the standard is to render the associated statement phrase.      *
   * @type {string}
   * @memberof PrecautionaryStatement
   */
  code: string
  /**
   * A sentence describing what the hazard consists of.      *      *      This phrase should be rendered to warn people interacting with the chemical.      *
   * @type {string}
   * @memberof PrecautionaryStatement
   */
  phrase: string
}

export interface IBiocideProductType {
  number: number
  description: string
  mainGroup: string
}

export interface IGhsClass {
  code: string
  label: string[]
}

export interface IPageMetadata {
  keywords: string
  description: string
  robots: string
}

export interface IAttribute {
  key: string
  label: string
  value: string | number
}

export interface IEstimatedDeliveryDaysRange {
  minDays: number
  maxDays: number
}

export interface IVariant {
  id: string
  sku: string
  gtin?: string
  name: string
  slug: string
  description: string
  images?: IVariantImage[]
  price?: IGrossPrice
  fees?: IFee[]
  attachments: IAttachments[]
  clientFields: IVariantClientFields
  isActive: boolean
  purchasable: boolean
  stockInformation?: IStockInformation
  unitPrice: IUnitPrice
  weight?: IWeight
  strikeThroughPrice?: IStrikeThroughPrice
  estimatedDeliveryDaysRange?: IEstimatedDeliveryDaysRange
  packagingUnits: IPackagingUnit[]
}

export interface IStock {
  id: string
  available: number
  isDiscontinued: boolean
}

export interface IStockInformation {
  isOutOfStock: boolean
  stock?: IStock
  isDiscontinued: boolean
}

export interface IPackagingUnit {
  name: string
  multiplier: number
  stockInformation: IStockInformation
  purchasable: boolean
  outOfStock: boolean
  strikeThroughPrice?: IStrikeThroughPrice
  price?: IGrossPrice
  unitPrice: IUnitPrice
}

export interface IWeight {
  net: number
  gross: number
  unit: string
}

export interface IUnitPrice {
  unit: string
  quantity: number
  gross: number
  net: number
  currency: string
}

export interface IVariantClientFields extends IClientFields {
  packageQuantity: number
}

export interface IVariantImage {
  id: string
  url: string
}

export interface IFee {
  id: string
  type: string
  price: {
    amount: number
  }
}

export interface IGrossPrice {
  tax: number
  net: number
  gross: number
  currency: string
}

export interface IStrikeThroughPrice {
  price: IGrossPrice
  discountPercentage: number
}

export function getVariantFromProduct(product: IProduct, variantId?: string) {
  return product.variants.find((el) => el.id === variantId)
}

const variantImagePlaceholder: IVariantImage = {
  id: 'placeholder',
  url: config.assets.placeholderImage,
}

export interface IFormattedImage {
  original: string
  thumbnail: string
  alt: string
  title: string
  thumbnailAlt: string
  thumbnailTitle: string
  fullscreen?: string
  description?: string
}

export function getImagesFromVariantOrPlaceholder(
  variant: IVariantSearch | IVariant
): IVariantImage[] {
  return variant.images && variant.images.length > 0
    ? variant.images
    : [variantImagePlaceholder]
}

export function getImageFromVariantOrPlaceholder(
  variant: IVariantSearch | IVariant
): IVariantImage {
  return variant.images && variant.images.length > 0
    ? variant.images[0]
    : variantImagePlaceholder
}

export function getImagePlaceholder() {
  return config.assets.placeholderImage
}

export function getDescriptionFromProductOrVariant(
  product: IProduct,
  variant: IVariant
) {
  return variant.description || product.description
}

export type IProductAggregation =
  | IProductAggregationTerm
  | IProductAggregationRange

export interface IProductAggregationTerm {
  filterKey: string
  filterLabel: string
  type: AggregationsTypes.TERM
  filterValues: IProductAggregationFilterValue[]
}

export interface IProductAggregationRange {
  filterKey: string
  filterLabel: string
  type: AggregationsTypes.RANGE
  count: number
  min: number
  max: number
  avg: number
  sum: number
}

export interface IProductAggregationFilterValue {
  value: string
  count: number
}

export interface ITaxonomyAggregationLeaf {
  id: string
  name: string
  slug: string
  children: ITaxonomyAggregationLeaf[]
  productCount: number
}

export interface IProductsQuery {
  page?: number
  search?: string
  filter?: IProductsQueryFilter
  offset?: number
  limit?: number
  order?: IProductsQueryOrder
  requiresAggregations?: 0 | 1
  track?: 0 | 1
}

export interface IProductsCollectionQuery extends IProductsQuery {
  limit: number
  page: number
}

export interface IProductsQueryFilter {
  [key: string]: string[] | string | IProductsQueryFilterRange | number
}
export interface IProductsQueryOrder {
  [key: string]: IProductsQueryOrderValues
}

export type IProductsQueryOrderValues = 'asc' | 'desc'

export interface IProductsQueryFilterRange {
  gte: number
  lte: number
}

export enum ProductViewType {
  GRID = 'GRID',
  LIST = 'LIST',
}

export enum ProductPromotionType {
  Variations = 'variations',
  Recommendation = 'recommendation',
  Bundle = 'bundle',
}

export interface IAffiliateInformation {
  partnerName: string
  referenceId?: string
}
