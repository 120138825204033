import React from 'react'
import Image from 'next/image'

interface IProps
  extends React.DetailedHTMLProps<
    React.ImgHTMLAttributes<HTMLImageElement>,
    HTMLImageElement
  > {
  src: string
  alt: string
  title?: string
  className?: string
  width: number
  height: number
  placeholder?: 'blur' | 'empty'
  layout?: Exclude<'fixed' | 'intrinsic' | 'responsive', 'fill'>
}

const LYSStaticImage: React.FC<IProps> = ({
  className,
  src,
  alt,
  title,
  width,
  height,
  layout,
  placeholder,
  ...props
}) => {
  return (
    <Image
      priority={true}
      placeholder={placeholder}
      className={className}
      alt={alt}
      src={src}
      width={width}
      height={height}
      layout={layout}
      title={title || alt}
      {...props}
    />
  )
}

export default LYSStaticImage
