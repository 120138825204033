import { IShopServices } from '@core/config/services'
import setupConverlyticsTracking from '@core/modules/converlytics-tracking'
import setupEmarsysTracking from '@core/modules/emarsys-tracking'
import { setupIgefaTracking } from '@core/tracking'
//import { _removeAllSubscribers } from '@core/utils/eventbus'

export default function initCustom(services: IShopServices) {
  // TODO: For development needs, see function documentation for more details
  // _removeAllSubscribers(_services.eventBus)
  setupIgefaTracking(services)
  setupConverlyticsTracking(services)
  setupEmarsysTracking(services)
}
