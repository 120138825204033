import {
  MenuContext,
  MenuItemDefinition,
} from '@core/components/shared/menu/MenuBehaviour/types'

export const getMenuPath = (current: MenuContext | undefined) => {
  let path = ''
  while (current) {
    path = path ? `${current.menuId}_${path}` : current.menuId
    current = current.parent
  }
  return path
}

export const generateMenuItemElementId = (
  menuPath: string,
  item: MenuItemDefinition
) => `menu_item_${menuPath}_${item.id}`

export const generateMenuContentElementId = (
  menuPath: string,
  item: MenuItemDefinition
) => `menu_content_${menuPath}_${item.id}`

export const focusMenuItem = (menuPath: string, item?: MenuItemDefinition) => {
  if (!item) return
  const nextEl = document.getElementById(
    generateMenuItemElementId(menuPath, item)
  )
  nextEl?.focus()
}
