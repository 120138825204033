import React, { useState } from 'react'
import { ICreateProductList } from '@core/api/ProductLists/types'
import AddToProductListSelect from '@core/components/Me/ProductList/AddToProductListSelect'
import AddToCartInput from '@core/components/ProductCollection/AddToCartInput'

import useCart from '@core/hooks/cart/useCart'
import usePriceRequests from '@core/hooks/priceRequest/usePriceRequests'
import useProductLists from '@core/hooks/productList/useProductLists'
import {
  getPackagingUnitsFromContext,
  useProductPriceRequestContext,
  useProductSearchContext,
} from '@core/utils/models/product/ProductContext'
import style from './index.module.less'
import PriceRequestPendingInfo from '@core/components/ProductCollection/PriceRequestStatusComponents/PriceRequestPendingInfo'
import PriceRequestCta from '@core/components/ProductCollection/PriceRequestStatusComponents/PriceRequestCta'
import PriceRequestStatusInfo from '@core/components/ProductCollection/PriceRequestStatusComponents/PriceRequestStatusInfo'
import { LYSCol, LYSRow } from '@core/components/Primitives'

interface OwnProps {
  hidePriceRequestPendingInfo?: boolean
  hideAddToCartButtonLabel?: boolean
}

const ProductActionElements: React.FC<OwnProps> = ({
  hidePriceRequestPendingInfo,
  hideAddToCartButtonLabel = false,
}) => {
  const [quantity, setQuantity] = useState<number>(1)
  const { addToCart } = useCart()
  const { addToProductList, createProductList } = useProductLists()

  const productSearchContext = useProductSearchContext()
  const productPriceRequestContext = useProductPriceRequestContext()

  const productContext = productSearchContext ?? productPriceRequestContext

  const { product } = productContext ?? {}

  const { isPendingPriceRequest, isRequestable } = usePriceRequests(product)

  if (!productContext || !product) return null

  const { packagingUnit } = getPackagingUnitsFromContext(productContext)
  if (!packagingUnit) return null

  const { multiplier } = packagingUnit

  const {
    rules: { isPurchasable },
  } = productContext

  const hasPrice = !!packagingUnit.price

  const { isDiscontinued } = product

  return (
    <LYSRow justify="space-around" align="middle">
      <LYSCol sm={20}>
        {!isDiscontinued && (
          <div className={style.buttons}>
            {!hasPrice &&
              !hidePriceRequestPendingInfo &&
              isPendingPriceRequest && <PriceRequestStatusInfo />}

            {!isRequestable && (
              <AddToCartInput
                hideLabel={hideAddToCartButtonLabel}
                onAddToCart={() =>
                  addToCart(
                    product.mainVariant,
                    product.name,
                    multiplier * quantity
                  )
                }
                onSetQuantity={setQuantity}
                quantity={quantity}
                isAllowedToAddToCart={productContext.rules.isPurchasable}
                purchasable={isPurchasable}
              />
            )}
            {isRequestable && (
              <>
                {isPendingPriceRequest && (
                  <PriceRequestPendingInfo paddingBottom={false} />
                )}
                {!isPendingPriceRequest && (
                  <PriceRequestCta variantId={product.mainVariant.id} />
                )}
              </>
            )}
          </div>
        )}
      </LYSCol>
      <LYSCol sm={4}>
        <AddToProductListSelect
          onSelectProductList={(productListId: string) =>
            addToProductList(productListId, product.mainVariant.id, quantity)
          }
          onCreateProductList={(productList: ICreateProductList) =>
            createProductList(productList, [
              { variantId: product.mainVariant.id, quantity: quantity },
            ])
          }
          notAllowedToAddToProductList={!productContext.rules.isPurchasable}
          product={product}
        />
      </LYSCol>
    </LYSRow>
  )
}

export default ProductActionElements
