import React from 'react'
import { PickerTimeProps } from 'antd/lib/date-picker/generatePicker'
import { Dayjs } from 'dayjs'
import DatePicker from './LYSDatePicker'

export type TimePickerProps = Omit<PickerTimeProps<Dayjs>, 'picker'>

const LYSTimePicker = React.forwardRef<any, TimePickerProps>((props, ref) => {
  return <DatePicker {...props} picker="time" mode={undefined} ref={ref} />
})

LYSTimePicker.displayName = 'TimePicker'

export default LYSTimePicker
