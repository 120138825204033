import { NextPageContext } from 'next'
import { UAParser } from 'ua-parser-js'
import { AsyncProps, PageType } from '@core/utils/pages/types'

function userAgentParser(ctx: NextPageContext) {
  let useragent = ''

  if (!!ctx && typeof ctx.req !== 'undefined') {
    useragent = ctx.req.headers['user-agent'] || ''
  } else if (typeof window !== 'undefined') {
    useragent = window.navigator.userAgent
  }
  const {
    device: { type },
  } = new UAParser(useragent).getResult()

  const isMobile: boolean = type === 'mobile'
  const isTablet: boolean = type === 'tablet'

  return {
    isMobile,
    isTablet,
  }
}

export const getDefaultPageProps = async (ctx: NextPageContext) => {
  const { services } = ctx
  const { prismicApi } = services

  const { isMobile, isTablet } = userAgentParser(ctx)

  const prismicInfoBanner = await prismicApi.getInfoBanner()

  const userDetails = await services.api.user.getUserDetails()

  const featureFlags = userDetails.featureFlags

  return {
    pageType: PageType.generic as PageType,
    isMobile,
    isTablet,
    prismicInfoBanner,
    featureFlags,
  }
}

export type DefaultPageProps = AsyncProps<
  ReturnType<typeof getDefaultPageProps>
>

export type PartialDefaultPageProps = Partial<DefaultPageProps>
