import { NextRouter } from 'next/router'

/// =========================================================== ///
/// ================= STORE RELATED CONFIG ==================== ///
/// =========================================================== ///
const gtmContainerId = 'GTM-PP4HNS5'
const isIgefaStore = true // toggle to true if this shop is for igefa
const hashPrefix = '1' // 1 = igefa, 2 = idealclean, 3 = idealsafety
const hashSalt = 'afegi' // should be set to something different for each instance
/// =========================================================== ///
/// ================ COMMON RELATED CONFIG ==================== ///
/// =========================================================== ///
const cookieName = 'converlyticsUserId'
/// =========================================================== ///
/// ============= PAGE VIEW RELATED CONFIG ==================== ///
/// =========================================================== ///
const pageViewEventName = 'pageView'
const pageNamePrefixes = {
  b2BShop: 'bc',
  b2CShop: 'pc',
}
const contentGroup1 = {
  b2BShop: 'bc-shop',
  b2CShop: 'pc-shop',
}
const contentGroup2 = {
  prefix: 'level-',
}
const contentGroup3 = {
  products: 'products',
}
const contentGroup4 = {
  topCategory: 'top-category',
  subCategory: 'sub-category',
  service: 'service', // needs special mapping
  legal: 'legal', // needs special mapping
}
const customPageTypesMap = {
  [contentGroup4.legal]: [
    'impressum',
    'terms-and-conditions',
    'data-protection',
    'cookie-policy',
    'agb',
    'datenschutz',
  ],
  [contentGroup4.service]: [
    'faq',
    'delivery',
    'return-policy',
    'hilfe_kontakt',
    'zahlung',
    'lieferung',
    'ruecksendung',
    'widerruf',
  ],
}
const dimension1 = {
  openShop: 'open-shop',
  closedShop: 'closed-shop',
}
const dimension2 = {
  existingCustomer: 'existing-customer',
  guestCustomer: 'guest-customer',
}
const dimension3 = {
  available: 'available',
  notAvailable: 'sold-out',
}
const dimension4 = {
  advancePayment: '-payment-in-advance',
  creditCard: '-credit-card',
  paypal: '-sofort-ueberweisung',
  bankTransfer: '-bank-transfer',
  invoice: '-invoice',
  unknown: '-unknown-payment',
}
const dimension5 = {
  loggedIn: 'logged-in',
  loggedOut: 'logged-out',
}
const dimension6 = {
  existingCustomer: 'registered',
  guestCustomer: 'guest',
}
const dimension7 = {
  openShop: 'open-shop',
  closedShop: 'closed-shop',
}
const routeMapRoutes = {
  home: '/',
  category: '/c/[...slugSlashId]',
  product: '/p/[...slugSlashId]',
  brand: '/brand/[...slugSlashId]',
  search: '/search-result',
  custom: '/custom-page',
  checkoutCart: '/cart',
  checkoutAddress: '/checkout/address', // does not use props mapping
  checkoutShipping: '/checkout/select-shipping', // does not use props mapping
  checkoutPayment: '/checkout/select-payment', // does not use props mapping
  checkoutOverview: '/checkout/overview', // does not use props mapping
  checkoutConfirmation: '/checkout/result', // does not use props mapping
  default: 'default',
}
const checkoutRoutes = [
  routeMapRoutes.checkoutCart,
  routeMapRoutes.checkoutAddress,
  routeMapRoutes.checkoutShipping,
  routeMapRoutes.checkoutPayment,
  routeMapRoutes.checkoutOverview,
  routeMapRoutes.checkoutConfirmation,
]
const routeMapRouteProps = {
  pageName: 'pageName',
  pageType: 'pageType',
}
const routeMap = {
  [routeMapRoutes.home]: {
    [routeMapRouteProps.pageName]: (_: NextRouter) => '/home',
    [routeMapRouteProps.pageType]: (_: NextRouter) => 'home',
  },
  [routeMapRoutes.category]: {
    [routeMapRouteProps.pageName]: (router: NextRouter) =>
      `/category${
        router.query?.slugSlashId ? '/' + router.query.slugSlashId[0] : ''
      }`,
    [routeMapRouteProps.pageType]: (_: NextRouter) => 'category',
  },
  [routeMapRoutes.product]: {
    [routeMapRouteProps.pageName]: (router: NextRouter) =>
      `/product_detail${
        router.query?.slugSlashId ? '/' + router.query.slugSlashId[0] : ''
      }`,
    [routeMapRouteProps.pageType]: (_: NextRouter) => 'product_detail',
  },
  [routeMapRoutes.brand]: {
    [routeMapRouteProps.pageName]: (router: NextRouter) =>
      `/brand_detail${
        router.query?.slugSlashId ? '/' + router.query.slugSlashId[0] : ''
      }`,
    [routeMapRouteProps.pageType]: (_: NextRouter) => 'brand_detail',
  },
  [routeMapRoutes.search]: {
    [routeMapRouteProps.pageName]: (_: NextRouter) => '/search',
    [routeMapRouteProps.pageType]: (_: NextRouter) => 'search',
  },
  [routeMapRoutes.custom]: {
    [routeMapRouteProps.pageName]: (router: NextRouter) => {
      return `/${getCustomPagePrefix(router)}${
        router.query?.slug ? '/' + router.query.slug : ''
      }`
    },
    [routeMapRouteProps.pageType]: (router: NextRouter) => {
      return getCustomPagePrefix(router)
    },
  },
  [routeMapRoutes.checkoutCart]: {
    [routeMapRouteProps.pageName]: (_: NextRouter) =>
      routeMapRoutes.checkoutCart,
    [routeMapRouteProps.pageType]: (_: NextRouter) => 'checkout',
  },
  [routeMapRoutes.checkoutAddress]: {
    [routeMapRouteProps.pageName]: (_: NextRouter) =>
      routeMapRoutes.checkoutAddress,
    [routeMapRouteProps.pageType]: (_: NextRouter) => 'checkout',
  },
  [routeMapRoutes.checkoutShipping]: {
    [routeMapRouteProps.pageName]: (_: NextRouter) =>
      routeMapRoutes.checkoutShipping,
    [routeMapRouteProps.pageType]: (_: NextRouter) => 'checkout',
  },
  [routeMapRoutes.checkoutPayment]: {
    [routeMapRouteProps.pageName]: (_: NextRouter) =>
      routeMapRoutes.checkoutPayment,
    [routeMapRouteProps.pageType]: (_: NextRouter) => 'checkout',
  },
  [routeMapRoutes.checkoutOverview]: {
    [routeMapRouteProps.pageName]: (_: NextRouter) =>
      routeMapRoutes.checkoutOverview,
    [routeMapRouteProps.pageType]: (_: NextRouter) => 'checkout',
  },
  [routeMapRoutes.checkoutConfirmation]: {
    [routeMapRouteProps.pageName]: (_: NextRouter) => '/order/success',
    [routeMapRouteProps.pageType]: (_: NextRouter) => 'checkout',
  },
  [routeMapRoutes.default]: {
    [routeMapRouteProps.pageName]: (router: NextRouter) => router.asPath,
    [routeMapRouteProps.pageType]: (_: NextRouter) => 'miscellaneous',
  },
}
/// ================================================================== ///
/// ============= ECOMMERCE EVENTS RELATED CONFIG ==================== ///
/// ================================================================== ///
const fallbackCurrency = 'EUR'
const ecommerceEventNames = {
  productCollection: 'productList', // used in category browser or search results page
  productView: 'detail',
  productClick: 'productClick',
  productAddToCart: 'addToCart',
  productRemoveFromCart: 'removeFromCart',
  promotionClick: 'promotionClick',
  checkout: 'checkout',
  purchase: 'checkout',
}

const eventNames = {
  promotionView: 'promotionView',
  promotionClick: 'promotionClick',
}

const checkoutSteps = {
  checkoutCart: 1,
  checkoutAddress: 2,
  checkoutShipping: 3,
  checkoutPayment: 4,
  checkoutOverview: 5,
  checkoutConfirmation: 6,
}
const ecommerceEventProps = {
  currencyCode: 'currencyCode',
  actionField: 'actionField',
  products: 'products',
  impressions: 'impressions', // used in category browser or search results page
  promotions: 'promotions',
  promotionView: 'promoView',
  productView: 'detail',
  productClick: 'click',
  productAddToCart: 'add',
  productRemoveFromCart: 'remove',
  promotionClick: 'promoClick',
  checkout: 'checkout',
  purchase: 'purchase',
}
const actionFieldProps = {
  list: 'list',
  step: 'step',
  option: 'option',
  action: 'action',
  orderId: 'id',
  orderRevenue: 'revenue',
  orderTax: 'tax',
  orderShipping: 'shipping',
  orderCoupon: 'coupon',
  orderAdditionalTaxes: 'additionalTaxes',
  orderAdditionalFees: 'additionalFees',
}
const defaultListValue = 'search' // used for list values when no promotion or category path is present (othervise promotion value or category path is used)
/// ================================================================== ///
/// ================ CUSTOM EVENTS RELATED CONFIG ==================== ///
/// ================================================================== ///
const customEventName = 'customEventTracking'
const customIconButtonLabel = 'IconButton'
const customEventCategories = {
  link: 'link',
  button: 'button',
  image: 'image',
  filter: 'filter',
  sorting: 'sorting',
  teaser: 'teaser-tracking',
}
const customEventActions = {
  click: 'click',
  reset: 'reset',
  list: 'list',
  view: 'view',
}

const allowedPassthroughEvents = [
  ecommerceEventNames.productClick,
  ecommerceEventNames.promotionClick,
  ecommerceEventNames.productAddToCart,
  ecommerceEventNames.productRemoveFromCart,
]

const config = {
  gtmContainerId,
  isIgefaStore,
  hashPrefix,
  hashSalt,
  cookieName,
  pageViewEventName,
  pageNamePrefixes,
  contentGroup1,
  contentGroup2,
  contentGroup3,
  contentGroup4,
  customPageTypesMap,
  dimension1,
  dimension2,
  dimension3,
  dimension4,
  dimension5,
  dimension6,
  dimension7,
  routeMapRoutes,
  checkoutRoutes,
  routeMapRouteProps,
  routeMap,
  fallbackCurrency,
  ecommerceEventNames,
  eventNames,
  checkoutSteps,
  ecommerceEventProps,
  actionFieldProps,
  defaultListValue,
  customEventName,
  customIconButtonLabel,
  customEventCategories,
  customEventActions,
  allowedPassthroughEvents,
}

// CUSTOM CONFIG RELATED UTILS
function getCustomPagePrefix(router: NextRouter): any {
  if (router.query.slug) {
    let prefix = ''
    const slug = Array.isArray(router.query.slug)
      ? router.query.slug[0]
      : router.query.slug
    Object.keys(config.customPageTypesMap).forEach((key) => {
      if (config.customPageTypesMap[key].indexOf(slug) > -1) prefix = key
    })
    if (prefix) return prefix
  }
  return config.routeMapRoutes.custom.substring(1)
}

export default config
