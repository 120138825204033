import { Col } from 'antd'
import { ColProps } from 'antd/lib/col'
import cn from 'classnames'
import style from './LYSCol.module.less'

export interface Props extends ColProps {
  /**
   * If true the column fills up the remaining space in the row by setting the
   * `flex` property to `flex: 1 1 0`. Works only when `flex` is enabled on
   * the parent row
   */
  fill?: boolean
  className?: string
}

const LYSCol: React.FC<Props> = (props) => {
  const { fill, ...antProps } = props
  const classNames = cn(props.className, fill && style.fill)
  return <Col {...antProps} className={classNames} />
}

export default LYSCol
