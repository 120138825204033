import {
  ICartItemProductInformation,
  ICartItemVariantInformation,
} from '@core/api/Cart/types'
import {
  IOrderItemVariantInformation,
  IOrderItemProductInformation,
} from '@core/api/Orders/types'
import {
  IProductListItemProductInformation,
  IProductListItemVariantInformation,
} from '@core/api/ProductLists/types'
import {
  IProduct,
  IProductSearch,
  IVariant,
  IVariantSearch,
} from '@core/api/Products/types'

type IProductWithSku =
  | IProduct
  | IProductSearch
  | IProductListItemProductInformation
  | IOrderItemProductInformation
  | ICartItemProductInformation

type IVariantWithSku =
  | IVariant
  | IVariantSearch
  | IProductListItemVariantInformation
  | IOrderItemVariantInformation
  | ICartItemVariantInformation

/**
 * Gets the SKU (Stock keeping unit, basically unique code used by client) that
 * should be displayed in the UI from a product/variant pair.
 * Depending on the shop we need to show either product or variant SKU.
 * By default the current implementation shows product SKU as SKU.
 * This function can be either overridden or made configurable to change this behaviour.
 * @param product
 * @param variant
 */
const getSku = (product: IProductWithSku, _variant: IVariantWithSku) => {
  return product.sku
}

export default getSku
