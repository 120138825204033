import { Modal } from 'antd'
import { ModalProps } from 'antd/lib/modal'

interface StaticExports {
  confirm: typeof Modal.confirm
  info: typeof Modal.info
}

export type Props = ModalProps

const LYSModal: React.FC<Props> & StaticExports = (props) => {
  return <Modal {...props} />
}

LYSModal.confirm = Modal.confirm
LYSModal.info = Modal.info

export default LYSModal
