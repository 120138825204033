import { List } from 'antd'
import { ListProps } from 'antd/lib/list'
import cn from 'classnames'
import style from './index.module.less'

interface StaticExports {
  Item: typeof List.Item
}
interface OwnProps {
  bordered?: boolean
}
export type Props = ListProps<any>

const LYSList: React.FC<OwnProps & Props> & StaticExports = (props) => {
  const { bordered, className, ...remainingProps } = props
  return (
    <List
      {...remainingProps}
      bordered={bordered}
      className={cn(bordered && style.bordered, className)}
    />
  )
}

LYSList.Item = List.Item

export default LYSList
