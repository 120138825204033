import { ICartState } from '@core/store/cart/types'
import { Action, ActionTypes } from './actions'

const initialState: ICartState = {
  cartDrawerOpen: false,
  cartUpdatePending: false,
}

function deepFreezeCart<T>(objectToFreeze: T): T {
  Object.freeze(objectToFreeze)
  if (objectToFreeze === undefined) {
    return objectToFreeze
  }

  Object.getOwnPropertyNames(objectToFreeze).forEach((prop) => {
    if (
      objectToFreeze &&
      objectToFreeze[prop as keyof T] !== null &&
      (typeof objectToFreeze[prop as keyof T] === 'object' ||
        typeof objectToFreeze[prop as keyof T] === 'function') &&
      !Object.isFrozen(objectToFreeze[prop as keyof T])
    ) {
      deepFreezeCart(objectToFreeze[prop as keyof T])
    }
  })

  return objectToFreeze
}

export default function cartReducer(
  state: ICartState = initialState,
  action: Action
) {
  switch (action.type) {
    case ActionTypes.SET_CART: {
      const cart = action.payload
      return {
        ...state,
        cart: deepFreezeCart(cart),
      }
    }

    case ActionTypes.TOGGLE_CART_DRAWER: {
      return {
        ...state,
        cartDrawerOpen: !state.cartDrawerOpen,
      }
    }

    case ActionTypes.CLOSE_CART_DRAWER: {
      return {
        ...state,
        cartDrawerOpen: false,
      }
    }

    case ActionTypes.CART_UPDATE_PENDING: {
      const pending = action.payload

      return {
        ...state,
        cartUpdatePending: pending,
      }
    }

    default:
      return state
  }
}
