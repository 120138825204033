/* eslint-disable no-dupe-class-members */
import { Environment } from '@core/utils/Environment'
// Determines if console logging is enabled
// By default only enabled on dev environments
// Can be force enabled using LOGGING_CONSOLE_ENABLE env variable
const useConsole =
  Environment.default.isDevelopment ||
  Environment.default.getBoolean('LOGGING_CONSOLE_ENABLE')

export class LoggingFacade {
  public debug(message: string, data?: { [key: string]: any }) {
    this._consoleLog('log', message, data)
  }

  public info(message: string, data?: { [key: string]: any }) {
    this._consoleLog('info', message, data)
  }
  public warn(message: string, data?: { [key: string]: any }) {
    this._consoleLog('warn', message, data)
  }

  public error(error: any, data?: { [key: string]: any }): void
  public error(message: string, error: any, data?: { [key: string]: any }): void
  public error(
    messageOrError: string | any,
    errorOrData?: { [key: string]: any },
    data?: { [key: string]: any }
  ): void {
    if (typeof messageOrError === 'string') {
      this._error(messageOrError, errorOrData, data)
    } else {
      this._error(undefined, messageOrError, errorOrData)
    }
  }

  private _error(
    message: string | undefined,
    error: any,
    data?: { [key: string]: any }
  ) {
    this._consoleLog('error', message, error, data)
  }

  private _consoleLog(
    level: 'log' | 'info' | 'debug' | 'warn' | 'error',
    ...args: any[]
  ) {
    if (!useConsole) return
    const sanitizedArgs = args.filter((arg) => !!arg)
    // eslint-disable-next-line no-console
    console[level](...sanitizedArgs)
  }
}

export const logging = new LoggingFacade()
