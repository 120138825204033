import React from 'react'
import cn from 'classnames'
import { FilePdfOutlined } from '@ant-design/icons'
import { IDocumentSearchItem } from '@core/api/DocumentSearch/types'
import { useTranslation } from '@core/i18n/i18n'
import { LYSRow, LYSCol, LYSIcon, LYSTypography } from '../Primitives'
import style from './AutoSuggestOptions.module.less'

const DocumentOption: React.FC<{
  document: IDocumentSearchItem
}> = ({ document }) => {
  const { t } = useTranslation()

  const firstAttachment = document.attachments[0]

  return (
    <a rel="noopener noreferrer" target="_blank" href={firstAttachment.url}>
      <LYSRow className={cn(style.option, style.document)}>
        <LYSCol>
          <LYSIcon
            className={style.icon}
            component={FilePdfOutlined}
            size={'sm'}
          />
        </LYSCol>
        <LYSCol fill={true} className={style.textCol}>
          <LYSTypography.Text ellipsis>{document.name}</LYSTypography.Text>
          <br />
          <LYSTypography.Text type={'secondary'} ellipsis>
            {firstAttachment.name} {t`search.document.download`}
          </LYSTypography.Text>
        </LYSCol>
      </LYSRow>
    </a>
  )
}

export default DocumentOption
