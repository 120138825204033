import React from 'react'
import cn from 'classnames'
import Link from 'next/link'
import { ITaxonomyAggregationLeaf } from '@core/api/Products/types'
import routes from '@core/config/routes'
import { LYSTypography } from '../Primitives'
import styles from './AutoSuggestOptions.module.less'

const TaxonomyOption: React.FC<{
  search: string
  taxonomy: ITaxonomyAggregationLeaf
}> = ({ taxonomy, search }) => (
  <div className={cn(styles.option, styles.term)}>
    <Link {...routes.searchResultTaxonomy(search, taxonomy)}>
      <LYSTypography.Text
        ellipsis
      >{`${taxonomy.name} (${taxonomy.productCount})`}</LYSTypography.Text>
    </Link>
  </div>
)

export default TaxonomyOption
