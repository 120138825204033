import ProductPrice, {
  ProductPriceProps,
} from '@original/components/shared/ProductPrice'
import React from 'react'
import { PriceTypes } from '@core/api/Channel/types'

import config from '@core/config/config'
import useChannel from '../../../hooks/channel/useChannel'
import { useTranslation } from '@core/i18n/i18n'
import { formatPrice } from '@core/utils/i18n'
import { useProductContext } from '@core/utils/models/product/ProductContext'

const IgefaProductPrice: React.FC<ProductPriceProps> = (props) => {
  const { t } = useTranslation()
  const { userChannel, isOpenChannel } = useChannel()
  const productContext = useProductContext()
  if (!productContext) return null

  const { price } = props

  let taxLabelTranslationString =
    userChannel?.displayedPriceType === PriceTypes.net
      ? 'product.price.plusTaxAndShipping'
      : 'product.price.taxIncludedPlusShipping'

  if (config.features.clickAndCollectEnabled && !isOpenChannel) {
    taxLabelTranslationString = `clickAndCollect.${taxLabelTranslationString}`
  }

  return (
    <ProductPrice
      {...props}
      slots={{
        additionalCosts: () => {
          return (
            <small>
              {t(taxLabelTranslationString, {
                taxValue: price && formatPrice(price.tax),
              })}
            </small>
          )
        },
      }}
    />
  )
}

export default IgefaProductPrice
