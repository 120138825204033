import React from 'react'
import cn from 'classnames'
import Link from 'next/link'
import routes from '@core/config/routes'
import { LYSTypography } from '../Primitives'
import styles from './AutoSuggestOptions.module.less'
import { AvailableSearchTypes } from './SearchTypeSwitch'

const TermOption: React.FC<{
  term: string
}> = ({ term }) => (
  <div className={cn(styles.option, styles.term)}>
    <Link {...routes.searchResult(term, AvailableSearchTypes.PRODUCT)}>
      <LYSTypography.Text ellipsis>{term}</LYSTypography.Text>
    </Link>
  </div>
)

export default TermOption
