import { useDispatch, useSelector } from 'react-redux'
import { IUser } from '@core/api/User/types'
import {
  getDefaultBillingInformation,
  getDefaultShippingAddress,
} from '@core/store/customer/selectors'
import { ILoginCredentials, IAgentLoginCredentials} from '@core/store/customer/types'
import {
  login as loginAction,
  agentLogin as agentLoginAction,
  logout as logoutAction,
  updateUser as updateUserAction,
} from '@core/store/user/actions'
import {
  isAuthenticated as selectIsAuthenticated,
  getAuthenticatedUser,
  getUserChannel,
  getUser,
} from '@core/store/user/selectors'
import { useServices } from '@core/utils/ioc'
import { getSingleQueryParamOrUndefined } from '@core/utils/url'

const useCurrentUser = (loginReturnUrl?: string) => {
  const dispatch = useDispatch()
  const { router } = useServices()
  const user = useSelector(getUser)
  const isAuthenticated = useSelector(selectIsAuthenticated)
  const authenticatedUser = useSelector(getAuthenticatedUser)
  const defaultBillingInformation = useSelector(getDefaultBillingInformation)
  const defaultShippingAddress = useSelector(getDefaultShippingAddress)
  const channel = useSelector(getUserChannel)

  const login = async (credentials: ILoginCredentials) => {
    const returnUrlParam = getSingleQueryParamOrUndefined(
      router.query,
      'returnurl'
    )
    const returnUrl = loginReturnUrl
      ? loginReturnUrl
      : returnUrlParam
      ? decodeURIComponent(returnUrlParam)
      : '/'

    await dispatch(loginAction(credentials, returnUrl))
  }

  const agentLogin = async (credentials: IAgentLoginCredentials) => {
    const returnUrlParam = getSingleQueryParamOrUndefined(
      router.query,
      'returnurl'
    )
    const returnUrl = loginReturnUrl
      ? loginReturnUrl
      : returnUrlParam
        ? decodeURIComponent(returnUrlParam)
        : '/'

    await dispatch(agentLoginAction(credentials, returnUrl))
  }

  const logout = () => dispatch(logoutAction())

  const updateUser = async (user: Partial<IUser>) =>
    await dispatch(updateUserAction(user))

  return {
    user,
    isAuthenticated,
    authenticatedUser,
    defaultBillingInformation,
    defaultShippingAddress,
    channel,
    agentLogin,
    login,
    logout,
    updateUser,
  }
}

export default useCurrentUser
