import React from 'react'
import cn from 'classnames'
import Price from '@core/components/Price'
import { LYSButton, LYSTypography } from '@core/components/Primitives'
import { LinkOutlined } from '@ant-design/icons'
import style from './index.module.less'
import HelpIconWithTooltip from '@core/components/shared/HelpIconWithTooltip'
import { useTranslation } from '@core/i18n/i18n'
import { useRouter } from 'next/router'
import routes from '@core/config/routes'

/* Hint added for 'Energie- und Transportkostenzuschlag' fee, can be removed soon, translation can then be deleted */

interface IProps {
  label: string
  amount: number
  key?: any
  isSecondaryText?: boolean
  isDiscount?: boolean
}

const SummaryRow: React.FC<IProps> = ({
  label,
  amount,
  isSecondaryText,
  isDiscount,
}) => {
  const { t } = useTranslation()
  const router = useRouter()

  const handleClick = () => {
    router.push(routes.delivery.href, routes.delivery.as)
  }

  return (
    <div className={cn(style.row, isDiscount && style.discount)}>
      {label === 'Energie- und Transportkostenzuschlag' ? (
        <LYSTypography.Text
          type={isSecondaryText ? 'secondary' : undefined}
          className={style.label}
        >
          {label}{' '}
          <HelpIconWithTooltip
            title={
              <>
                <p>{t('cart.price.fee.energyAndTransport')}</p>
                <LYSButton
                  onClick={handleClick}
                  type="link"
                  icon={<LinkOutlined rev={undefined} />}
                >
                  {t('footer.deliveryText')}
                </LYSButton>
              </>
            }
            color="white"
          />
        </LYSTypography.Text>
      ) : (
        <LYSTypography.Text
          type={isSecondaryText ? 'secondary' : undefined}
          className={style.label}
        >
          {label}
        </LYSTypography.Text>
      )}
      <LYSTypography.Text>
        {isDiscount && '-'}
        <Price value={amount} />
      </LYSTypography.Text>
    </div>
  )
}
export default SummaryRow
