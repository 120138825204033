import { useSelector } from 'react-redux'
import { getCategoryNavigation } from '@core/store/categories/selectors'

const useCategoryNavigation = () => {
  const root = useSelector(getCategoryNavigation)

  return {
    root,
  }
}

export default useCategoryNavigation
