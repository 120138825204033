import { Table } from 'antd'
import { TableProps } from 'antd/lib/table'

export type Props<T> = TableProps<T>

function LYSTable<T extends { [key: string]: any }>(props: Props<T>) {
  return <Table<T> {...props} />
}

LYSTable.Column = Table.Column
LYSTable.ColumnGroup = Table.ColumnGroup

export default LYSTable
