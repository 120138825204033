import { IShopServices } from '@core/config/services'
import trackingConfig from '@core/modules/converlytics-tracking/config'
import { DataLayerPushable } from '@core/modules/converlytics-tracking/types'
import { Environment } from '@core/utils/Environment'
import {
  consentChangedEvent,
  IConsentChangedEvent,
} from '@core/utils/consent/events'
import { logging } from '@core/utils/logging'
import { mergeDeep } from '@core/utils/merge'

type EventQueue = DataLayerPushable[]

const logDebug = (message: string, data?: any) => {
  if (!Environment.default.isDevelopment) return
  logging.debug(message, data)
}

export class ConverlyticsQueue {
  private _queue: EventQueue = []
  private _commitTimeoutId: ReturnType<typeof setTimeout> | undefined
  private _hasConsent = false
  private _hasPageViewEvent = false
  private _requireReset = false

  constructor(services: IShopServices, private _debounceInterval: number) {
    const { consent, eventBus, router } = services
    // Update consent state
    this._hasConsent = consent.consent.statistics
    eventBus.subscribe<IConsentChangedEvent>(
      consentChangedEvent.type,
      (event) => {
        logDebug(
          'Converlytics: Consent change',
          event.payload.consent.statistics
        )
        this._hasConsent = event.payload.consent.statistics
        this.requestCommit()
      }
    )
    // Commit + clear queue when leaving the page and reset data layer
    // Commit might still not do anything without consent or a page view event
    // in which case we discard previous events due to the clear call
    router.events.on('routeChangeStart', () => {
      logDebug('Converlytics: Route change start')
      this.commit()
      this.clear()
      // We can clear pending commit, since we just committed
      this._commitTimeoutId = undefined
      // Indicate that we are waiting for a new page view event
      this._hasPageViewEvent = false
      // Indicate that next commit should reset data layer
      // This ensures that previous events / settings in the data layer
      // will not be applied to the new page
      this._requireReset = true
    })
  }

  /**
   * Queues an event for an eventual commit to the GTM data layer
   * Certain events will be committed immediately, see `isPassThroughEvent`
   * @param event
   */
  public push(event: DataLayerPushable) {
    logDebug('Converlytics: push event', event)
    // Certain events need to be pushed to dataLayer immediately regardless
    // if there is a route change going on, e.g. click events which immediately
    // trigger a routing change still need to be tracked for the previous page
    if (isPassThroughEvent(event)) {
      this.commitEvent(event)
      return
    }

    // Indicate that we have a page view event
    if (event.event === 'pageView') {
      this._hasPageViewEvent = true
    }

    this._queue.push(event)
    this.requestCommit()
  }

  private clear() {
    this._queue = []
  }

  /**
   * Debounced commit, which handles the case that page view and other events
   * might be received out of order
   * @private
   */
  private requestCommit() {
    if (this._commitTimeoutId) clearTimeout(this._commitTimeoutId)
    this._commitTimeoutId = setTimeout(
      () => this.commit(),
      this._debounceInterval
    )
  }

  /**
   * Commits all queued events to the GTM data layer
   * @private
   */
  private commit() {
    // Don't execute commit until consent was given, this effectively waits until GTM is initialized
    if (!this._hasConsent) return
    // Don't commit without a page view event, we need to ensure all events on a page are sent after the page view event
    if (!this._hasPageViewEvent) return

    logDebug('Converlytics: commit')
    ensureDataLayer()

    if (this._requireReset) {
      logDebug('Converlytics: reset data layer')
      resetDataLayer()
      this._requireReset = false
    }

    const mergedQueue = mergeQueue(this._queue)
    const sortedQueue = sortQueue(mergedQueue)

    sortedQueue.forEach((event) => this.commitEvent(event))

    this.clear()
  }

  /**
   * Immediately commits a single event to the GTM data layer
   * @param event
   * @private
   */
  private commitEvent(event: DataLayerPushable) {
    // Don't commit individual events until consent was given, even for pass-through events
    if (!this._hasConsent) return
    logDebug('Converlytics: commit event', event)
    window.dataLayer.push(event)
  }
}

const ensureDataLayer = () => {
  if (!window.dataLayer) window.dataLayer = []
}

const resetDataLayer = () => {
  window.dataLayer.push(function () {
    // @ts-ignore
    this.reset()
  })
}

const isPassThroughEvent = (event: DataLayerPushable) => {
  const isAllowedPassThroughEventType =
    trackingConfig.allowedPassthroughEvents.indexOf(event.event) > -1
  const isClickEvent =
    event.event === trackingConfig.customEventName &&
    event.eventAction === trackingConfig.customEventActions.click

  return isAllowedPassThroughEventType || isClickEvent
}

/**
 * Ensures page view event is first event in the queue,
 * order of other events is not important currently
 * @param queue
 */
const sortQueue = (queue: EventQueue) => {
  const sortedQueue = [...queue]
  const pageViewEventIndex = sortedQueue.findIndex(
    (event) => event.event === 'pageView'
  )
  if (pageViewEventIndex < 0) return sortedQueue
  const pageViewEvent = sortedQueue[pageViewEventIndex]
  sortedQueue.splice(pageViewEventIndex, 1)
  sortedQueue.unshift(pageViewEvent)
  return sortedQueue
}

const eventsToMerge = [trackingConfig.ecommerceEventNames.productCollection]

const mergeQueue = (queue: EventQueue) => {
  const groupedEvents: { [key: string]: DataLayerPushable[] } = {}

  const tmpQueue = [...queue].filter((item) => {
    if (eventsToMerge.includes(item.event)) {
      groupedEvents[item.event] = [...(groupedEvents[item.event] || []), item]
      return false
    } else {
      return true
    }
  })

  Object.keys(groupedEvents).forEach((key) => {
    tmpQueue.push(mergeDeep({}, ...groupedEvents[key]))
  })

  return tmpQueue
}
