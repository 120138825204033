import { useEffect } from 'react'
import cn from 'classnames'
import NProgress from 'nprogress'
import style from './index.module.less'

interface Props {
  className?: string
}

interface StaticProps {
  start(): void
  set(value: number): void
  inc(value?: number): void
  done(): void
}

const GlobalProgressBar: React.FC<Props> & StaticProps = ({ className }) => {
  useEffect(() => {
    // configure NProgress
    NProgress.configure({
      showSpinner: false,
      parent: `*.${style.progressBar}`,
    })
  }, [])

  return <div className={cn(style.progressBar, className)} />
}

GlobalProgressBar.start = () => {
  NProgress.start()
}
GlobalProgressBar.set = (value: number) => {
  NProgress.set(value)
}
GlobalProgressBar.inc = (value?: number) => {
  NProgress.inc(value)
}
GlobalProgressBar.done = () => {
  NProgress.done()
}

export default GlobalProgressBar
