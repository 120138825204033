import getConfig from 'next/config'

interface Config {
  [key: string]: string | undefined
}

export interface CommerceConnectorConfig {
  partnerNames: {
    [key: string]: string
  }
}

export class Environment {
  constructor(private _config: Config) {}

  // "Default" environment
  // - try to use publicRuntimeConfig from Next.js
  // - which can be undefined on server, in which case we fall back to process.env which should hold the same value
  public static default = new Environment(
    getConfig()?.publicRuntimeConfig || process.env
  )

  get(key: string): string | undefined {
    return this._config[key]
  }

  getInt(key: string): number | undefined {
    const stringValue = this.get(key)

    return stringValue ? Number.parseInt(stringValue, 10) : undefined
  }

  getBoolean(key: string): boolean {
    const stringValue = this.get(key)

    return (
      stringValue?.toLowerCase() === 'true' ||
      stringValue?.toLowerCase() === '1'
    )
  }

  get isDevelopment() {
    return process.env.NODE_ENV === 'development'
  }

  get isProduction() {
    return process.env.NODE_ENV === 'production'
  }

  get isServer() {
    return typeof window === 'undefined'
  }

  get isClient() {
    return !this.isServer
  }
}
