import { combineReducers } from 'redux'
import { IAppState } from '@core/store/types'
import app from './app/reducer'
import cart from './cart/reducer'
import categories from './categories/reducer'
import customer from './customer/reducer'
import modals from './modals/reducer'
import priceRequests from './priceRequests/reducer'
import productDetail from './product-detail/reducer'
import productLists from './productList/reducer'
import user from './user/reducer'

const rootReducer = combineReducers<IAppState>({
  categories,
  productDetail,
  user,
  cart,
  app,
  priceRequests,
  customer,
  productLists,
  modals,
})

export default rootReducer
