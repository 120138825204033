export enum ModalType {
  PRICE_REQUEST = 'priceRequest',
  PRICE_REQUEST_PHONE_NUMBER = 'priceRequestPhoneNumber',
  CART_UPDATE = 'cartUpdate',
}

export enum ActionTypes {
  SHOW_MODAL = 'MODALS/SHOW',
  DISMISS_MODAL = 'MODALS/DISMISS',
  CLEAR_QUEUE = 'MODALS/CLEAR',
}

export interface IShowModal {
  type: ActionTypes.SHOW_MODAL
  payload: ModalType
}

export interface IHideModal {
  type: ActionTypes.DISMISS_MODAL
  payload?: ModalType
}

export interface IClearQueue {
  type: ActionTypes.CLEAR_QUEUE
}

export const showModal = (modalType: ModalType): IShowModal => ({
  type: ActionTypes.SHOW_MODAL,
  payload: modalType,
})

export const hideModal = (modalType?: ModalType) => ({
  type: ActionTypes.DISMISS_MODAL,
  payload: modalType,
})

export const clearQueue = () => ({
  type: ActionTypes.CLEAR_QUEUE,
})

export type Action = IShowModal | IHideModal | IClearQueue
