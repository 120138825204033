import React from 'react'
import Link from 'next/link'
import { LYSModal, LYSTypography, LYSButton } from '@core/components/Primitives'
import config from '@core/config/config'
import useModals from '@core/hooks/modals/useModals'
import { useTranslation } from '@core/i18n/i18n'
import { ModalType } from '@core/store/modals/actions'
import CartItemEvents from '../CartItemEvents'
import styles from './index.module.less'
import CartPriceRequestList from '../CartPriceRequestList'

const CartEventsModal: React.FC = () => {
  const { hide } = useModals()
  const { t } = useTranslation()

  function handleClose() {
    hide(ModalType.CART_UPDATE)
  }

  return (
    <LYSModal
      visible={true}
      footer={null}
      onCancel={handleClose}
      className={styles.modal}
    >
      <div className={styles.modal_content}>
        <div className={styles.modal_header}>
          <img
            src={config.assets.priceRequestModal}
            className={styles.modal_icon}
            alt={t('alt.successPriceRequest')}
          />
          <LYSTypography.Title level={3} inheritColor>
            {t`cart.eventsModal.title`}
          </LYSTypography.Title>
        </div>

        <CartItemEvents hideCartItemEventHint={true} />

        <CartPriceRequestList />

        <div className={styles.modal_footer}>
          <Link href={`/cart`}>
            <LYSButton
              onClick={handleClose}
              type={'primary'}
            >{t`cart.eventsModal.cta`}</LYSButton>
          </Link>
        </div>
      </div>
    </LYSModal>
  )
}

export default CartEventsModal
