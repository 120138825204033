import { IChannel } from '@core/api/Channel/types'
import { GenderContext, GenderTypes } from '@core/api/Customer/types'
import I18N from '../config/i18n'

export const formatPrice = (price: any, additionalOptions = {}) => {
  if (!Intl) {
    return 0
  }
  return new Intl.NumberFormat(I18N.languageCode, {
    style: 'currency',
    currency: I18N.currencyShort,
    ...additionalOptions,
  }).format(Math.round((parseInt(String(price), 10) / 100) * 100) / 100)
}

interface IPrice {
  net: number
  gross: number
}

export const formatChannelPrice = (price: IPrice, channel: IChannel) =>
  formatPrice(price[channel.displayedPriceType])

export const calcTax = (price: any, tax: any) => {
  return (100 + parseInt(tax, 10) * parseInt(price, 10)) / 100
}

export const getGenderTitleTranslationKey = (
  gender: GenderTypes,
  genderContext?: GenderContext
) => {
  return gender !== null
    ? genderContext
      ? `gender.${gender}.${genderContext}`
      : `gender.${gender}`
    : ''
}
