import * as React from 'react'
import { notification } from 'antd'
import {
  CheckCircleOutlined,
  WarningOutlined,
  InfoCircleOutlined,
} from '@ant-design/icons'
import { ApiError, isApiError, ErrorGroup } from '@core/api/Platform/errors'
import { LYSIcon } from '@core/components/Primitives'
import { i18n } from '../../i18n/i18n'
import style from './index.module.less'

const getValidationMessage = (error: ApiError) => {
  const errorObject = error.response?.data.data
  if (errorObject && errorObject.length === 1) {
    return errorObject[0].translatedMessage
  }
}

export const showErrorNotification = (error: any, path: string) => {
  if (isApiError(error) && error.isTimeout) {
    showNotification(i18n.t('api.timeout'), 'warning')
  } else {
    if (
      isApiError(error) &&
      error.getFingerprint()[0] === ErrorGroup.Validation
    ) {
      showNotification(getValidationMessage(error), 'warning')
    } else {
      showNotification(i18n.t(path), 'warning')
    }
  }
}

export const showNotification = (
  messageText: string | React.ReactNode,
  display: string | undefined
) => {
  notification.open({
    message: messageText,
    icon:
      display === 'success' ? (
        <LYSIcon
          component={CheckCircleOutlined}
          className={style.successColor}
          data-testid="testnotification"
        />
      ) : display === 'info' ? (
        <LYSIcon component={InfoCircleOutlined} className={style.infoColor} />
      ) : (
        <LYSIcon component={WarningOutlined} className={style.errorColor} />
      ),
  })
}
interface NotificationProps {
  messageText: string
  display?: string
}

type Props = NotificationProps
const Notification: React.FC<Props> = (props: Props) => {
  const { messageText, display } = props
  return <> {showNotification(messageText, display)} </>
}

export default Notification
