import { IPriceRequestsState } from '@core/store/priceRequests/types'
import { Action, ActionTypes } from './actions'

const initialState: IPriceRequestsState = {
  priceRequests: [],
  modalVisible: false,
}

export default function priceRequestsReducer(
  state: IPriceRequestsState = initialState,
  action: Action
) {
  switch (action.type) {
    case ActionTypes.SET_PRICE_REQUESTS: {
      const { priceRequests } = action.payload
      const sorted = priceRequests.sort((itemA, itemB) => {
        return (
          new Date(itemB.createdAt).valueOf() -
          new Date(itemA.createdAt).valueOf()
        )
      })
      return {
        ...state,
        priceRequests: sorted,
      }
    }
    default:
      return state
  }
}
