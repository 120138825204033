import * as React from 'react'
import cn from 'classnames'
import { LYSTypography } from '@core/components/Primitives'
import { formatPrice } from '../../utils/i18n'
import style from './index.module.less'

interface OwnProps {
  value: number
  className?: string
}
type Props = OwnProps

const Price: React.FC<Props> = (props: Props) => {
  const { value, className } = props
  const classNames = cn(style.text, className)

  return (
    <LYSTypography.Text data-testid="formatted-price" className={classNames}>
      {formatPrice(value)}
    </LYSTypography.Text>
  )
}

export default Price
