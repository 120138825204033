import { ICart } from '@core/api/Cart/types'

export function getHasCartItemEvents(cart?: ICart) {
  return (
    cart?.items.some((item) => item.events && item.events.length > 0) ||
    cart?.items.some((item) => item.stockInformation?.isOutOfStock) ||
    (cart?.unavailableItems && cart?.unavailableItems?.length > 0) ||
    false
  )
}
