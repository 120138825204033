import cn from 'classnames'
import style from './index.module.less'

export interface MenuBarProps {
  className?: string
  anchor?: boolean
}

export const MenuBar: React.FC<MenuBarProps> = ({
  className,
  anchor,
  children,
}) => {
  const classNames = cn(style.menuBar, anchor && style.anchor, className)

  return (
    <div className={classNames} role={'menubar'}>
      {children}
    </div>
  )
}
