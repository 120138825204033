import * as React from 'react'
import cn from 'classnames'
import Link from 'next/link'
import { ICartItem } from '@core/api/Cart/types'
import CartPriceSummary from '@core/components/Cart/CartPriceSummary'
import CartProductRow from '@core/components/Cart/QuickCart/Menu/CartProductRow'
import {
  LYSCard,
  LYSTypography,
  LYSRow,
  LYSCol,
  LYSAlert,
} from '@core/components/Primitives'
import useCart from '@core/hooks/cart/useCart'
import useCartEvents from '@core/hooks/cart/useCartEvents'
import { useTranslation } from '@core/i18n/i18n'
import { RenderProp, Slot, template } from '@core/utils/templates'
import CartHazardWarning from '../../CartHazardWarning'
import CartMenuButtons from './CartMenuButtons'
import style from './index.module.less'

export interface QuickCartMenuSlots {
  hazardWarnings: RenderProp<void>
  cartEvents: RenderProp<void>
  itemList: RenderProp<void>
  priceSummary: RenderProp<void>
  footerButtons: RenderProp<void>
}

export interface QuickCartMenuProps {
  className?: string
}

export const QuickCartMenu = template<QuickCartMenuProps, QuickCartMenuSlots>(
  ({ className, slots }) => {
    const { t } = useTranslation()
    const { cart, removeFromCart, hazardousItems } = useCart()
    const { hasCartItemEvents } = useCartEvents()

    const items = cart ? cart.items : []
    const cartHasItems = cart && !!cart.items.length

    const renderEventAlertMessage = () => (
      <span>
        {t('cart.events.cartDrawerHint')}{' '}
        <Link href="/cart" prefetch={false}>
          {t('cart.title')}
        </Link>
      </span>
    )

    return (
      <div className={cn(style.wrapper, className)}>
        <Slot render={slots?.hazardWarnings}>
          {!hasCartItemEvents && hazardousItems?.length > 0 && (
            <CartHazardWarning />
          )}
        </Slot>
        <Slot render={slots?.cartEvents}>
          {hasCartItemEvents && (
            <LYSAlert
              message={renderEventAlertMessage()}
              type="warning"
              showIcon={true}
              className={style.hintWrap}
            />
          )}
        </Slot>

        <LYSRow justify={'space-between'} className={style.cartItemContainer}>
          <LYSCol xs={24}>
            <Slot render={slots?.itemList}>
              {cartHasItems ? (
                items.map((item: ICartItem, index: number) => (
                  <CartProductRow
                    item={item}
                    key={index}
                    removeProduct={() => removeFromCart(item)}
                  />
                ))
              ) : (
                <LYSTypography.Title level={3} className={style.noArticlesHint}>
                  {t('cart.cartDrawer.noArticles')}
                </LYSTypography.Title>
              )}
            </Slot>
          </LYSCol>
        </LYSRow>
        <LYSRow className={style.cartFooter}>
          <LYSCol xs={24}>
            {cart && (
              <>
                <Slot render={slots?.priceSummary}>
                  {cartHasItems && (
                    <LYSCard size="small">
                      <CartPriceSummary priceObject={cart.price} />
                    </LYSCard>
                  )}
                </Slot>
                <Slot render={slots?.footerButtons}>
                  <CartMenuButtons />
                </Slot>
              </>
            )}
          </LYSCol>
        </LYSRow>
      </div>
    )
  }
)
