import { useMemo } from 'react'
import { useSelector } from 'react-redux'
import { ICartItem } from '@core/api/Cart/types'
import { useTranslation } from '@core/i18n/i18n'
import { getUserCart } from '@core/store/cart/selectors'
import { useServices } from '@core/utils/ioc'
import { getHasCartItemEvents } from '@core/utils/models/cart'

const useCartEvents = () => {
  const { productContextService } = useServices()
  const { t } = useTranslation()
  const cart = useSelector(getUserCart)

  const outOfStockItems = useMemo(
    () =>
      cart?.items.filter((item) => {
        const productContext =
          productContextService.getProductCartItemContext(item)
        return productContext.stock.isOutOfStock
      }),
    [productContextService, cart]
  )

  const priceChangedItems = cart?.items.filter((item) =>
    item.events?.some((event) => event.name === 'item_price_changed')
  )

  // LCP-1790 only items with price that are unavailable

  const unavailableItems = cart?.unavailableItems?.filter(
    (item) => !!item.price
  )

  const getUnavailableMessage = (item: ICartItem) => {
    return item.events?.some(
      (event) => event.name === 'item_variant_not_accessible'
    )
      ? t('cart.events.notAccessible')
      : item.events?.some(
          (event) => event.name === 'item_variant_not_purchasable'
        )
      ? t('cart.events.notPurchasable')
      : t('cart.events.genericNotAvailable')
  }

  const hasCartItemEvents = getHasCartItemEvents(cart)

  return {
    outOfStockItems,
    priceChangedItems,
    unavailableItems,
    getUnavailableMessage,
    hasCartItemEvents,
  }
}

export default useCartEvents
