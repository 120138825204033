import * as React from 'react'
import Link from 'next/link'
import { RightCircleOutlined } from '@ant-design/icons'
import { LYSEmpty, LYSMenu, LYSTypography } from '@core/components/Primitives'
import { Props as MenuProps } from '@core/components/Primitives/LYSMenu'
import routes from '@core/config/routes'
import { useTranslation } from '@core/i18n/i18n'
import style from './index.module.less'
import useProductLists from '@core/hooks/productList/useProductLists'

export type UserMenuProps = MenuProps

const ProductListMenuContent = (props: UserMenuProps) => {
  const { t } = useTranslation()
  const { productLists } = useProductLists()

  return (
    <LYSMenu className={style.menu} {...props}>
      {productLists.length > 0 ? (
        productLists.map((list, index) => {
          if (index < 5) {
            return (
              <LYSMenu.Item key={list.id} className={style.menuItem}>
                <Link {...routes.productList(list.id)}>
                  <LYSTypography.Text ellipsis className={style.menuItemText}>
                    {`${list.label} (${list.itemsCount})`}
                  </LYSTypography.Text>
                </Link>
              </LYSMenu.Item>
            )
          }
        })
      ) : (
        <LYSEmpty />
      )}
      <LYSMenu.Divider />
      <LYSMenu.Item
        icon={<RightCircleOutlined className={style.button} rev={undefined} />}
        className={style.menuItem}
      >
        <Link {...routes.productList()}>
          <LYSTypography.Text className={style.button}>
            {productLists.length > 0
              ? t('menu.showAllOrderLists')
              : t('menu.createFirstOrderLists')}
          </LYSTypography.Text>
        </Link>
      </LYSMenu.Item>
    </LYSMenu>
  )
}

export default ProductListMenuContent
