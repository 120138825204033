import * as React from 'react'
import cn from 'classnames'
import style from './FooterItemCollection.module.less'

interface Props {
  flexDirection: 'row' | 'column'
  className?: string
  children: React.ReactNode
}

const FooterItemCollection: React.FC<Props> = (props) => {
  const { flexDirection, children, className } = props

  return (
    <div className={cn(style.flex, style[flexDirection], className)}>
      {children}
    </div>
  )
}

export default FooterItemCollection
