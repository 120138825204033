import { ProductContext, ProductContextType } from './types'

export const isProductDetailContext = (productContext?: ProductContext) =>
  productContext?.type === ProductContextType.ProductDetail

export const isProductSearchContext = (productContext?: ProductContext) =>
  productContext?.type === ProductContextType.ProductSearch

export const isProductListItemContext = (productContext?: ProductContext) =>
  productContext?.type === ProductContextType.ProductListItem

export const isProductCartItemContext = (productContext?: ProductContext) =>
  productContext?.type === ProductContextType.ProductListItem

export const isProductPriceRequestContext = (productContext?: ProductContext) =>
  productContext?.type === ProductContextType.ProductPriceRequest
