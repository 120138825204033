import { useEffect, useState } from 'react'
import { IProductSearch, IProductsQuery } from '@core/api/Products/types'
import { RecommendationTypes } from '@core/api/Recommendations/types'
import { useServices } from '@core/utils/ioc'
import flatten from 'lodash/flatten'
import config from '@core/config/config'

interface RecommendationsType {
  recommendationType?: RecommendationTypes
  variantIds?: string[]
  recommendationsEnabled?: boolean
}

const useRecommendations = (props: RecommendationsType) => {
  const {
    recommendationsEnabled = true,
    recommendationType,
    variantIds,
  } = props
  const { api } = useServices()
  const [recommendations, setRecommendations] = useState<IProductSearch[]>()
  const [recommendedVariantIds, setRecommendedVariantIds] = useState<string[]>()
  const [isLoading, setLoading] = useState<boolean>(false)

  useEffect(() => {
    const fetchRecommendations = async () => {
      setLoading(true)
      const recommendationsResponse =
        await api.recommendations.fetchRecommendations(
          config.features.quickSearchRecommendations.limit,
          variantIds
        )
      setRecommendedVariantIds(
        flatten(
          recommendationsResponse?.map((rec) =>
            rec.recommendations.map((rec) => rec.variantId)
          )
        )
      )
    }

    const fetchRecommendationsByType = async (
      recommendationType: RecommendationTypes
    ) => {
      setLoading(true)
      const recommendationsResponse =
        await api.recommendations.fetchRecommendationsByType(
          recommendationType,
          config.features.quickSearchRecommendations.limit,
          variantIds
        )
      setRecommendedVariantIds(
        recommendationsResponse?.recommendations.map((rec) => rec.variantId)
      )
    }

    if (recommendationsEnabled) {
      if (recommendationType) {
        fetchRecommendationsByType(recommendationType)
      } else {
        fetchRecommendations()
      }
    }
  }, [
    api.recommendations,
    recommendationType,
    recommendationsEnabled,
    variantIds,
  ])

  useEffect(() => {
    const fetchProducts = async (params: IProductsQuery) => {
      const { hits } = await api.products.fetchProducts(params)
      setRecommendations(hits)
      setLoading(false)
    }

    if (recommendedVariantIds && recommendedVariantIds.length) {
      const params: IProductsQuery = {
        filter: {
          variantId: recommendedVariantIds,
        },
        search: '',
        page: 1,
        requiresAggregations: 0,
      }

      fetchProducts(params)
    }
    setLoading(false)
  }, [api.products, recommendedVariantIds])

  return {
    isLoading,
    recommendations,
  }
}

export default useRecommendations
