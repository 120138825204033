import * as React from 'react'
import { ShoppingCartOutlined } from '@ant-design/icons'
import {
  LYSButton,
  LYSIcon,
  LYSInputGroup,
  LYSInputNumber,
} from '@core/components/Primitives'
import useCart from '@core/hooks/cart/useCart'
import { useTranslation } from '@core/i18n/i18n'
import style from './index.module.less'
import cn from 'classnames'
import { useProductContext } from '@core/utils/models/product/ProductContext'
import { SizeType } from 'antd/lib/config-provider/SizeContext'

export interface OwnProps {
  onAddToCart: () => void
  onSetQuantity: (quantity: number) => void
  onBlur?: () => void
  quantity: number
  hideLabel?: boolean
  isAllowedToAddToCart: boolean
  purchasable: boolean // needed for idealclean
  size?: SizeType // override size at places needed e.g. quickcart
  className?: string
}

const AddToCartInput: React.FC<OwnProps> = (props) => {
  const {
    onAddToCart,
    onSetQuantity,
    quantity,
    onBlur,
    hideLabel,
    isAllowedToAddToCart,
    size,
    className,
  } = props
  const { t } = useTranslation()
  const { isCartUpdatePending } = useCart()

  const handleQuantityChange = (value?: number | string | null) => {
    if (value) onSetQuantity(value as number)
  }

  const handleClick: React.MouseEventHandler = (e) => {
    e.stopPropagation()
    e.preventDefault()
  }

  const productContext = useProductContext()

  if (!productContext) return null

  return (
    <LYSInputGroup
      compact={true}
      className={cn(style.inputWrapper, className)}
      size={'default'}
    >
      <LYSInputNumber
        onClick={handleClick}
        value={quantity}
        onChange={handleQuantityChange}
        onBlur={onBlur}
        min={1}
        size={size ? size : 'large'}
        disabled={!isAllowedToAddToCart || isCartUpdatePending}
        onPressEnter={onAddToCart}
      />
      <LYSButton
        onClick={onAddToCart}
        size={size ? size : 'large'}
        actionButtonStyle={true}
        type="primary"
        disabled={!isAllowedToAddToCart || isCartUpdatePending}
        data-testid={
          isAllowedToAddToCart ? 'add-to-cart' : 'add-to-cart-disabled'
        }
      >
        <div className={style.buttonContentWrap}>
          <LYSIcon component={ShoppingCartOutlined} size={'sm'} />
          {!hideLabel && (
            <span className={style.buttonLabel}>{t('addToCart.addText')}</span>
          )}
        </div>
      </LYSButton>
    </LYSInputGroup>
  )
}

export default AddToCartInput
