import { useEffect, useState } from 'react'
import { RecommendationTypes } from '@core/api/Recommendations/types'
import { useServices } from '@core/utils/ioc'

const useLastSearches = (isOpen: boolean) => {
  const { api } = useServices()
  const [lastSearchStrings, setLastSearchStrings] = useState<string[]>()

  useEffect(() => {
    const fetchLastSearches = async () => {
      const response = await api.recommendations.fetchRecommendationsByType(
        RecommendationTypes.LAST_SEARCHED_TERMS,
        5
      )
      setLastSearchStrings(response?.recommendations.map((rec) => rec.string!))
    }

    fetchLastSearches()
  }, [api.recommendations, isOpen])

  return {
    lastSearchStrings,
  }
}

export default useLastSearches
