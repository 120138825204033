import axios, { AxiosError, AxiosRequestConfig } from 'axios'
import createAuthRefreshInterceptor from 'axios-auth-refresh'
import Brands from '@core/api/Brands'
import Awards from '@core/api/Awards'
import Cart from '@core/api/Cart'
import Categories from '@core/api/Categories'
import Channel from '@core/api/Channel'
import Checkout from '@core/api/Checkout'
import Customer from '@core/api/Customer'
import DocumentSearch from '@core/api/DocumentSearch'
import ExternalOrders from '@core/api/ExternalOrders'
import NewsletterSubscription from '@core/api/NewsletterSubscription'
import Orders from '@core/api/Orders'
import { ApiError } from '@core/api/Platform/errors'
import {
  ExtendedAxiosInstance,
  ExtendedAxiosRequestConfig,
  ExtendedAxiosResponse,
} from '@core/api/Platform/types'
import PriceRequests from '@core/api/PriceRequests'
import ProductLists from '@core/api/ProductLists'
import Products from '@core/api/Products'
import Recommendations from '@core/api/Recommendations'
import User from '@core/api/User'
import { Cache } from '@core/utils/caching/Cache'
import CookiesFacade, { AUTH_TOKEN } from '@core/utils/cookies'
import Events from '@core/api/Events'

interface IPlatformOptions {
  cookies: CookiesFacade
  apiKey: string
  apiUrl: string
  apiTimeout: number
  cache?: Cache
}

class Platform {
  public apiKey: string
  public apiUrl: string
  public cookies: CookiesFacade

  public api: ExtendedAxiosInstance

  /* Class extended */
  public user!: User
  public categories!: Categories
  public products!: Products
  public cart!: Cart
  public checkout!: Checkout
  public customer!: Customer
  public orders!: Orders
  public externalOrders!: ExternalOrders
  public productLists!: ProductLists
  public newsletterSubscription!: NewsletterSubscription
  public channel!: Channel
  public brands!: Brands
  public awards!: Awards
  public priceRequests!: PriceRequests
  public documentSearch!: DocumentSearch
  public recommendations!: Recommendations
  public events!: Events

  /**
   * Creates Platform Instance
   */
  constructor(options: IPlatformOptions) {
    this.apiKey = options.apiKey
    this.apiUrl = options.apiUrl
    this.cookies = options.cookies

    this.api = axios.create({
      baseURL: this.apiUrl,
      timeout: options.apiTimeout,
      headers: {
        Accept: 'application/json',
        'Content-Type': 'application/json',
      },
    })

    // Add cache interceptor if cache is used
    if (options.cache) {
      this.api.interceptors.request.use(cacheRequestInterceptor(options.cache))
      this.api.interceptors.response.use(
        cacheResponseInterceptor(options.cache)
      )
    }

    // Add authorization header if auth token is present
    this.api.interceptors.request.use(
      addAuthenticationInterceptor(this.cookies)
    )
    // Setup silent JWT token refresh which:
    // - intercepts all requests that fail with 401 or 403
    // - tries to refresh the token
    // - reruns all failed requests with renewed token,
    //   or without token in case we had to log out
    createAuthRefreshInterceptor(this.api, authRefreshHandler(this), {
      skipWhileRefreshing: false,
      statusCodes: [401],
    })

    // Setup API error mapper
    this.api.interceptors.response.use(
      undefined,
      errorMapperResponseInterceptor
    )

    this.user = new User(this.api, this.cookies)
    this.categories = new Categories(this.api, this.cookies)
    this.products = new Products(this.api, this.cookies)
    this.cart = new Cart(this.api, this.cookies)
    this.checkout = new Checkout(this.api, this.cookies)
    this.customer = new Customer(this.api, this.cookies)
    this.orders = new Orders(this.api, this.cookies)
    this.externalOrders = new ExternalOrders(this.api, this.cookies)
    this.productLists = new ProductLists(this.api, this.cookies)
    this.newsletterSubscription = new NewsletterSubscription(
      this.api,
      this.cookies
    )
    this.channel = new Channel(this.api, this.cookies)
    this.brands = new Brands(this.api, this.cookies)
    this.awards = new Awards(this.api, this.cookies)
    this.priceRequests = new PriceRequests(this.api, this.cookies)
    this.documentSearch = new DocumentSearch(this.api, this.cookies)
    this.recommendations = new Recommendations(this.api, this.cookies)
    this.events = new Events(this.api, this.cookies)
  }
}

const cacheRequestInterceptor =
  (cache: Cache) => (config: ExtendedAxiosRequestConfig) => {
    // Each request must explicitly opt-in into caching
    if (!config.useCache) return config

    const uri = axios.getUri(config)

    if (!cache.has(uri)) return config

    const cachedResponse = cache.get(uri)

    // Mark data as coming from cache
    config.fromCache = true
    // @ts-ignore
    config.adapter = () => Promise.resolve(cachedResponse)

    return config
  }

const cacheResponseInterceptor =
  (cache: Cache) => (response: ExtendedAxiosResponse) => {
    // Ignore failed requests and responses coming from cache
    if (
      ![200, 404].includes(response.status) ||
      !response.config.useCache ||
      response.config.fromCache
    )
      return response

    const uri = axios.getUri(response.config)

    cache.put(uri, response)

    return response
  }

const addAuthenticationInterceptor =
  (cookies: CookiesFacade) => (config: AxiosRequestConfig) => {
    const authToken = cookies.get(AUTH_TOKEN)
    if (authToken && config.headers) {
      config.headers.Authorization = `Bearer ${authToken}`
    } else {
      // Since we rerun the same request config in case of 401 errors,
      // we need to remove the auth header if the user was logged out
      if (config.headers) {
        delete config.headers['Authorization']
      }
    }
    return config
  }

const authRefreshHandler = (platform: Platform) => async () =>
  await platform.user.refreshToken()

const errorMapperResponseInterceptor = (error: AxiosError) => {
  throw new ApiError(error)
}

export default Platform
