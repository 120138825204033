import React, { useState } from 'react'
import cn from 'classnames'
import Link from 'next/link'
import {
  IProductSearch,
  getImageFromVariantOrPlaceholder,
} from '@core/api/Products/types'
import AddToCartInput from '@core/components/ProductCollection/AddToCartInput'
import config from '@core/config/config'
import routes from '@core/config/routes'
import useCart from '@core/hooks/cart/useCart'
import { useTranslation } from '@core/i18n/i18n'
import { usePageContext } from '@core/utils/pages/PageContext'
import { getProductImageSEOProps } from '@core/utils/seo/getProductImageSEOProps'
import { RenderProp, Slot, template } from '@core/utils/templates'
import { LYSRow, LYSCol, LYSTypography } from '../Primitives'
import ProductPrice from '../shared/ProductPrice'
import style from './AutoSuggestOptions.module.less'
import AutoSuggestPriceRequest from './AutoSuggestPriceRequest'
import { useServices } from '@core/utils/ioc'

export interface ProductOptionProps {
  product: IProductSearch
}

interface ProductOptionSlots {
  productVariationName: RenderProp<ProductOptionProps>
  productName: RenderProp<ProductOptionProps>
  productImage: RenderProp<ProductOptionProps>
  productPrice: RenderProp<ProductOptionProps>
  addToCart: RenderProp<ProductOptionProps>
}

const ProductOption = template<ProductOptionProps, ProductOptionSlots>(
  ({ product, slots }) => {
    const { t } = useTranslation()
    const { productContextService } = useServices()
    const image = getImageFromVariantOrPlaceholder(product.mainVariant)
    const { mainVariant, name } = product
    const { rules, packagingUnitsInformation } =
      productContextService.getProductSearchContext(product, mainVariant)
    const { slug, id } = mainVariant
    const { packagingUnit } = packagingUnitsInformation
    if (!packagingUnit) return null
    const { price, unitPrice, strikeThroughPrice } = packagingUnit
    const { href, as } = routes.productDetail(slug, id)
    const { addToCart } = useCart()
    const [quantity, setQuantity] = useState<number>(1)
    const {
      props: { isMobile },
    } = usePageContext()

    return (
      <Link href={href} as={as}>
        <LYSRow className={cn(style.option, style.product)}>
          <LYSCol className={style.image}>
            <Slot render={slots?.productImage} props={{ product }}>
              <img
                src={image.url}
                alt={getProductImageSEOProps(name).alt}
                width={300}
                height={300}
              />
            </Slot>
          </LYSCol>
          <LYSCol fill={true} className={style.textCol}>
            <Slot render={slots?.productName} props={{ product }}>
              <LYSTypography.Text ellipsis={true}>
                {product.isNew && config.features.newProductsFeature && (
                  <span className={style.newProductText}>
                    {t('product.new')}{' '}
                  </span>
                )}
                {product.name}
              </LYSTypography.Text>
            </Slot>
            <br />
            <Slot render={slots?.productVariationName} props={{ product }}>
              {!product.isDiscontinued && (
                <LYSTypography.Text type={'secondary'} ellipsis={true}>
                  {product.variationName}
                </LYSTypography.Text>
              )}
              {product.isDiscontinued && (
                <LYSTypography.Text type={'danger'} ellipsis={true}>
                  {t`product.discontinued.hint`}
                </LYSTypography.Text>
              )}
            </Slot>
          </LYSCol>
          {config.features.addToCartInSearchEnabled && !isMobile && (
            <Slot render={slots?.addToCart} props={{ product }}>
              <LYSCol className={style.addToCartCol}>
                <div
                  onClick={(e) => {
                    e.preventDefault()
                    e.stopPropagation()
                    return false
                  }}
                >
                  {price ? (
                    <AddToCartInput
                      onAddToCart={() =>
                        addToCart(product.mainVariant, product.name, quantity)
                      }
                      onSetQuantity={setQuantity}
                      quantity={quantity}
                      isAllowedToAddToCart={rules.isPurchasable}
                      purchasable={rules.isPurchasable}
                      size="small"
                      hideLabel={true}
                      className={style.addToCart}
                    />
                  ) : (
                    <AutoSuggestPriceRequest product={product} />
                  )}
                </div>
              </LYSCol>
            </Slot>
          )}
          <Slot render={slots?.productPrice} props={{ product }}>
            {rules.isPurchasable && price && (
              <LYSCol className={cn(style.textCol, style.priceCol)}>
                <ProductPrice
                  price={price}
                  compactPrice={true}
                  unitPrice={unitPrice}
                  strikePrice={strikeThroughPrice?.price}
                  mainPriceHolderClass={style.mainPriceHolder}
                />
              </LYSCol>
            )}
          </Slot>
        </LYSRow>
      </Link>
    )
  }
)

export default ProductOption
