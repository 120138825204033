import { Popover } from 'antd'
import { PopoverProps } from 'antd/lib/popover'

type Props = PopoverProps

const LYSPopover: React.FC<Props> = (props) => {
  return <Popover {...props} />
}

export default LYSPopover
