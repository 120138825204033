type ErrorContext = {
  [key: string]: any
}
type Fingerprint = string[]

export abstract class BaseError extends Error {
  constructor(message: string) {
    super(message)
    this.name = this.constructor.name
    if (typeof Error.captureStackTrace === 'function') {
      Error.captureStackTrace(this, this.constructor)
    } else {
      this.stack = new Error(message).stack
    }
  }

  get shouldEscalate() {
    return true
  }
  getContext(): ErrorContext | undefined {
    return undefined
  }

  getFingerprint(): Fingerprint | undefined {
    return undefined
  }

  getLevel() {
    return 'error'
  }
}
