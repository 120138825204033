import { i18n } from '@core/i18n/i18n'

export function getProductImageSEOProps(
  productName: string,
  imageIndex?: number
) {
  return {
    alt: i18n
      .t('product.imageAlt', {
        replace: {
          productName,
          imageIndex,
        },
      })
      .trim(),
    title: i18n
      .t('product.imageTitle', {
        replace: {
          productName,
          imageIndex,
        },
      })
      .trim(),
  }
}
