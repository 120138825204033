import React from 'react'
import usePriceRequests from '@core/hooks/priceRequest/usePriceRequests'
import { IProductSearch } from '@core/api/Products/types'
import PriceRequestPendingInfo from '../ProductCollection/PriceRequestStatusComponents/PriceRequestPendingInfo'
import PriceRequestCta from '../ProductCollection/PriceRequestStatusComponents/PriceRequestCta'

interface Props {
  product: IProductSearch
}

const AutoSuggestPriceRequest: React.FC<Props> = ({ product }) => {
  const variantId = product.mainVariant.id

  const { isPendingPriceRequest, isRequestable } = usePriceRequests(product)

  if (!product || !variantId) return null

  return (
    <>
      {isRequestable && (
        <>
          {isPendingPriceRequest && (
            <>
              <PriceRequestPendingInfo smallLayout={true} />
            </>
          )}
          {!isPendingPriceRequest && (
            <PriceRequestCta variantId={variantId} smallLayout={true} />
          )}
        </>
      )}
    </>
  )
}

export default AutoSuggestPriceRequest
