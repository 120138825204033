import qs from 'qs'
import BaseAPI from '@core/api/Platform/BaseAPI'
import { logging } from '@core/utils/logging'
import { IProduct, IProductCollection, IProductsQuery } from './types'

class Products extends BaseAPI {
  public async fetchProducts(
    query?: IProductsQuery
  ): Promise<IProductCollection> {
    const response = await this.api.get<IProductCollection>(
      `products?${qs.stringify(query)}`,
      { useCache: true, genericUrl: 'products' }
    )
    const { data } = response
    return data
  }

  public async fetchProductDetail(id: string): Promise<IProduct | undefined> {
    try {
      const response = await this.api.get<IProduct>(`products/${id}`, {
        useCache: true,
        genericUrl: 'products/{id}',
      })
      const { data } = response
      return data
    } catch (e) {
      if (e.response?.status === 404) {
        // Expected, but we log it anyway
        // A large number of occurrences might indicate an API issue
        logging.error(e)
        return undefined
      }
      throw e
    }
  }

  public async fetchProductDetailByVariant(
    variantId: string
  ): Promise<IProduct | undefined> {
    try {
      const response = await this.api.get<IProduct>(
        `products/by-variant/${variantId}`,
        { useCache: true, genericUrl: 'products/by-variant/{id}' }
      )
      const { data } = response
      return data
    } catch (e) {
      if (e.response?.status === 404) {
        // Expected, but we log it anyway
        // A large number of occurrences might indicate an API issue
        logging.error(e)
        return undefined
      }
      throw e
    }
  }
}

export default Products
