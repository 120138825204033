import React, { createContext, useContext, useState } from 'react'
import cn from 'classnames'
import { useDebouncedCallback } from 'use-debounce'
import { keyboardNavigation } from '@core/components/shared/menu/MenuBehaviour/KeyboardNavigation'
import {
  KeyboardNavigationDirection,
  MenuContext,
  MenuItemDefinition,
  MenuItemContext,
} from '@core/components/shared/menu/MenuBehaviour/types'
import {
  generateMenuContentElementId,
  generateMenuItemElementId,
  getMenuPath,
} from '@core/components/shared/menu/MenuBehaviour/util'
import style from './index.module.less'

const DEFAULT_DEBOUNCE = 250

const MenuItemContextDefinition = createContext<MenuItemContext>({})
const MenuContextDefinition = createContext<MenuContext>({
  menuId: '',
  items: [],
  keyboardNavigationDirection: 'horizontal',
  select: () => undefined,
  isSelected: () => false,
  close: () => undefined,
  subMenus: {},
})

export const useMenuContext = () => {
  const behaviourContext = useContext(MenuContextDefinition)
  const itemContext = useContext(MenuItemContextDefinition)

  return {
    ...behaviourContext,
    isActiveItem:
      itemContext.item && behaviourContext.isSelected(itemContext.item),
  }
}

export interface MenuTriggerProps {
  item: MenuItemDefinition
}

const MenuTrigger: React.FC<MenuTriggerProps> = ({ item, children }) => {
  const menu = useMenuContext()
  const child = React.Children.only(children)
  const menuPath = getMenuPath(menu)
  const menuItemElementId = generateMenuItemElementId(menuPath, item)
  const menuContentElementId = generateMenuContentElementId(menuPath, item)
  const keyboard = keyboardNavigation(menu, item)
  const isActive = menu.isSelected(item)

  if (!React.isValidElement(child))
    throw new Error('Single child must be a React element')

  const handleClick = () => {
    menu.select(item)
  }
  const handleMouseEnter = () => {
    menu.select(item)
  }
  const handleMouseLeave = () => {
    menu.select(undefined)
  }
  const handleFocus = () => {
    menu.select(item)

    // Focusing a sub menu item will blur a parent item,
    // which would cause the parent menu to close
    // As a workaround we force parent menus to stay open by
    // reselecting the current item
    // This relies on the fact that there is a debounce for the selection
    // Removing the debounce would immediately clear the parent menu,
    // thus removing the submenus ability to reselect it
    let currentMenu = menu.parent
    while (currentMenu) {
      currentMenu.select(currentMenu.selectedItem)
      currentMenu = currentMenu.parent
    }
  }
  const handleBlur = () => {
    // Similar to above - when focus leaves the menu completely,
    // we want to close the whole menu / all parent menus
    let currentMenu: MenuContext | undefined = menu
    while (currentMenu) {
      currentMenu.select(undefined)
      currentMenu = currentMenu.parent
    }
  }
  const enhancedChild = React.cloneElement(child, {
    ...child.props,
    id: menuItemElementId,
    onClick: handleClick,
    onMouseEnter: handleMouseEnter,
    onMouseLeave: handleMouseLeave,
    onFocus: handleFocus,
    onBlur: handleBlur,
    onKeyDown: keyboard.handle,
    'aria-owns': item?.children?.length ? menuContentElementId : undefined,
    'aria-expanded': isActive ? 'true' : 'false',
  })

  return (
    <MenuItemContextDefinition.Provider value={{ item }}>
      {enhancedChild}
    </MenuItemContextDefinition.Provider>
  )
}

export interface MenuContentProps {
  item: MenuItemDefinition
}

const MenuContent: React.FC<MenuContentProps> = ({ item, children }) => {
  const menu = useMenuContext()
  const child = React.Children.only(children)
  const menuPath = getMenuPath(menu)
  const menuContentElementId = generateMenuContentElementId(menuPath, item)

  if (!React.isValidElement(child))
    throw new Error('Single child must be a React element')

  const className = cn(
    child.props.className,
    menu.isSelected(item) ? style.active : style.inactive
  )

  const handleMouseEnter = () => {
    menu.select(item)
  }
  const handleMouseLeave = () => {
    menu.select(undefined)
  }

  const enhancedChild = React.cloneElement(child, {
    ...child.props,
    id: menuContentElementId,
    className,
    onMouseEnter: handleMouseEnter,
    onMouseLeave: handleMouseLeave,
  })

  return (
    <MenuItemContextDefinition.Provider value={{ item }}>
      {enhancedChild}
    </MenuItemContextDefinition.Provider>
  )
}

export interface MenuBehaviourProps {
  menuId: string
  items: MenuItemDefinition[]
  keyboardNavigationDirection: KeyboardNavigationDirection
  initialSelectedItem?: MenuItemDefinition
  preventDeselection?: boolean
  relayClose?: boolean
  debounce?: number | false
  onClose?: () => void
}

interface MenuBehaviourStaticExports {
  Trigger: typeof MenuTrigger
  Content: typeof MenuContent
}

export const MenuBehaviour: React.FC<MenuBehaviourProps> &
  MenuBehaviourStaticExports = ({
  menuId,
  items,
  keyboardNavigationDirection,
  initialSelectedItem,
  preventDeselection,
  relayClose,
  debounce,
  children,
  onClose,
}) => {
  const [selectedItem, setSelectedItem] = useState<
    MenuItemDefinition | undefined
  >(initialSelectedItem)
  const parentBehaviour = useMenuContext()
  const parentItemContext = useContext(MenuItemContextDefinition)
  const debounceDisabled = debounce === 0 || debounce === false
  const debounceDelay = debounce || DEFAULT_DEBOUNCE

  const select = (item: MenuItemDefinition | undefined) => {
    if (item === undefined && preventDeselection) return
    setSelectedItem(item)
  }

  const debouncedSelect = useDebouncedCallback<typeof select>(
    select,
    debounceDelay
  )

  const isSelected = (item: MenuItemDefinition) =>
    !!selectedItem && selectedItem.id === item.id

  const close = () => {
    if (onClose) onClose()
    if (!preventDeselection) setSelectedItem(undefined)
    if (relayClose) parentBehaviour.close()
  }

  const menuBehaviour = {
    menuId,
    items,
    keyboardNavigationDirection,
    selectedItem,
    select: debounceDisabled ? select : debouncedSelect,
    isSelected,
    close,
    parent: parentBehaviour,
    subMenus: {},
  }

  // Register sub menu in parent menu
  if (parentItemContext.item) {
    parentBehaviour.subMenus[parentItemContext.item.id] = menuBehaviour
  }

  return (
    <MenuContextDefinition.Provider value={menuBehaviour}>
      {children}
    </MenuContextDefinition.Provider>
  )
}

MenuBehaviour.Trigger = MenuTrigger
MenuBehaviour.Content = MenuContent
