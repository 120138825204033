import { ExtendedAxiosError } from '@core/api/Platform/types'
import { BaseError } from '@core/utils/error/BaseError'
import { getCleanUrlWithoutParams } from '@core/utils/url'

const missingParam = 'unknown'

export enum ErrorGroup {
  Timeout = 'Timeout',
  Permissions = 'Permission error',
  Validation = 'Validation error',
  NotFound = 'Not found',
  Server = 'Server error',
  Other = 'Other',
}

const isTimeout = (axiosError: ExtendedAxiosError) => {
  return (
    axiosError.response?.status?.toString() === undefined ||
    axiosError.message?.toLowerCase().indexOf('timeout') >= 0 ||
    axiosError.code === 'ECONNABORTED'
  )
}

const ERRORS_TO_IGNORE = [
  ErrorGroup.Timeout,
  // Mostly invalidated tokens and results in token refresh
  ErrorGroup.Permissions,
  // Should bubble up on Server logging
  ErrorGroup.Server,
  ErrorGroup.NotFound,
  ErrorGroup.Validation,
]

const getErrorGroup = (axiosError: ExtendedAxiosError) => {
  const status = axiosError.response?.status
  if (isTimeout(axiosError)) return ErrorGroup.Timeout
  if (status && [401, 403].includes(status)) return ErrorGroup.Permissions
  if (status && [400, 409, 422].includes(status)) return ErrorGroup.Validation
  if (status && [404].includes(status)) return ErrorGroup.NotFound
  if (status && status >= 500) return ErrorGroup.Server
  return ErrorGroup.Other
}

const getUrl = (axiosError: ExtendedAxiosError) => {
  return (
    axiosError?.config.genericUrl ||
    (axiosError?.config.url &&
      getCleanUrlWithoutParams(axiosError.config.url)) ||
    missingParam
  )
}

const getMethod = (axiosError: ExtendedAxiosError) => {
  // axiosError.config is not typed as optional, but we had cases where it's undefined: https://lyskahq.atlassian.net/browse/CF-1791
  return axiosError?.config.method?.toUpperCase() || missingParam
}

const getStatusCode = (axiosError: ExtendedAxiosError) => {
  return isTimeout(axiosError)
    ? 'Timeout'
    : axiosError.response?.status?.toString() || missingParam
}

export class ApiError extends BaseError {
  private _axiosError: ExtendedAxiosError
  private _group: ErrorGroup

  constructor(axiosError: ExtendedAxiosError) {
    const group = getErrorGroup(axiosError)
    const method = getMethod(axiosError)
    const url = getUrl(axiosError)
    const status = getStatusCode(axiosError)
    const message = `${group}: ${method} /${url} | ${status}`

    super(message)
    this.name = 'ApiError'
    this._axiosError = axiosError
    this._group = group
  }

  get request() {
    return this._axiosError.request
  }

  get response() {
    return this._axiosError.response
  }

  get config() {
    return this._axiosError.config
  }

  get code() {
    return this._axiosError.code
  }

  get isTimeout() {
    return this._axiosError.code === 'ECONNABORTED'
  }

  get shouldEscalate() {
    return !ERRORS_TO_IGNORE.includes(this._group)
  }

  getContext() {
    return {
      message: this._axiosError.message,
      code: this._axiosError.code,
      config: this._axiosError.config,
      response: this._axiosError.response,
    }
  }

  getFingerprint() {
    return [
      getErrorGroup(this._axiosError),
      getMethod(this._axiosError),
      getUrl(this._axiosError),
      getStatusCode(this._axiosError),
    ]
  }

  getLevel() {
    return 'warning'
  }
}

export function isApiError(error: any): error is ApiError {
  return (error as ApiError).name === 'ApiError'
}
