import Router from 'next/router'

interface ScrollCache {
  [path: string]: {
    x: number
    y: number
  }
}

export const setupScrollManager = () => {
  if (typeof window === 'undefined') {
    throw new Error('Scroll manager can only be used on client side')
  }

  const router = Router
  const scrollCache: ScrollCache = {}
  let restoreScroll = false

  if (window.history?.scrollRestoration) {
    window.history.scrollRestoration = 'manual'
  }

  router.events.on('routeChangeStart', () => {
    scrollCache[router.asPath] = {
      x: window.scrollX || window.pageXOffset,
      y: window.scrollY || window.pageYOffset,
    }
  })

  router.events.on('routeChangeComplete', () => {
    if (!restoreScroll) return
    const position = scrollCache[router.asPath]
    if (!position) return

    restoreScroll = false
    window.scrollTo(position.x, position.y)
  })

  router.beforePopState(() => {
    restoreScroll = true
    return true
  })
}
