import { CustomerTypes } from '@core/api/Channel/types'
import { Environment } from '@core/utils/Environment'
import coreConfig from '@original/config/config'

export default {
  ...coreConfig,
  baseUrl:
    Environment.default.get('SHOP_BASE_URL') || 'https://www.store.igefa.de',
  storeName: 'igefa Store',
  prismic: {
    name: 'igefa-shop',
    landingPageSlug: 'landing-page',
  },
  assets: {
    ...coreConfig.assets,
    favicon: '/static/assets/igefa/favicon.png',
    storeLogo: '/static/assets/igefa/store-logo.svg',
    storeLogoClosedShop: '/static/assets/igefa/store-logo-closed.svg',
    storeLogoMobile: '/static/assets/igefa/store-logo.svg',
    serviceImage: '/static/assets/igefa/service-image.png',
    inpacsLogo: '/static/assets/igefa/inpacs-logo.svg',
    contactSuccess: '/static/assets/contactSuccess.svg',
    shippingMethods: {
      ...coreConfig.assets.shippingMethods,
      ups: '/static/assets/shippingMethods/ups.png',
      dpd: '/static/assets/shippingMethods/dpd.png',
      fleet: '/static/assets/igefa/truck_delivery.svg',
      package: '/static/assets/igefa/package_delivery.png',
      default: '/static/assets/igefa/default-shipping-method.png',
      fixed_price_shipping: '/static/assets/igefa/tour_plan.png',
      pickup_location: '/static/assets/igefa/click-collect.png',
      rxthHWtc9ZWr8Xsgx3FLGe: '/static/assets/igefa/click-collect.png', // "Selbstabholung"
      '6AwCytuyVjjDproSCaGGxf': '/static/assets/igefa/package_delivery.png', // Paketdienst / "fixed_price_shipping"
      aBbPWrv6QomuEVnPfMXvki: '/static/assets/igefa/tour_plan.png', // "Igefa Logistik" / "fixed_price_shipping"
      '9sQmkn7f79wCRPCMMHhKwd': '/static/assets/igefa/tour_plan.png', // "Igefa Logistik" / "fixed_price_shipping"
    },
    paymentMethods: {
      ...coreConfig.assets.paymentMethods,
      vorkasse: '/static/assets/paymentMethods/text-vorkasse.png',
    },
    certificates: {
      trustedshops: '/static/assets/trustedShops.svg',
    },
  },

  cookiebot: {
    cbid: 'b8897f69-9b72-4396-9438-dbcb3d2f481a',
  },

  features: {
    ...coreConfig.features,
    suppliedCountries: ['DE'], // Array of alpha-2 country codes
    defaultCustomerType: CustomerTypes.company,
    addressBlacklistedStrings: ['Postfach', 'Postfiliale', 'Packstation'], // expects an array of strings
    billingInformationEditable: false,
    // remove as soon as feature is ready from BE side
    extendedShippingInformationEnabled: Environment.default.getBoolean(
      'ENABLE_EXTENDED_SHIPPING_INFO'
    ),
    header: {
      productListDropdown: true,
      userMenuWithText: true,
      buttonType: 'link',
    },
    categoryNavigation: {
      visibleCategoryNavItems: false,
      overflowCategoryNavItems: true,
      hiddenCategoryNavItems: false,
    },
    scrollToTopButton: { enabled: true, withText: true },
    newProductsFeature: true,
    clickAndCollectEnabled: true,
    pictureZoom: {
      ...coreConfig.features.pictureZoom,
      enabled: false,
    },
    fullSizeImageGallery: {
      ...coreConfig.features.fullSizeImageGallery,
      enabled: false,
    },
    addToCartInSearchEnabled: true,
    exactProductSearchEnabled: true,
    productNumberRegex: /^\d{7}$/,
    searchResultRecommendations: true,
    filters: {
      ...coreConfig.features.filters,
      awardsFilter: {
        active: true,
        filterKey: 'award',
        icon: '/static/assets/igefa/logos/eco-label-leaf.svg',
      },
      showOnlyWithPriceFilter: true,
    },
  },
  emarsys: {
    merchantId: '14A76F3B4C23954C',
  },
  defaultNewsletterSource: 'igefa_shop',
  shippingMethods: {
    selectionSorting: ['aBbPWrv6QomuEVnPfMXvki', 'rxthHWtc9ZWr8Xsgx3FLGe'],
  },
}
