import { Checkbox } from 'antd'
import { CheckboxProps } from 'antd/lib/checkbox'
import PropTypes from 'prop-types'

export type Props = CheckboxProps

const LYSCheckbox: React.FC<Props> = (props: Props) => {
  return <Checkbox {...props} />
}

LYSCheckbox.defaultProps = {
  checked: false,
  disabled: false,
  indeterminate: false,
}

LYSCheckbox.propTypes = {
  checked: PropTypes.bool,
  disabled: PropTypes.bool,
  indeterminate: PropTypes.bool,
  onChange: PropTypes.func,
}

export default LYSCheckbox
