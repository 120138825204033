import { sha256 } from 'js-sha256'
import { CustomerTypes, RegistrationTypes } from '@core/api/Channel/types'
import { IUser } from '@core/api/User/types'
import { IShopServices } from '@core/config/services'
import { IViewPageEvent, viewPageEvent } from '@core/events/view'
import { ConverlyticsQueue } from '@core/modules/converlytics-tracking/ConverlyticsQueue'
import { ICategoryPageProps } from '@core/pages/c/[...slugSlashId]'
import { getAuthenticatedUser } from '@core/store/user/selectors'
import { IGenericPageContext } from '@core/utils/pages/PageContext'
import { PageType } from '@core/utils/pages/types'
import trackingConfig from './config'
import { getRouteProperty } from './utils'

// CUSTOM CONVERLYTICS TYPES DEFINITIONS
interface ConverlyticsPageViewData {
  event: string
  pageName: string
  userId?: string // !!! change from snake case (user_id) to camel case userId
  cg1?: string
  cg2?: string
  cg3?: string
  cg4?: string
  dimension1?: string
  dimension5?: string
  dimension6?: string
  dimension7?: string
}

let previousUser: IUser | null = null

const setupPageTracking = (
  services: IShopServices,
  queue: ConverlyticsQueue
) => {
  const { store, cookies, eventBus } = services

  store.subscribe(() => {
    const user = getAuthenticatedUser(store.getState())
    if (!previousUser && user) {
      // save user tracking id into cookie upon login
      const hash = sha256(`${user.email}.${trackingConfig.hashSalt}`)
      cookies.set(
        trackingConfig.cookieName,
        `${trackingConfig.hashPrefix}-${hash}`
      )
      previousUser = user
    } else if (previousUser && !user) {
      // remove user tracking id cookie upon logout
      cookies.remove(trackingConfig.cookieName)
      previousUser = null
    }
  })

  eventBus.subscribe<IViewPageEvent>(viewPageEvent.type, (event) => {
    const data = createBaseData(services)
    addContentGroups(services, event.payload.pageContext, data)
    addPageDimensions(services, data)

    queue.push(data)
  })
}

const createBaseData = (services: IShopServices): ConverlyticsPageViewData => {
  const { cookies } = services

  const data: ConverlyticsPageViewData = {
    event: 'pageView',
    pageName: getPageName(services),
  }

  const trackingId = cookies.get(trackingConfig.cookieName)
  if (trackingId) {
    data.userId = trackingId
  }

  return data
}

const addContentGroups = (
  services: IShopServices,
  pageContext: IGenericPageContext,
  data: ConverlyticsPageViewData
) => {
  addContentGroup1(services, data)
  addContentGroup2(services, pageContext, data)
  addContentGroup3(services, data)
  addContentGroup4(services, pageContext, data)
}

const addPageDimensions = (
  services: IShopServices,
  data: ConverlyticsPageViewData
) => {
  addPageDimension1(services, data)
  addPageDimension5(services, data)
  addPageDimension6(services, data)
  addPageDimension7(services, data)
}

const getPageName = (services: IShopServices): string => {
  const { router, store } = services

  let pageName = trackingConfig.pageNamePrefixes.b2CShop as string

  const user = getAuthenticatedUser(store.getState())
  if (user) {
    pageName =
      user.channel.allowedCustomerTypes.indexOf(CustomerTypes.company) > -1
        ? (trackingConfig.pageNamePrefixes.b2BShop as string)
        : (trackingConfig.pageNamePrefixes.b2CShop as string)
  }

  pageName += getRouteProperty(
    router,
    trackingConfig.routeMapRouteProps.pageName
  )
  return pageName
}

// customer focus
const addContentGroup1 = (
  services: IShopServices,
  data: ConverlyticsPageViewData
) => {
  const { store } = services
  data.cg1 = trackingConfig.contentGroup1.b2CShop as string
  const user = getAuthenticatedUser(store.getState())
  if (user) {
    data.cg1 =
      user.channel.allowedCustomerTypes.indexOf(CustomerTypes.company) > -1
        ? (trackingConfig.contentGroup1.b2BShop as string)
        : (trackingConfig.contentGroup1.b2CShop as string)
  }
}

// category level - applies only on category browse
const addContentGroup2 = (
  _services: IShopServices,
  pageContext: IGenericPageContext,
  data: ConverlyticsPageViewData
) => {
  if (pageContext.props.pageType === PageType.category) {
    const categoryPageProps = pageContext.props as ICategoryPageProps
    const path = categoryPageProps.category?.breadcrumbs.hierarchy
    data.cg2 = path
      ? `${trackingConfig.contentGroup2.prefix}${path.length - 1}`
      : `${trackingConfig.contentGroup2.prefix}0`
  }
}

// details - applies only on product detail pages
const addContentGroup3 = (
  services: IShopServices,
  data: ConverlyticsPageViewData
) => {
  const { router } = services
  if (router.route === trackingConfig.routeMapRoutes.product) {
    data.cg3 = trackingConfig.contentGroup3.products
  }
}

// page type
const addContentGroup4 = (
  services: IShopServices,
  pageContext: IGenericPageContext,
  data: ConverlyticsPageViewData
) => {
  const { router } = services
  if (pageContext.props.pageType === PageType.category) {
    const categoryPageProps = pageContext.props as ICategoryPageProps
    const path = categoryPageProps.category?.breadcrumbs.hierarchy
    data.cg4 =
      path && path.length > 2
        ? trackingConfig.contentGroup4.subCategory
        : trackingConfig.contentGroup4.topCategory
  } else {
    data.cg4 = getRouteProperty(
      router,
      trackingConfig.routeMapRouteProps.pageType
    )
  }
}

// shop type - applied only on product detail page
const addPageDimension1 = (
  services: IShopServices,
  data: ConverlyticsPageViewData
) => {
  const { store, router } = services
  if (
    trackingConfig.isIgefaStore &&
    router.route === trackingConfig.routeMapRoutes.product
  ) {
    data.dimension1 = trackingConfig.dimension1.openShop
    const user = getAuthenticatedUser(store.getState())
    if (user) {
      data.dimension1 =
        user.channel.registrationType === RegistrationTypes.closed
          ? trackingConfig.dimension1.closedShop
          : trackingConfig.dimension1.openShop
    }
  }
}

// user login status - applied on every page
const addPageDimension5 = (
  services: IShopServices,
  data: ConverlyticsPageViewData
) => {
  const { store } = services
  const user = getAuthenticatedUser(store.getState())
  data.dimension5 = user
    ? trackingConfig.dimension5.loggedIn
    : trackingConfig.dimension5.loggedOut
}

// checkout status - applies only on certain checkout pages
const addPageDimension6 = (
  services: IShopServices,
  data: ConverlyticsPageViewData
) => {
  const { router, store } = services
  if (
    trackingConfig.checkoutRoutes.indexOf(router.route) > -1 &&
    router.route !== trackingConfig.routeMapRoutes.checkoutConfirmation
  ) {
    const user = getAuthenticatedUser(store.getState())
    data.dimension6 = user
      ? trackingConfig.dimension6.existingCustomer
      : trackingConfig.dimension6.guestCustomer
  }
}

// shop type - applied on every page page
const addPageDimension7 = (
  services: IShopServices,
  data: ConverlyticsPageViewData
) => {
  const { store } = services
  data.dimension7 = trackingConfig.dimension7.openShop
  const user = getAuthenticatedUser(store.getState())
  if (user) {
    data.dimension7 =
      user.channel.registrationType === RegistrationTypes.closed
        ? trackingConfig.dimension7.closedShop
        : trackingConfig.dimension7.openShop
  }
}

export default setupPageTracking
