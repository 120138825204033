import { createSelector } from 'reselect'
import { IAppState } from '@core/store/types'

const getPriceRequestState = (state: IAppState) => state.priceRequests

export const getPriceRequests = createSelector(
  getPriceRequestState,
  (priceRequestState) => priceRequestState.priceRequests
)

export const getAnsweredPriceRequests = createSelector(
  getPriceRequests,
  (priceRequests) =>
    priceRequests.filter(
      (priceReq) =>
        priceReq.status === 'done' ||
        priceReq.status === 'rejected' ||
        (priceReq.status === 'acknowledged' &&
          priceReq.productInformation.mainVariant.price !== null)
    )
)

export const getNotificationPriceRequests = createSelector(
  getPriceRequests,
  (priceRequests) =>
    priceRequests.filter(
      (priceReq) => priceReq.status === 'done' || priceReq.status === 'rejected'
    )
)

export const getPendingPriceRequests = createSelector(
  getPriceRequests,
  (priceRequests) =>
    priceRequests.filter(
      (priceReq) =>
        priceReq.status === 'open' ||
        priceReq.status === 'requested' ||
        priceReq.status === 'rejected'
    )
)

export const getIsPendingPriceRequest = (productSku: string) =>
  createSelector(
    getPriceRequests,
    (priceRequests) =>
      priceRequests.findIndex(
        (request) =>
          request.productInformation?.sku === productSku &&
          (request.status === 'open' ||
            request.status === 'requested' ||
            request.status === 'rejected')
      ) >= 0
  )
