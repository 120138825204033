export interface IChannel {
  id: string
  name: string
  displayedPriceType: PriceTypes
  clientFields?: {
    [key: string]: any
  }
  registrationType: RegistrationTypes
  allowedCustomerTypes: CustomerTypes[]
  displayStockMode: StockInformationTypes
  isDefault?: boolean
}

export enum PriceTypes {
  net = 'net',
  gross = 'gross',
}

export enum RegistrationTypes {
  open = 'open',
  closed = 'closed',
  approvalRequired = 'approvalRequired',
}

export enum CustomerTypes {
  personal = 'personal',
  company = 'company',
}

export enum StockInformationTypes {
  disabled = 'disabled',
  simple = 'simple',
  extended = 'extended',
}
