import { Row } from 'antd'
import { RowProps } from 'antd/lib/row'

enum GutterSizes {
  'none' = 0,
  'xs' = 8,
  'sm' = 12,
  'md' = 16,
  'lg' = 24,
  'xl' = 36,
  '2xl' = 48,
  '3xl' = 72,
}
export declare type GutterType = keyof typeof GutterSizes

interface OwnProps {
  gutter?: GutterType | GutterType[]
}
type Props = OwnProps & Omit<RowProps, 'gutter'>

const getSpacingNumber = (size: GutterType) => GutterSizes[size]

const getGutterValue = (
  gutter?: GutterType | GutterType[]
): [number, number] | undefined => {
  if (!gutter) return undefined

  const horizontalGutter: GutterType = Array.isArray(gutter)
    ? gutter[0]
    : gutter
  const verticalGutter: GutterType = Array.isArray(gutter) ? gutter[1] : gutter

  return [getSpacingNumber(horizontalGutter), getSpacingNumber(verticalGutter)]
}
const LYSRow: React.FC<Props> = (props) => {
  return <Row {...props} gutter={getGutterValue(props.gutter)} />
}

export default LYSRow
