import React from 'react'
import { MessageOutlined } from '@ant-design/icons'
import { LYSTypography, LYSIcon } from '@core/components/Primitives'
import { useTranslation } from '@core/i18n/i18n'
import styles from './index.module.less'
import cn from 'classnames'

interface IProps {
  smallLayout?: boolean
  paddingBottom?: boolean
}

const PriceRequestPendingInfo: React.FC<IProps> = ({
  smallLayout,
  paddingBottom,
}) => {
  const { t } = useTranslation()
  return (
    <>
      <div
        className={cn(
          styles.pending_container,
          (smallLayout || !paddingBottom) && styles.pending_container_small
        )}
      >
        <LYSIcon component={MessageOutlined} className={styles.icon} />
        <LYSTypography.Text strong className={styles.title}>
          {smallLayout
            ? t`priceRequest.pendingInfoShort`
            : t`priceRequest.pendingInfo`}
        </LYSTypography.Text>
      </div>
    </>
  )
}

export default PriceRequestPendingInfo
