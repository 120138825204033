import React from 'react'
import LYSTypography from '@core/components/Primitives/LYSTypography'

interface Props {
  errorText: string
  className?: string
}

const ErrorText: React.FC<Props> = (props) => {
  const { errorText, className } = props

  return (
    <LYSTypography.Text type="danger" className={className}>
      {errorText}
    </LYSTypography.Text>
  )
}

export default ErrorText
