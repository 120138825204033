import { IRegisterAccount } from '@core/api/Customer/types'
import { createAction } from '@reduxjs/toolkit'

export const registrationEvent = createAction(
  'auth/registration',
  (credentials: IRegisterAccount) => {
    return {
      payload: {
        credentials,
      },
    }
  }
)

export type IRegistrationEvent = ReturnType<typeof registrationEvent>
