import React, { useState } from 'react'
import {
  LYSModal,
  LYSTypography,
  LYSCheckbox,
  LYSButton,
} from '@core/components/Primitives'
import config from '@core/config/config'
import usePriceRequests from '@core/hooks/priceRequest/usePriceRequests'
import { useTranslation } from '@core/i18n/i18n'
import styles from './index.module.less'
import Link from 'next/link'
import PriceRequestProductStatus from './PriceRequestProductStatus'
import { usePageContext } from '@core/utils/pages/PageContext'

const PriceRequestModalOverview: React.FC = () => {
  const { t } = useTranslation()
  const {
    props: { isMobile },
  } = usePageContext()
  const [dontShowAgain, setDontShowAgain] = useState(false)
  const {
    hidePriceRequestModal,
    notificationPriceRequests,
    isSessionPRModalHidden,
    acknowledgePriceRequests,
  } = usePriceRequests()

  const handleCloseModal = () => {
    if (dontShowAgain) {
      acknowledgePriceRequests()
    } else {
      hidePriceRequestModal()
    }
  }

  if (notificationPriceRequests.length <= 0) return null

  return isMobile ? (
    <LYSModal
      visible={!isSessionPRModalHidden}
      footer={null}
      onCancel={handleCloseModal}
      className={styles.modal}
    >
      <LYSTypography.Title
        level={5}
        style={{
          fontSize: '11px',
        }}
        inheritColor
      >
        {t`priceRequest.modal.title`}
      </LYSTypography.Title>
      <LYSTypography.Text
        style={{
          fontSize: '9px',
        }}
      >
        {t`priceRequest.modal.info`}
      </LYSTypography.Text>
      <br />
      <Link href="/me/price-requests/overview">
        <a
          style={{
            fontSize: '10px',
            textDecoration: 'underline',
          }}
          onClick={handleCloseModal}
        >
          {t('priceRequest.allRequests')}
        </a>
      </Link>
    </LYSModal>
  ) : (
    <LYSModal
      visible={!isSessionPRModalHidden}
      footer={null}
      onCancel={handleCloseModal}
      className={styles.modal}
    >
      <div className={styles.modal_content}>
        <div className={styles.modal_header}>
          <img
            src={config.assets.priceRequestModal}
            className={styles.modal_icon}
            alt={t('alt.successPriceRequest')}
          />
          <LYSTypography.Title level={3} inheritColor>
            {t`priceRequest.modal.title`}
          </LYSTypography.Title>
          <LYSTypography.Paragraph>
            {t`priceRequest.modal.info`}
          </LYSTypography.Paragraph>
        </div>
        {notificationPriceRequests.map((priceRequest) => (
          <PriceRequestProductStatus
            requestProduct={priceRequest}
            key={`modal-${priceRequest.id}`}
            showAddToCart={false}
          />
        ))}

        <div className={styles.modal_footer}>
          <LYSCheckbox
            checked={dontShowAgain}
            onChange={() => setDontShowAgain(!dontShowAgain)}
          >{t`priceRequest.modal.doNotShowAgainLabel`}</LYSCheckbox>

          <Link href="/me/price-requests/overview">
            <LYSButton type="primary" onClick={handleCloseModal}>
              {t('priceRequest.allRequests')}
            </LYSButton>
          </Link>
        </div>
      </div>
    </LYSModal>
  )
}

export default PriceRequestModalOverview
