import coreConfig from '@original/modules/converlytics-tracking/config'

/// =========================================================== ///
/// ================= STORE RELATED CONFIG ==================== ///
/// =========================================================== ///
const gtmContainerId = 'GTM-PP4HNS5'
const isIgefaStore = true // toggle to true if this shop is for igefa
const hashPrefix = '1' // 1 = igefa, 2 = idealclean, 3 = idealsafety
const hashSalt = 'afegi' // should be set to something different for each instance

const pageNamePrefixes = {
  b2BShop: 'bc',
  b2CShop: 'bc',
}
const contentGroup1 = {
  b2BShop: 'bc-shop',
  b2CShop: 'bc-shop',
}

export default {
  ...coreConfig,
  pageNamePrefixes,
  contentGroup1,
  gtmContainerId,
  isIgefaStore,
  hashPrefix,
  hashSalt,
}
