import React, { useEffect, useRef } from 'react'
import cn from 'classnames'
import { CloseOutlined } from '@ant-design/icons'
import { LYSButton, LYSIcon, LYSTypography } from '@core/components/Primitives'
import { MenuItem } from '@core/components/header/Navigation/MobileCategoryNavMenu/MenuItem'
import { MenuItemList } from '@core/components/header/Navigation/MobileCategoryNavMenu/MenuItemList'
import {
  MenuBehaviour,
  useMenuContext,
} from '@core/components/shared/menu/MenuBehaviour'
import useCategoryNavigation from '@core/hooks/categories/useCategoryNavigation'
import { useTranslation } from '@core/i18n/i18n'
import { RenderProp, Slot, template } from '@core/utils/templates'
import style from './index.module.less'
import { useAppBarContext } from '../../AppBar/Context/appBarContext'
import { Button } from 'antd'

export interface MobileCategoryNavMenuProps {
  triggerClassName?: string
  overlayClassName?: string
  overlayHeaderClassName?: string
  overlayContentClassName?: string
}

export interface MobileCategoryNavMenuSlots {
  trigger?: RenderProp<void>
  overlayHeader?: RenderProp<void>
  overlayContent?: RenderProp<void>
}

const mainMenuItem = {
  id: 'main',
}
const mainMenuItems = [mainMenuItem]

const CloseButton: React.FC = () => {
  const behavior = useMenuContext()
  return (
    <LYSButton
      className={style.closeButton}
      type={'link'}
      onClick={() => behavior.close()}
    >
      <LYSIcon component={CloseOutlined} size={'sm'} title={'Close'} />
    </LYSButton>
  )
}

const MobileCategoryNavMenu = template<
  MobileCategoryNavMenuProps,
  MobileCategoryNavMenuSlots
>(
  ({
    slots,
    overlayClassName,
    triggerClassName,
    overlayHeaderClassName,
    overlayContentClassName,
  }) => {
    const { t } = useTranslation()
    const { root } = useCategoryNavigation()
    const { categoryMenuOpen, setCategoryMenuOpen } = useAppBarContext()

    const ref = useRef<HTMLButtonElement>(null)

    useEffect(() => {
      if (categoryMenuOpen) {
        ref?.current?.click()
      }
    }, [categoryMenuOpen])

    return (
      <MenuBehaviour
        debounce={false}
        menuId={'mobileMenu'}
        items={mainMenuItems}
        keyboardNavigationDirection={'horizontal'}
        onClose={() => {
          if (setCategoryMenuOpen) setCategoryMenuOpen(false)
        }}
      >
        <nav role="navigation">
          <MenuBehaviour.Trigger item={mainMenuItem}>
            <Slot render={slots?.trigger}>
              <Button
                style={{ display: 'none' }}
                ref={ref}
                className={triggerClassName}
              />
            </Slot>
          </MenuBehaviour.Trigger>
          <MenuBehaviour.Content item={mainMenuItem}>
            <div className={cn(style.overlay, overlayClassName)}>
              <div className={cn(style.header, overlayHeaderClassName)}>
                <Slot render={slots?.overlayHeader}>
                  <LYSTypography.Text visualAppearance={'h4'}>
                    {t('flyOut.productCategoriesText')}
                  </LYSTypography.Text>
                  <CloseButton />
                </Slot>
              </div>
              <div className={cn(style.content, overlayContentClassName)}>
                <Slot render={slots?.overlayContent}>
                  {root ? (
                    <MenuItemList
                      MenuItemRenderer={MenuItem}
                      rootCategory={root}
                      level={1}
                    />
                  ) : null}
                </Slot>
              </div>
            </div>
          </MenuBehaviour.Content>
        </nav>
      </MenuBehaviour>
    )
  }
)

export default MobileCategoryNavMenu
