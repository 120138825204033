import { UrlObject } from 'url'
import { NextRouter } from 'next/router'
import { Environment } from '@core/utils/Environment'

export type ShopRouter = NextRouter & {
  /**
   * Executes base push and scrolls window to top
   * @param url
   * @param as
   * @param options
   */
  pushAndScrollTop(
    url: UrlObject | string,
    as?: UrlObject | string,
    options?: any
  ): Promise<boolean>

  /**
   * Navigates to a URL using a full page reload
   * @param url
   */
  navigate(url: string): void
}

export const createShopRouter = (nextRouter: NextRouter): ShopRouter => {
  // Could be handled a bit more gracefully, but extending the Next router works for now
  // Future options could be to write a complete proxy or just extract utility functions
  const shopRouter = nextRouter as ShopRouter

  shopRouter.pushAndScrollTop = async (
    url: UrlObject | string,
    as?: UrlObject | string,
    options?: any
  ) => {
    if (Environment.default.isServer) {
      throw new Error('pushAndScrollTop can only be used on client')
    }
    const result = await nextRouter.push(url, as, options)
    if (result) {
      window.scrollTo(0, 0)
    }
    return result
  }

  shopRouter.navigate = (url: string) => {
    if (Environment.default.isServer) {
      throw new Error('navigate can only be used on client')
    }
    window.location.href = url
  }

  return shopRouter
}
