import * as React from 'react'

import config from '@core/config/config'
import useChannel from '@core/hooks/channel/useChannel'
import { useTranslation } from '@core/i18n/i18n'
import CartPriceSummary, {
  CartPriceSummaryProps,
} from '@original/components/Cart/CartPriceSummary'
import SummaryRow from './SummaryRow'

const IgefaCartPriceSummary: React.FC<CartPriceSummaryProps> = (props) => {
  const { t } = useTranslation()
  const { shipping } = props.priceObject
  const { userChannel, isOpenChannel } = useChannel()
  const { displayedPriceType } = userChannel!

  return (
    <CartPriceSummary
      {...props}
      slots={{
        shippingRow: () => {
          return (
            <SummaryRow
              label={
                config.features.clickAndCollectEnabled && !isOpenChannel
                  ? t(
                      `clickAndCollect.cart.price.shipping.${displayedPriceType}`
                    )
                  : t(`cart.price.shipping.${displayedPriceType}`)
              }
              amount={shipping[displayedPriceType]}
            />
          )
        },
      }}
    />
  )
}

export default IgefaCartPriceSummary
