import { useSelector } from 'react-redux'
import { IChannel, RegistrationTypes } from '@core/api/Channel/types'
import { getUserChannel } from '@core/store/user/selectors'

const useChannel = () => {
  const userChannel: IChannel | undefined = useSelector(getUserChannel)

  const isOpenChannel =
    !userChannel || userChannel.registrationType === RegistrationTypes.open

  const isClosedChannel =
    userChannel?.registrationType === RegistrationTypes.closed

  return {
    userChannel,
    isOpenChannel,
    isClosedChannel,
  }
}

export default useChannel
