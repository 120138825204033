import { TOptions } from 'i18next'
import { IEstimatedDeliveryDaysRange } from '@core/api/Products/types'

export const getEstimatedDateRangeTranslation = (
  estimatedDeliveryDaysRange?: IEstimatedDeliveryDaysRange
): null | {
  translationString: string
  options: TOptions
} => {
  if (!estimatedDeliveryDaysRange) {
    return null
  }

  const { minDays, maxDays } = estimatedDeliveryDaysRange

  if (!minDays && !maxDays) {
    return null
  }

  if (!minDays || !maxDays || minDays === maxDays) {
    return {
      translationString: 'product.estimatedDelivery',
      options: {
        count: minDays || maxDays,
      },
    }
  }

  return {
    translationString: 'product.estimatedDelivery_range',
    options: {
      min: minDays,
      max: maxDays,
      count: 1,
    },
  }
}
