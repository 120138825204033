import { MouseEvent } from 'react'
import cn from 'classnames'
import Link from 'next/link'
import { LYSButton } from '@core/components/Primitives'
import { LYSButtonProps } from '@core/components/Primitives/LYSButton'
import { useMenuContext } from '@core/components/shared/menu/MenuBehaviour'
import { Route } from '@core/config/routes'
import style from './index.module.less'

export interface MenuItemProps extends LYSButtonProps {
  route?: Route
  activatable?: boolean
  activeClassName?: string
  inactiveClassName?: string
}

export const MenuItem: React.FC<MenuItemProps> = ({
  route,
  activatable,
  activeClassName,
  inactiveClassName,
  children,
  ...buttonProps
}) => {
  const menuBehaviour = useMenuContext()

  const classNames = cn(
    style.item,
    buttonProps.className,
    activatable && style.activatable,
    activatable && menuBehaviour.isActiveItem && style.active,
    activatable && menuBehaviour.isActiveItem
      ? activeClassName
      : inactiveClassName
  )

  const handleClick = (e: MouseEvent<HTMLElement>) => {
    if (route) {
      menuBehaviour.close()
    } else {
      if (buttonProps.onClick) {
        buttonProps.onClick(e)
      }
    }
  }

  const button = (
    <LYSButton
      type={'link'}
      role={'menuitem'}
      {...buttonProps}
      className={classNames}
      onClick={handleClick}
    >
      {children}
    </LYSButton>
  )

  return route ? <Link {...route}>{button}</Link> : button
}
