import * as React from 'react'
import cn from 'classnames'
import { PriceTypes } from '@core/api/Channel/types'
import { IGrossPrice, IUnitPrice } from '@core/api/Products/types'
import { LYSTypography } from '@core/components/Primitives'
import config from '@core/config/config'
import { useTranslation } from '@core/i18n/i18n'
import { formatPrice, formatChannelPrice } from '@core/utils/i18n'
import useChannel from '../../../hooks/channel/useChannel'
import style from './index.module.less'
import { RenderProp, Slot, template } from '@core/utils/templates'
import { calculateDiscount } from './helper'
import {
  getPackagingUnitsFromContext,
  useProductContext,
} from '@core/utils/models/product/ProductContext'

interface ProductPriceSlots {
  basePrice?: RenderProp<void>
  unitPrice?: RenderProp<void>
  strikePrice?: RenderProp<void>
  additionalCosts?: RenderProp<void>
  additionalElementEnd?: RenderProp<void>
  discountHint?: RenderProp<void>
}

export interface ProductPriceProps {
  price?: IGrossPrice
  compactPrice?: boolean
  hideAdditionalCosts?: boolean
  mainPriceHolderClass?: string
  priceClass?: string
  strikePriceClass?: string
  hintClass?: string
  unitPrice?: IUnitPrice
  strikePrice?: IGrossPrice
  showDiscountHint?: boolean
}

const ProductPrice = template<ProductPriceProps, ProductPriceSlots>(
  ({ slots, ...props }) => {
    const {
      price,
      compactPrice,
      mainPriceHolderClass,
      priceClass,
      strikePriceClass,
      hintClass,
      unitPrice,
      strikePrice,
      hideAdditionalCosts,
      showDiscountHint = false,
    } = props

    const { t } = useTranslation()
    const { userChannel } = useChannel()
    const productContext = useProductContext()
    if (!productContext) return null
    const { packagingUnit } = getPackagingUnitsFromContext(productContext)

    if (!packagingUnit) return null

    const renderUnitPrice = () => {
      if (!unitPrice) return
      const priceInfo = {
        price: formatChannelPrice(unitPrice, userChannel!),
        unit: unitPrice.unit,
        quantity: unitPrice.quantity,
      }
      return (
        <div className={style.unitPrice}>
          <LYSTypography.Text type={'secondary'}>
            {hideAdditionalCosts
              ? null
              : compactPrice
              ? t('product.unitPrice.compact', priceInfo)
              : t('product.unitPrice.default', priceInfo)}
          </LYSTypography.Text>
        </div>
      )
    }

    const visualAppearance = compactPrice ? undefined : 'h3'

    let taxLabelTranslationString =
      userChannel?.displayedPriceType === PriceTypes.net
        ? 'product.price.plusTaxAndShipping'
        : 'product.price.taxIncludedPlusShipping'

    if (config.features.clickAndCollectEnabled) {
      taxLabelTranslationString = `clickAndCollect.${taxLabelTranslationString}`
    }
    const discount = formatPrice(
      calculateDiscount(price, strikePrice, userChannel) ?? 0
    )

    return userChannel ? (
      <>
        <div className={cn(style.mainPriceHolder, mainPriceHolderClass)}>
          {!!strikePrice && (
            <Slot render={slots?.strikePrice}>
              <LYSTypography.Text
                type={'secondary'}
                visualAppearance={visualAppearance}
                className={cn(style.strikePriceValue, strikePriceClass)}
              >
                {formatChannelPrice(strikePrice, userChannel)}
              </LYSTypography.Text>
            </Slot>
          )}
          <Slot render={slots?.basePrice}>
            <LYSTypography.Text
              visualAppearance={visualAppearance}
              className={cn(
                style.priceValue,
                !!strikePrice && style.priceValueWithStrikePrice,
                priceClass
              )}
              data-testid="product-price"
            >
              {price && formatChannelPrice(price, userChannel)}
            </LYSTypography.Text>
          </Slot>
          {!!strikePrice && showDiscountHint && (
            <Slot render={slots?.discountHint}>
              <LYSTypography.Paragraph data-testid="calculated-discount">
                {t('productDetail.strikePriceDiscountHint')}
                <span>{discount}</span>
              </LYSTypography.Paragraph>
            </Slot>
          )}
        </div>
        <Slot render={slots?.unitPrice}>{renderUnitPrice()}</Slot>
        {!compactPrice && !hideAdditionalCosts && (
          <Slot render={slots?.additionalCosts}>
            <small className={hintClass}>
              {t(taxLabelTranslationString, {
                taxValue: price && formatPrice(price.tax),
              })}
            </small>
          </Slot>
        )}
        <Slot render={slots?.additionalElementEnd} />
      </>
    ) : (
      <>
        <br />
        <br />
      </>
    )
  }
)

export default ProductPrice
