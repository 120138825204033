import React from 'react'
import cn from 'classnames'
import { QuickCart } from '@core/components/Cart/QuickCart'
import { LYSSpacer } from '@core/components/Primitives'
import MobileCategoryNavMenu from '@core/components/header/Navigation/MobileCategoryNavMenu'
import { usePageContext } from '@core/utils/pages/PageContext'
import AutoSuggestResponsive from './AutoSuggestResponsive'
import StoreLogo from './StoreLogo'
import { UserMenu } from './UserMenu'
import customStyle from './custom.module.less'
import style from './index.module.less'
import config from '@core/config/config'
import { ProductListMenu } from './ProductListMenu'
import Link from 'next/link'
import { useTranslation } from '@core/i18n/i18n'
import useCurrentUser from '@core/hooks/user/useCurrentUser'

/**
 * customization:
 * - category menu right of logo
 * - custom category nav menu component on desktop
 */

const AppBar: React.FC = () => {
  const {
    props: { isMobile },
  } = usePageContext()

  const { isAuthenticated } = useCurrentUser()
  const isOverflowCategoryNav =
    config.features.categoryNavigation.overflowCategoryNavItems
  const { t } = useTranslation()
  return (
    <div
      className={cn(
        style.appbar,
        isOverflowCategoryNav && style.appbarWithoutShadow
      )}
      data-testid={'igefa_appBar'}
    >
      <StoreLogo
        customWidth={97}
        customHeight={40}
        className={style.storeLogo}
      />
      <LYSSpacer className={style.spacer} />
      {isMobile && <MobileCategoryNavMenu />}
      <AutoSuggestResponsive
        autoSuggestClassName={cn(style.search, customStyle.autoSuggest)}
        toggleButtonClassName={cn(style.appbarButton, customStyle.appbarButton)}
      />

      {isMobile && !isAuthenticated ? (
        <>
          <Link href={'/auth/login'}>{t('appBar.loginText')}</Link>
        </>
      ) : (
        <>
          <UserMenu
            buttonClassName={cn(style.appbarButton, customStyle.appbarButton)}
          />
          {!isMobile && (
            <>
              <ProductListMenu
                buttonClassName={cn(
                  style.appbarButton,
                  customStyle.appbarButton
                )}
              />
              <QuickCart buttonClassName={customStyle.appbarButton} />
            </>
          )}
        </>
      )}
    </div>
  )
}
export default AppBar
