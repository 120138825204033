import cn from 'classnames'
import style from './index.module.less'

export interface MenuDropdownProps {
  className?: string
  anchored?: boolean
  fullWidth?: boolean
}

export const MenuDropdown: React.FC<MenuDropdownProps> = ({
  className,
  anchored,
  fullWidth,
  children,
  ...rest
}) => {
  const classNames = cn(
    style.menuDropdown,
    anchored && style.anchored,
    fullWidth && style.fullWidth,
    className
  )

  return (
    <div {...rest} className={classNames}>
      {children}
    </div>
  )
}
