import React from 'react'
import { Tag } from 'antd'
import { TagProps } from 'antd/lib/tag'

type Props = TagProps

const LYSTag: React.FC<Props> = (props) => {
  return <Tag {...props} />
}

export default LYSTag
