import { IShopServices } from '@core/config/services'
import { ConverlyticsQueue } from '@core/modules/converlytics-tracking/ConverlyticsQueue'
import setupCommerceTracking from './commerceTracking'
import setupEventTracking from './eventTracking'
import setupPageTracking from './pageTracking'

const DEFAULT_DEBOUNCE_DURATION = 500

interface ConvertlyticsTrackingOptions {
  debounceDuration?: number
}

const setupConverlyticsTracking = (
  services: IShopServices,
  {
    debounceDuration = DEFAULT_DEBOUNCE_DURATION,
  }: ConvertlyticsTrackingOptions = {}
) => {
  const queue = new ConverlyticsQueue(services, debounceDuration)
  setupPageTracking(services, queue)
  setupCommerceTracking(services, queue)
  setupEventTracking(services, queue)
}

export default setupConverlyticsTracking
