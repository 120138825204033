import BaseAPI from '@core/api/Platform/BaseAPI'
import { IBrand } from '../Products/types'

class Brands extends BaseAPI {
  public async fetchBrandById(brandId: string): Promise<IBrand | undefined> {
    try {
      const response = await this.api.get<IBrand>(`brands/${brandId}`, {
        useCache: true,
        genericUrl: 'brands/{brandId}',
      })
      const { data } = response

      return data
    } catch (e) {
      if (e.response?.status === 404) return undefined
      throw e
    }
  }
}

export default Brands
