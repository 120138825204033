import Router from 'next/router'
import GlobalProgressBar from '@core/components/header/GlobalProgressBar'
import { IShopServices } from '@core/config/services'
import { forceCssRefresh } from '@core/utils/next'
import { setupScrollManager } from '@core/utils/scrollManager'
import initCustom from './init.custom'

export default function init(services: IShopServices) {
  /* Core init logic */

  const { environment } = services

  // Setup router hooks
  Router.events.on('routeChangeStart', () => GlobalProgressBar.start())
  Router.events.on('routeChangeComplete', () => {
    // The following hack is from https://github.com/zeit/next-plugins/issues/282#issuecomment-432127816 and is to fix the next.js router.
    if (environment.isDevelopment) {
      forceCssRefresh()
    }

    GlobalProgressBar.done()
  })
  setupScrollManager()

  /* Custom init logic */
  initCustom(services)
}
