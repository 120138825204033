import uniqBy from 'lodash/uniqBy'
import Prismic from 'prismic-javascript'
import ResolvedApi from 'prismic-javascript/types/ResolvedApi'
import { Document } from 'prismic-javascript/types/documents'
import config from '@core/config/config'
import linkResolver from '@core/prismic/linkResolver'
import { ShopStore } from '@core/store/configureStore'
import { Cache } from '@core/utils/caching/Cache'
import CookiesFacade from '@core/utils/cookies'
import {
  IBrandContent,
  ICategoryContent,
  IInfoBannerContent,
  IPageLayout,
  IPageSitemapConfig,
  IProductContent,
} from './types'

const getCacheKey = (contentType: string, uid: string) =>
  `prismic|${contentType}|${uid}`

const REPOSITORY = process.env.PRISMIC_REPOSITORY_NAME
const REF_API_URL = `https://${REPOSITORY}.cdn.prismic.io/api/v2`
export const API_TOKEN = process.env.PRISMIC_API_TOKEN

export const PrismicClient = Prismic.client(REF_API_URL, {
  accessToken: API_TOKEN,
})

class PrismicApi {
  constructor(
    protected store?: ShopStore,
    protected cache?: Cache,
    protected cookies?: CookiesFacade
  ) {}

  protected static async getApi() {
    return Prismic.getApi(
      `https://${config.prismic.name}.cdn.prismic.io/api/v2`
    )
  }

  protected async getByUID(contentType: string, uid?: string) {
    if (!uid) return

    if (this.cache?.has(getCacheKey(contentType, uid))) {
      return this.cache?.get(getCacheKey(contentType, uid))
    }

    const api = await PrismicApi.getApi()
    let content
    try {
      content = await api.getByUID(contentType, uid)
    } catch (e) {
      // Prismic throws 400 error if the type does not have any content yet
      if (e.status !== 400) {
        console.error(e)
        return undefined
      }
    }

    if (this.cache) {
      this.cache.put(getCacheKey(contentType, uid), content)
    }

    return content
  }

  public async getProduct(
    productId?: string
  ): Promise<IProductContent | undefined> {
    return (await this.getByUID('product', productId)) as
      | IProductContent
      | undefined
  }

  public async getPreviewUrl(
    previewId: string,
    document?: string
  ): Promise<string> {
    const api = await PrismicApi.getApi()

    const previewResolver = api.getPreviewResolver(previewId, document)
    const url = await previewResolver.resolve(linkResolver, '/')
    if (this.cookies) {
      this.cookies.set(Prismic.previewCookie, previewId as string, {
        maxAge: 30 * 60 * 1000,
        path: '/',
        httpOnly: false,
      })
    }
    return url
  }

  public async getCategory(
    categoryId: string
  ): Promise<ICategoryContent | undefined> {
    const cacheKey = getCacheKey('category', categoryId)
    if (this.cache?.has(cacheKey)) {
      return this.cache?.get(cacheKey)
    }

    const api = await PrismicApi.getApi()

    try {
      const doc = await api.queryFirst(
        [
          Prismic.Predicates.at('document.type', 'category'),
          Prismic.Predicates.in('my.category.uid', [categoryId.toLowerCase()]),
        ],
        {
          // ref to fetch preview version if cookie is set
          ref: this.cookies?.get(Prismic.previewCookie) || '',
        }
      )
      if (this.cache) {
        this.cache.put(cacheKey, doc)
      }

      return doc
    } catch (e) {
      console.error(e)
    }
  }

  public async getPageLayoutList(): Promise<IPageSitemapConfig[]> {
    const api = await PrismicApi.getApi()

    const recursivelyFetchPages = async (
      api: ResolvedApi,
      fromPage: number,
      documents: Document[]
    ): Promise<Document[]> => {
      const response = await api.query(
        Prismic.Predicates.at('document.type', 'page_layout'),
        {
          page: fromPage,
          pageSize: 100,
          fetch: ['page_layout.page_url', 'page_layout.indexable'],
          // ref to fetch preview version if cookie is set
          ref: this.cookies?.get(Prismic.previewCookie) || '',
        }
      )
      if (response.next_page !== null) {
        return recursivelyFetchPages(
          api,
          fromPage + 1,
          documents.concat(response.results)
        )
      }
      return documents.concat(response.results)
    }

    const pageList = await recursivelyFetchPages(api, 1, [])
    const pageSlugs = pageList.reduce(
      (pageListUids: IPageSitemapConfig[], cur) =>
        cur.data?.page_url
          ? pageListUids.concat([
              { slug: cur.data.page_url, indexable: cur.data.indexable },
            ])
          : pageListUids,
      []
    )
    return uniqBy(pageSlugs, 'slug')
  }

  public async getPageLayoutById(id: string): Promise<IPageLayout | undefined> {
    const api = await PrismicApi.getApi()
    const doc = await api.queryFirst(
      [
        Prismic.Predicates.at('document.type', 'page_layout'),
        Prismic.Predicates.at('document.id', id),
      ],
      {
        // ref to fetch preview version if cookie is set
        ref: this.cookies?.get(Prismic.previewCookie) || '',
        // Fetch URLs for page links within slices
        fetchLinks: ['page_link.url', 'page_layout.page_url'],
      }
    )
    return doc?.data
  }

  public async getPageLayout(
    pageSlug: string
  ): Promise<IPageLayout | undefined> {
    const cacheKey = getCacheKey('page_layout', pageSlug)
    if (this.cache?.has(cacheKey)) {
      return this.cache?.get(cacheKey)
    }

    const api = await PrismicApi.getApi()
    const doc = await api.queryFirst(
      [
        Prismic.Predicates.at('document.type', 'page_layout'),
        Prismic.Predicates.at('my.page_layout.page_url', pageSlug),
      ],
      {
        // ref to fetch preview version if cookie is set
        ref: this.cookies?.get(Prismic.previewCookie) || '',
        // Fetch URLs for page links within slices
        fetchLinks: ['page_link.url', 'page_layout.page_url'],
      }
    )

    if (this.cache) {
      this.cache.put(cacheKey, doc?.data)
    }
    return doc?.data
  }

  public async getBrand(
    brandSlug?: string
  ): Promise<IBrandContent | undefined> {
    return (await this.getByUID('brand', brandSlug)) as
      | IBrandContent
      | undefined
  }

  public async getAward(awardId: string): Promise<IBrandContent | undefined> {
    return (await this.getByUID('award', awardId.toLowerCase())) as
      | IBrandContent
      | undefined
  }

  public async getInfoBanner(): Promise<IInfoBannerContent | undefined> {
    return (await this.getByUID('infobanner', 'infobanner')) as
      | IInfoBannerContent
      | undefined
  }
}

export default PrismicApi
