import { AnyAction, applyMiddleware, createStore, Middleware } from 'redux'
import { composeWithDevTools } from 'redux-devtools-extension'
import { createLogger } from 'redux-logger'
import thunkMiddleware, { ThunkDispatch } from 'redux-thunk'
import { IAppState, IStoreDependencies } from '@core/store/types'
import rootReducer from './rootReducer'

// Providing this type to applyMiddleWare allows us to dispatch
// thunk actions from the store
type DispatchFunctionType = ThunkDispatch<
  IAppState,
  IStoreDependencies,
  AnyAction
>

const getMiddleWares = (dependencies: IStoreDependencies) => {
  const middleWares: Middleware[] = []

  // Thunk middleware
  middleWares.push(thunkMiddleware.withExtraArgument(dependencies))

  // Logger middleware
  if (
    dependencies.environment.isDevelopment &&
    dependencies.environment.getBoolean('REDUX_LOGGING_ENABLED')
  ) {
    middleWares.push(
      createLogger({
        level: 'info',
        collapsed: true,
      })
    )
  }

  return middleWares
}

export default function configureStore(
  initialState: IAppState | undefined,
  dependencies: IStoreDependencies
) {
  const middleWares: Middleware[] = getMiddleWares(dependencies)

  const middleware = applyMiddleware<DispatchFunctionType, IAppState>(
    ...middleWares
  )
  const enhancer = dependencies.environment.isDevelopment
    ? composeWithDevTools(middleware)
    : middleware

  return createStore(rootReducer, initialState, enhancer)
}

export type ShopStore = ReturnType<typeof configureStore>
