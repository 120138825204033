import * as React from 'react'
import { DeleteOutlined } from '@ant-design/icons'
import { ICartItem } from '@core/api/Cart/types'
import {
  LYSTypography,
  LYSList,
  LYSAlert,
  LYSButton,
} from '@core/components/Primitives'
import ProductDetailLink from '@core/components/ProductCollection/ProductDetailLink'
import useCart from '@core/hooks/cart/useCart'
import useCartEvents from '@core/hooks/cart/useCartEvents'
import { useTranslation } from '@core/i18n/i18n'
import UnavailableCartItem from '../CartItemList/UnavailableCartItem'
import style from './index.module.less'

interface Props {
  hideCartItemEventHint?: boolean
}

const CartItemEvents: React.FC<Props> = ({ hideCartItemEventHint }) => {
  const { t } = useTranslation()
  const [loading, setLoading] = React.useState<boolean>(false)

  const { removeMultipleFromCart, isCartUpdatePending } = useCart()

  const {
    outOfStockItems,
    priceChangedItems,
    unavailableItems,
    hasCartItemEvents,
  } = useCartEvents()

  const itemLinks = (items?: ICartItem[]) =>
    items?.map((item, index) => (
      <ProductDetailLink
        slug={item.product.variant.slug}
        id={item.product.variant.id}
        key={item.product.variant.id}
      >
        {item.product.name}
        {index !== items.length - 1 && ','}
      </ProductDetailLink>
    ))

  const renderMessagePriceChange = () => (
    <div>
      <LYSTypography.Text>{t('cart.events.priceChanged')}</LYSTypography.Text>
      <br />
      {itemLinks(priceChangedItems)}
    </div>
  )

  const renderMessageOutOfStock = () => (
    <div>
      <LYSTypography.Text>{t('cart.events.outOfStock')}</LYSTypography.Text>
      <br />
      {itemLinks(outOfStockItems)}
    </div>
  )

  const removeUnavailableItems = () => {
    setLoading(true)
    return unavailableItems && removeMultipleFromCart(unavailableItems)
  }

  const renderMessageUnavailableItems = () => (
    <div>
      <LYSTypography.Text>
        {t('cart.events.unavailableItems')}
      </LYSTypography.Text>
      <LYSList bordered={true} className={style.unavailableItemList}>
        {unavailableItems!.map((item) => {
          return (
            <UnavailableCartItem
              cartItem={item}
              key={item.product.variant.id}
              hideCartItemEventHint={hideCartItemEventHint}
            />
          )
        })}
      </LYSList>
      <div className={style.button}>
        <LYSButton
          icon={<DeleteOutlined rev={undefined} />}
          size="small"
          type="text"
          onClick={removeUnavailableItems}
          loading={loading}
          disabled={isCartUpdatePending}
        >
          {t('cart.events.unavailableItemsAction')}
        </LYSButton>
      </div>
    </div>
  )

  return hasCartItemEvents ? (
    <>
      {priceChangedItems && priceChangedItems?.length > 0 && (
        <LYSAlert
          message={renderMessagePriceChange()}
          type="warning"
          showIcon={true}
          className={style.hintWrap}
        />
      )}

      {outOfStockItems && outOfStockItems?.length > 0 && (
        <LYSAlert
          message={renderMessageOutOfStock()}
          type="warning"
          showIcon={true}
          className={style.hintWrap}
        />
      )}

      {unavailableItems && unavailableItems?.length > 0 && (
        <LYSAlert
          message={renderMessageUnavailableItems()}
          type="error"
          showIcon={true}
          className={style.hintWrap}
        />
      )}
    </>
  ) : null
}

export default CartItemEvents
