import * as React from 'react'
import FooterLinkCollection from '@core/components/Footer/FooterItemCollection'
import FooterLink from '@core/components/Footer/FooterLink'
import {
  LYSCol,
  LYSDivider,
  LYSRow,
  LYSTypography,
} from '@core/components/Primitives'
import LYSStaticImage from '@core/components/Primitives/LYSStaticImage'
import useCategoryNavigation from '@core/hooks/categories/useCategoryNavigation'
import useChannel from '@core/hooks/channel/useChannel'
import { useTranslation } from '@core/i18n/i18n'
import config from '../../config/config'
import routes from '../../config/routes'
import SmallBenefits from '../Benefits/SmallBenefits'
import style from './index.module.less'

const Footer: React.FC = () => {
  const { t } = useTranslation()
  const { root } = useCategoryNavigation()
  const { isOpenChannel, isClosedChannel } = useChannel()

  const mainCategories = root?.children
  return (
    <div className={style.footer} data-testid={'footer'}>
      <LYSRow>
        <SmallBenefits hideTitle={true} className={style.benefits} />
      </LYSRow>
      <LYSRow className={style.middleFooterRow}>
        <LYSCol xs={24} sm={12} lg={5}>
          <LYSTypography.Paragraph visualAppearance={'h4'} inheritColor>
            {t('footer.customerServiceText')}
          </LYSTypography.Paragraph>
          <FooterLinkCollection flexDirection={'column'}>
            <FooterLink
              href={`/search-document`}
              as={`/search-document`}
              textLabel={'Dokumentensuche'}
            />

            <FooterLink
              href={`/page/[slug]`}
              as={`/page/allgemeine-fragen-faq`}
              textLabel={'Allgemeine Fragen'}
            />

            <FooterLink
              href={`/page/[slug]`}
              as={`/page/lieferung`}
              textLabel={'Lieferung'}
            />
            <FooterLink
              href={`/page/[slug]`}
              as={`/page/kontakt`}
              textLabel={'Kontakt'}
            />
          </FooterLinkCollection>

          <LYSTypography.Paragraph visualAppearance={'h4'} inheritColor>
            {t('footer.myAccountText')}
          </LYSTypography.Paragraph>
          <FooterLinkCollection flexDirection={'column'}>
            <FooterLink
              href={'/me'}
              as={'/me'}
              textLabel={t('footer.myAccountText')}
            />
          </FooterLinkCollection>
        </LYSCol>
        <LYSCol xs={24} sm={12} lg={7}>
          <LYSTypography.Paragraph visualAppearance={'h4'} inheritColor>
            {t('footer.categoriesText')}
          </LYSTypography.Paragraph>
          {mainCategories && (
            <FooterLinkCollection flexDirection={'column'}>
              <>
                {mainCategories.map((category) => {
                  const taxonomyRoute = routes.taxonomy(category)
                  return (
                    <FooterLink
                      href={taxonomyRoute.href}
                      as={taxonomyRoute.as}
                      textLabel={category.name}
                      key={category.id}
                    />
                  )
                })}
              </>
            </FooterLinkCollection>
          )}
          <LYSTypography.Paragraph visualAppearance={'h4'} inheritColor>
            {t('footer.assortments')}
          </LYSTypography.Paragraph>
          <FooterLinkCollection flexDirection={'column'}>
            <FooterLink
              href={`/page/[slug]`}
              as={`/page/chemie-geraete`}
              textLabel={'Chemie & Geräte'}
            />

            <FooterLink
              href="/page/[slug]"
              as="/page/einweg-verpackungen"
              textLabel={'Einweg und Verpackungen'}
            />
            <FooterLink
              href="/page/[slug]"
              as="/page/papier-spender"
              textLabel={'Papier und Spender'}
            />
            <FooterLink
              href="/page/[slug]"
              as="/page/medical"
              textLabel={'Medical'}
            />
          </FooterLinkCollection>
        </LYSCol>
        <LYSCol xs={24} lg={12} className={style.footerNav}>
          <LYSTypography.Paragraph visualAppearance={'h4'} inheritColor>
            {t('footer.paymentMethodsText')}
          </LYSTypography.Paragraph>

          <FooterLinkCollection flexDirection={'row'}>
            <>
              {isOpenChannel && (
                <LYSStaticImage
                  src={config.assets.paymentMethods.payone_paypal}
                  width={56}
                  height={40}
                  alt={t`checkout.payment.methods.payone_paypal`}
                />
              )}

              <LYSStaticImage
                src={config.assets.paymentMethods.invoice}
                width={56}
                height={40}
                alt={t`checkout.payment.methods.invoice`}
              />
              {isOpenChannel && (
                <>
                  <LYSStaticImage
                    src={config.assets.paymentMethods.credit_card}
                    width={56}
                    height={40}
                    alt={t`checkout.payment.methods.credit_card`}
                  />
                  <LYSStaticImage
                    src={config.assets.paymentMethods.prepayment_invoice}
                    width={56}
                    height={40}
                    alt={t`checkout.payment.methods.prepayment_invoice`}
                  />
                </>
              )}
            </>
          </FooterLinkCollection>
          <LYSTypography.Text>{t('footer.vatNotice')}</LYSTypography.Text>

          <LYSDivider className={style.divider} />

          <LYSTypography.Paragraph visualAppearance={'h4'} inheritColor>
            {t('footer.deliveryPatnerText')}
          </LYSTypography.Paragraph>
          <FooterLinkCollection
            flexDirection={'row'}
            className={style.footerLogoCollection}
          >
            <>
              {isOpenChannel && (
                <>
                  <LYSStaticImage
                    src={config.assets.shippingMethods.dpd}
                    width={57}
                    height={40}
                    alt={'DPD'}
                  />
                  <LYSStaticImage
                    src={config.assets.shippingMethods.fleet}
                    width={57}
                    height={40}
                    alt={'Fuhrpark'}
                  />
                </>
              )}
              {isClosedChannel && (
                <>
                  <div className={style.deliveryWithLabel}>
                    <LYSStaticImage
                      src={config.assets.shippingMethods.fleet}
                      width={57}
                      height={40}
                      alt={'Fuhrpark'}
                    />
                    <LYSTypography.Text>
                      {t('footer.delivery.fleet')}
                    </LYSTypography.Text>
                  </div>
                  <div className={style.deliveryWithLabel}>
                    <LYSStaticImage
                      src={config.assets.shippingMethods.package}
                      width={57}
                      height={40}
                      alt={'Paketdienst'}
                    />
                    <LYSTypography.Text>
                      {t('footer.delivery.package')}
                    </LYSTypography.Text>
                  </div>
                </>
              )}
            </>
          </FooterLinkCollection>
        </LYSCol>
      </LYSRow>
      <LYSRow
        justify={'center'}
        align={'middle'}
        className={style.footerLogoCollection}
      >
        <a
          href={'https://www.inpacs.com/'}
          target="blank"
          rel="noopener noreferrer"
        >
          <LYSStaticImage
            width={113}
            height={40}
            src={config.assets.inpacsLogo}
            alt={'inpacs'}
          />
        </a>
        <a href="https://www.igefa.de" target="blank" rel="noopener noreferrer">
          <LYSStaticImage
            src={config.assets.storeLogo}
            alt="Igefa Homepage"
            width={97}
            height={40}
          />
        </a>
      </LYSRow>
    </div>
  )
}

export default Footer
