import { IConsentInfo } from '@core/utils/consent/types'
import { createAction } from '@reduxjs/toolkit'

export const consentChangedEvent = createAction(
  'consent/changed',
  (consent: IConsentInfo) => {
    return {
      payload: {
        consent,
      },
    }
  }
)

export type IConsentChangedEvent = ReturnType<typeof consentChangedEvent>

export default {
  consentChangedEvent,
}
