import { ITeaserElement } from '@core/prismic/types'
import { createAction } from '@reduxjs/toolkit'

export const teaserViewEvent = createAction(
  'teaser/view',
  (teaser: ITeaserElement) => {
    return {
      payload: {
        teaser,
      },
    }
  }
)

export const teaserClickEvent = createAction(
  'teaser/click',
  (teaser: ITeaserElement) => {
    return {
      payload: {
        teaser,
      },
    }
  }
)

export type ITeaserViewEvent = ReturnType<typeof teaserViewEvent>
export type ITeaserClickEvent = ReturnType<typeof teaserClickEvent>

export default {
  teaserViewEvent,
  teaserClickEvent,
}
