import * as React from 'react'
import { useTranslation } from '@core/i18n/i18n'
import SmallBenefits, {
  SmallBenefitsProps,
} from '@original/components/Benefits/SmallBenefits'
import config from '../../../config/config'
import style from './index.module.less'
import useChannel from '@core/hooks/channel/useChannel'

const IgefaSmallBenefits: React.FC<SmallBenefitsProps> = (props) => {
  const { isClosedChannel } = useChannel()

  const { t } = useTranslation()
  return (
    <>
      <SmallBenefits
        {...props}
        slots={{
          extraTop: () => {
            return (
              isClosedChannel && (
                <img
                  className={style.trustedshops}
                  src={config.assets.certificates.trustedshops}
                  loading="lazy"
                  alt={'trustedshops'}
                />
              )
            )
          },
          firstBenefitText: () => t('benefits.orderingText'),
          secondBenefitText: () => t('benefits.deliveryText'),
          thirdBenefitText: () => t('benefits.personalAdviceText'),
        }}
      />
    </>
  )
}

export default IgefaSmallBenefits
