import { IPriceRequestsQuery } from '@core/api/PriceRequests/types'
import { IPriceRequest } from '@core/api/PriceRequests/types'
import {
  showNotification,
  showErrorNotification,
} from '@core/components/Notification'
import thunk from '@core/store/thunk'
import { logging } from '@core/utils/logging'
import { i18n } from '../../i18n/i18n'
import { ModalType, showModal } from '../modals/actions'
import { getAnsweredPriceRequests } from './selectors'

export enum ActionTypes {
  SET_PRICE_REQUESTS = 'PRICE_REQUESTS/SET_PRICE_REQUESTS',
}

interface ISetPriceRequests {
  type: ActionTypes.SET_PRICE_REQUESTS
  payload: {
    priceRequests: IPriceRequest[]
  }
}

const setPriceRequests = (
  priceRequests: IPriceRequest[]
): ISetPriceRequests => ({
  type: ActionTypes.SET_PRICE_REQUESTS,
  payload: {
    priceRequests,
  },
})

const checkForUpdatedPriceRequests = () =>
  thunk(async (dispatch, getState) => {
    try {
      const answeredPriceRequests = getAnsweredPriceRequests(getState())

      if (answeredPriceRequests.length) {
        dispatch(showModal(ModalType.PRICE_REQUEST))
      }
    } catch (e) {
      logging.error(e)
    }
  })

/**
 * Limit default set to 1000 due to following issue:
 * https://gitlab.com/lyska/lyska.cloud/services/lyska/shop/-/merge_requests/774#note_331859046
 * Will rethink these kind of situations
 */
export const fetchPriceRequests = (
  params: IPriceRequestsQuery = { limit: 1000 },
  disableUpdateCheck = false
) =>
  thunk(async (dispatch, _getState, dependencies) => {
    try {
      const result = await dependencies.api.priceRequests.fetchPriceRequests(
        params
      )

      dispatch(setPriceRequests(result.priceRequests))

      if (!disableUpdateCheck) {
        dispatch(checkForUpdatedPriceRequests())
      }
    } catch (e) {
      logging.error(e)
    }
  })

export const createPriceRequest = (variantId: string) =>
  thunk(async (dispatch, _getState, dependencies) => {
    try {
      await dependencies.api.priceRequests.createPriceRequest(variantId)
      dispatch(fetchPriceRequests())

      showNotification(i18n.t('priceRequest.request.success'), 'success')
    } catch (e) {
      logging.error(e)
      showErrorNotification(e, 'priceRequest.create.error')
    }
  })

export const deletePriceRequest = (variantId: string) =>
  thunk(async (dispatch, _getState, dependencies) => {
    try {
      await dependencies.api.priceRequests.deletePriceRequest(variantId)

      dispatch(fetchPriceRequests())

      showNotification(i18n.t('priceRequest.delete.success'), 'success')
    } catch (e) {
      logging.error(e)
    }
  })

export const acknowledgeAllPriceRequests = () =>
  thunk(async (dispatch, getState, dependencies) => {
    try {
      const anweredPriceRequests = getAnsweredPriceRequests(getState())

      for (const {
        productInformation: {
          mainVariant: { id },
        },
      } of anweredPriceRequests) {
        try {
          await dependencies.api.priceRequests.acknowledgePriceRequest(id)
        } catch (e) {
          logging.error(e)
        }
      }

      dispatch(fetchPriceRequests(undefined, true))
    } catch (e) {
      logging.error(e)
    }
  })

export type Action = ISetPriceRequests
