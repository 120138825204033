import * as React from 'react'
import cn from 'classnames'
import Link from 'next/link'
import LYSStaticImage from '@core/components/Primitives/LYSStaticImage'
import config from '@core/config/config'
import style from './index.module.less'
import useChannel from '@core/hooks/channel/useChannel'

export interface Props {
  className?: string
  customWidth?: number
  customHeight?: number
}

const StoreLogo: React.FC<Props> = ({
  className,
  customHeight,
  customWidth,
}) => {
  const { isClosedChannel } = useChannel()
  return (
    <Link href={'/'}>
      <a className={cn(style.storeLogo, className)}>
        <LYSStaticImage
          src={
            isClosedChannel
              ? config.assets.storeLogoClosedShop
              : config.assets.storeLogo
          }
          alt={config.storeName}
          width={customWidth ? customWidth : 281}
          height={customHeight ? customHeight : 89}
        />
      </a>
    </Link>
  )
}

export default StoreLogo
