export type BranchOfficeNumberTypes = keyof typeof branchOfficeUrls

export const branchOfficeUrls = {
  // Kruse
  '0201': 'https://igefakruse.cdn.prismic.io/api/v2',
  '0204': 'https://igefakruse.cdn.prismic.io/api/v2',
  '0210': 'https://igefakruse.cdn.prismic.io/api/v2',
  '0701': 'https://igefakruse.cdn.prismic.io/api/v2',
  '1108': 'https://igefakruse.cdn.prismic.io/api/v2',
  '1201': 'https://igefakruse.cdn.prismic.io/api/v2',
  // Aarndt
  '0601': 'https://igefaarndt.cdn.prismic.io/api/v2',
  '0602': 'https://igefaarndt.cdn.prismic.io/api/v2',
  '0603': 'https://igefaarndt.cdn.prismic.io/api/v2',
  '0605': 'https://igefaarndt.cdn.prismic.io/api/v2',
  '0613': 'https://igefaarndt.cdn.prismic.io/api/v2',
  '0612': 'https://igefaarndt.cdn.prismic.io/api/v2',
  '1601': 'https://igefaarndt.cdn.prismic.io/api/v2',
  // W&U
  '0101': 'https://igefawittrockuhlenwinkel.cdn.prismic.io/api/v2',
  '0102': 'https://igefawittrockuhlenwinkel.cdn.prismic.io/api/v2',
  '0103': 'https://igefawittrockuhlenwinkel.cdn.prismic.io/api/v2',
  '0105': 'https://igefawittrockuhlenwinkel.cdn.prismic.io/api/v2',
  '0106': 'https://igefawittrockuhlenwinkel.cdn.prismic.io/api/v2',
  '1109': 'https://igefawittrockuhlenwinkel.cdn.prismic.io/api/v2',
  '0115': 'https://igefawittrockuhlenwinkel.cdn.prismic.io/api/v2',
  '1112': 'https://igefawittrockuhlenwinkel.cdn.prismic.io/api/v2',
  // Ei-Ka
  '0301': 'https://igefaeichlerkammerer.cdn.prismic.io/api/v2',
  '0401': 'https://igefaeichlerkammerer.cdn.prismic.io/api/v2',
  '1301': 'https://igefaeichlerkammerer.cdn.prismic.io/api/v2',
}
