import { useCallback, useMemo } from 'react'
import uniq from 'lodash/uniq'
import { useDispatch, useSelector } from 'react-redux'
import {
  ICartItem,
  IShippingMethod,
  SHIPPING_PICKUP_TYPE,
} from '@core/api/Cart/types'
import {
  ICreateProductList,
  IProductListItem,
  IProductListItemVariantInformation,
} from '@core/api/ProductLists/types'
import {
  IVariant,
  IVariantSearch,
  IProductSearch,
} from '@core/api/Products/types'
import {
  addProductListItemsToCart,
  addToCart as dispatchAddToCart,
  removeFromCart as removeFromCartAction,
  updateQuantity as updateQuantityAction,
  checkout as checkoutAction,
  addBundleItemsToCart,
  applyVoucherCode as applyVoucherCodeAction,
  replaceInCart,
  toggleCartDrawer as toggleCartDrawerAction,
  closeCartDrawer as closeCartDrawerAction,
  createCartFromOrder as createCartFromOrderAction,
  deleteAllItemsFromCartAction,
  removeMultipleFromCart as removeMultipleFromCartAction,
  saveCartAsProductList as saveCartAsProductListAction,
} from '@core/store/cart/actions'
import {
  getUserCart,
  isCheckoutReady,
  getCartCustomerInformation,
  getCartDrawerOpen,
  getCartUpdatePending,
} from '@core/store/cart/selectors'
import { ICheckoutConsent } from '@core/store/cart/types'
import { DispatchWithThunk } from '@core/store/types'
import useRequestReducer from '@core/utils/useRequestReducer'
import { IPriceRequestVariant } from '@core/api/PriceRequests/types'

const useCart = () => {
  const dispatch = useDispatch<DispatchWithThunk>()
  const cart = useSelector(getUserCart)
  const cartCustomerInformation = useSelector(getCartCustomerInformation)
  const cartDrawerOpen = useSelector(getCartDrawerOpen)
  const isCartUpdatePending = useSelector(getCartUpdatePending)
  const checkoutReady = useSelector(isCheckoutReady)
  const [requestState, requestActions] = useRequestReducer()

  const addToCart = useCallback(
    (
      variant:
        | IVariantSearch
        | IProductListItemVariantInformation
        | IVariant
        | IPriceRequestVariant,
      productName: string,
      itemQuantity: number
    ) => {
      return dispatch(dispatchAddToCart(variant, productName, itemQuantity))
    },
    [dispatch]
  )

  const updateQuantity = useCallback(
    (item: ICartItem, quantity: number) => {
      return dispatch(updateQuantityAction(item, quantity))
    },
    [dispatch]
  )

  const removeFromCart = useCallback(
    (item: ICartItem) => {
      return dispatch(removeFromCartAction(item))
    },
    [dispatch]
  )

  const removeMultipleFromCart = useCallback(
    (item: ICartItem[]) => {
      return dispatch(removeMultipleFromCartAction(item))
    },
    [dispatch]
  )

  const deleteAllItemsFromCart = useCallback(() => {
    return dispatch(deleteAllItemsFromCartAction())
  }, [dispatch])

  const replaceItemInCart = useCallback(
    (previousItem: ICartItem, newItem: IProductSearch) => {
      return dispatch(replaceInCart({ previousItem, newItem }))
    },
    [dispatch]
  )

  const addProductListItems = useCallback(
    (items: IProductListItem[]) => {
      return dispatch(addProductListItemsToCart(items))
    },
    [dispatch]
  )

  const addBundleItems = useCallback(
    (items: IProductSearch[]) => {
      return dispatch(addBundleItemsToCart(items))
    },
    [dispatch]
  )

  const toggleCartDrawer = useCallback(() => {
    return dispatch(toggleCartDrawerAction())
  }, [dispatch])

  const closeCartDrawer = useCallback(() => {
    return dispatch(closeCartDrawerAction())
  }, [dispatch])

  const saveCartAsProductList = useCallback(
    (productList: ICreateProductList) => {
      return dispatch(saveCartAsProductListAction(productList))
    },
    [dispatch]
  )

  const checkout = useCallback(
    (consent: ICheckoutConsent) => {
      return dispatch(checkoutAction(consent))
    },
    [dispatch]
  )

  const createCartFromOrder = useCallback(
    (orderId: string) => {
      return dispatch(createCartFromOrderAction(orderId))
    },
    [dispatch]
  )

  const isPickupShippingMethod = useCallback(
    (methodToCheck?: IShippingMethod) => {
      return methodToCheck?.type === SHIPPING_PICKUP_TYPE
    },
    []
  )

  const availableItems = useMemo(
    () =>
      cart?.items.filter(
        (item) =>
          !item.product.variant.packagingUnits[0].stockInformation
            ?.isDiscontinued
      ),
    [cart]
  )

  const hazardousItems = useMemo(
    () =>
      cart
        ? uniq(cart.items.filter((item) => item.product.ghsClasses.length > 0))
        : [],
    [cart]
  )

  /**
   * Had a call with Moritz and Manu. For a reproducable situation the item i
   * updated as discontinued was inside `items`. They said this is in a normal situation
   * inside `unavailabeItems`.
   * So i decided to check both for discontinued items
   */
  const discontinuedItems = useMemo(
    () =>
      cart
        ? uniq(
            [...(cart.items || []), ...(cart.unavailableItems || [])].filter(
              (item) => item.product.isDiscontinued
            )
          )
        : [],
    [cart]
  )

  const eligiblePriceRequestItems = useMemo(
    () => cart?.unavailableItems?.filter((item) => item.price === null) ?? [],
    [cart]
  )

  const applyVoucherCode = useCallback(
    async (code: string) => {
      requestActions.dispatchInit()
      try {
        await dispatch(applyVoucherCodeAction(code))

        requestActions.dispatchSuccess()
      } catch (e) {
        requestActions.dispatchFailure(e)
      }
    },
    // eslint-disable-next-line react-hooks/exhaustive-deps
    [dispatch]
  )

  return {
    cart,
    cartDrawerOpen,
    addToCart,
    updateQuantity,
    removeFromCart,
    removeMultipleFromCart,
    deleteAllItemsFromCart,
    replaceItemInCart,
    addProductListItems,
    addBundleItems,
    toggleCartDrawer,
    closeCartDrawer,
    availableItems,
    discontinuedItems,
    hazardousItems,
    checkoutReady,
    checkout,
    applyVoucherCode,
    cartCustomerInformation,
    createCartFromOrder,
    isPickupShippingMethod,
    isCartUpdatePending,
    requestState,
    saveCartAsProductList,
    eligiblePriceRequestItems,
  }
}

export default useCart
