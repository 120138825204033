import { AddToProductListResult } from '@core/api/ProductLists'
import {
  IAddProductListItems,
  ICreateProductList,
  IProductList,
  IProductListCollection,
  IUpdateProductList,
} from '@core/api/ProductLists/types'
import {
  showNotification,
  showErrorNotification,
} from '@core/components/Notification'
import routes from '@core/config/routes'
import { ProductListSortOption } from '@core/store/productList/types'
import thunk from '@core/store/thunk'
import { logging } from '@core/utils/logging'
import { sanitizeModel } from '@core/utils/models/sanitizeModel'
import { i18n } from '../../i18n/i18n'

export enum ActionTypes {
  SET_PRODUCT_LISTS = 'PRODUCT_LIST/SET_PRODUCT_LISTS',
  SET_PRODUCT_LIST_POSITION = 'PRODUCT_LIST/SET_PRODUCT_LIST_POSITION',
  SET_PRODUCT_LIST_SORT_ORDER = 'PRODUCT_LIST/SET_PRODUCT_LIST_SORT_ORDER',
}

export interface ISetProductLists {
  type: ActionTypes.SET_PRODUCT_LISTS
  payload: {
    productLists: IProductList[]
  }
}

export interface ISetProductListPosition {
  type: ActionTypes.SET_PRODUCT_LIST_POSITION
  payload: {
    productList: IProductList
    position: number
  }
}

export interface ISetProductListSortOrder {
  type: ActionTypes.SET_PRODUCT_LIST_SORT_ORDER
  payload: {
    sortOrder: ProductListSortOption
  }
}

export const fetchProductLists = () =>
  thunk(async (dispatch, _getState, dependencies) => {
    try {
      await dependencies.api.productLists
        .fetchProductLists()
        .then((result: IProductListCollection) => {
          dispatch(setProductLists(result.productLists))
        })
    } catch (error) {
      logging.error(error)
    }
  })

export const setProductLists = (
  productLists: IProductList[]
): ISetProductLists => ({
  type: ActionTypes.SET_PRODUCT_LISTS,
  payload: {
    productLists,
  },
})

export const addToProductList = (
  productListId: string,
  productListItems: IAddProductListItems
) =>
  thunk(async (dispatch, _getState, dependencies) => {
    const result = await dependencies.api.productLists.addToProductList(
      productListId,
      productListItems
    )

    switch (result.type) {
      case AddToProductListResult.SUCCESS:
        showNotification(
          i18n.t('addToProductlist.addSuccessNotification'),
          'success'
        )

        dispatch(fetchProductLists())
        break
      case AddToProductListResult.ALREADY_EXISTS_ERROR:
        showErrorNotification(
          result.error,
          i18n.t('addToProductlist.addExistingErrorNotification')
        )

        break
      case AddToProductListResult.UNEXPECTED_ERROR:
        showErrorNotification(
          result.error,
          i18n.t('addToProductlist.addErrorNotification')
        )

        break
    }
  })

export const createProductList = (
  productList: ICreateProductList,
  itemsToAdd?: IAddProductListItems,
  navigateToList?: boolean
) =>
  thunk(async (dispatch, _getState, dependencies) => {
    try {
      productList = sanitizeModel(productList)
      const createdProductList =
        await dependencies.api.productLists.createProductList(productList)

      showNotification(
        i18n.t('createProductList.createSuccessNotification'),
        'success'
      )

      dispatch(fetchProductLists())

      if (itemsToAdd) {
        dispatch(addToProductList(createdProductList.id, itemsToAdd))
      }

      if (navigateToList) {
        const route = routes.productList(createdProductList.id)
        dependencies.router.pushAndScrollTop(route.href, route.as, {
          shallow: true,
        })
      }
    } catch (error) {
      showErrorNotification(error, 'createProductList.createErrorNotification')
    }
  })

export const deleteProductList = (productListId: string) =>
  thunk(async (dispatch, _getState, dependencies) => {
    try {
      await dependencies.api.productLists.deleteProductList(productListId)

      showNotification(
        i18n.t('deleteProductList.deleteSuccessNotification'),
        'success'
      )

      dispatch(fetchProductLists())
      const route = routes.productList()
      dependencies.router.pushAndScrollTop(route.href, route.as, {
        shallow: true,
      })
    } catch (error) {
      showErrorNotification(error, 'deleteProductList.deleteErrorNotification')
    }
  })

export const updateProductList = (
  productListId: string,
  updatedProductList: IUpdateProductList
) =>
  thunk(async (dispatch, _getState, dependencies) => {
    try {
      await dependencies.api.productLists.updateProductList(
        productListId,
        updatedProductList
      )

      showNotification(
        i18n.t('updateProductList.updateSuccessNotification'),
        'success'
      )

      dispatch(fetchProductLists())
    } catch (error) {
      showErrorNotification(error, 'updateProductList.updateErrorNotification')
    }
  })

export const deleteItemsFromProductList = (
  productListId: string,
  itemIds: string[]
) =>
  thunk(async (dispatch, _getState, dependencies) => {
    try {
      await dependencies.api.productLists.deleteFromProductList(
        productListId,
        itemIds
      )

      /**
       * Get new counds
       */
      dispatch(fetchProductLists())

      showNotification(
        i18n.t('deleteFromProductList.deleteSuccessNotification'),
        'success'
      )
    } catch (error) {
      showErrorNotification(
        error,
        'deleteFromProductList.deleteErrorNotification'
      )
    }
  })

export const deleteProductListItemEvents = (productListId: string) =>
  thunk(async (_dispatch, _getState, dependencies) => {
    try {
      await dependencies.api.productLists.deleteProductListItemEvents(
        productListId
      )
    } catch (error) {
      showErrorNotification(error, 'productList.deleteEvents.errorNotification')
    }
  })

export const updateProductListItemPosition = (
  productListId: string,
  variantId: string,
  position: number
) =>
  thunk(async (_dispatch, _getState, dependencies) => {
    try {
      await dependencies.api.productLists.updateProductListItemPosition(
        productListId,
        variantId,
        position
      )
    } catch (error) {
      showErrorNotification(error, 'updateProductListItem.errorNotification')
    }
  })

export const updateProductListItemQuantity = (
  productListId: string,
  variantId: string,
  quantity: number
) =>
  thunk(async (_dispatch, _getState, dependencies) => {
    try {
      await dependencies.api.productLists.updateProductListItemQuantity(
        productListId,
        variantId,
        quantity
      )

      showNotification(
        i18n.t('updateProductListItemQuantity.successNotification'),
        'success'
      )
    } catch (error) {
      logging.error(error)

      showErrorNotification(error, 'updateProductListItem.errorNotification')
    }
  })

export const setProductListPosition = (
  productListId: string,
  position: number
) =>
  thunk(async (dispatch, getState, dependencies) => {
    try {
      const productList = getState().productLists.productLists.find(
        (list) => list.id === productListId
      )

      if (!productList) return
      dispatch(_setProductListPosition(productList, position))

      const updatedProductList = {
        ...productList,
        position,
      }

      await dependencies.api.productLists.updateProductList(
        productListId,
        updatedProductList
      )
      await dispatch(fetchProductLists())
    } catch (error) {
      logging.error(error)
    }
  })

const _setProductListPosition = (
  productList: IProductList,
  position: number
): ISetProductListPosition => ({
  type: ActionTypes.SET_PRODUCT_LIST_POSITION,
  payload: {
    productList,
    position,
  },
})

export const setProductListSortOrder = (sortOrder: ProductListSortOption) => {
  return {
    type: ActionTypes.SET_PRODUCT_LIST_SORT_ORDER,
    payload: { sortOrder },
  }
}

export type Action =
  | ISetProductLists
  | ISetProductListPosition
  | ISetProductListSortOrder
