import { AxiosResponse } from 'axios'
import BaseAPI from '@core/api/Platform/BaseAPI'
import { Unsaved } from '@core/api/types'
import { AUTH_TOKEN } from '@core/utils/cookies'
import {
  IBillingInformation,
  IBillingInformationCollection,
  ICustomerSelfUpdate,
  ICustomerTeam,
  IInviteTeamMemberPayload,
  IRegisterAccount,
  IShippingAddress,
  IShippingAddressCollection,
  ShippingAddressStatus,
} from './types'

class Customer extends BaseAPI {
  public async register(register: IRegisterAccount): Promise<void> {
    await this.api.post('customer/register', register)
  }

  public async updateCustomer(customer: ICustomerSelfUpdate): Promise<void> {
    await this.api.put<ICustomerSelfUpdate>('customer', customer)
  }

  public async switchChannel(channelId: string): Promise<void> {
    const response = await this.api.put<{ token: string }>(
      `customer/switch-channel/${channelId}`,
      undefined,
      {
        genericUrl: 'customer/switch-channel/{channelId}',
      }
    )
    const {
      data: { token },
    } = response

    this.cookies.set(AUTH_TOKEN, token)
  }

  public async createShippingAddress(
    address: Unsaved<IShippingAddress>
  ): Promise<IShippingAddress> {
    const response = await this.api.post<
      Unsaved<IShippingAddress>,
      AxiosResponse<IShippingAddress>
    >('customer/shipping-addresses', address)
    return response.data
  }

  public async updateShippingAddress(
    address: IShippingAddress
  ): Promise<IShippingAddress> {
    const response = await this.api.put<IShippingAddress>(
      `customer/shipping-addresses/${address.id}`,
      address,
      {
        genericUrl: 'customer/shipping-addresses/{addressId}',
      }
    )
    const { data } = response
    return data
  }

  public async createBillingInformation(
    billingInformation: Unsaved<IBillingInformation>
  ): Promise<IBillingInformation> {
    const response = await this.api.post<
      Unsaved<IBillingInformation>,
      AxiosResponse<IBillingInformation>
    >('customer/billing-information', billingInformation)
    const { data } = response
    return data
  }

  public async updateBillingInformation(
    billingInformation: IBillingInformation
  ): Promise<IBillingInformation> {
    // Check Mapping for Information in Update Call i.e. VATId
    // TODO: Review handling of Array Field on Address and Billing
    billingInformation.clientFields = []
    const response = await this.api.put<IBillingInformation>(
      `customer/billing-information/${billingInformation.id}`,
      billingInformation,
      {
        genericUrl: 'customer/billing-information/{billingInformationId}',
      }
    )
    const { data } = response
    return data
  }

  public async deleteShippingAddress(
    addressId: string
  ): Promise<IShippingAddress> {
    const response = await this.api.delete<IShippingAddress>(
      `customer/shipping-addresses/${addressId}`,
      {
        genericUrl: 'customer/shipping-addresses/{addressId}',
      }
    )
    const { data } = response
    return data
  }

  public async setDefaultShippingAddress(addressId: string) {
    const response = await this.api.put<IShippingAddress>(
      `customer/shipping-addresses/${addressId}/default`,
      undefined,
      {
        genericUrl: 'customer/shipping-addresses/{addressId}/default',
      }
    )
    const { data } = response
    return data
  }

  public async getShippingAddresses(
    status: ShippingAddressStatus = ShippingAddressStatus.ACTIVE
  ): Promise<IShippingAddressCollection> {
    const response = await this.api.get<IShippingAddressCollection>(
      `customer/shipping-addresses?filter[status]=${status}`,
      {
        genericUrl: 'customer/shipping-addresses',
      }
    )

    const { data } = response
    return data
  }

  public async getShippingAddress(id: string): Promise<IShippingAddress> {
    const response = await this.api.get<IShippingAddress>(
      `customer/shipping-addresses/${id}`,
      {
        genericUrl: 'customer/shipping-addresses/{id}',
      }
    )
    const { data } = response
    return data
  }

  public async activateShippingAddress(id: string): Promise<void> {
    await this.api.put(`customer/shipping-addresses/${id}/activate`, {
      genericUrl: 'customer/shipping-addresses/{id}/activate',
    })
  }

  public async deactivateShippingAddress(id: string): Promise<void> {
    await this.api.put(`customer/shipping-addresses/${id}/deactivate`, {
      genericUrl: 'customer/shipping-addresses/{id}/deactivate',
    })
  }

  public async getBillingInformation(): Promise<IBillingInformationCollection> {
    const response = await this.api.get<IBillingInformationCollection>(
      'customer/billing-information'
    )

    const { data } = response
    return data
  }

  public async getBillingInformationById(
    id: string
  ): Promise<IBillingInformation> {
    const response = await this.api.get<IBillingInformation>(
      `customer/billing-information/${id}`,
      {
        genericUrl: 'customer/billing-information/{id}',
      }
    )
    const { data } = response
    return data
  }

  public async getTeam(): Promise<ICustomerTeam> {
    const response = await this.api.get<ICustomerTeam>(`customer/team`)

    const { data } = response
    return data
  }

  public async inviteTeamMember(payload: IInviteTeamMemberPayload) {
    return this.api.post(`customer/team`, {
      ...payload,
    })
  }

  public async activateTeamMember(userId: string) {
    return this.api.put(`customer/team/activate/${userId}`, undefined, {
      genericUrl: 'customer/team/activate/{userId}',
    })
  }

  public async deactivateTeamMember(userId: string) {
    return this.api.put(`customer/team/deactivate/${userId}`, undefined, {
      genericUrl: 'customer/team/deactivate/{userId}',
    })
  }
}

export default Customer
