import { ICart, ICartItem } from '@core/api/Cart/types'
import { ITaxonomy } from '@core/api/Categories/types'
import { IOrder, IOrderItem } from '@core/api/Orders/types'
import { IProduct } from '@core/api/Products/types'
import { IShopServices } from '@core/config/services'
import { IPurchaseEvent, purchaseEvent } from '@core/events/checkout'
import {
  IViewCategoryEvent,
  IViewPageEvent,
  IViewProductEvent,
  viewCategoryEvent,
  viewPageEvent,
  viewProductEvent,
} from '@core/events/view'
import { getUserCart } from '@core/store/cart/selectors'
import { getAuthenticatedUser } from '@core/store/user/selectors'
import {
  consentChangedEvent,
  IConsentChangedEvent,
} from '@core/utils/consent/events'
import { logging } from '@core/utils/logging'
import getSku from '@core/utils/models/getSku'
import {
  EmarsysCartItem,
  EmarsysCommands,
  EmarsysRecommendOptions,
} from './types'

// CUSTOM HASHING FUNCTIONALITY FOR CART ITEMS
const computeCartItemsHash = (items: ICartItem[]) => {
  return items
    ? [...items]
        .sort((left, right) =>
          left.product.variant.id < right.product.variant.id ? -1 : 1
        )
        .reduce((acc, item) => {
          return `${acc}|${item.product.variant.id}|${item.quantity}`
        }, '')
    : ''
}

// CUSTOM LOGGING
const log = (
  message: string,
  data?: {
    [key: string]: any
  }
) => {
  if (enableLogging) {
    logging.info(message, data)
  }
}

// COMMAND QUEUEING / DEBOUNCE
const COMMAND_DEBOUNCE_INTERVAL = 500
let enableLogging = false
let enableTestMode = false
let hasConsent = false
let executeIntervalId: any | undefined
let commandQueue: EmarsysCommands = []
let previousCart: ICart | undefined

const queueCommands = (commands: EmarsysCommands) => {
  commandQueue = [...commandQueue, ...commands]
  requestCommit()
}

/**
 * Request to commit all the commands in the queue, with a debounce / timeout
 * We use a debounce to collect all commands on a page, which can be sent
 * from different components / at different times.
 * For example the checkout success page sends a page view event and a purchase event when mounting,
 * which we capture together into one queue / `go` command with the debounce
 */
const requestCommit = () => {
  if (executeIntervalId) {
    clearTimeout(executeIntervalId)
  }
  executeIntervalId = setTimeout(commitCommands, COMMAND_DEBOUNCE_INTERVAL)
}

/**
 * Function used to execute all commands gathered in command queue.
 * Function adds final `go` command itself. This last command is required by ScarabQueue to flush the queue and send everything on it to Emarsys.
 * If the command queue holds multiple commands of the same, then only the first command of the type is picked,
 * which means commands are deduplicated
 * Exceptions are made for commands that are white-listed such as recommend (a page should be able to load multiple recommendations)
 */
const WHITE_LISTED_COMMANDS = ['recommend']

const commitCommands = () => {
  // We can only evaluate commands as soon as cart is loaded
  if (commandQueue.length < 1 || !previousCart) return
  // Also prevent commit until consent is given. This can prevent duplicate go command errors, in cases where commands are only
  // fired after giving consent (e.g. product recommendations), which can have a large enough delay to trigger the commit twice
  if (!hasConsent) return

  const commands = commandQueue
  const existingCommandTypes: string[] = []
  commandQueue = []

  commands.push(() => ['go'])
  commands
    // Evaluate commands
    .map((command) => command())
    // Remove null values
    .filter((evaluatedCommand) => !!evaluatedCommand)
    // Remove duplicate commands
    .filter((evaluatedCommand) => {
      if (
        !WHITE_LISTED_COMMANDS.includes(evaluatedCommand![0]) &&
        existingCommandTypes.includes(evaluatedCommand![0])
      )
        return false
      existingCommandTypes.push(evaluatedCommand![0])
      return true
    })
    // Finally execute commands
    .forEach((evaluatedCommand) => {
      log('Emarsys command', evaluatedCommand)
      if (window.ScarabQueue) {
        window.ScarabQueue.push(evaluatedCommand!)
      }
    })
}

// SCARAB QUEUE COMMANDS ENHANCERS
const addTestModeCommand = (commands: EmarsysCommands) => {
  if (enableTestMode) {
    commands.unshift(() => ['testMode'])
  }
}

const addEmailCommand = (
  services: IShopServices,
  commands: EmarsysCommands
) => {
  commands.push(() => {
    const user = getAuthenticatedUser(services.store.getState())

    return user && ['setEmail', user.email]
  })
}

const addCartCommand = (services: IShopServices, commands: EmarsysCommands) => {
  commands.push(() => {
    const cart = getUserCart(services.store.getState())
    const emarsysCart: Array<EmarsysCartItem> = []
    if (cart) {
      cart.items.forEach((item: ICartItem) => {
        emarsysCart.push({
          item: getSku(item.product, item.product.variant),
          quantity: item.quantity,
          price: item.total ? item.total?.net / 100 : 0,
        })
      })
    }

    return ['cart', emarsysCart]
  })
}

const addViewCommand = (commands: EmarsysCommands, product: IProduct) => {
  commands.push(() => ['view', product.sku])
}

const addCategoryCommand = (commands: EmarsysCommands, category: ITaxonomy) => {
  const categoryPath = category.breadcrumbs.hierarchy.slice()
  // Root category shouldn't be tracked
  categoryPath.shift()

  const breadcrumb = categoryPath?.map((category) => category.name).join(' > ')

  commands.push(() => ['category', breadcrumb])
}

const addPurchaseCommand = (commands: EmarsysCommands, order: IOrder, cart: ICart) => {
  commands.push(() => [
    'purchase',
    {
      orderId: order.id,
      items: order.items.map((item: IOrderItem) => ({
        item: getSku(item.product, item.product.variant),
        price: item.price.net / 100,
        quantity: item.quantity,
      })),
      paymentMethodLabel: cart.paymentMethod?.label
    },
  ])
}

export const addRecommendCommand = (options: EmarsysRecommendOptions) => {
  queueCommands([() => ['recommend', options]])
}

/**
 * Function used to create basic commands reuqired by ScarabQueue on every event.
 */
const createRequiredCommands = (services: IShopServices) => {
  const commands: EmarsysCommands = []
  addTestModeCommand(commands)
  addEmailCommand(services, commands)
  addCartCommand(services, commands)
  return commands
}

const setupEmarsysTracking = (services: IShopServices) => {
  if (!window.ScarabQueue) window.ScarabQueue = []
  enableTestMode = services.environment.getBoolean('EMARSYS_SDK_TEST_MODE')
  enableLogging = services.environment.isDevelopment
  previousCart = getUserCart(services.store.getState())
  hasConsent = services.consent.consent.marketing
  setupViewHandlers(services)
  setupCheckoutHandlers(services)
  setupCartUpdateHandlers(services)
  setupConsentHandlers(services)
  log('Emarsys tracking initialized')
}

const setupViewHandlers = (services: IShopServices) => {
  services.eventBus.subscribe<IViewPageEvent>(viewPageEvent.type, () => {
    const commands = createRequiredCommands(services)
    queueCommands(commands)
  })

  services.eventBus.subscribe<IViewProductEvent>(
    viewProductEvent.type,
    ({ payload }) => {
      const commands = createRequiredCommands(services)
      addViewCommand(commands, payload.product)
      queueCommands(commands)
    }
  )

  services.eventBus.subscribe<IViewCategoryEvent>(
    viewCategoryEvent.type,
    ({ payload }) => {
      const commands = createRequiredCommands(services)
      addCategoryCommand(commands, payload.category)
      queueCommands(commands)
    }
  )
}

const setupCheckoutHandlers = (services: IShopServices) => {
  services.eventBus.subscribe<IPurchaseEvent>(
    purchaseEvent.type,
    ({ payload }) => {
      const commands = createRequiredCommands(services)
      addPurchaseCommand(commands, payload.order, payload.orderCart)
      queueCommands(commands)
    }
  )
}

const setupCartUpdateHandlers = (services: IShopServices) => {
  // Store subscription listening to changes on cart items
  services.store.subscribe(() => {
    const state = services.store.getState()
    const cart = getUserCart(state)

    // Ignore if there is no cart
    if (!cart) return

    // If there is no previous cart, then the initial cart was just loaded from API
    // In this case we commit events that were queued up
    if (!previousCart) {
      previousCart = cart
      requestCommit()
    }

    // Subsequent cart changes indicate that the cart was modified
    // In this case resend cart event if there was any change to cart items
    const previousCartItemsHash = computeCartItemsHash(previousCart.items)
    const currentCartItemsHash = computeCartItemsHash(cart.items)
    previousCart = cart

    if (currentCartItemsHash !== previousCartItemsHash) {
      const commands = createRequiredCommands(services)
      queueCommands(commands)
    }
  })
}

const setupConsentHandlers = (services: IShopServices) => {
  const { eventBus } = services

  eventBus.subscribe<IConsentChangedEvent>(
    consentChangedEvent.type,
    (event) => {
      hasConsent = event.payload.consent.marketing
      requestCommit()
    }
  )
}

export default setupEmarsysTracking
