import * as React from 'react'
import { useMemo } from 'react'
import {
  getImageFromVariantOrPlaceholder,
  IProductSearch,
} from '@core/api/Products/types'
import {
  LYSCard,
  LYSCol,
  LYSRow,
  LYSTypography,
} from '@core/components/Primitives'
import ProductDetailLink from '@core/components/ProductCollection/ProductDetailLink'
import ProductActionElements from '@core/components/shared/ProductCard/ProductActionElements'
import ProductPrice from '@core/components/shared/ProductPrice'
import ProductShortInformation from '@core/components/shared/ProductShortInformation'
// eslint-disable-next-line import/no-cycle
import StockInformation from '@core/components/shared/StockInformation'
import { StockInfoLocation } from '@core/components/shared/StockInformation/types'
import { useTranslation } from '@core/i18n/i18n'
import { useServices } from '@core/utils/ioc'
import getSku from '@core/utils/models/getSku'
import { ProductContextProvider } from '@core/utils/models/product/ProductContext'
import { RenderProp, template, Slot } from '@core/utils/templates'
import style from './index.module.less'

interface OwnProps {
  product: IProductSearch
}

interface ProductCollectionListItemSlots {
  image?: RenderProp<void>
  descriptionHolder?: RenderProp<void>
  title?: RenderProp<void>
  description?: RenderProp<void>
  price?: RenderProp<void>
  stockInformation?: RenderProp<void>
  actionElements?: RenderProp<void>
}

const ProductCollectionListItem = template<
  OwnProps,
  ProductCollectionListItemSlots
>(({ product, slots }) => {
  const { productContextService } = useServices()
  const image = getImageFromVariantOrPlaceholder(product.mainVariant)
  const { t } = useTranslation()
  const productContext = useMemo(
    () =>
      productContextService.getProductSearchContext(
        product,
        product.mainVariant
      ),
    [productContextService, product]
  )

  return (
    <ProductContextProvider productContext={productContext}>
      <LYSCard size="small" withBoxShadow={true}>
        <LYSRow justify="space-between" align="middle">
          <LYSCol xs={24} md={15}>
            <LYSRow className={style.productInfoWrap}>
              <LYSCol xs={24} sm={6} className={style.productImageWrap}>
                <Slot render={slots?.image}>
                  <ProductDetailLink
                    product={product}
                    slug={product.mainVariant.slug}
                    id={product.mainVariant.id}
                  >
                    <div className={style.imageHolder}>
                      <img
                        src={image.url}
                        className={style.image}
                        alt={product.name}
                        title={product.name}
                        loading="lazy"
                        width={200}
                        height={200}
                      />
                    </div>
                  </ProductDetailLink>
                </Slot>
              </LYSCol>
              <LYSCol xs={24} sm={18} className={style.productDescriptionWrap}>
                <Slot render={slots?.descriptionHolder}>
                  <Slot render={slots?.title}>
                    <ProductDetailLink
                      product={product}
                      slug={product.mainVariant.slug}
                      id={product.mainVariant.id}
                    >
                      <LYSTypography.Title
                        level={4}
                        className={style.productName}
                      >
                        {product.name}
                      </LYSTypography.Title>
                    </ProductDetailLink>
                  </Slot>
                  <Slot render={slots?.description}>
                    <ProductShortInformation
                      shortDescription={product.shortDescription}
                      variationName={product.variationName}
                      sku={getSku(product, product.mainVariant)}
                    />
                  </Slot>
                </Slot>
              </LYSCol>
            </LYSRow>
          </LYSCol>

          <LYSCol xs={24} md={3} className={style.productPrice}>
            <Slot render={slots?.price}>
              {product.mainVariant.packagingUnits[0].price && (
                <ProductPrice
                  price={product.mainVariant.packagingUnits[0].price}
                  unitPrice={product.mainVariant.packagingUnits[0].unitPrice}
                  strikePrice={
                    product.mainVariant.packagingUnits[0].strikeThroughPrice
                      ?.price
                  }
                  showDiscountHint={true}
                />
              )}
              {productContext.rules.displayNoPriceHint && (
                <div className={style.hintContainer}>
                  {t('product.hint.noPrice')}
                </div>
              )}
            </Slot>
          </LYSCol>
          <LYSCol xs={24} md={6} className={style.inputWrap}>
            <Slot render={slots?.stockInformation}>
              {!productContext.rules.hideStockInformation && (
                <StockInformation
                  stock={productContext.stock}
                  location={StockInfoLocation.ProductCollectionItem}
                />
              )}
            </Slot>
            <Slot render={slots?.actionElements}>
              <ProductActionElements hidePriceRequestPendingInfo />
            </Slot>
          </LYSCol>
        </LYSRow>
      </LYSCard>
    </ProductContextProvider>
  )
})

export default ProductCollectionListItem
