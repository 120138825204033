import * as React from 'react'
import { LYSTypography, LYSAlert } from '@core/components/Primitives'
import config from '@core/config/config'
import { useTranslation } from '@core/i18n/i18n'
import style from './index.module.less'

const CartHazardWarning: React.FC = () => {
  const { t } = useTranslation()

  const message = (
    <>
      <LYSTypography.Text>{t('cart.hazardousItemsHint')}</LYSTypography.Text>
      <img
        className={style.ghsImage}
        src={config.assets.genericWarningImage}
        alt={t('alt.hazardousItems')}
      />
    </>
  )

  return (
    <LYSAlert
      message={message}
      type="info"
      showIcon={true}
      className={style.hintWrap}
    />
  )
}

export default CartHazardWarning
