import * as React from 'react'
import cn from 'classnames'
import { CheckOutlined } from '@ant-design/icons'
import { LYSCard, LYSIcon, LYSTypography } from '@core/components/Primitives'
import { useTranslation } from '@core/i18n/i18n'
import style from './index.module.less'
import { RenderProp, Slot, template } from '@core/utils/templates'

export interface SmallBenefitsProps {
  hideTitle?: boolean
  className?: string
}

interface SmallBenefitsSlots {
  title?: RenderProp<void>
  extraTop?: RenderProp<void>
  firstBenefit?: RenderProp<void>
  secondBenefit?: RenderProp<void>
  thirdBenefit?: RenderProp<void>
  benefitsTitle?: RenderProp<void>
  firstBenefitText?: RenderProp<void>
  secondBenefitText?: RenderProp<void>
  thirdBenefitText?: RenderProp<void> /* used for igefa trusted shop icon */
  firstBenefitsIcon?: RenderProp<void>
  secondBenefitsIcon?: RenderProp<void>
  thirdBenefitsIcon?: RenderProp<void>
}

const SmallBenefits = template<SmallBenefitsProps, SmallBenefitsSlots>(
  ({ slots, ...props }) => {
    const { t } = useTranslation()
    const { hideTitle, className } = props
    return (
      <LYSCard bordered={false} className={cn(className, style.wrapper)}>
        {!hideTitle && (
          <Slot render={slots?.title}>
            <LYSTypography.Text visualAppearance={'h4'}>
              <Slot render={slots?.benefitsTitle}>{t('benefits.title')}</Slot>
            </LYSTypography.Text>
          </Slot>
        )}
        <div className={style.contentWrap}>
          <Slot render={slots?.extraTop} />
          <div>
            <Slot render={slots?.firstBenefit}>
              <div className={style.points}>
                <Slot render={slots?.firstBenefitsIcon}>
                  <LYSIcon className={style.icon} component={CheckOutlined} />
                </Slot>
                <Slot render={slots?.firstBenefitText}>
                  {t('benefits.deliveryText')}
                </Slot>
              </div>
            </Slot>
            <Slot render={slots?.secondBenefit}>
              <div className={style.points}>
                <Slot render={slots?.secondBenefitsIcon}>
                  <LYSIcon className={style.icon} component={CheckOutlined} />
                </Slot>
                <Slot render={slots?.secondBenefitText}>
                  {t('benefits.personalAdviceText')}
                </Slot>
              </div>
            </Slot>
            <Slot render={slots?.thirdBenefit}>
              <div className={style.points}>
                <Slot render={slots?.thirdBenefitsIcon}>
                  <LYSIcon className={style.icon} component={CheckOutlined} />
                </Slot>
                <Slot render={slots?.thirdBenefitText}>
                  {t('benefits.orderingText')}
                </Slot>
              </div>
            </Slot>
          </div>
        </div>
      </LYSCard>
    )
  }
)

export default SmallBenefits
