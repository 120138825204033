import React, { useMemo } from 'react'
import cn from 'classnames'
import { IEstimatedDeliveryDaysRange } from '@core/api/Products/types'
import { LYSTypography } from '@core/components/Primitives'
import { ILYSTypographyProps } from '@core/components/Primitives/LYSTypography'
import { getEstimatedDateRangeTranslation } from '@core/components/shared/EstimatedDeliveryRange/helper'
import { useTranslation } from '@core/i18n/i18n'
import styles from './styles.module.less'

interface IOwnProps {
  estimatedDeliveryDaysRange?: IEstimatedDeliveryDaysRange
}

type IProps = IOwnProps & Partial<ILYSTypographyProps>

const EstimatedDeliveryRange: React.FC<IProps> = ({
  estimatedDeliveryDaysRange,
  className,
  ...rest
}) => {
  const { t } = useTranslation()

  const dateRangeInfo = useMemo(() => {
    const translation = getEstimatedDateRangeTranslation(
      estimatedDeliveryDaysRange
    )
    if (!translation) {
      return null
    }

    const { translationString, options } = translation

    return t(translationString, options)
  }, [estimatedDeliveryDaysRange, t])

  if (!dateRangeInfo) {
    return null
  }

  return (
    <LYSTypography.Text
      className={cn(styles.deliveryRange, className)}
      type={'success'}
      {...rest}
    >
      {dateRangeInfo}
    </LYSTypography.Text>
  )
}

export default EstimatedDeliveryRange
