import { ITaxonomy } from '@core/api/Categories/types'
import { ColumnCategoryNavigation } from '@core/components/header/Navigation/shared/ColumnCategoryNavigation'
import { MenuBar } from '@core/components/shared/menu/MenuBar'
import { MenuBehaviour } from '@core/components/shared/menu/MenuBehaviour'
import { MenuDropdown } from '@core/components/shared/menu/MenuDropdown'
import useCategoryNavigation from '@core/hooks/categories/useCategoryNavigation'
import { RenderProp, Slot, template } from '@core/utils/templates'
import style from './index.module.less'
import config from '@core/config/config'
import { CategoryNavItem } from './CategoryNavItem'
import { LYSDivider, LYSMenu } from '@core/components/Primitives'
import useRedirectToCategoryPage from './useRedirectToCategoryPage'
import { useTranslation } from '@core/i18n/i18n'
import React from 'react'

export interface CategoryNavBarSlots {
  navBarContent: RenderProp<void>
  dropdownContent: RenderProp<{ category: ITaxonomy }>
}

export interface CategoryNavBarComponents {
  CategoryNavItem: typeof CategoryNavItem
  MenuDropdown: typeof MenuDropdown
}

export interface CategoryNavBarProps {
  className?: string
}

export const CategoryNavBar = template<
  CategoryNavBarProps,
  CategoryNavBarSlots,
  CategoryNavBarComponents
>(({ slots, getComponents, className }) => {
  const redirectToCategoryPage = useRedirectToCategoryPage()
  const { root } = useCategoryNavigation()
  const level1Categories = root?.children || []
  const { t } = useTranslation()

  // Only render the component in browser, otherwise the antd overflow menu doesn't find
  // the correct DOM element and renders the menu in the body which causes duplicated content
  const [isBrowser, setBrowser] = React.useState<boolean>(false)

  React.useEffect(() => {
    setBrowser(true)
  }, [])

  const components = getComponents({
    MenuDropdown,
    CategoryNavItem,
  })

  const renderContent = (category: ITaxonomy) => (
    <>
      <MenuBehaviour.Trigger item={category}>
        <components.CategoryNavItem activatable category={category} />
      </MenuBehaviour.Trigger>
      <MenuBehaviour.Content item={category}>
        <components.MenuDropdown anchored fullWidth className={style.dropdown}>
          <Slot render={slots?.dropdownContent} props={{ category }}>
            <ColumnCategoryNavigation
              categoryRoot={category}
              level2ListClassName={style.wrappedList}
            />
          </Slot>
        </components.MenuDropdown>
      </MenuBehaviour.Content>
    </>
  )

  if (
    isBrowser &&
    config.features.categoryNavigation.overflowCategoryNavItems
  ) {
    return (
      <LYSMenu
        mode="horizontal"
        className={style.menu}
        overflowedIndicator={
          <div className={style.overflow}>
            <LYSDivider type="vertical" />
            <LYSMenu.SubMenu
              key="overflow"
              title={t('category.others')}
              popupClassName={style.popup}
              className={style.overflowSubMenu}
            />
          </div>
        }
      >
        {level1Categories.map((category) => (
          <LYSMenu.SubMenu
            key={category.id}
            title={category.name}
            onTitleClick={() => redirectToCategoryPage(category)}
            popupClassName={style.popup}
          >
            {category.children.map((child) => (
              <LYSMenu.SubMenu
                title={child.name}
                key={child.id}
                onTitleClick={() => redirectToCategoryPage(child)}
                popupClassName={style.popup}
              >
                {child.children.map((subChild) => (
                  <LYSMenu.Item
                    onClick={() => redirectToCategoryPage(subChild)}
                    key={subChild.name}
                  >
                    {subChild.name}
                  </LYSMenu.Item>
                ))}
              </LYSMenu.SubMenu>
            ))}
          </LYSMenu.SubMenu>
        ))}
      </LYSMenu>
    )
  }

  return (
    <MenuBehaviour
      menuId={'categoryNavBar'}
      items={level1Categories}
      keyboardNavigationDirection={'horizontal'}
    >
      <nav role="navigation" className={className}>
        <MenuBar anchor className={style.menuBar}>
          <Slot render={slots?.navBarContent}>
            {level1Categories.map((category) => (
              <div key={category.id}>{renderContent(category)}</div>
            ))}
          </Slot>
        </MenuBar>
      </nav>
    </MenuBehaviour>
  )
})
