import {
  IProductsCollectionQuery,
  IProductsQueryOrder,
} from '@core/api/Products/types'
import config from '@core/config/config'
import {
  getNumberFromQueryParamOrUndefined,
  getQueryParamsString,
  getSingleQueryParamOrUndefined,
} from '@core/utils/url'
import { ParsedUrlQuery } from 'querystring'
import qs from 'qs'

const parseProductCollectionQuery = (
  url: string,
  defaultFilters: Record<string, any> = {}
) => {
  // @TODO: Fix typing workaround for ParsedUrlQuery
  const urlQuery = qs.parse(getQueryParamsString(url)) as ParsedUrlQuery
  const page =
    getNumberFromQueryParamOrUndefined(urlQuery.page) ||
    config.productCollection.defaultPage
  const limit =
    getNumberFromQueryParamOrUndefined(urlQuery.limit) ||
    config.productCollection.defaultLimit
  const searchTerm = getSingleQueryParamOrUndefined(urlQuery, 'search')
  const filter = {
    ...((urlQuery.filter as unknown as Record<string, unknown>) || {}),
    ...defaultFilters,
  }
  const order = urlQuery.order || null

  const query: IProductsCollectionQuery = {
    limit,
    page,
    filter,
  }
  if (searchTerm) query.search = searchTerm
  if (order) query.order = order as unknown as IProductsQueryOrder

  return query
}

export default parseProductCollectionQuery
