import BaseAPI from '@core/api/Platform/BaseAPI'
import { AUTH_REFRESH_TOKEN, AUTH_TOKEN } from '@core/utils/cookies'
import { logging } from '@core/utils/logging'
import { IAnonymousUser, ILoginResponse, IUser } from './types'

class User extends BaseAPI {
  public async login({
    username,
    password,
  }: {
    username: string
    password: string
  }): Promise<void> {
    try {
      const response = await this.api.post<ILoginResponse>('user/login', {
        username,
        password,
      })
      const {
        data: { token, refresh_token },
      } = response
      this.setAuthToken(token)
      this.setRefreshAuthToken(refresh_token)
    } catch (error) {
      this.removeAuthToken()
      throw error
    }
  }

  public async agentLogin({ token }: { token: string }): Promise<void> {
    try {
      this.setAuthToken(token)
      this.setRefreshAuthToken('')
    } catch (error) {
      this.removeAuthToken()
      throw error
    }
  }

  public logout() {
    this.removeAuthToken()
  }

  public async refreshToken() {
    // If there is no refresh token we can only logout
    const refreshToken = this.cookies.get(AUTH_REFRESH_TOKEN)

    if (!refreshToken) {
      this.logout()
      return
    }

    // Try to refresh the token
    try {
      logging.debug('Refreshing authentication token...')
      const response = await this.api.post<ILoginResponse>(
        'user/token/refresh',
        {
          refresh_token: refreshToken,
        },
        // This is required by axios-auth-refresh to not rerun this request
        { skipAuthRefresh: true } as any
      )

      // Success, store new tokens
      logging.debug('Token refresh successful')
      this.setAuthToken(response.data.token)
      this.setRefreshAuthToken(response.data.refresh_token)
    } catch (e) {
      logging.warn('Failed token refresh', e.data)
      // Something went wrong, logout
      this.logout()
    }
  }

  public async getUserDetails(): Promise<IUser | IAnonymousUser> {
    try {
      const response = await this.api.get<IUser | IAnonymousUser>('user/show')
      return response.data
    } catch (error) {
      if (error.response?.status === 401 || error.response?.status === 403) {
        this.removeAuthToken()
      }
      throw error
    }
  }

  public async updateUser(user: IUser): Promise<void> {
    await this.api.put('user', user)
  }

  public async changePassword(
    currentPassword: string,
    newPassword: string
  ): Promise<void> {
    await this.api.post('user/change-password', {
      currentPassword,
      newPassword,
    })
  }

  public async resetPassword(token: string, password: string): Promise<void> {
    await this.api.post('user/password-reset', {
      secret: token,
      password,
    })
  }

  public async validatePasswordResetToken(token: string): Promise<any> {
    try {
      return await this.api.post('user/validate-password-reset-token', {
        token: token,
      })
    } catch (error) {
      return error.response
    }
  }

  public async requestPasswordReset(email: string): Promise<void> {
    await this.api.post('user/request-password-reset', { email })
  }

  private setAuthToken(token: string) {
    this.cookies.set(AUTH_TOKEN, token)
  }

  private setRefreshAuthToken(token: string) {
    this.cookies.set(AUTH_REFRESH_TOKEN, token)
  }

  private removeAuthToken() {
    this.cookies.remove(AUTH_TOKEN)
    this.cookies.remove(AUTH_REFRESH_TOKEN)
  }
}

export default User
