import { IChannel } from '@core/api/Channel/types'
import seoConfig from '@core/config/seo'

export function getContactEmail(channel?: IChannel): string {
  return (
    (channel as any)?.email ||
    channel?.clientFields?.email ||
    seoConfig.organizationDetails.contactPoint.email
  )
}
