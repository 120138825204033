import React from 'react'
import { NextComponentType } from 'next'
import { AppContext } from 'next/app'
import Router, { NextRouter } from 'next/router'
import { IShopServices, setupServices } from '@core/config/services'

export interface WrappedAppProps {
  services: IShopServices
  initialProps: any // stuff returned from getInitialProps
  initialState: any // stuff in the Store state after getInitialProps
  router?: NextRouter // Router from withRouter HOC
}

const SERVICES_KEY = '__SHOP_SERVICES__'

const isServer = typeof window === 'undefined'

const getOrCreateServices = (ctx?: AppContext, appProps?: WrappedAppProps) => {
  let services: IShopServices
  const nextRouter = appProps?.router || ctx?.router || Router

  if (isServer) {
    services =
      appProps?.services || setupServices(isServer, ctx, appProps, nextRouter)
  } else {
    // Browser: Memoize container as singleton on window
    services =
      (window as any)[SERVICES_KEY] ||
      ((window as any)[SERVICES_KEY] = setupServices(
        isServer,
        ctx,
        appProps,
        nextRouter
      ))
  }

  return services
}

const withServices = (AppComponent: NextComponentType) =>
  class WrappedApp extends React.Component<WrappedAppProps> {
    static async getInitialProps(ctx: AppContext) {
      // isServer was previously defined by next-redux-wrapper
      // Since we use it everywhere, we apply it here as well
      ctx.ctx.isServer = isServer
      const services = getOrCreateServices(ctx)

      // Provide container to nested calls of getInitialProps
      ctx.ctx.services = services

      let initialProps = {}

      // Run nested getInitialProps
      if (AppComponent.getInitialProps) {
        initialProps = await (AppComponent.getInitialProps as any)(ctx)
      }

      // Server: Pass store state to client
      const storeProps = {
        initialState: services.store.getState(),
      }

      // Server: Prevent container being serialized to client
      ;(services as any).toJSON = () => ({})

      return {
        services,
        initialProps,
        ...storeProps,
      }
    }

    private services: IShopServices

    constructor(props: WrappedAppProps, context: any) {
      super(props, context)

      // Browser: getInitialProps is not called on initial render, so in that case we have to create the container here
      this.services = getOrCreateServices(undefined, props)
    }

    render() {
      const { initialProps, ...props } = this.props

      return (
        <AppComponent {...props} {...initialProps} services={this.services} />
      )
    }
  }

export default withServices
