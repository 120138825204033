import BaseAPI from '@core/api/Platform/BaseAPI'
import { IChannel } from './types'

class Channel extends BaseAPI {
  public async getChannels(): Promise<IChannel[]> {
    const response = await this.api.get<IChannel[]>('channels/open')
    return response.data
  }
}

export default Channel
