import React from 'react'
import { ConfigContext } from 'antd/lib/config-provider'
import DefaultEmptyImg from 'antd/lib/empty/empty'
import SimpleEmptyImg from 'antd/lib/empty/simple'
import classNames from 'classnames'

export type Props = EmptyProps

const defaultEmptyImg = <DefaultEmptyImg />
const simpleEmptyImg = <SimpleEmptyImg />

export interface TransferLocale {
  description: string
}

export interface EmptyProps {
  prefixCls?: string
  className?: string
  style?: React.CSSProperties
  /** @since 3.16.0 */
  imageStyle?: React.CSSProperties
  image?: React.ReactNode
  description?: React.ReactNode
  children?: React.ReactNode
}

interface EmptyType extends React.FC<EmptyProps> {
  PRESENTED_IMAGE_DEFAULT: React.ReactNode
  PRESENTED_IMAGE_SIMPLE: React.ReactNode
}

const Empty: EmptyType = ({
  className,
  prefixCls: customizePrefixCls,
  image = defaultEmptyImg,
  description,
  children,
  imageStyle,
  ...restProps
}) => {
  const { getPrefixCls, direction } = React.useContext(ConfigContext)

  const prefixCls = getPrefixCls('empty', customizePrefixCls)
  const alt = typeof description === 'string' ? description : 'empty'

  let imageNode: React.ReactNode = null

  if (typeof image === 'string') {
    imageNode = <img alt={alt} src={image} width={103} height={100} />
  } else {
    imageNode = image
  }

  return (
    <div
      className={classNames(
        prefixCls,
        {
          [`${prefixCls}-normal`]: image === simpleEmptyImg,
          [`${prefixCls}-rtl`]: direction === 'rtl',
        },
        className
      )}
      {...restProps}
    >
      <div className={`${prefixCls}-image`} style={imageStyle}>
        {imageNode}
      </div>
      {description && (
        <div className={`${prefixCls}-description`}>{description}</div>
      )}
      {children && <div className={`${prefixCls}-footer`}>{children}</div>}
    </div>
  )
}

Empty.PRESENTED_IMAGE_DEFAULT = defaultEmptyImg
Empty.PRESENTED_IMAGE_SIMPLE = simpleEmptyImg

export default Empty
