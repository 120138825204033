import BaseAPI from '@core/api/Platform/BaseAPI'
import { ICheckout, ICheckoutResponse } from './types'

class Checkout extends BaseAPI {
  public async checkout(checkout: ICheckout): Promise<ICheckoutResponse> {
    const response = await this.api.post<ICheckoutResponse>(
      'checkout',
      checkout
    )
    const { data } = response
    return data
  }
}

export default Checkout
