import { IChannel } from '@core/api/Channel/types'
import { BranchOfficeNumberTypes, branchOfficeUrls } from './branchOfficeUrls'

const getCustomChannelPrismicUrl = (channel?: IChannel) => {
  if (!channel) return undefined

  const branchOfficeNumber: BranchOfficeNumberTypes =
    channel.clientFields?.branchOfficeNumber

  return branchOfficeNumber ? branchOfficeUrls[branchOfficeNumber] : undefined
}

export default getCustomChannelPrismicUrl
