export const contactConfig = {
  name: 'igefa E-Commerce GmbH & Co. KG',
  telephone: '+49 (0) 30 57701543-0',
  get phoneLink() {
    const tel = contactConfig.telephone as string
    return `tel:${tel.replace(/(\s|-)/g, '')}`
  },
  faxNumber: '+49 (0) 30 57701543-2',
  email: 'store@igefa.de',
  contactType: 'customer service',
  address: {
    streetAddress: 'Ludwig-Erhard-Ring 16',
    addressLocality: 'Blankenfelde-Mahlow OT Dahlewitz',
    postalCode: '15827',
    addressCountry: 'Germany',
  },
  social: [] as Array<{ url: string; icon: any }>,
}
