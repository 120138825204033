import BaseAPI from '@core/api/Platform/BaseAPI'
import { IContactRequest } from './types'

class ContactForm extends BaseAPI {
  public async sendContactRequest(values: IContactRequest): Promise<void> {
    await this.api.post('/contact-form', values)
  }
}

export default ContactForm
