import { useState } from 'react'
import cn from 'classnames'
import Link from 'next/link'
import { DownOutlined, UpOutlined } from '@ant-design/icons'
import {
  LYSButton,
  LYSDivider,
  LYSIcon,
  LYSTypography,
} from '@core/components/Primitives'
import { MenuItemList } from '@core/components/header/Navigation/MobileCategoryNavMenu/MenuItemList'
import { MenuItemProps } from '@core/components/header/Navigation/MobileCategoryNavMenu/types'
import { useMenuContext } from '@core/components/shared/menu/MenuBehaviour'
import routes from '@core/config/routes'
import style from './MenuItem.module.less'

export const MenuItem: React.FC<MenuItemProps> = ({ category, level }) => {
  const [open, setOpen] = useState(false)
  const behaviour = useMenuContext()

  const headerClassNames = cn(
    style.header,
    style[`level${level}`],
    open && style.active
  )
  const sublistClassNames = cn(style.sublist, open && style.active)
  const hasChildren = category.children.length > 0

  const handleLinkClick = () => {
    behaviour.close()
  }

  const handleToggleClick = () => {
    setOpen(!open)
  }

  return (
    <li role={'treeitem'}>
      <div className={headerClassNames}>
        <Link {...routes.taxonomy(category)}>
          <span onClick={handleLinkClick}>
            <LYSTypography.Text className={open ? style.activeLink : undefined}>
              {category.name}
            </LYSTypography.Text>
          </span>
        </Link>
        {hasChildren ? (
          <LYSButton
            type={'link'}
            className={style.toggleButton}
            onClick={handleToggleClick}
          >
            <LYSIcon component={open ? UpOutlined : DownOutlined} size={'sm'} />
          </LYSButton>
        ) : null}
      </div>
      {hasChildren ? (
        <MenuItemList
          MenuItemRenderer={MenuItem}
          level={level + 1}
          rootCategory={category}
          className={sublistClassNames}
        />
      ) : null}
      {level === 1 && <LYSDivider className={style.dividers} />}
    </li>
  )
}
