import cn from 'classnames'
import { ITaxonomy } from '@core/api/Categories/types'
import { MenuItemProps } from '@core/components/header/Navigation/MobileCategoryNavMenu/types'
import style from './MenuItemList.module.less'

export interface MenuItemListProps {
  MenuItemRenderer: React.FC<MenuItemProps>
  level: number
  rootCategory: ITaxonomy
  className?: string
}

export const MenuItemList: React.FC<MenuItemListProps> = ({
  MenuItemRenderer,
  rootCategory,
  level,
  className,
}) => {
  return (
    <ul className={cn(style.menuItemList, className)}>
      {rootCategory.children.map((category) => (
        <MenuItemRenderer key={category.id} category={category} level={level} />
      ))}
    </ul>
  )
}
