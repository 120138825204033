import BaseAPI from '@core/api/Platform/BaseAPI'
import { IPriceRequestsCollection, IPriceRequestsQuery } from './types'

class PriceRequests extends BaseAPI {
  public async fetchPriceRequests(params: IPriceRequestsQuery) {
    const response = await this.api.get<IPriceRequestsCollection>(
      `price-requests`,
      {
        params,
      }
    )
    const { data } = response
    return data
  }

  public async createPriceRequest(variantId: string) {
    const response = await this.api.post(`price-requests`, {
      variant: variantId,
    })
    const { data } = response
    return data
  }

  public async deletePriceRequest(variantId: string) {
    await this.api.delete(`price-requests/${variantId}`, {
      genericUrl: 'price-requests/{variantId}',
    })
    return true
  }

  public async acknowledgePriceRequest(variantId: string) {
    await this.api.put<any>(
      `price-requests/${variantId}/acknowledge`,
      undefined,
      {
        genericUrl: 'price-requests/{variantId}/acknowledge',
      }
    )
  }
}
export default PriceRequests
