import { StockInfoLocation } from './types'

export const showInStock = (location: StockInfoLocation): boolean =>
  location === StockInfoLocation.PDP ||
  location === StockInfoLocation.ProductListItem

export const showOutOfStock = (location: StockInfoLocation): boolean =>
  location === StockInfoLocation.PDP ||
  location === StockInfoLocation.CartPageItem ||
  location === StockInfoLocation.ProductListItem
