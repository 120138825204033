import * as next from 'next'

export type NextPageWithLayout<Props = any> = next.NextPage<Props> & {
  Layout?: React.FC<any>
}

export const forceCssRefresh = () => {
  const els: any = document.querySelectorAll(
    'link[href*="/_next/static/css/styles.chunk.css"]'
  )
  const timestamp = new Date().valueOf()

  // .forEach on querySelector result not supported by IE11
  for (const element of els) {
    element.href = `/_next/static/css/styles.chunk.css?v=${timestamp}`
  }
}
