import { PostalAddress } from 'schema-dts'
import config from './config'
import { contactConfig } from './contactConfig'

const seoConfig = {
  separator: '|',
  image: `${config.baseUrl}/static/assets/store-logo.png`,
  defaultType: 'website',
  organizationDetails: {
    name: contactConfig.name,
    url: config.baseUrl,
    sameAs: [
      'https://www.youtube.com/user/igefaHGmbH',
      'https://twitter.com/igefaDe',
      'https://www.xing.com/companies/igefa',
    ],
    logo: config.baseUrl + config.assets.storeLogo,
    contactPoint: {
      telephone: contactConfig.telephone,
      faxNumber: contactConfig.faxNumber,
      email: contactConfig.email,
      contactType: 'customer service',
    } as PostalAddress,
    address: {
      streetAddress: contactConfig.address.streetAddress,
      addressLocality: contactConfig.address.addressLocality,
      postalCode: contactConfig.address.postalCode,
      addressCountry: contactConfig.address.addressCountry,
    } as PostalAddress,
  },
}
export default seoConfig
