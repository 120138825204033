import isArray from 'lodash/isArray'
import isObject from 'lodash/isObject'
import mapValues from 'lodash/mapValues'

const cleanValuesDeep = (value: any): any => {
  if (isArray(value)) {
    return value.map(cleanValuesDeep)
  }
  if (isObject(value)) {
    return mapValues(value, cleanValuesDeep)
  }
  if (value === '') return null
  return value
}

export const sanitizeModel = <T extends any>(model: T): T => {
  return cleanValuesDeep(model)
}
