import { IRequestClosedShopAccess } from '@core/api/Channel/types'
import { showNotification } from '@core/components/Notification'
import thunk from '@core/store/thunk'
import { sanitizeModel } from '@core/utils/models/sanitizeModel'
import { i18n } from '../../i18n/i18n'
import { fetchUser } from '../user/actions'

export * from '@original/store/customer/actions'

export const requestClosedShopAccess = (
  credentials: IRequestClosedShopAccess
) =>
  thunk(async (dispatch, getState, dependencies) => {
    const { user } = getState()
    try {
      credentials = sanitizeModel(credentials)
      await dependencies.api.channel.requestClosedShopAccess(credentials)
      if (user.user) dispatch(fetchUser())
    } catch (error) {
      showNotification(i18n.t('error.default'), 'warning')
      throw new Error(error)
    }
  })
