import * as React from 'react'
import { LYSDivider, LYSLayout } from '@core/components/Primitives'
import AppBar from '@core/components/header/AppBar'
import GlobalProgressBar from '@core/components/header/GlobalProgressBar'
import TopBar from '@core/components/header/TopBar'
import config from '@core/config/config'
import PrismicInfoBanner from '@core/prismic/components/PrismicInfoBanner'
import { usePageContext } from '@core/utils/pages/PageContext'
import MenuBar from './MenuBar'
import style from './index.module.less'
import cn from 'classnames'
import MobileNavigation from '../Navigation/MobileNavigation'
import { AppBarContextProvider } from '../AppBar/Context/appBarContext'

const Header: React.FC = () => {
  const {
    props: { isMobile, isTablet, prismicInfoBanner },
  } = usePageContext()
  const isTouchDevice = isMobile || isTablet

  const isVisibleCategoryNav =
    config.features.categoryNavigation.visibleCategoryNavItems
  const isOverflowCategoryNav =
    config.features.categoryNavigation.overflowCategoryNavItems

  return (
    <LYSLayout.Header
      className={cn(
        style.header,
        isOverflowCategoryNav && style.headerWithShadow
      )}
    >
      {prismicInfoBanner && (
        <PrismicInfoBanner infoBanner={prismicInfoBanner} />
      )}
      <TopBar />
      <AppBarContextProvider>
        <AppBar />
        {isMobile && (
          <>
            <MobileNavigation />
          </>
        )}
        {!isTouchDevice && (isVisibleCategoryNav || isOverflowCategoryNav) && (
          <>
            {isOverflowCategoryNav && <LYSDivider className={style.divider} />}
            <MenuBar />
          </>
        )}
      </AppBarContextProvider>
      <GlobalProgressBar className={style.progressBar} />
    </LYSLayout.Header>
  )
}

export default Header
