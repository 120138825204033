import { getOrCreateCart } from '@core/store/cart/actions'
import thunk from '@core/store/thunk'
import { fetchUserRelatedData } from '@core/store/user/actions'
import { isAuthenticated } from '@core/store/user/selectors'
import { logging } from '@core/utils/logging'

export const init = () =>
  thunk(async (dispatch, getState) => {
    try {
      // Load additional user data
      // initial user fetch is done on server
      if (isAuthenticated(getState())) {
        await dispatch(fetchUserRelatedData())
      }
    } catch (error) {
      logging.error('App init action failed', error)
    }

    // Load existing cart or create a new one
    await dispatch(getOrCreateCart())
  })
