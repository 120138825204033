import React, { useMemo } from 'react'
import { TFunction } from 'next-i18next'
import { PriceTypes } from '@core/api/Channel/types'
import { IPriceRequest } from '@core/api/PriceRequests/types'
import { LYSRow, LYSCol, LYSTypography } from '@core/components/Primitives'
import useChannel from '@core/hooks/channel/useChannel'
import { useTranslation } from '@core/i18n/i18n'
import { formatDate } from '@core/utils/formatDateTime'
import { formatPrice } from '@core/utils/i18n'
import { getProductImageSEOProps } from '@core/utils/seo/getProductImageSEOProps'
import ProductDetailLink from '../ProductDetailLink'
import styles from './index.module.less'
import { ProductContextProvider } from '@core/utils/models/product/ProductContext'
import { useServices } from '@core/utils/ioc'
import ProductActionElements from '@core/components/shared/ProductCard/ProductActionElements'
import { usePageContext } from '@core/utils/pages/PageContext'

interface IProps {
  requestProduct: IPriceRequest
  showAddToCart?: boolean
}

function getStatusColorClass(requestProduct: IPriceRequest) {
  const { status, productInformation } = requestProduct
  switch (status) {
    case 'open':
    case 'requested':
      return styles.product_open
    case 'acknowledged':
      if (!productInformation.mainVariant.packagingUnits[0].price) {
        return styles.product_open
      } else {
        return styles.product_done
      }
    case 'rejected':
      return styles.product_rejected
    case 'done':
      return styles.product_done
  }
}

function getStatusText({
  product,
  t,
  displayedPriceType,
}: {
  product: IPriceRequest
  t: TFunction
  displayedPriceType: PriceTypes
}) {
  const {
    status,
    productInformation: { mainVariant },
  } = product
  switch (status) {
    case 'open':
    case 'requested':
      return t`priceRequest.status.pending`
    case 'rejected':
      return t`priceRequest.status.rejected`
    case 'done':
    case 'acknowledged':
      // Just an extra null check to prevent displaying broken data
      if (mainVariant?.packagingUnits[0]?.price?.net) {
        return formatPrice(
          mainVariant?.packagingUnits[0]?.price[displayedPriceType]
        )
      } else {
        return t`priceRequest.status.pending`
      }
  }
}

const PriceRequestProductStatus: React.FC<IProps> = ({
  requestProduct,
  showAddToCart = true,
}) => {
  const { t } = useTranslation()
  const { productContextService } = useServices()
  const productContext = useMemo(
    () => productContextService.getProductPriceRequestContext(requestProduct),
    [productContextService, requestProduct]
  )

  const { product, createdAt } = productContext
  const { image, name, sku, mainVariant } = product
  const { userChannel } = useChannel()
  const { displayedPriceType } = userChannel!
  const formattedDate = formatDate(createdAt)
  const {
    props: { isMobile },
  } = usePageContext()

  return (
    <ProductContextProvider productContext={productContext}>
      <LYSRow gutter={'md'} align={'middle'} className={styles.product_status}>
        <LYSCol xs={6} md={2}>
          <ProductDetailLink slug={mainVariant.slug} id={mainVariant.id}>
            {image?.url && (
              <img
                className={styles.product_image}
                src={image.url}
                alt={getProductImageSEOProps(name).alt}
              />
            )}
          </ProductDetailLink>
        </LYSCol>
        <LYSCol xs={18} md={22} className={styles.product_col}>
          <LYSRow gutter={['sm', 'none']} className={styles.product_row}>
            <LYSCol xs={24} md={8}>
              <ProductDetailLink slug={mainVariant.slug} id={mainVariant.id}>
                <LYSTypography.Text
                  visualAppearance={isMobile ? 'p' : 'h4'}
                  style={{
                    fontWeight: 'bold',
                  }}
                >
                  {name}
                </LYSTypography.Text>
              </ProductDetailLink>
            </LYSCol>
            {!isMobile && (
              <LYSCol xs={24} md={4} className={styles.product_col}>
                <LYSTypography.Text>{sku}</LYSTypography.Text>
              </LYSCol>
            )}
            <LYSCol xs={24} md={4} className={styles.product_col}>
              <LYSTypography.Text
                style={{
                  fontSize: isMobile ? '12px' : 'inherit',
                }}
              >
                {t('priceRequest.requestedAt')} {formattedDate}
              </LYSTypography.Text>
            </LYSCol>
            <LYSCol
              xs={showAddToCart ? 12 : 24}
              md={12}
              className={styles.product_price_holder}
            >
              <LYSTypography.Text
                className={getStatusColorClass(requestProduct)}
                style={{
                  fontSize: isMobile ? '12px' : 'inherit',
                }}
              >
                <b>
                  {getStatusText({
                    product: requestProduct,
                    t,
                    displayedPriceType,
                  })}
                </b>
              </LYSTypography.Text>
            </LYSCol>
            {showAddToCart && (
              <LYSCol xs={18} md={4}>
                <ProductActionElements
                  hideAddToCartButtonLabel={true}
                  hidePriceRequestPendingInfo={true}
                />
              </LYSCol>
            )}
          </LYSRow>
        </LYSCol>
      </LYSRow>
    </ProductContextProvider>
  )
}

export default PriceRequestProductStatus
