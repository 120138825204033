import BaseAPI from '@core/api/Platform/BaseAPI'
import { ApiValidationError } from '../types'
import {
  IAddProductListItems,
  ICreateProductList,
  IProductList,
  IProductListCollection,
  IProductListItemCollection,
  IProductListItemsQuery,
  IUpdateProductList,
} from './types'

export enum AddToProductListResult {
  SUCCESS = 'SUCCESS',
  ALREADY_EXISTS_ERROR = 'ALREADY_EXISTS_ERROR',
  UNEXPECTED_ERROR = 'UNEXPECTED_ERROR',
}

export interface AddToProductListApiResult {
  type: AddToProductListResult
  error?: ApiValidationError
}

export default class ProductLists extends BaseAPI {
  public async fetchProductLists(): Promise<IProductListCollection> {
    const response = await this.api.get<IProductListCollection>('product-lists')
    const { data } = response
    return data
  }

  public async fetchProductList(id: string): Promise<IProductList | undefined> {
    try {
      const response = await this.api.get<IProductList>(`product-lists/${id}`, {
        genericUrl: 'product-lists/{id}',
      })
      const { data } = response
      return data
    } catch (e) {
      if (e.response?.status === 404) {
        return undefined
      }
      throw e
    }
  }

  public async fetchProductListItems(
    { productListId, ...params }: IProductListItemsQuery,
    timeout?: number
  ): Promise<IProductListItemCollection | undefined> {
    const response = await this.api.get<IProductListItemCollection>(
      `/product-lists/${productListId}/items`,
      {
        params,
        timeout,
        genericUrl: 'product-lists/{productListId}/items',
      }
    )
    const { data } = response
    return data
  }

  public async addToProductList(
    productListId: string,
    productListItems: IAddProductListItems
  ): Promise<AddToProductListApiResult> {
    try {
      await this.api.post<IProductListItemCollection>(
        `product-lists/${productListId}/items`,
        productListItems,
        {
          genericUrl: 'product-lists/{productListId}/items',
        }
      )
      return { type: AddToProductListResult.SUCCESS }
    } catch (e) {
      if (e.response?.status === 409) {
        return { type: AddToProductListResult.ALREADY_EXISTS_ERROR, error: e }
      }
      return { type: AddToProductListResult.UNEXPECTED_ERROR, error: e }
    }
  }

  public async createProductList(
    productList: ICreateProductList
  ): Promise<IProductList> {
    const response = await this.api.post<IProductList>(
      'product-lists',
      productList
    )
    const { data } = response
    return data
  }

  public async deleteProductList(productListId: string): Promise<void> {
    await this.api.delete<IProductList>(`product-lists/${productListId}`, {
      genericUrl: 'product-lists/{productListId}',
    })
  }

  public async deleteFromProductList(
    productListId: string,
    variantIds: string[]
  ): Promise<void> {
    await this.api.delete(`product-lists/${productListId}/items/remove`, {
      data: {
        variantIds,
      },
      genericUrl: 'product-lists/{productListId}/items/remove',
    })
  }

  public async deleteProductListItemEvents(
    productListId: string
  ): Promise<void> {
    await this.api.delete(`product-lists/${productListId}/events`, {
      genericUrl: 'product-lists/{productListId}/events',
    })
  }

  public async addProductListToCart(
    productListId: string,
    cartId: string
  ): Promise<void> {
    await this.api.put(
      `product-lists/${productListId}/add-to-cart`,
      { cartId },
      {
        genericUrl: 'product-lists/{productListId}/add-to-cart',
      }
    )
  }

  public async updateProductList(
    productListId: string,
    productList: IUpdateProductList
  ): Promise<void> {
    const response = await this.api.put(
      `product-lists/${productListId}`,
      productList,
      {
        genericUrl: 'product-lists/{productListId}',
      }
    )
    const { data } = response
    return data
  }

  public async updateProductListItemPosition(
    productListId: string,
    variantId: string,
    position: number
  ): Promise<void> {
    const response = await this.api.put(
      `product-lists/${productListId}/items/${variantId}/position`,
      {
        position,
      },
      {
        genericUrl: 'product-lists/{productListId}/items/{variantId}/position',
      }
    )
    const { data } = response
    return data
  }

  public async updateProductListItemQuantity(
    productListId: string,
    variantId: string,
    quantity: number
  ): Promise<void> {
    const response = await this.api.put(
      `product-lists/${productListId}/items/${variantId}/quantity`,
      {
        quantity,
      },
      {
        genericUrl: 'product-lists/{productListId}/items/{variantId}/quantity',
      }
    )
    const { data } = response
    return data
  }
}
