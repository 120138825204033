import React from 'react'
import { LYSButton } from '@core/components/Primitives'
import { useTranslation } from '@core/i18n/i18n'
import ButtonLayout from '../ButtonLayout'

interface Props {
  submitText?: string
  cancelText?: string
  submitOnlyButton?: boolean
  submitDisabled?: boolean
  onCancel?: () => void
}

const FormButtons: React.FC<Props> = (props) => {
  const { submitText, cancelText, submitOnlyButton, submitDisabled, onCancel } =
    props

  const { t } = useTranslation()

  return (
    <ButtonLayout justify={submitOnlyButton ? 'center' : 'space-between'}>
      {!submitOnlyButton && (
        <LYSButton type="default" onClick={onCancel}>
          {cancelText ? cancelText : t('general.cancel')}
        </LYSButton>
      )}

      <LYSButton
        type="primary"
        data-testid="submit-form-button"
        disabled={submitDisabled}
        htmlType="submit"
      >
        {submitText ? submitText : t('general.save')}
      </LYSButton>
    </ButtonLayout>
  )
}

export default FormButtons
