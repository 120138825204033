import React from 'react'
import { HeartOutlined, DownOutlined } from '@ant-design/icons'
import { LYSDropdown, LYSButton, LYSIcon } from '@core/components/Primitives'
import { usePageContext } from '@core/utils/pages/PageContext'
import useCurrentUser from '../../../../hooks/user/useCurrentUser'
import { useTranslation } from '@core/i18n/i18n'
import ProductListMenuContent from './ProductListMenuContent'
import style from './index.module.less'

interface ProductListMenuProps {
  buttonClassName?: string
}

export const ProductListMenu: React.FC<ProductListMenuProps> = ({
  buttonClassName,
}) => {
  const { isAuthenticated } = useCurrentUser()
  const { t } = useTranslation()
  const {
    props: { isMobile, isTablet },
  } = usePageContext()

  const isTouchDevice = isMobile || isTablet

  if (!isAuthenticated && !isMobile) return null
  return (
    <LYSDropdown overlay={<ProductListMenuContent />}>
      <LYSButton
        className={buttonClassName}
        type="link"
        ghost
        icon={<HeartOutlined rev={undefined} />}
      >
        {!isTouchDevice && (
          <>
            {t('menu.orderListText')}
            <LYSIcon className={style.icon} component={DownOutlined} />
          </>
        )}
      </LYSButton>
    </LYSDropdown>
  )
}
