import { useReducer } from 'react'

enum Actions {
  INIT,
  SUCCESS,
  FAILURE,
  RESET,
}

interface StateType {
  isLoading: boolean
  isError: boolean
  error?: any
  errorMessage?: string
}

type ActionType =
  | {
      type: Actions.INIT | Actions.RESET | Actions.SUCCESS
    }
  | {
      type: Actions.FAILURE
      payload?: {
        error?: any
        errorMessage?: string
      }
    }

interface ActionExport {
  dispatchInit: () => void
  dispatchSuccess: () => void
  dispatchFailure: (error?: any, errorMessage?: string) => void
  dispatchReset: () => void
}

const requestReducer = (state: StateType, action: ActionType): StateType => {
  switch (action.type) {
    case Actions.INIT:
      return {
        ...state,
        isLoading: true,
        isError: false,
        error: undefined,
        errorMessage: undefined,
      }
    case Actions.SUCCESS:
      return {
        ...state,
        isLoading: false,
        isError: false,
        error: undefined,
        errorMessage: undefined,
      }
    case Actions.FAILURE:
      return {
        ...state,
        isLoading: false,
        isError: true,
        error: action.payload?.error,
        errorMessage: action.payload?.errorMessage,
      }
    case Actions.RESET:
      return { ...state, isLoading: false, isError: false, error: undefined }
  }
}

const defaultInitialState = { isLoading: false, isError: false }

const useRequestReducer = (
  initialState?: StateType
): [StateType, ActionExport] => {
  const [state, dispatch] = useReducer(
    requestReducer,
    initialState || defaultInitialState
  )
  const actions = {
    dispatchInit: () => dispatch({ type: Actions.INIT }),
    dispatchSuccess: () => dispatch({ type: Actions.SUCCESS }),
    dispatchFailure: (error?: any, errorMessage?: string) =>
      dispatch({ type: Actions.FAILURE, payload: { error, errorMessage } }),
    dispatchReset: () => dispatch({ type: Actions.RESET }),
  }
  return [state, actions]
}

export default useRequestReducer
