import qs from 'qs'
import { ITaxonomy, ITaxonomyWithoutChildren } from '@core/api/Categories/types'
import {
  IProductsQuery,
  ITaxonomyAggregationLeaf,
} from '@core/api/Products/types'
import { AvailableSearchTypes } from '@core/components/AutoSuggest/SearchTypeSwitch'
import { ProductListSortOption } from '@core/store/productList/types'

export interface Route {
  href: string
  as?: string
}

export const createProductQueryUrlForRoute = (
  route: Route,
  newQuery: IProductsQuery
) => {
  const newFullQuery = {
    ...{ filter: undefined },
    ...{ order: undefined },
    ...newQuery,
  }
  const stringifiedBuiltQuery = qs.stringify(newQuery)
  const stringifiedFullQuery = qs.stringify(newFullQuery)

  return {
    href: `${route.href}&${stringifiedFullQuery}`,
    as: `${route?.as}&${stringifiedBuiltQuery}`,
  }

  // replace the query in the asPath
}
const routes = {
  // Content pages
  taxonomy(taxonomy: ITaxonomy | ITaxonomyWithoutChildren): Route {
    return {
      href: `/c/[...slugSlashId]`,
      as: `/c/${taxonomy.slug}/${taxonomy.id}`,
    }
  },
  productDetail(slug: string, id: string): Route {
    return {
      href: `/p/[...slugSlashId]`,
      as: `/p/${slug}/${id}`,
    }
  },

  searchResult(
    searchText: string,
    searchType: AvailableSearchTypes,
    taxonomyId?: string
  ): Route {
    const encodedSearchText = encodeURIComponent(searchText)
    const url = {
      href: `/search-result?search=${encodedSearchText}&searchType=${searchType}`,
      as: `/search?search=${encodedSearchText}&searchType=${searchType}`,
    }
    if (taxonomyId) {
      return createProductQueryUrlForRoute(url, {
        filter: {
          taxonomy: taxonomyId,
        },
      })
    }

    return url
  },

  searchResultTaxonomy(
    searchText: string,
    taxonomyLeaf: ITaxonomyAggregationLeaf
  ): Route {
    const query = qs.stringify({
      ...{ search: searchText },
      ...{ filter: { taxonomy: taxonomyLeaf.id } },
    })
    return {
      href: `/search-result?${query}`,
      as: `/search?${query}`,
    }
  },
  brandDetail(slug: string, id: string): Route {
    return {
      href: `/brand/[...slugSlashId]`,
      as: `/brand/${slug}/${id}`,
    }
  },
  awardDetail(id: string): Route {
    return {
      href: `/award/[id]`,
      as: `/award/${id}`,
    }
  },
  orderOverview(orderId?: string): Route {
    const query = qs.stringify(
      {
        orderId,
      },
      {
        addQueryPrefix: true,
      }
    )
    return {
      href: `/me/orders/overview${query}`,
      as: `/me/orders/overview${query}`,
    }
  },
  login(returnUrl?: string): Route {
    const query = qs.stringify(
      {
        returnurl: returnUrl ? encodeURIComponent(returnUrl) : undefined,
      },
      {
        addQueryPrefix: true,
      }
    )

    return {
      href: `/auth/login${query}`,
      as: `/auth/login${query}`,
    }
  },
  // Checkout
  cart: {
    href: `/cart`,
    as: 'cart',
  } as Route,
  checkoutAddress: {
    href: `/checkout/address`,
    as: `/checkout/address`,
  } as Route,
  checkoutShipping: {
    href: `/checkout/select-shipping`,
    as: `/checkout/select-shipping`,
  } as Route,
  checkoutPayment: {
    href: `/checkout/select-payment`,
    as: `/checkout/select-payment`,
  } as Route,
  checkoutOverview: {
    href: `/checkout/overview`,
    as: `/checkout/overview`,
  } as Route,
  // Me pages
  productList(productListId?: string): Route {
    if (!productListId) {
      return {
        href: '/me/product-list',
        as: '/me/product-list',
      }
    }
    return {
      href: `/me/product-list?id=${productListId}`,
      as: `/me/product-list/${productListId}`,
    }
  },
  productListPrint(
    productListId: string,
    sortOrder: ProductListSortOption
  ): Route {
    return {
      href: `/me/product-list/print?id=${productListId}&sortOrder=${sortOrder}`,
      as: `/me/product-list/${productListId}/print?sortOrder=${sortOrder}`,
    }
  },
  addresses: {
    href: `/me/addresses`,
    as: `/me/addresses`,
  },
  // Static / Prismic pages
  dataProtection: {
    href: '/page/[slug]',
    as: '/page/data-protection',
    prefetch: false,
  } as Route,
  termsAndConditions: {
    href: '/page/[slug]',
    as: `/page/terms-and-conditions`,
    prefetch: false,
  } as Route,
  imprint: {
    href: '/page/[slug]',
    as: `/page/impressum`,
    prefetch: false,
  } as Route,
  faq: {
    href: '/page/[slug]',
    as: `/page/faq`,
    prefetch: false,
  } as Route,
  delivery: {
    href: '/page/[slug]',
    as: `/page/delivery`,
    prefetch: false,
  } as Route,
  returnPolicy: {
    href: '/page/[slug]',
    as: `/page/return-policy`,
    prefetch: false,
  } as Route,
  recommendations: {
    href: `/recommendations`,
  } as Route,
  contactForm: {
    href: `/page/kontakt`,
    as: '/page/kontakt',
    prefetch: false,
  } as Route,
}

export default routes
