import { i18n } from '../i18n/i18n'
import config from './config'
export type Callback = (error?: string) => void

export interface ShopValidationRule {
  (rule: any, value: any): Promise<void>
}

export interface ShopValidationRuleSet {
  validatePassword: ShopValidationRule
  validateStreet: ShopValidationRule
  validatePostalCode: ShopValidationRule
  validatePhoneNumber: ShopValidationRule
  validateVatId: ShopValidationRule
  validateAddressBlacklistedStrings: ShopValidationRule
}

export default {
  validatePassword: async (_rule: any, value: any) => {
    // minimum 6 characters, 1 upper case, 1 lower case, 1 digit and 1 special character
    const passwordPattern =
      /^(?=.*[a-z])(?=.*[A-Z])(?=.*\d)(?=.*[^\w|^\s]+).{6,}$/
    if (passwordPattern.test(value)) {
      return
    } else {
      throw new Error(i18n.t('form.validation.invalidPassword'))
    }
  },
  validateStreet: async (_rule: any, value: any) => {
    // minimum 1 letter and 1 digit
    const streetPattern = /^(?=.*\d)(?=.*[a-zA-Z])/
    if (streetPattern.test(value)) {
      return
    } else {
      throw new Error(i18n.t('form.validation.wrongFormat'))
    }
  },
  validatePostalCode: async (_rule: any, value: any) => {
    // only digits
    const postalCodePattern = /^[0-9]{5}$/
    if (postalCodePattern.test(value)) {
      return
    } else {
      throw new Error(i18n.t('form.validation.invalidPostalCode'))
    }
  },
  validatePhoneNumber: async (_rule: any, value: any) => {
    // matches empty string, common phone number patterns, with & without country code, brackets, white spaces and slashes
    const phoneNumberPattern =
      /^$|^[+]*[0-9]*[\s]*[(]{0,1}[0-9]{1,4}[)]{0,1}[-\s./0-9]{5,}$/

    if (phoneNumberPattern.test(value) || !value) {
      return
    } else {
      throw new Error(i18n.t('form.validation.wrongFormat'))
    }
  },

  validateVatId: async (_rule: any, value: any) => {
    const vatIdPattern = config.features.vatIdValidationRegex
    if (!value || vatIdPattern.test(value)) {
      return
    } else {
      throw new Error(i18n.t('form.validation.invalidVatId'))
    }
  },

  validateAddressBlacklistedStrings: async (_rule: any, value: any) => {
    const blacklistedStrings = config.features.addressBlacklistedStrings
    if (blacklistedStrings.length === 0) {
      return
    }

    const blacklistedStringsRegex = new RegExp(
      `^.*(${blacklistedStrings.join('|')})+.*$`
    )
    if (blacklistedStringsRegex.test(value)) {
      throw new Error(
        i18n.t('form.validation.hasBlacklistedStrings', {
          blacklistedStrings: blacklistedStrings.join(', '),
        })
      )
    }
  },
} as ShopValidationRuleSet
