import { IChannel } from '@core/api/Channel/types'
import { IGrossPrice } from '@core/api/Products/types'

export const calculateDiscount = (
  price?: IGrossPrice,
  strikePrice?: IGrossPrice,
  userChannel?: IChannel
) => {
  if (!price || !strikePrice || !userChannel) return undefined

  return Math.abs(
    price[userChannel.displayedPriceType] -
      strikePrice[userChannel.displayedPriceType]
  )
}
