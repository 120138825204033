import qs from 'qs'
import BaseAPI from '@core/api/Platform/BaseAPI'
import { IOrder, IOrderCollection, IOrdersQuery } from './types'

class Orders extends BaseAPI {
  public async fetchOrders(filter?: IOrdersQuery): Promise<IOrderCollection> {
    const response = await this.api.get<IOrderCollection>(
      `orders?${qs.stringify(filter)}`
    )
    const { data } = response
    return data
  }
  public async fetchOrderDetail(id: string): Promise<IOrder | undefined> {
    try {
      const response = await this.api.get<IOrder>(`orders/${id}`, {
        genericUrl: 'orders/${id}',
      })
      const { data } = response
      return data
    } catch (e) {
      if (e.response?.status === 404) return undefined
      throw e
    }
  }
}
export default Orders
