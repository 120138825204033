import React, { useState } from 'react'
import {
  CaretDownOutlined,
  CaretUpOutlined,
  CheckOutlined,
} from '@ant-design/icons'
import { useTranslation } from '@core/i18n/i18n'
import { LYSIcon, LYSTypography } from '../Primitives'
import styles from './index.module.less'

export enum AvailableSearchTypes {
  PRODUCT = 'product',
  DOCUMENT = 'document',
}

const OPTIONS = [AvailableSearchTypes.PRODUCT, AvailableSearchTypes.DOCUMENT]

interface IProps {
  active: AvailableSearchTypes
  onChange: (option: AvailableSearchTypes) => void
}

const SearchTypeSwitch: React.FC<IProps> = ({ active, onChange }) => {
  const { t } = useTranslation()
  const [dropDownVisible, setDropdownVisible] = useState(false)

  function handleSelect(option: AvailableSearchTypes) {
    setDropdownVisible(false)
    onChange(option)
  }

  return (
    <>
      <div
        className={styles.typeswitch}
        onClick={() => setDropdownVisible(!dropDownVisible)}
      >
        <LYSIcon
          component={dropDownVisible ? CaretUpOutlined : CaretDownOutlined}
        />
      </div>
      {dropDownVisible && (
        <div className={styles.typeswitch__optionholder}>
          {OPTIONS.map((el, index) => (
            <div
              className={styles.typeswitch__option}
              key={`searchtype-option-${index}`}
              onClick={() => handleSelect(el)}
            >
              <LYSTypography.Text>
                {t(`search.searchSwitch.${el}`)}
              </LYSTypography.Text>
              {el === active && (
                <LYSIcon
                  className={styles.typeswitch__icon}
                  component={CheckOutlined}
                />
              )}
            </div>
          ))}
        </div>
      )}
    </>
  )
}

export default SearchTypeSwitch
