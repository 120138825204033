import React from 'react'
import useCart from '@core/hooks/cart/useCart'
import { useServices } from '@core/utils/ioc'
import { usePageContext } from '@core/utils/pages/PageContext'
import { RenderProp, Slot, template } from '@core/utils/templates'
import { QuickCartDrawer, QuickCartDrawerProps } from './Drawer'
import { QuickCartMenu, QuickCartMenuProps } from './Menu'
import { QuickCartButton } from './QuickCartButton'

export interface QuickCartProps {
  drawerClassName?: string
  buttonClassName?: string
  menuClassName?: string
}

interface QuickCartSlots {
  CartMenu?: RenderProp<QuickCartMenuProps>
  CartDrawer?: RenderProp<QuickCartDrawerProps>
}

interface QuickCartComponents {
  CartMenuToggle: typeof QuickCartButton
}
export const QuickCart = template<
  QuickCartProps,
  QuickCartSlots,
  QuickCartComponents
>(
  ({
    slots,
    getComponents,
    buttonClassName,
    drawerClassName,
    menuClassName,
  }) => {
    const { router } = useServices()
    const { cart, toggleCartDrawer } = useCart()

    const {
      props: { isMobile },
    } = usePageContext()
    const components = getComponents({
      CartMenuToggle: QuickCartButton,
    })

    const handleClick = () => {
      if (isMobile) {
        router.pushAndScrollTop('/cart')
      } else {
        toggleCartDrawer()
      }
    }

    return (
      <>
        <components.CartMenuToggle
          className={buttonClassName}
          onClick={handleClick}
          itemsInCart={cart?.items.length || 0}
          data-testid="quickcart-button"
        />
        <Slot render={slots?.CartDrawer}>
          <QuickCartDrawer className={drawerClassName}>
            <Slot render={slots?.CartMenu}>
              <QuickCartMenu className={menuClassName} />
            </Slot>
          </QuickCartDrawer>
        </Slot>
      </>
    )
  }
)
