import React from 'react'
import { ICartItem } from '@core/api/Cart/types'
import { getImagePlaceholder } from '@core/api/Products/types'
import { LYSList, LYSRow, LYSTypography } from '@core/components/Primitives'
import styles from './index.module.less'
import ProductDetailLink from '@core/components/ProductCollection/ProductDetailLink'

interface IProps {
  item: ICartItem
}

const CartPriceRequestItem: React.FC<IProps> = ({ item }) => {
  const { product } = item
  const { variant } = item.product

  const image = variant.imageUrl || product.imageUrl || getImagePlaceholder()

  return (
    <>
      <LYSList.Item key={product.variant.id} className={styles.listItem}>
        <LYSRow
          className={styles.row}
          justify="start"
          align="middle"
          gutter={'sm'}
        >
          <ProductDetailLink slug={variant.slug} id={variant.id}>
            <img
              className={styles.image}
              src={image}
              alt={product.name}
              loading="lazy"
            />
          </ProductDetailLink>

          <div className={styles.infoColumn}>
            <div className={styles.productName}>
              <ProductDetailLink slug={variant.slug} id={variant.id}>
                <LYSTypography.Text strong={true}>
                  {product.name}
                </LYSTypography.Text>
                <br />
              </ProductDetailLink>
            </div>
            <div>
              <LYSTypography.Text>{product.variant.name}</LYSTypography.Text>
            </div>
          </div>
        </LYSRow>
      </LYSList.Item>
    </>
  )
}

export default CartPriceRequestItem
