import CartEventsModal from '@core/components/Cart/CartEventsModal'
import PriceRequestModalOverview from '@core/components/ProductCollection/PriceRequestStatusComponents/PriceRequestModalOverview'
import PriceRequestModalPhoneNumber from '@core/components/ProductCollection/PriceRequestStatusComponents/PriceRequestModalPhoneNumber'
import useModals from '@core/hooks/modals/useModals'
import { ModalType } from '@core/store/modals/actions'

export const PageOverlays: React.FC = () => {
  const { activeModal } = useModals()

  return (
    <>
      {activeModal === ModalType.PRICE_REQUEST && <PriceRequestModalOverview />}
      {activeModal === ModalType.PRICE_REQUEST_PHONE_NUMBER && (
        <PriceRequestModalPhoneNumber />
      )}

      {activeModal === ModalType.CART_UPDATE && <CartEventsModal />}
    </>
  )
}
