import { Button } from 'antd'
import { ButtonProps } from 'antd/lib/button'
import cn from 'classnames'
import style from './LYSButton.module.less'
export interface LYSButtonProps extends ButtonProps {
  withoutPadding?: boolean
  actionButtonStyle?: boolean
}

const LYSButton: React.FC<LYSButtonProps> = (props) => {
  const { withoutPadding, actionButtonStyle, className, ...remainingProps } =
    props
  const classNames = cn(
    withoutPadding && style.withoutPadding,
    actionButtonStyle && style.actionButtonStyle,
    className
  )
  return <Button className={classNames} {...remainingProps} />
}

export default LYSButton
