import {
  IProductListState,
  ProductListSortOption,
} from '@core/store/productList/types'
import { moveItem } from '@core/utils/arrays'
import { Action, ActionTypes } from './actions'

const initialState: IProductListState = {
  isLoading: false,
  isError: false,
  productLists: [],
  sortOrder: ProductListSortOption.MANUAL,
}

// TODO add loading & error states
export default function productListReducer(
  state: IProductListState = initialState,
  action: Action
) {
  switch (action.type) {
    case ActionTypes.SET_PRODUCT_LISTS: {
      return {
        ...state,
        productLists: action.payload.productLists,
      }
    }
    case ActionTypes.SET_PRODUCT_LIST_POSITION: {
      const { productList, position } = action.payload

      const reorderedProductLists = [...state.productLists]
      moveItem(reorderedProductLists, productList, position)

      return {
        ...state,
        productLists: reorderedProductLists,
      }
    }
    case ActionTypes.SET_PRODUCT_LIST_SORT_ORDER: {
      const { sortOrder } = action.payload
      return { ...state, sortOrder }
    }
    default:
      return state
  }
}
