import React, { useState } from 'react'

import {
  LYSList,
  LYSAlert,
  LYSTypography,
  LYSButton,
} from '@core/components/Primitives'
import { useTranslation } from '@core/i18n/i18n'
import styles from './index.module.less'
import CartPriceRequestItem from '../CartPriceRequestItem'
import useCart from '@core/hooks/cart/useCart'
import usePriceRequests from '@core/hooks/priceRequest/usePriceRequests'
import LYSStaticImage from '@core/components/Primitives/LYSStaticImage'
import config from '@core/config/config'

const CartPriceRequestList: React.FC = () => {
  const [showSuccess, setShowSuccess] = useState(false)
  const { t } = useTranslation()
  const { eligiblePriceRequestItems, isCartUpdatePending, removeFromCart } =
    useCart()
  const { create } = usePriceRequests()
  const handlePriceRequest = () => {
    eligiblePriceRequestItems.map(async (item) => {
      await create(item.product.variant.id)
      await removeFromCart(item)
      setShowSuccess(true)
    })
  }
  if (eligiblePriceRequestItems.length < 1) return null
  return (
    <LYSAlert
      className={styles.cartPriceRequestHint}
      message={''}
      type={showSuccess ? 'success' : 'info'}
      description={
        showSuccess ? (
          <LYSTypography.Text>{t`cart.priceRequest.success`}</LYSTypography.Text>
        ) : (
          <>
            <LYSTypography.Text>{t`cart.noPrice.title`}</LYSTypography.Text>
            <LYSList bordered className={styles.priceRequestList}>
              {eligiblePriceRequestItems?.map((item) => {
                return (
                  <CartPriceRequestItem
                    key={`no-price-${item.product.id}`}
                    item={item}
                  />
                )
              })}
            </LYSList>
            <div className={styles.button}>
              <LYSButton
                size="small"
                type="primary"
                onClick={() => handlePriceRequest()}
                disabled={isCartUpdatePending}
              >
                <span
                  style={{
                    marginRight: '5px',
                  }}
                >
                  <LYSStaticImage
                    src={config.assets.priceRequest}
                    alt={'Illustration'}
                    width={10}
                    height={10}
                  />
                </span>
                {t`priceRequest.cta`}
              </LYSButton>
            </div>
          </>
        )
      }
    />
  )
}

export default CartPriceRequestList
