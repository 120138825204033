import { Cache } from '@core/utils/caching/Cache'
import CookiesFacade from '@core/utils/cookies'
import CorePlatform from '../../../core/api/Platform/index'
import ContactForm from '../ContactForm'

interface IPlatformOptions {
  cookies: CookiesFacade
  apiKey: string
  apiUrl: string
  apiTimeout: number
  cache?: Cache
}

class Platform extends CorePlatform {
  public contactForm: ContactForm

  constructor(options: IPlatformOptions) {
    super(options)

    this.contactForm = new ContactForm(this.api, this.cookies)
  }
}

export default Platform
