export interface IRecommendation {
  type: RecommendationTypes
  recommendations: IRecommendationVariant[]
}

export interface IRecommendationVariant {
  score: number // minimum: 0
  variantId: string
  variantSku: string
  type: string
  string?: string
}

export enum RecommendationTypes {
  MOST_BOUGHT_VARIANT = 'most_bought_variant',
  PRODUCT_LIST = 'product_list',
  RECENT_ORDERS = 'recent_orders',
  LAST_VIEWED_VARIANTS = 'last_viewed_variants',
  LAST_SEARCHED_TERMS = 'last_searched_terms',
  FREQUENTLY_BOUGHT_TOGETHER = 'frequently_bought_together',
}
