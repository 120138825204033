import Prismic from 'prismic-javascript'
import { IChannel, RegistrationTypes } from '@core/api/Channel/types'
import { IPageLayout } from '@core/prismic/types'
import { getUserChannel } from '@core/store/user/selectors'
import CorePrismicApi from '@original/prismic/PrismicApi'
import config from '../config/config'
import getCustomChannelPrismicUrl from './getChannelPrismicUrl'

export const KNOWN_SLUGS = {
  LANDING_PAGE: 'landing-page',
  SALES_CONDITIONS: 'allgemeine-verkaufsbedingungen',
  CONTACT: 'kontakt',
  DELIVERY: 'lieferung',
}

const getCacheKey = (contentType: string, query: string) =>
  `prismic|${contentType}|${query}`

export default class IgefaPrismicApi extends CorePrismicApi {
  async getPageLayout(pageSlug: string): Promise<IPageLayout | undefined> {
    const channel = this.store && getUserChannel(this.store.getState())

    const channelPrismicUrl = getCustomChannelPrismicUrl(channel)

    const defaultApi = await Prismic.getApi(
      `https://${config.prismic.name}.cdn.prismic.io/api/v2`
    )
    const customApi =
      channelPrismicUrl && (await Prismic.getApi(channelPrismicUrl))

    const customizedQuery = getCustomizedPageLayoutQuery(pageSlug, channel)

    const cacheKey = getCacheKey('page_layout', JSON.stringify(customizedQuery))

    if (this.cache?.has(cacheKey)) {
      return this.cache!.get(cacheKey)
    }

    const customDocument =
      customApi &&
      (await customApi.queryFirst(customizedQuery, {
        // Fetch URLs for page links within slices
        fetchLinks: ['page_link.url', 'page_layout.page_url'],
      }))

    let defaultDocument = undefined

    if (!customDocument) {
      defaultDocument = await defaultApi.queryFirst(customizedQuery, {
        // Fetch URLs for page links within slices
        fetchLinks: ['page_link.url', 'page_layout.page_url'],
      })
    }

    const document = customDocument || defaultDocument

    if (this.cache) {
      this.cache.put(cacheKey, document?.data)
    }

    return document?.data
  }
}

export const getCustomizedPageLayoutQuery = (
  pageSlug: string,
  channel?: IChannel
) => {
  const query = [
    Prismic.Predicates.at('document.type', 'page_layout'),
    Prismic.Predicates.at('my.page_layout.page_url', pageSlug),
  ]

  if (!channel) return query

  switch (pageSlug) {
    case KNOWN_SLUGS.LANDING_PAGE:
    case KNOWN_SLUGS.SALES_CONDITIONS:
    case KNOWN_SLUGS.CONTACT:
    case KNOWN_SLUGS.DELIVERY:
      query.push(queryOpenClosedType(channel))
      break
  }

  return query
}

const queryOpenClosedType = (channel: IChannel) => {
  const tagName =
    channel.registrationType === RegistrationTypes.closed
      ? 'closed-shop'
      : 'open-shop'
  return Prismic.Predicates.at('document.tags', [tagName])
}
