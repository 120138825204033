import { ICart, ICartItem } from '@core/api/Cart/types'
import { CustomerTypes, RegistrationTypes } from '@core/api/Channel/types'
import { IProduct, IProductSearch } from '@core/api/Products/types'
import { IShopServices } from '@core/config/services'
import { IPurchaseEvent, purchaseEvent } from '@core/events/checkout'
import {
  IProductClickEvent,
  IProductCollectionViewEvent,
  productClickEvent,
  productCollectionViewEvent,
} from '@core/events/product'
import {
  IViewPageEvent,
  IViewProductEvent,
  viewPageEvent,
  viewProductEvent,
} from '@core/events/view'
import { ConverlyticsQueue } from '@core/modules/converlytics-tracking/ConverlyticsQueue'
import { getUserCart } from '@core/store/cart/selectors'
import { getAuthenticatedUser } from '@core/store/user/selectors'
import getSku from '@core/utils/models/getSku'
import trackingConfig from './config'
import { DataLayerPushable } from './types'
import {
  createBreadcrumbs,
  getCheckoutOption,
  getCheckoutStep,
  getCurrency,
  getList,
  getPaymentMethod,
} from './utils'

let previousCart: ICart | null = null

const setupCommerceTracking = (
  services: IShopServices,
  queue: ConverlyticsQueue
) => {
  const { eventBus, router, store } = services

  eventBus.subscribe<IViewProductEvent>(
    viewProductEvent.type,
    ({ payload }) => {
      const data = {
        event: trackingConfig.ecommerceEventNames.productView,
        ecommerce: {
          [trackingConfig.ecommerceEventProps.currencyCode]: getCurrency(store),
          [trackingConfig.ecommerceEventProps.productView]: {
            [trackingConfig.ecommerceEventProps.actionField]: {
              [trackingConfig.actionFieldProps.list]:
                trackingConfig.defaultListValue,
            },
            [trackingConfig.ecommerceEventProps.products]: [
              createProductDataFromProduct(services, {
                product: payload.product,
              }),
            ],
          },
        },
      }

      queue.push(data)
    }
  )

  eventBus.subscribe<IProductClickEvent>(
    productClickEvent.type,
    ({ payload }) => {
      const { product, options, pageContext } = payload
      const { isPromotion, promotionType } = options
      const list = getList(pageContext)

      const productData = createProductDataFromSearchItem(services, {
        item: product,
        list,
        position: 1,
      })

      if (isPromotion) {
        let prefix = trackingConfig.pageNamePrefixes.b2CShop as string
        const user = getAuthenticatedUser(store.getState())
        if (user) {
          prefix =
            user.channel.allowedCustomerTypes.indexOf(CustomerTypes.company) >
            -1
              ? (trackingConfig.pageNamePrefixes.b2BShop as string)
              : (trackingConfig.pageNamePrefixes.b2CShop as string)
        }
        productData.creative = `${prefix}.${promotionType}`
        productData.list = promotionType
      }

      const data = {
        event: isPromotion
          ? trackingConfig.ecommerceEventNames.promotionClick
          : trackingConfig.ecommerceEventNames.productClick,
        ecommerce: isPromotion
          ? {
              [trackingConfig.ecommerceEventProps.currencyCode]:
                getCurrency(store),
              [trackingConfig.ecommerceEventProps.promotionClick]: {
                [trackingConfig.ecommerceEventProps.promotions]: [productData],
              },
            }
          : {
              [trackingConfig.ecommerceEventProps.currencyCode]:
                getCurrency(store),

              [trackingConfig.ecommerceEventProps.productClick]: {
                [trackingConfig.ecommerceEventProps.actionField]: {
                  [trackingConfig.actionFieldProps.list]: list,
                },
                [trackingConfig.ecommerceEventProps.products]: [productData],
              },
            },
      }

      queue.push(data)
    }
  )

  eventBus.subscribe<IProductCollectionViewEvent>(
    productCollectionViewEvent.type,
    ({ payload }) => {
      const { productList, options, pageContext } = payload
      const { isPromotion, promotionType } = options
      let productData: ProductData[] = []
      const list = getList(pageContext)

      if (productList) {
        productData = productList.map((item, index) =>
          createProductDataFromSearchItem(services, {
            item,
            position: index + 1,
            list,
          })
        )
        if (isPromotion) {
          let prefix = trackingConfig.pageNamePrefixes.b2CShop as string
          const user = getAuthenticatedUser(store.getState())
          if (user) {
            prefix =
              user.channel.allowedCustomerTypes.indexOf(CustomerTypes.company) >
              -1
                ? (trackingConfig.pageNamePrefixes.b2BShop as string)
                : (trackingConfig.pageNamePrefixes.b2CShop as string)
          }
          productData.forEach((item) => {
            item.creative = `${prefix}.${promotionType}`
            item.list = promotionType
          })
        }
      }
      const data = {
        event: trackingConfig.ecommerceEventNames.productCollection,
        ecommerce: isPromotion
          ? {
              [trackingConfig.ecommerceEventProps.currencyCode]:
                getCurrency(store),
              [trackingConfig.ecommerceEventProps.promotionView]: {
                [trackingConfig.ecommerceEventProps.promotions]: productData,
              },
            }
          : {
              [trackingConfig.ecommerceEventProps.currencyCode]:
                getCurrency(store),
              [trackingConfig.ecommerceEventProps.impressions]: productData,
            },
      }

      queue.push(data)
    }
  )

  eventBus.subscribe<IViewPageEvent>(viewPageEvent.type, (_) => {
    if (
      trackingConfig.checkoutRoutes.indexOf(router.route) > -1 &&
      router.route !== trackingConfig.routeMapRoutes.checkoutConfirmation
    ) {
      const cart = getUserCart(store.getState())
      let productData: ProductData[] = []

      if (cart) {
        productData = cart.items.map((item, index) =>
          createProductDataFromCartItem(services, { item, position: index + 1 })
        )
      }

      const data = {
        event: trackingConfig.ecommerceEventNames.checkout,
        ecommerce: {
          [trackingConfig.ecommerceEventProps.currencyCode]: getCurrency(store),
          [trackingConfig.ecommerceEventProps.checkout]: {
            [trackingConfig.ecommerceEventProps.actionField]: {
              [trackingConfig.actionFieldProps.step]: getCheckoutStep(router),
              [trackingConfig.actionFieldProps.option]:
                getCheckoutOption(store),
            },
            [trackingConfig.ecommerceEventProps.products]: productData,
          },
        },
      }

      queue.push(data)
    }
  })

  eventBus.subscribe<IPurchaseEvent>(purchaseEvent.type, ({ payload }) => {
    const { order, orderCart } = payload
    if (orderCart.items.length > 0) {
      const itemsTax = order.items.reduce(
        (acc, cur) => (cur.price ? acc + cur.price.tax : acc + 0),
        0
      )
      const additionalTaxes = order.price.feesV2.reduce(
        (acc, cur) => acc + cur.amount.gross,
        0
      )
      const additionalFees = order.price.taxes.reduce(
        (acc, cur) => acc + cur.amount,
        0
      )
      const user = getAuthenticatedUser(store.getState())
      const data = {
        event: trackingConfig.ecommerceEventNames.purchase,
        ecommerce: {
          [trackingConfig.ecommerceEventProps.currencyCode]: getCurrency(store),
          [trackingConfig.ecommerceEventProps.purchase]: {
            [trackingConfig.ecommerceEventProps.actionField]: {
              [trackingConfig.actionFieldProps.step]: getCheckoutStep(router),
              [trackingConfig.actionFieldProps.option]:
                getCheckoutOption(store),
              [trackingConfig.actionFieldProps.action]: 'checkout',
              [trackingConfig.actionFieldProps.orderId]:
                order.purchaseOrderNumber,
              [trackingConfig.actionFieldProps.orderRevenue]:
                (order.price.subtotal.gross + order.price.shipping.gross) / 100,
              [trackingConfig.actionFieldProps.orderTax]: itemsTax / 100,
              [trackingConfig.actionFieldProps.orderShipping]:
                order.price.shipping.gross / 100,
              [trackingConfig.actionFieldProps.orderAdditionalTaxes]:
                additionalTaxes / 100,
              [trackingConfig.actionFieldProps.orderAdditionalFees]:
                additionalFees / 100,
              [trackingConfig.actionFieldProps.orderCoupon]:
                orderCart.voucherInformation
                  ? orderCart.voucherInformation.code
                  : '',
              dimension2: user
                ? trackingConfig.dimension2.existingCustomer
                : trackingConfig.dimension2.guestCustomer,
              dimension4: getPaymentMethod(order.paymentMethod),
              metric3: order.price.total.gross / 100,
              metric4: orderCart.voucherInformation
                ? orderCart.voucherInformation.absoluteDiscountValue / 100
                : 0,
            },
            [trackingConfig.ecommerceEventProps.products]: orderCart.items.map(
              (item) =>
                createProductDataFromCartItem(services, {
                  item,
                })
            ),
          },
        },
      }

      if (trackingConfig.isIgefaStore) {
        let dimension1 = trackingConfig.dimension1.openShop
        if (user) {
          dimension1 =
            user.channel.registrationType === RegistrationTypes.closed
              ? trackingConfig.dimension1.closedShop
              : trackingConfig.dimension1.openShop
        }
        // @ts-ignore
        data.ecommerce[trackingConfig.ecommerceEventProps.purchase][
          trackingConfig.ecommerceEventProps.actionField
        ].dimension1 = dimension1
      }

      queue.push(data)
    }
  })

  setupCartUpdateHandlers(services, queue)
}

interface ProductData {
  id: string // sku
  name: string
  brand: string
  category: string
  variant: string
  price: number
  quantity?: number //
  position?: number // present only in impression / promotion lists, cart and order
  dimension1?: string // only on igefa with product interactions
  dimension3?: string // product availability
  metric1?: number // product voucher value - only on purchase events
  metric2?: number // product original price - only on product view / add / remove / purchase
  list?: string // in case of impressions / promotions this holds category breadcrumbs
  creative?: string // in case of promotions
}

interface CreateProductFromProduct {
  product: IProduct
  quantity?: number
  position?: number
}

const createProductDataFromProduct = (
  services: IShopServices,
  data: CreateProductFromProduct
): ProductData => {
  const { router, store, productContextService } = services
  const { product, quantity, position } = data
  const productContext = productContextService.getProductDetailContext(
    product,
    product.mainVariant
  )

  const productData: ProductData = {
    id: getSku(product, product.mainVariant),
    name: product.name,
    brand: product.brand ? product.brand.name : '',
    category: createBreadcrumbs(product.breadcrumbs.hierarchy),
    variant: product.variationName || 'Standard',
    price: product.mainVariant.packagingUnits[0].price
      ? product.mainVariant.packagingUnits[0].price.net / 100
      : 0,
    dimension3: productContext.stock.isOutOfStock
      ? trackingConfig.dimension3.notAvailable
      : trackingConfig.dimension3.available,
    metric2: product.mainVariant.packagingUnits[0].price
      ? product.mainVariant.packagingUnits[0].price.net / 100
      : 0,
  }

  if (quantity) productData.quantity = quantity
  if (position) productData.position = position

  if (
    trackingConfig.isIgefaStore &&
    router.route === trackingConfig.routeMapRoutes.product
  ) {
    productData.dimension1 = trackingConfig.dimension1.openShop
    const user = getAuthenticatedUser(store.getState())
    if (user) {
      productData.dimension1 =
        user.channel.registrationType === RegistrationTypes.closed
          ? trackingConfig.dimension1.closedShop
          : trackingConfig.dimension1.openShop
    }
  }

  return productData
}

interface CreateProductFromSearchItem {
  item: IProductSearch | IProduct
  quantity?: number
  position?: number
  list?: string
}

const createProductDataFromSearchItem = (
  services: IShopServices,
  data: CreateProductFromSearchItem
): ProductData => {
  const { store } = services
  const { item, quantity, position, list } = data
  const breadcrumb =
    'breadcrumbs' in item
      ? // IProduct
        createBreadcrumbs(item.breadcrumbs.hierarchy)
      : // IProductSearch
        item.clientFields.converlyticsBreadcrumbs

  let isOutOfStock
  if ('stockInformation' in item.mainVariant) {
    isOutOfStock = item.mainVariant.stockInformation?.isOutOfStock
  } else if ('outOfStock' in item.mainVariant) {
    isOutOfStock = item.mainVariant.outOfStock
  } else {
    isOutOfStock = false
  }

  const productData: ProductData = {
    id: item.sku,
    name: item.name,
    brand: item.brand ? item.brand.name : '',
    category: breadcrumb,
    variant: item.mainVariant.name,
    price: item.mainVariant.price ? item.mainVariant.price.net / 100 : 0,
    dimension3: isOutOfStock
      ? trackingConfig.dimension3.notAvailable
      : trackingConfig.dimension3.available,
    metric2: item.mainVariant.price ? item.mainVariant.price.net / 100 : 0,
  }

  if (trackingConfig.isIgefaStore) {
    let dimension1 = trackingConfig.dimension1.openShop
    const user = getAuthenticatedUser(store.getState())
    if (user) {
      dimension1 =
        user.channel.registrationType === RegistrationTypes.closed
          ? trackingConfig.dimension1.closedShop
          : trackingConfig.dimension1.openShop
    }
    productData.dimension1 = dimension1
  }

  if (quantity) productData.quantity = quantity
  if (position) productData.position = position
  if (list) productData.list = list

  return productData
}

interface CreateProductFromCartItem {
  item: ICartItem
  position?: number
}

const createProductDataFromCartItem = (
  services: IShopServices,
  data: CreateProductFromCartItem
): ProductData => {
  const { store, productContextService } = services
  const { item, position } = data
  const productContext = productContextService.getProductCartItemContext(item)

  const productData: ProductData = {
    id: item.product.sku,
    name: item.product.name,
    brand: item.brandName || '',
    category: item.product.clientFields.converlyticsBreadcrumbs,
    variant: item.product.variant.name,
    price: item.price ? item.price.net / 100 : 0,
    quantity: item.quantity,
    dimension3: productContext.stock.isOutOfStock
      ? trackingConfig.dimension3.notAvailable
      : trackingConfig.dimension3.available,
    metric2: item.price ? item.price.net / 100 : 0,
  }

  if (trackingConfig.isIgefaStore) {
    let dimension1 = trackingConfig.dimension1.openShop
    const user = getAuthenticatedUser(store.getState())
    if (user) {
      dimension1 =
        user.channel.registrationType === RegistrationTypes.closed
          ? trackingConfig.dimension1.closedShop
          : trackingConfig.dimension1.openShop
    }
    productData.dimension1 = dimension1
  }

  if (position) productData.position = position

  return productData
}

interface CartUpdateData {
  add: DataLayerPushable | null
  remove: DataLayerPushable | null
}

const setupCartUpdateHandlers = (
  services: IShopServices,
  queue: ConverlyticsQueue
) => {
  // Subscribe to changes on cart
  const { store } = services
  store.subscribe(() => {
    const state = store.getState()
    const currentCart = getUserCart(state)

    // Do not track if there is no cart
    if (!currentCart) return

    // Do not track if there was no previous cart (cart just got loaded for the first time)
    if (!previousCart) {
      previousCart = currentCart
      return
    }

    // Do not track if cart id has changed (When carts are not the same due to login or logout)
    if (previousCart && currentCart && previousCart.id !== currentCart.id) {
      previousCart = currentCart
      return
    }

    // Track only when cart is the same
    if (previousCart && currentCart && previousCart.id === currentCart.id) {
      const { additions, removals } = getCartItemsUpdates(
        currentCart,
        previousCart
      )
      previousCart = currentCart
      const data: CartUpdateData = { add: null, remove: null }
      if (additions.length > 0) {
        const add = {
          event: trackingConfig.ecommerceEventNames.productAddToCart,
          ecommerce: {
            [trackingConfig.ecommerceEventProps.currencyCode]:
              getCurrency(store),
            [trackingConfig.ecommerceEventProps.productAddToCart]: {
              [trackingConfig.ecommerceEventProps.actionField]: {
                [trackingConfig.actionFieldProps.action]: trackingConfig
                  .ecommerceEventProps.productAddToCart as string,
                [trackingConfig.actionFieldProps.option]:
                  getCheckoutOption(store),
              },
              [trackingConfig.ecommerceEventProps.products]: additions.map(
                (item) => createProductDataFromCartItem(services, { item })
              ),
            },
          },
        }
        data.add = add
      }

      if (removals.length > 0) {
        const remove = {
          event: trackingConfig.ecommerceEventNames.productRemoveFromCart,
          ecommerce: {
            [trackingConfig.ecommerceEventProps.currencyCode]:
              getCurrency(store),
            [trackingConfig.ecommerceEventProps.productRemoveFromCart]: {
              [trackingConfig.ecommerceEventProps.actionField]: {
                [trackingConfig.actionFieldProps.step]: trackingConfig
                  .ecommerceEventProps.productRemoveFromCart as string,
                [trackingConfig.actionFieldProps.option]:
                  getCheckoutOption(store),
              },
              [trackingConfig.ecommerceEventProps.products]: removals.map(
                (item) => createProductDataFromCartItem(services, { item })
              ),
            },
          },
        }
        data.remove = remove
      }

      if (data.add) queue.push(data.add)
      if (data.remove) queue.push(data.remove)
    }
  })
}

const getCartItemsUpdates = (currentCart: ICart, previousCart: ICart) => {
  const additions: ICartItem[] = []
  const removals: ICartItem[] = []

  // compare current cart items with previous cart items
  //fisrt handling additions
  currentCart.items.forEach((currentItem) => {
    // looking for item on previous cart
    const previousItem = previousCart!.items.find(
      (item) => item.product.variant.id === currentItem.product.variant.id
    )
    if (previousItem) {
      // item found on previous cart, might have updated quantityt
      if (currentItem.quantity > previousItem.quantity) {
        // current ittem quantity has increased
        additions.push({
          ...currentItem,
          quantity: currentItem.quantity - previousItem.quantity,
        })
      }
    } else {
      // item not found on previous cart, needs to be added to cart
      additions.push({ ...currentItem })
    }
  })

  //second handling removals
  previousCart.items.forEach((previousItem) => {
    // looking for item on current cart
    const currentItem = currentCart.items.find(
      (item) => item.product.variant.id === previousItem.product.variant.id
    )
    if (currentItem) {
      // item found on previous cart, might have updated quantityt
      if (currentItem.quantity < previousItem.quantity) {
        // current ittem quantity has decreased
        removals.push({
          ...previousItem,
          quantity: previousItem.quantity - currentItem.quantity,
        })
      }
    } else {
      // item not found on previous cart, needs to be added to cart
      removals.push({ ...previousItem })
    }
  })
  return { additions, removals }
}

export default setupCommerceTracking
