import OriginalChannel from '@original/api/Channel'
import { IRequestClosedShopAccess } from './types'

class Channel extends OriginalChannel {
  public async requestClosedShopAccess(
    credentials: IRequestClosedShopAccess
  ): Promise<void> {
    await this.api.post<IRequestClosedShopAccess>(
      'channels/closed-shop-request',
      credentials
    )
  }
}

export default Channel
