import { Typography } from 'antd'
import { TypographyProps } from 'antd/lib/typography'
import cn from 'classnames'
import style from './LYSTypography.module.less'

interface IWithDecoratedProps {
  visualAppearance?: 'h1' | 'h2' | 'h3' | 'h4' | 'p'
  inheritColor?: boolean
  className?: string
}

type Props = TypographyProps & IWithDecoratedProps

export type ILYSTypographyProps = Props

interface StaticExports {
  Text: typeof DecoratedText
  Title: typeof DecoratedTitle
  Paragraph: typeof DecoratedParagraph
}

function constructClassNames(props: IWithDecoratedProps) {
  const { className, visualAppearance, inheritColor } = props
  const classNames = [
    inheritColor ? style.color_inherit : '',
    visualAppearance ? style[visualAppearance] : '',
    className,
  ]

  return cn(classNames)
}

function withDecoratedClassNames<P extends Record<string, unknown>>(
  Component: React.ComponentType<P>
) {
  const WithDecoratedClassNames: React.FC<P & IWithDecoratedProps> = ({
    visualAppearance,
    inheritColor,
    className,
    ...props
  }) => {
    return (
      <Component
        {...(props as P)}
        className={constructClassNames({
          visualAppearance,
          inheritColor,
          className,
        })}
      />
    )
  }
  return WithDecoratedClassNames
}

const DecoratedText = withDecoratedClassNames(Typography.Text)
const DecoratedTitle = withDecoratedClassNames(Typography.Title)
const DecoratedParagraph = withDecoratedClassNames(Typography.Paragraph)

const LYSTypography: React.FC<Props> & StaticExports = ({
  visualAppearance,
  inheritColor,
  className,
  ...props
}) => (
  <Typography
    {...props}
    className={constructClassNames({
      visualAppearance,
      inheritColor,
      className,
    })}
  />
)

LYSTypography.Text = DecoratedText
LYSTypography.Title = DecoratedTitle
LYSTypography.Paragraph = DecoratedParagraph

export default LYSTypography
