import * as React from 'react'
import { LYSLayout } from '@core/components/Primitives'
import Footer from './Footer'
import SecondaryFooter from './SecondaryFooter'
import ServiceFooter from './ServiceFooter'

const AppFooter: React.FC = () => {
  return (
    <LYSLayout.Footer>
      <ServiceFooter />
      <Footer />
      <SecondaryFooter />
    </LYSLayout.Footer>
  )
}

export default AppFooter
