import * as React from 'react'
import cn from 'classnames'
import { ICartPrice, IFeesV2, ITaxes } from '@core/api/Cart/types'
import { PriceTypes } from '@core/api/Channel/types'
import Price from '@core/components/Price'
import { LYSDivider, LYSTypography } from '@core/components/Primitives'
import config from '@core/config/config'
import useChannel from '@core/hooks/channel/useChannel'
import { useTranslation } from '@core/i18n/i18n'
import { template, RenderProp, Slot } from '@core/utils/templates'
import SummaryRow from './SummaryRow'
import style from './index.module.less'

export interface CartPriceSummarySlots {
  valueOfGoodsRow: RenderProp<void>
  shippingRow: RenderProp<void>
  feesRow: RenderProp<void>
  subtotalRow: RenderProp<void>
  voucherDiscountRow: RenderProp<void>
  taxesRow: RenderProp<void>
  totalRow: RenderProp<void>
}

export interface CartPriceSummaryProps {
  priceObject: ICartPrice
}

const CartPriceSummary = template<CartPriceSummaryProps, CartPriceSummarySlots>(
  ({ priceObject, slots }) => {
    const { t } = useTranslation()
    const {
      valueOfGoods,
      subtotalWithFees,
      total,
      shipping,
      taxes,
      feesV2,
      voucherDiscount,
    } = priceObject
    const { userChannel } = useChannel()
    const { displayedPriceType } = userChannel!

    return (
      <div>
        <Slot render={slots?.valueOfGoodsRow}>
          <SummaryRow
            label={t(`cart.price.valueOfGoods.${displayedPriceType}`)}
            amount={
              displayedPriceType === PriceTypes.net
                ? valueOfGoods.net
                : valueOfGoods.gross
            }
          />
        </Slot>

        <Slot render={slots?.shippingRow}>
          <SummaryRow
            label={
              config.features.clickAndCollectEnabled
                ? t(`clickAndCollect.cart.price.shipping.${displayedPriceType}`)
                : t(`cart.price.shipping.${displayedPriceType}`)
            }
            amount={shipping[displayedPriceType]}
          />
        </Slot>

        <Slot render={slots?.feesRow}>
          {feesV2 &&
            feesV2.map((fee: IFeesV2) => (
              <SummaryRow
                label={fee.label}
                amount={fee.amount[displayedPriceType]}
                key={fee.label}
              />
            ))}
        </Slot>

        <Slot render={slots?.subtotalRow}>
          <SummaryRow
            label={t(`product.price.subtotal.${displayedPriceType}`)}
            amount={subtotalWithFees[displayedPriceType]}
          />
        </Slot>

        <Slot render={slots?.voucherDiscountRow}>
          {voucherDiscount && (
            <SummaryRow
              label={t(`cart.price.voucherDiscount.${displayedPriceType}`)}
              amount={voucherDiscount[displayedPriceType]}
              isDiscount={true}
            />
          )}
        </Slot>

        <LYSDivider className={style.divider} />

        <div className={style.totalHolder}>
          <Slot render={slots?.taxesRow}>
            {Array.isArray(taxes) &&
              taxes.map((tax: ITaxes) => (
                <SummaryRow
                  label={t(`cart.price.tax.${displayedPriceType}`, {
                    VATValue: tax.label,
                  })}
                  amount={tax.amount}
                  key={tax.label}
                  isSecondaryText={true}
                />
              ))}
          </Slot>
          <Slot render={slots?.totalRow}>
            <div className={cn(style.row, style.total)}>
              <LYSTypography.Text strong={true}>
                {t('product.price.total.gross')}
              </LYSTypography.Text>
              <LYSTypography.Text data-testid="cart-price-sum" strong={true}>
                <Price value={total.gross} />
              </LYSTypography.Text>
            </div>
          </Slot>
        </div>
      </div>
    )
  }
)

export default CartPriceSummary
