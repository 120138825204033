import { logging } from '@core/utils/logging'
/**
 * linkResolver is a prismic-dom specific concept that allows to customize how
 * document links are interpreted. Extracted as a separate function for potential
 * customization
 * @param doc
 */
const linkResolver = (doc: PrismicDocumentLink) => {
  switch (doc.type) {
    case 'page_layout': {
      const url = doc.data?.page_url || doc.uid || '/'
      return `/page/${url}`
    }
    case 'page_link':
      return doc.data?.url || '/'
    default:
      logging.warn(`Link to unsupported document type: ${doc.type}`)
      return '/'
  }
}

export default linkResolver
