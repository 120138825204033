import BaseAPI from '@core/api/Platform/BaseAPI'
import { INewsletterSubscription } from './types'

class NewsletterSubscription extends BaseAPI {
  public async subscribe(data: INewsletterSubscription) {
    await this.api.post('/newsletter-subscriptions', data)
  }
  public async unsubscribe(id: string) {
    await this.api.delete(`/newsletter-subscriptions/${id}`, {
      genericUrl: 'newsletter-subscriptions/{id}',
    })
  }
}
export default NewsletterSubscription
