import * as React from 'react'
import { MailOutlined } from '@ant-design/icons'
import {
  LYSCol,
  LYSRow,
  LYSTypography,
  LYSIcon,
} from '@core/components/Primitives'
import useChannel from '@core/hooks/channel/useChannel'
import useCurrentUser from '@core/hooks/user/useCurrentUser'
import { useTranslation } from '@core/i18n/i18n'
import { getContactEmail } from '@core/utils/contactInformation'
import config from '../../config/config'
import ServiceFooterContactInfo from './ServiceFooterContactInfo'
import style from './index.module.less'

const ServiceFooter: React.FC = () => {
  const { t } = useTranslation()
  const { userChannel, isClosedChannel, isOpenChannel } = useChannel()
  const { user } = useCurrentUser()

  const serviceMail = getContactEmail(userChannel)

  const currentUser = user.user || user.anonymous

  const renderOpenChannelInfo = () => (
    <>
      <div className={style.serviceImage}>
        <img src={config.assets.serviceImage} loading="lazy" alt={'service'} />
      </div>
      <LYSTypography.Title level={3}>
        {t('serviceFooter.title2')}
      </LYSTypography.Title>
      <div>
        <a href={`mailto:${serviceMail}`}>
          <LYSIcon component={MailOutlined} />{' '}
          <LYSTypography.Text>{serviceMail}</LYSTypography.Text>
        </a>
      </div>
    </>
  )

  const renderClosedShopContacts = () => {
    const fieldSalesContact = currentUser?.fieldSalesContacts[0]
    const inHouseContact = currentUser?.inHouseContacts[0]
    const hasContacts = !!fieldSalesContact || !!inHouseContact

    if (!hasContacts) {
      return (
        <ServiceFooterContactInfo
          fullCol={true}
          contact={{
            id: '',
            name: userChannel?.name || t`footer.contactText`,
            clientFields: {
              position: '',
              phonenumber: userChannel?.clientFields?.phonenumber,
              fax: userChannel?.clientFields?.fax,
              email: getContactEmail(userChannel),
              image: '',
            },
          }}
        />
      )
    }

    return (
      <>
        {!!fieldSalesContact && (
          <ServiceFooterContactInfo
            fullCol={!inHouseContact}
            contact={fieldSalesContact}
          />
        )}
        <LYSCol xs={24} md={0}>
          <br />
        </LYSCol>
        {!!inHouseContact && (
          <ServiceFooterContactInfo
            fullCol={!fieldSalesContact}
            contact={inHouseContact}
          />
        )}
      </>
    )
  }

  return (
    <div className={style.serviceFooter}>
      {isOpenChannel && renderOpenChannelInfo()}
      {isClosedChannel && (
        <>
          <LYSTypography.Title level={3}>
            {t('serviceFooter.title')}
          </LYSTypography.Title>

          <LYSTypography.Paragraph>
            {t(`serviceFooter.subtitle.${userChannel?.registrationType}`)}
          </LYSTypography.Paragraph>
          {isClosedChannel && (
            <div className={style.serviceImage}>
              <img
                src={config.assets.serviceImage}
                loading="lazy"
                alt={'service'}
              />
            </div>
          )}
          <LYSTypography.Paragraph>
            {t('serviceFooter.openingHours')}
          </LYSTypography.Paragraph>
          <LYSRow> {renderClosedShopContacts()}</LYSRow>
        </>
      )}
    </div>
  )
}

export default ServiceFooter
