import routes from '@core/config/routes'
import Link from 'next/link'

export const openShopNotification = (
  <>
    Bestellung nur möglich als Servicepartner.{' '}
    <Link {...routes.registerClosedShop}>
      Jetzt einloggen oder Servicepartner werden.
    </Link>
  </>
)
