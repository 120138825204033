import { Card } from 'antd'
import { CardProps } from 'antd/lib/card'
import cn from 'classnames'
import style from './index.module.less'

/**
 * See here for full docs: https://github.com/react-component/menu#api
 */
interface StaticExports {
  Grid: typeof Card.Grid
}

interface OwnProps {
  withBoxShadow?: boolean
}

const LYSCard: React.FC<OwnProps & CardProps> & StaticExports = (props) => {
  const { withBoxShadow, className, ...remainingProps } = props

  return (
    <Card
      {...remainingProps}
      className={cn(withBoxShadow && style.withBoxShadow, className)}
    />
  )
}

LYSCard.Grid = Card.Grid

export default LYSCard
