import { KeyboardEvent } from 'react'
import first from 'lodash/first'
import {
  MenuContext,
  MenuItemDefinition,
} from '@core/components/shared/menu/MenuBehaviour/types'
import {
  focusMenuItem,
  getMenuPath,
} from '@core/components/shared/menu/MenuBehaviour/util'

export const keyboardNavigation = (
  menu: MenuContext,
  item: MenuItemDefinition
) => {
  const menuPath = getMenuPath(menu)

  const navigateNext = () => {
    const currentIndex = menu.items.findIndex((i) => i.id === item.id)
    const nextIndex =
      currentIndex >= 0 ? (currentIndex + 1) % menu.items.length : 0
    const nextItem = menu.items[nextIndex]

    focusMenuItem(menuPath, nextItem)
  }

  const navigatePrevious = () => {
    const currentIndex = menu.items.findIndex((i) => i.id === item.id)
    const nextIndex =
      currentIndex >= 0
        ? (currentIndex - 1 + menu.items.length) % menu.items.length
        : 0
    const nextItem = menu.items[nextIndex]

    focusMenuItem(menuPath, nextItem)
  }

  const navigateDown = () => {
    const subMenu = menu.subMenus[item.id]

    if (!subMenu) return

    const subMenuPath = getMenuPath(subMenu)
    const firstItem = first(subMenu.items)

    focusMenuItem(subMenuPath, firstItem)
  }

  const navigateUp = () => {
    menu.select(undefined)

    const parentMenuPath = getMenuPath(menu.parent)
    const selectedParentItem = menu.parent?.selectedItem
      ? menu.parent.items.find(
          (item) => item.id === menu.parent?.selectedItem?.id
        )
      : first(menu.parent?.items)

    if (!selectedParentItem) return
    focusMenuItem(parentMenuPath, selectedParentItem)
  }

  return {
    handle: (e: KeyboardEvent<HTMLElement>) => {
      // Down arrow key
      if (e.keyCode === 40) {
        if (menu.keyboardNavigationDirection === 'horizontal') {
          navigateDown()
        } else {
          navigateNext()
        }
        e.preventDefault()
        e.stopPropagation()
      }
      // Up arrow key
      if (e.keyCode === 38) {
        if (menu.keyboardNavigationDirection === 'horizontal') {
          navigateUp()
        } else {
          navigatePrevious()
        }
        e.preventDefault()
        e.stopPropagation()
      }
      // Right arrow key
      if (e.keyCode === 39) {
        if (menu.keyboardNavigationDirection === 'horizontal') {
          navigateNext()
        } else {
          navigateDown()
        }
        e.preventDefault()
        e.stopPropagation()
      }
      // Left arrow key
      if (e.keyCode === 37) {
        if (menu.keyboardNavigationDirection === 'horizontal') {
          navigatePrevious()
        } else {
          navigateUp()
        }
        e.preventDefault()
        e.stopPropagation()
      }
      // Escape
      if (e.keyCode === 27) {
        menu.close()
      }
    },
  }
}
