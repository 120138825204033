import { ICartItem, ICartItemVariantInformation } from '@core/api/Cart/types'
import {
  IPriceRequest,
  IPriceRequestVariant,
} from '@core/api/PriceRequests/types'
import {
  IProductListItem,
  IProductListItemVariantInformation,
} from '@core/api/ProductLists/types'
import {
  IProduct,
  IProductSearch,
  IVariant,
  IVariantSearch,
} from '@core/api/Products/types'
import { getDefaultRules } from '@core/utils/models/product/defaultRules'
import {
  ProductCartItemContext,
  ProductDetailContext,
  ProductContextType,
  ProductListItemContext,
  ProductSearchContext,
  ProductPriceRequestContext,
} from '@core/utils/models/product/types'

export const getPurchasablePackagingUnitFromVariant = (
  variant:
    | IVariant
    | IVariantSearch
    | IProductListItemVariantInformation
    | ICartItemVariantInformation
    | IPriceRequestVariant
) => {
  const purchaseablePackagingUnit = variant.packagingUnits.find(
    (packagingUnit) => packagingUnit.purchasable && packagingUnit
  )
  return purchaseablePackagingUnit ?? variant.packagingUnits[0]
}

export class ProductContextService {
  getProductDetailContext(
    product: IProduct,
    variant: IVariant
  ): ProductDetailContext {
    const packagingUnit = getPurchasablePackagingUnitFromVariant(variant)
    return {
      type: ProductContextType.ProductDetail,
      product,
      variant,
      rules: getDefaultRules({
        hasPrice: !!packagingUnit?.price,
        isOutOfStock: packagingUnit?.stockInformation?.isOutOfStock ?? false,
        isDiscontinued:
          packagingUnit?.stockInformation?.isDiscontinued ?? false,
        isPurchasable: packagingUnit?.purchasable ?? false,
      }),
      stock: packagingUnit?.stockInformation ?? {
        isOutOfStock: false,
        isDiscontinued: false,
      },
      packagingUnitsInformation: {
        packagingUnit,
        packagingUnits: variant.packagingUnits,
      },
    }
  }

  getProductSearchContext(
    product: IProductSearch,
    variant: IVariantSearch
  ): ProductSearchContext {
    const packagingUnit = getPurchasablePackagingUnitFromVariant(variant)
    return {
      type: ProductContextType.ProductSearch,
      product,
      variant,
      rules: getDefaultRules({
        hasPrice: !!packagingUnit?.price,
        isOutOfStock: packagingUnit.outOfStock ?? false,
        isDiscontinued: variant.isDiscontinued ?? false,
        isPurchasable: packagingUnit?.purchasable ?? false,
      }),
      stock: {
        isOutOfStock: packagingUnit.outOfStock,
        isDiscontinued: variant.isDiscontinued,
      },
      packagingUnitsInformation: {
        packagingUnit: packagingUnit,
        packagingUnits: variant.packagingUnits,
      },
    }
  }

  getProductListItemContext(
    productListItem: IProductListItem
  ): ProductListItemContext {
    const packagingUnit = getPurchasablePackagingUnitFromVariant(
      productListItem.product.variant
    )

    return {
      type: ProductContextType.ProductListItem,
      product: productListItem.product,
      variant: productListItem.product.variant,
      productListItem,
      rules: getDefaultRules({
        hasPrice: !!packagingUnit?.price,
        isOutOfStock: packagingUnit?.stockInformation?.isOutOfStock ?? false,
        isDiscontinued:
          packagingUnit?.stockInformation?.isDiscontinued ?? false,
        isPurchasable: packagingUnit?.purchasable ?? false,
      }),
      stock: packagingUnit?.stockInformation ?? {
        isOutOfStock: false,
        isDiscontinued: false,
      },
      packagingUnitsInformation: {
        packagingUnit: productListItem.product.variant.packagingUnits[0],
        packagingUnits: productListItem.product.variant.packagingUnits,
      },
    }
  }

  getProductPriceRequestContext(
    priceRequest: IPriceRequest
  ): ProductPriceRequestContext {
    const packagingUnit = getPurchasablePackagingUnitFromVariant(
      priceRequest.productInformation.mainVariant
    )
    return {
      type: ProductContextType.ProductPriceRequest,
      product: priceRequest.productInformation,
      variant: priceRequest.productInformation.mainVariant,
      rules: getDefaultRules({
        hasPrice: !!packagingUnit?.price,
        isOutOfStock: packagingUnit?.stockInformation?.isOutOfStock ?? false,
        isDiscontinued:
          packagingUnit?.stockInformation?.isDiscontinued ?? false,
        isPurchasable: packagingUnit?.purchasable ?? false,
      }),
      stock: packagingUnit?.stockInformation ?? {
        isOutOfStock: false,
        isDiscontinued: false,
      },
      packagingUnitsInformation: {
        packagingUnit:
          priceRequest.productInformation.mainVariant.packagingUnits[0],
        packagingUnits: [],
      },
      createdAt: priceRequest.createdAt,
    }
  }

  getProductCartItemContext(cartItem: ICartItem): ProductCartItemContext {
    const packagingUnit = getPurchasablePackagingUnitFromVariant(
      cartItem.product.variant
    )
    return {
      type: ProductContextType.ProductCartItem,
      product: cartItem.product,
      variant: cartItem.product.variant,
      cartItem,
      rules: getDefaultRules({
        hasPrice: !!packagingUnit?.price,
        isOutOfStock: packagingUnit?.stockInformation?.isOutOfStock ?? false,
        isDiscontinued:
          packagingUnit?.stockInformation?.isDiscontinued ?? false,
        isPurchasable: packagingUnit?.purchasable ?? false,
      }),
      stock: packagingUnit?.stockInformation ?? {
        isOutOfStock: false,
        isDiscontinued: false,
      },
      packagingUnitsInformation: {
        packagingUnit: packagingUnit,
        packagingUnits: cartItem.product.variant.packagingUnits,
      },
    }
  }
}
