import { IPaymentMethod, IShippingMethod } from '@core/api/Cart/types'
import config from '@core/config/config'

export const getPaymentMethodImage = (method: IPaymentMethod) => {
  const { id, type } = method
  return (
    config.assets.paymentMethods[
      id as keyof typeof config.assets.paymentMethods
    ] ||
    config.assets.paymentMethods[
      type as keyof typeof config.assets.paymentMethods
    ] ||
    config.assets.paymentMethods.default
  )
}

export const getShippingMethodImage = (method: IShippingMethod) => {
  const { id, type } = method
  return (
    config.assets.shippingMethods[
      id as keyof typeof config.assets.shippingMethods
    ] ||
    config.assets.shippingMethods[
      type as keyof typeof config.assets.shippingMethods
    ] ||
    config.assets.shippingMethods.default
  )
}

export const getGhsClassImage = (type: string) => {
  return (
    config.assets.ghsClasses[type as keyof typeof config.assets.ghsClasses] ||
    false
  )
}
