import { AxiosError } from 'axios'
import {
  IBillingInformation,
  IBillingInformationCollection,
  IRegisterAccount,
  IShippingAddress,
  ICustomerSelfUpdate,
  ICustomerTeamMember,
  IInviteTeamMemberPayload,
  ShippingAddressStatus,
} from '@core/api/Customer/types'
import { Unsaved, ApiValidationError } from '@core/api/types'
import {
  showNotification,
  showErrorNotification,
} from '@core/components/Notification'
import config from '@core/config/config'
import { registrationEvent } from '@core/events/auth'
import thunk from '@core/store/thunk'
import { logging } from '@core/utils/logging'
import { sanitizeModel } from '@core/utils/models/sanitizeModel'
import { i18n } from '../../i18n/i18n'

export enum ActionTypes {
  SET_SHIPPING_ADDRESSES = 'CUSTOMER/SET_SHIPPING_ADDRESSES',
  SET_BILLING_INFORMATION = 'CUSTOMER/SET_BILLING_INFORMATION',
  SET_TEAM_MEMBERS = 'CUSTOMER/SET_TEAM_MEMBERS',
  SET_IS_ACCOUNT_OWNER = 'CUSTOMER/SET_IS_ACCOUNT_OWNER',
}

export interface ISetBillingInformation {
  type: ActionTypes.SET_BILLING_INFORMATION
  payload: {
    addresses: IBillingInformation[]
  }
}

export interface ISetShippingAddresses {
  type: ActionTypes.SET_SHIPPING_ADDRESSES
  payload: {
    addresses: IShippingAddress[]
  }
}

export interface ISetTeamMembers {
  type: ActionTypes.SET_TEAM_MEMBERS
  payload: {
    teamMembers: ICustomerTeamMember[]
  }
}

export interface ISetIsAccountOwner {
  type: ActionTypes.SET_IS_ACCOUNT_OWNER
  payload: {
    isOwner: boolean
  }
}

export const register = (credentials: IRegisterAccount) =>
  thunk(async (_dispatch, _getState, dependencies) => {
    try {
      credentials = sanitizeModel(credentials)
      await dependencies.api.customer.register(credentials)
      dependencies.eventBus.publish(registrationEvent(credentials))
    } catch (error) {
      showErrorNotification(error, 'error.default')
      throw error
    }
  })

export const updateCustomer = (customer: ICustomerSelfUpdate) =>
  thunk(async (_dispatch, _getState, dependencies) => {
    try {
      customer = sanitizeModel(customer)
      await dependencies.api.customer.updateCustomer(customer)
    } catch (error) {
      logging.error(error, { customer })
    }
  })

export const switchChannel = (channelId: string) =>
  thunk(async (_dispatch, _getState, dependencies) => {
    try {
      const {
        features: { channelSwitchEffect },
      } = config

      await dependencies.api.customer.switchChannel(channelId)

      if (channelSwitchEffect.redirect) {
        await dependencies.router.navigate(channelSwitchEffect.redirectUrl)
      } else {
        await dependencies.router.reload()
      }
    } catch (error) {
      showErrorNotification(error, 'error.default')
      throw error
    }
  })

export const getShippingAddresses = () =>
  thunk(async (dispatch, _getState, dependencies) => {
    try {
      const [{ addresses: activeAddresses }, { addresses: inactiveAddresses }] =
        await Promise.all([
          dependencies.api.customer.getShippingAddresses(
            ShippingAddressStatus.ACTIVE
          ),
          dependencies.api.customer.getShippingAddresses(
            ShippingAddressStatus.INACTIVE
          ),
        ])
      dispatch(setShippingAddresses([...activeAddresses, ...inactiveAddresses]))
    } catch (error) {
      logging.error(error)
    }
  })

export const setShippingAddresses = (
  addresses: IShippingAddress[]
): ISetShippingAddresses => ({
  type: ActionTypes.SET_SHIPPING_ADDRESSES,
  payload: {
    addresses,
  },
})

export const createShippingAddress = (
  address: Unsaved<IShippingAddress>,
  setAsDefaultShippingAddress?: boolean,
  disableNotification?: boolean
) =>
  thunk(async (dispatch, _getState, dependencies) => {
    address = sanitizeModel(address)

    try {
      const savedAddress =
        await dependencies.api.customer.createShippingAddress(address)

      if (!disableNotification) {
        showNotification(
          i18n.t('me.addresses.createSuccessNotification'),
          'success'
        )
      }

      if (setAsDefaultShippingAddress) {
        try {
          await dependencies.api.customer.setDefaultShippingAddress(
            savedAddress.id
          )
        } catch (error) {
          logging.error(error, {
            address,
            setAsDefaultShippingAddress,
            disableNotification,
          })

          showErrorNotification(
            error,
            'me.addresses.setDefaultErrorNotification'
          )
        }
      }
      dispatch(getShippingAddresses())
      return savedAddress
    } catch (error) {
      if (!disableNotification) {
        showErrorNotification(error, 'me.addresses.createErrorNotification')
      }

      throw error
    }
  })

export const updateShippingAddress = (
  address: IShippingAddress,
  setAsDefaultShippingAddress?: boolean
) =>
  thunk(async (dispatch, _getState, dependencies) => {
    address = sanitizeModel(address)

    try {
      const savedAddress =
        await dependencies.api.customer.updateShippingAddress(address)

      showNotification(
        i18n.t('me.addresses.updateSuccessNotification'),
        'success'
      )

      if (setAsDefaultShippingAddress) {
        try {
          await dependencies.api.customer.setDefaultShippingAddress(address.id)
        } catch (error) {
          logging.error(error, {
            address,
            setAsDefaultShippingAddress,
          })

          showErrorNotification(
            error,
            'me.addresses.setDefaultErrorNotification'
          )
        }
      }

      dispatch(getShippingAddresses())
      return savedAddress
    } catch (error) {
      logging.error(error)

      showErrorNotification(error, 'me.addresses.updateErrorNotification')

      throw error
    }
  })

export const setDefaultShippingAddress = (id: string) =>
  thunk(async (dispatch, _getState, dependencies) => {
    try {
      await dependencies.api.customer.setDefaultShippingAddress(id)

      showNotification(
        i18n.t('me.addresses.setDefaultSuccessNotification'),
        'success'
      )

      dispatch(getShippingAddresses())
    } catch (error) {
      logging.error(error, { id })

      showErrorNotification(error, 'me.addresses.setDefaultErrorNotification')
    }
  })

export const activateShippingAddress = (id: string) =>
  thunk(async (dispatch, __, dependencies) => {
    try {
      await dependencies.api.customer.activateShippingAddress(id)
      await dispatch(getShippingAddresses())

      showNotification(
        i18n.t('me.addresses.activateSuccessNotification'),
        'success'
      )
    } catch (error) {
      logging.error(error)
      showErrorNotification(error, i18n.t('error.default'))
    }
  })

export const deactivateShippingAddress = (id: string) =>
  thunk(async (dispatch, __, dependencies) => {
    try {
      await dependencies.api.customer.deactivateShippingAddress(id)
      await dispatch(getShippingAddresses())

      showNotification(
        i18n.t('me.addresses.deactivateSuccessNotification'),
        'success'
      )
    } catch (error) {
      logging.error(error)
      showErrorNotification(error, i18n.t('error.default'))
    }
  })

export const getBillingAddresses = () =>
  thunk(async (dispatch, _getState, dependencies) => {
    try {
      await dependencies.api.customer
        .getBillingInformation()
        .then((result: IBillingInformationCollection) => {
          dispatch(setBillingInformation(result.addresses))
        })
    } catch (error) {
      logging.error(error)
    }
  })

export const setBillingInformation = (
  addresses: IBillingInformation[]
): ISetBillingInformation => ({
  type: ActionTypes.SET_BILLING_INFORMATION,
  payload: {
    addresses,
  },
})

export const deleteShippingAddress = (id: string) =>
  thunk(async (dispatch, _getState, dependencies) => {
    try {
      await dependencies.api.customer.deleteShippingAddress(id)

      showNotification(
        i18n.t('me.addresses.deleteSuccessNotification'),
        'success'
      )

      dispatch(getShippingAddresses())
    } catch (error) {
      logging.error(error, { id })

      showErrorNotification(error, 'me.addresses.deleteErrorNotification')
    }
  })

export const createBillingInformation = (
  information: Unsaved<IBillingInformation>,
  disableNotification?: boolean
) =>
  thunk(async (dispatch, _getState, dependencies) => {
    information = sanitizeModel(information)
    try {
      const savedBillingInformation =
        await dependencies.api.customer.createBillingInformation(information)

      if (!disableNotification) {
        showNotification(
          i18n.t('me.billingInformation.createSuccessNotification'),
          'success'
        )
      }

      await dispatch(getBillingAddresses())
      return savedBillingInformation
    } catch (error) {
      logging.error(error, {
        information,
        disableNotification,
      })

      showErrorNotification(
        error,
        'me.billingInformation.updateErrorNotification'
      )

      throw error
    }
  })

export const updateBillingInformation = (
  billingInformation: IBillingInformation
) =>
  thunk(async (dispatch, _getState, dependencies) => {
    billingInformation = sanitizeModel(billingInformation)

    try {
      const savedBillingInformation =
        await dependencies.api.customer.updateBillingInformation(
          billingInformation
        )

      showNotification(
        i18n.t('me.billingInformation.updateSuccessNotification'),
        'success'
      )

      await dispatch(getBillingAddresses())
      return savedBillingInformation
    } catch (error) {
      logging.error(error, { billingInformation })

      showErrorNotification(
        error,
        'me.billingInformation.updateErrorNotification'
      )

      throw error
    }
  })

export const setTeamMembers = (
  teamMembers: ICustomerTeamMember[]
): ISetTeamMembers => ({
  type: ActionTypes.SET_TEAM_MEMBERS,
  payload: {
    teamMembers,
  },
})

export const setIsAccountOwner = (isOwner: boolean): ISetIsAccountOwner => ({
  type: ActionTypes.SET_IS_ACCOUNT_OWNER,
  payload: {
    isOwner,
  },
})

export const fetchTeamMembers = () =>
  thunk(async (dispatch, _getState, dependencies) => {
    try {
      const { teamMembers } = await dependencies.api.customer.getTeam()

      dispatch(setIsAccountOwner(true))
      dispatch(setTeamMembers(teamMembers))
    } catch (error) {
      /**
       * Failing with mostly 401 because user is not owner of customer)
       * So log only if not expected
       * https://lyskahq.atlassian.net/browse/CF-1479
       */
      const expectedErrorCodes = [401, 403]
      if (!expectedErrorCodes.includes(error.response?.status)) {
        logging.error(error)
      }
    }
  })

export const inviteUserToTeam = (payload: IInviteTeamMemberPayload) =>
  thunk(async (dispatch, _getState, dependencies) => {
    const { email } = payload
    payload = sanitizeModel(payload)
    await dependencies.api.customer
      .inviteTeamMember(payload)
      .then(() => {
        showNotification(
          i18n.t('me.team.inviteSuccessNotification', { email }),
          'success'
        )

        dispatch(fetchTeamMembers())
      })
      .catch((e: AxiosError) => {
        if (e.response?.status === 422) {
          const unprocessableError = e as ApiValidationError
          unprocessableError.response!.data.data.forEach((dataItem) => {
            showErrorNotification(e, `me.team.invite.error.${dataItem.code}`)
          })
        } else {
          showErrorNotification(e, 'me.team.invite.error.generic')
          logging.error(e)
        }
      })
  })

export const activateTeamMember = (userId: string) =>
  thunk(async (dispatch, _getState, dependencies) => {
    try {
      await dependencies.api.customer.activateTeamMember(userId)

      showNotification(i18n.t('me.team.activateSuccessNotification'), 'success')

      dispatch(fetchTeamMembers())
    } catch (error) {
      logging.error(error)

      showErrorNotification(error, 'me.team.activateErrorNotification')
    }
  })

export const deactivateTeamMember = (userId: string) =>
  thunk(async (dispatch, _getState, dependencies) => {
    try {
      await dependencies.api.customer.deactivateTeamMember(userId)

      showNotification(
        i18n.t('me.team.deactivateSuccessNotification'),
        'success'
      )

      dispatch(fetchTeamMembers())
    } catch (error) {
      logging.error(error)

      showErrorNotification(error, 'me.team.deactivateErrorNotification')
    }
  })

export type Action =
  | ISetShippingAddresses
  | ISetBillingInformation
  | ISetTeamMembers
  | ISetIsAccountOwner
