import BaseAPI from '@core/api/Platform/BaseAPI'
import { IAward, IAwardCollection, IAwardsQuery } from '../Products/types'

class Awards extends BaseAPI {
  public async fetchAwardById(awardId: string): Promise<IAward | undefined> {
    try {
      const response = await this.api.get<IAward>(`awards/${awardId}`, {
        useCache: true,
        genericUrl: 'awards/{awardId}',
      })
      const { data } = response

      return data
    } catch (e) {
      if (e.response?.status === 404) return undefined
      throw e
    }
  }

  public async fetchAwards(query?: IAwardsQuery): Promise<IAwardCollection> {
    const response = await this.api.get<IAwardCollection>(`awards`, {
      useCache: true,
      genericUrl: 'awards',
      params: query,
    })
    const { data } = response

    return data
  }
}

export default Awards
