import { Menu } from 'antd'
import { MenuProps } from 'antd/lib/menu'

interface StaticExports {
  SubMenu: typeof Menu.SubMenu
  ItemGroup: typeof Menu.ItemGroup
  Item: typeof Menu.Item
  Divider: typeof Menu.Divider
}

export type Props = MenuProps

const LYSMenu: React.FC<Props> & StaticExports = (props) => {
  return <Menu {...props} />
}

LYSMenu.SubMenu = Menu.SubMenu
LYSMenu.ItemGroup = Menu.ItemGroup
LYSMenu.Item = Menu.Item
LYSMenu.Divider = Menu.Divider

export default LYSMenu
