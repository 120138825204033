import { CustomerTypes } from '@core/api/Channel/types'
import { GoogleMapsApiLibrary } from '@core/modules/google-maps/types'
import { Environment, CommerceConnectorConfig } from '@core/utils/Environment'

const config = {
  /**
   * Absolute base URL including protocol and domain,
   * used for generating absolute URLs, eg. in canonical, sitemap, tracking
   */
  baseUrl:
    Environment.default.get('SHOP_BASE_URL') ||
    'https://stage.shop.demo.lyska.cloud',
  storeName: 'Lyska Cloud',
  shopApi: '/shop/v1',
  feedApi: '/feed/v1',
  apiTimeout: Environment.default.getInt('API_TIMEOUT') || 35 * 1000,
  assets: {
    sitemaps: {
      products: [],
    },
    newsletterDiscount: '/static/assets/berger/newsletter-discount.svg',
    storeLogo: '/static/assets/core/demo-shop-logo.svg',
    storeLogoMobile: '/static/assets/core/demo-shop-logo.svg',
    favicon: '/static/assets/favicon.png',
    placeholderImage: '/static/assets/image-placeholder.svg',
    service: '/static/assets/service.png',
    help: '/static/assets/help.svg',
    spechBubble: '/static/assets/speech-bubble.svg',
    paypalLogo: '/static/assets/payment-paypal.png',
    upsLogo: '/static/assets/shipping-partner-ups.png',
    tflexLogo: '/static/assets/trans-o-flex-logo.svg',
    inpacsLogo: '/static/assets/inpacs-logo.svg',
    categoryBackground: '/static/assets/category-background.jpg',
    germanyFlag: '/static/assets/germany.png',
    newsletterMailLogo: '/static/assets/mail.svg',
    noResultsImage: '/static/assets/no-results.png',
    noResultsDocumentImage: '/static/assets/no-results-document.png',
    genericWarningImage: '/static/assets/ghsClasses/ghs_class_07.svg',
    shipmentLogo: '/static/assets/truck.svg#truck',
    priceRequest: '/static/assets/price-request.svg',
    priceRequestDark: '/static/assets/price-request-dark.svg',
    priceRequestModal: '/static/assets/price-request-modal.svg',
    heroArrow: '/static/assets/arrow.svg',
    passwordResetIllustration: '/static/assets/password-reset-illustration.svg',
    paymentMethods: {
      default: '/static/assets/paymentMethods/invoice.png',
      invoice: '/static/assets/paymentMethods/invoice.png',
      payone_paypal: '/static/assets/paymentMethods/paypal.png',
      payone_invoice: '/static/assets/paymentMethods/invoice.png',
      payone_credit_card: '/static/assets/paymentMethods/creditcard.png',
      payone_prepayment: '/static/assets/paymentMethods/text-vorkasse.png',
      prepayment_invoice: '/static/assets/paymentMethods/text-vorkasse.png',
      paypal: '/static/assets/paymentMethods/paypal.png',
      credit_card: '/static/assets/paymentMethods/creditcard.png',
    },
    shippingMethods: {
      default: '/static/assets/shippingMethods/pickup.png',
      dhl: '/static/assets/shippingMethods/dhl.png',
      DHL: '/static/assets/shippingMethods/dhl.png',
      hermes: '/static/assets/shippingMethods/hermes.png',
      tof: '/static/assets/shippingMethods/tof.alt.png',
      pickup_location: '/static/assets/shippingMethods/pickup.png',
    },
    ghsClasses: {
      GHS01: '/static/assets/ghsClasses/ghs_class_01.svg',
      GHS02: '/static/assets/ghsClasses/ghs_class_02.svg',
      GHS03: '/static/assets/ghsClasses/ghs_class_03.svg',
      GHS04: '/static/assets/ghsClasses/ghs_class_04.svg',
      GHS05: '/static/assets/ghsClasses/ghs_class_05.svg',
      GHS06: '/static/assets/ghsClasses/ghs_class_06.svg',
      GHS07: '/static/assets/ghsClasses/ghs_class_07.svg',
      GHS08: '/static/assets/ghsClasses/ghs_class_08.svg',
      GHS09: '/static/assets/ghsClasses/ghs_class_09.svg',
    },
    result: {
      notFound: '/static/assets/notfound.svg',
      serverError: '/static/assets/servererror.svg',
      unauthorized: '/static/assets/unauthorized.svg',
    },
  },
  productCollection: {
    defaultPage: 1,
    defaultLimit: 20,
  },
  categoryNavigation: {
    /**
     * Determines how many levels should be displayed in the category navigation menu
     * Number includes root category, more levels take longer to load
     */
    depth: 3,
    enableSEOShadowCategoriesMenu: false,
  },
  shippingMethods: {
    // Fixed sorting by id´s
    selectionSorting: [] as string[],
  },
  payone: {
    scriptUrl: 'https://secure.pay1.de/client-api/js/v1/payone_hosted.js',
  },
  prismic: {
    name: 'lyska-shop-dev',
    landingPageSlug: 'landing-page',
  },
  cookiebot: {
    cbid: '53e85ab0-07a6-415c-a113-0bf947f13a9c',
  },
  googleMaps: {
    apiKey: Environment.default.get('GOOGLE_MAPS_API_KEY'),
    libraries: ['places'] as GoogleMapsApiLibrary[],
    places: {
      /**
       * See https://developers.google.com/maps/documentation/javascript/reference/places-autocomplete-service#ComponentRestrictions
       */
      componentRestrictions: {
        country: ['de'],
      } as google.maps.places.ComponentRestrictions,
    },
  },
  commerceConnector: {
    /**
     * The following utm_source url values can be added as partnerName for commerceConnector. The key is what we accept
     * as 'utm_source' url param, the value is what we want to send to the api
     */
    partnerNames: {
      commerceconnector: 'commerce_connector',
    },
  } as CommerceConnectorConfig,
  features: {
    defaultCustomerType: CustomerTypes.personal,
    channelSwitchEffect: {
      // if set to false, the current page will be refreshed after channel switch
      redirect: false,
      redirectUrl: '',
    },
    quickSearchRecommendations: { enabled: true, limit: 5 },
    quickSearchHistory: { enabled: true, limit: 5 }, // enabled for igefa & idealclean
    newsletterSubscribeOnRegister: true,
    newsletterSubscribeOnCheckout: true,
    addressBlacklistedStrings: [], // expects an array of strings
    multiUserEnabled: true,
    suppliedCountries: ['DE'], // Array of alpha-2 country codes
    billingInformationEditable: true,
    searchTypeSwitchEnabled: true,
    addressCompletionEnabled: true,
    enabledEnrichProductListItems: true,
    embeddedGuestCheckout: true,
    header: {
      productListDropdown: true, // dropdown in header containing the 5 latest product lists
      userMenuWithText: true,
      buttonType: 'link',
    },
    categoryNavigation: {
      // different types of category navigation, only one should be set to `true`
      visibleCategoryNavItems: false, // all category items visible
      overflowCategoryNavItems: true, // max. one line of category items, overflow dynamically moved to dropdown
      hiddenCategoryNavItems: false, // all category items hidden in a menu
    },
    newProductsFeature: true, // highlights new products with a badge and enables filtering for new products

    // Regular expression for getting the part of the small image, that has to be
    // replaced with a new string, to get the large image's src.
    // ex: http://cdn.url.com/230x230/image.url => http://cdn.url.com/1000x1000/image.url
    largeImageCalculation: {
      smallImageSearchRegex: /^$/,
      largeImageSrcReplace: '',
    },
    // Picture Zoom settings for enabling and handling picture zoom on PDP
    pictureZoom: {
      enabled: true,
    },
    fullSizeImageGallery: {
      enabled: true,
    },
    scrollToTopButton: { enabled: true, withText: false },
    // exact search by product/article number
    exactProductSearchEnabled: false,
    productNumberRegex: /^$/,
    // if we want to search by partial product number
    looseProductNumberRegex: /^$/,
    addToCartInSearchEnabled: true,
    /**
     * Default prefix that will be added to vat ids entered by customers in the billing information form
     * Leave empty to add no prefix
     */
    vatIdPrefix: 'DE',
    /**
     * Regex for the default vat id validator
     */
    vatIdValidationRegex: /^DE[\d]{9}$/,
    clickAndCollectEnabled: false,
    exportProductListToCSV: true,
    setDeliveryTrackingConsentByDefault: false,
    reducedProductListCard: true,
    hideProductListEvents: false, // hide the infobox with productListEvents on the productList page
    filters: {
      awardsFilter: {
        active: false,
        filterKey: '',
        icon: '',
      },
      onStockFilter: true, // wether or not to show the onStock filter in the ProductCollection filters
      ownBrandsFilter: true, // wether or not to show the ownBrandsFilter filter in the ProductCollection filters
      showOnlyWithPriceFilter: true,
      showOnlyPurchasedProducts: true,
      showOnlyRecentlyPurchasedProducts: true, // products bought in the last 6 months
      showOnProductListFilter: true, // filter for products on the user's product lists
    },
    searchResultRecommendations: true, // Display recommendations on search result page if no search results exist
    useMailJetNewsletterSubscription: {
      enabled: false, // if false, we use our api endpoint to subscribe to newsletter
      mailJetWidgetUrl: '',
      mailJetPopupUrl: '',
    },
    showLastVisitedProductsOnPDP: true, // if true, we show the last visited products in a recommentation slider on the pdp
  },
  defaultNewsletterSource: 'shop',
  guenstiger: {
    GTid: '',
  },
}

export default config
