import { useEffect, useState } from 'react'
import { useDebouncedCallback } from 'use-debounce'
import { IDocumentSearchItem } from '@core/api/DocumentSearch/types'
import {
  ICampaignUrl,
  IProductSearch,
  IProductsQuery,
  ISuggestions,
  ITaxonomyAggregationLeaf,
} from '@core/api/Products/types'
import { useServices } from '@core/utils/ioc'
import { AvailableSearchTypes } from './SearchTypeSwitch'

export type IAutoCompleteSuggestions = {
  termSuggestions?: string[]
  documentSuggestions?: IDocumentSearchItem[]
  productSuggestions?: IProductSearch[]
  taxonomyAggregationRoot?: ITaxonomyAggregationLeaf
  campaignUrl?: ICampaignUrl
}

function cleanUpTermSuggestions(
  suggestions?: ISuggestions
): string[] | undefined {
  return suggestions?.searchTermSuggestions?.suggestions.filter(
    (s) => s !== suggestions!.searchTermSuggestions!.searchTerm
  )
}

const useSuggestions = (
  activeSearchType: AvailableSearchTypes,
  requiresAggregations: boolean,
  taxonomyId?: string
) => {
  const { api, productQueryService } = useServices()
  const [searchValue, setSearchValue] = useState<string>('')
  const [suggestions, setSuggestions] = useState<IAutoCompleteSuggestions>({})

  const [isLoading, setLoading] = useState<boolean>(false)
  const debounced = useDebouncedCallback((value) => {
    setSearchValue(value)
    if (value === searchValue) setLoading(false)
  }, 500)

  useEffect(() => {
    // empty string would show all products / documents
    if (searchValue === '') return
    const fetchSuggestions = async () => {
      const params: IProductsQuery = {
        search: searchValue,
        page: 1,
        requiresAggregations: requiresAggregations ? 1 : 0,
      }

      if (taxonomyId) {
        params.filter = {
          taxonomy: taxonomyId,
        }
      }

      if (activeSearchType === AvailableSearchTypes.DOCUMENT) {
        const { hits } = await api.documentSearch.searchDocuments(params)

        setSuggestions({
          documentSuggestions:
            hits.filter(
              (suggestion) =>
                suggestion.attachments && suggestion.attachments.length > 0
            ) || [],
        })
      } else {
        // if the search value is a product number, we add an sku filter to the query
        if (
          params.search &&
          productQueryService.validExactProductSearch(params)
        ) {
          params.filter = {
            sku: params.search,
          }
        }

        const { hits, suggestions, taxonomyAggregationRoot, campaignUrl } =
          await api.products.fetchProducts(params)

        setSuggestions({
          productSuggestions: hits,
          termSuggestions: cleanUpTermSuggestions(suggestions),
          taxonomyAggregationRoot,
          campaignUrl,
        })
      }

      setLoading(false)
    }

    fetchSuggestions()
  }, [
    api.products,
    searchValue,
    activeSearchType,
    api.documentSearch,
    requiresAggregations,
    productQueryService,
    taxonomyId,
  ])

  const loadSuggestions = (value: string) => {
    setLoading(true)
    debounced(value)
  }

  const clearSuggestions = () => {
    setSearchValue('')
    setSuggestions({})
    setLoading(false)
  }

  return {
    isLoading,
    suggestions,
    loadSuggestions,
    clearSuggestions,
  }
}

export default useSuggestions
