import { ICart } from '@core/api/Cart/types'
import { IOrder } from '@core/api/Orders/types'
import { createAction } from '@reduxjs/toolkit'

export const purchaseEvent = createAction(
  'checkout/purchase',
  (order: IOrder, orderCart: ICart) => {
    return {
      payload: {
        order,
        orderCart,
      },
    }
  }
)

export type IPurchaseEvent = ReturnType<typeof purchaseEvent>

export default {
  purchaseEvent,
}
