/* eslint-disable import/no-cycle */
import { useTranslation } from '@core/i18n/i18n'
import { useProductContext } from '@core/utils/models/product/ProductContext'
import StockInformation, {
  StockInformationProps,
} from '@original/components/shared/StockInformation'

const IgefaStockInformation: React.FC<StockInformationProps> = (props) => {
  const { t } = useTranslation()
  const productContext = useProductContext()

  const availableStock = props.stock.stock?.available
  const variantName =
    productContext?.packagingUnitsInformation.packagingUnit.name ||
    productContext?.variant?.name ||
    ''

  return (
    <StockInformation
      {...props}
      slots={{
        inStockText: () => {
          return t('product.stockInformation.inStockExtended', {
            stock: availableStock,
            variantName,
          })
        },
      }}
    />
  )
}

export default IgefaStockInformation
