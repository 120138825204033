import React from 'react'
import { NextComponentType, NextPageContext } from 'next'
import { AppContext } from 'next/app'
import Error from '@core/components/Error'
import routes from '@core/config/routes'
import { logging } from '@core/utils/logging'
import { NextPageWithLayout } from '@core/utils/next'
import { AUTH_REFRESH_TOKEN, AUTH_TOKEN } from './cookies'

interface ErrorBoundaryProps {
  hasError?: boolean
}

interface ErrorBoundaryState {
  hasError: boolean
}

const isAppContext = (ctx: AppContext | NextPageContext) => {
  return 'ctx' in ctx
}

/**
 * This HOC catches unhandled application or page level errors, logs them, and renders
 * a 500 error message as fallback.
 * It catches React render errors and unhandled errors from getInitialProps.
 * @param Component
 */
const withErrorBoundary = <TProps,>(
  Component:
    | NextComponentType<NextPageContext, TProps, TProps>
    | NextPageWithLayout<TProps>
) =>
  class ShopAppErrorBoundary extends React.Component<
    ErrorBoundaryProps,
    ErrorBoundaryState
  > {
    constructor(props: ErrorBoundaryProps, context: unknown) {
      super(props, context)
      this.state = {
        hasError: false,
      }
    }

    static retried403 = false

    // prettier-ignore
    static Layout = ('Layout' in Component) ? Component.Layout : undefined

    static async getInitialProps(
      ctx: AppContext | NextPageContext
    ): Promise<TProps & ErrorBoundaryProps> {
      let wrappedComponentProps = isAppContext(ctx) ? { pageProps: {} } : {}
      let hasError = false

      try {
        // Run nested getInitialProps
        if (Component.getInitialProps) {
          wrappedComponentProps = await (Component.getInitialProps as any)(ctx)
        }
      } catch (e) {
        if (e.response?.status === 403 && !this.retried403) {
          const cookiesService = isAppContext(ctx)
            ? (ctx as AppContext).ctx?.services?.cookies
            : (ctx as NextPageContext).services?.cookies

          if (cookiesService) {
            cookiesService.remove(AUTH_TOKEN)
            cookiesService.remove(AUTH_REFRESH_TOKEN)

            this.retried403 = true

            const res = isAppContext(ctx)
              ? (ctx as AppContext).ctx.res
              : (ctx as NextPageContext).res

            if (res) {
              res.writeHead(307, { Location: routes.login().href })
              res.end()
            }

            return this.getInitialProps(ctx)
          }
        }

        logging.error('getInitialProps failed', e)
        hasError = true
      }

      return {
        hasError,
        ...wrappedComponentProps,
      } as any
    }

    componentDidCatch(error: Error, errorInfo: React.ErrorInfo): void {
      logging.error(error, { reactErrorInfo: errorInfo })
    }

    static getDerivedStateFromError(): Partial<ErrorBoundaryState> {
      return { hasError: true }
    }

    render() {
      const { hasError: propsError, ...props } = this.props
      const { hasError: stateError } = this.state

      if (stateError || propsError) return <Error statusCode={500} />

      return React.createElement(
        Component as any,
        props as React.PropsWithChildren<TProps>
      )
    }
  }

export default withErrorBoundary
