import { IAdditionalCheckoutInfo } from '@core/api/Cart/types'
import { IAppState } from '@core/store/types'

export const isCartEmpty = (state: IAppState) => {
  return !state.cart.cart || state.cart.cart.items.length === 0
}

export const getUserCart = (state: IAppState) => {
  return state.cart.cart
}

export const getCartCustomerInformation = (state: IAppState) => {
  return state.cart.cart?.customerInformation
}
export const getCartDrawerOpen = (state: IAppState) => {
  return state.cart.cartDrawerOpen
}

export const getCartUpdatePending = (state: IAppState) => {
  return state.cart.cartUpdatePending
}

export const getCartShippingAddress = (state: IAppState) => {
  return state.cart.cart && state.cart.cart.shippingAddress
}

// TODO: choose the correct address once default is implemented in backend
export const getCartBillingInformation = (state: IAppState) => {
  return state.cart.cart && state.cart.cart.billingInformation
}

export const getCartPaymentMethod = (state: IAppState) => {
  return state.cart.cart && state.cart.cart.paymentMethod
}

export const getCartShippingMethod = (state: IAppState) => {
  return state.cart.cart && state.cart.cart.shippingMethod
}

export const getCartCustomerType = (state: IAppState) => {
  return (
    state.cart.cart &&
    state.cart.cart.billingInformation?.clientFields.customerType
  )
}

export const isCheckoutReady = (state: IAppState) => {
  const cart = state.cart.cart
  if (!cart) return false
  const {
    items,
    billingInformation,
    paymentMethod,
    shippingAddress,
    shippingMethod,
  } = cart
  return (
    items.length > 0 &&
    !!shippingAddress &&
    !!billingInformation &&
    !!paymentMethod &&
    !!shippingMethod
  )
}

export const getAdditionalCheckoutInfo = (
  state: IAppState
): IAdditionalCheckoutInfo => ({
  // Workaround: PO Number temporarily stored on billing info client fields
  customerPurchaseOrderNumber:
    state.cart.cart?.billingInformation?.clientFields
      ?.customerPurchaseOrderNumber,
})
