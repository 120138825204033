import { Tooltip } from 'antd'
import { TooltipProps } from 'antd/lib/tooltip'

type Props = TooltipProps

const LYSTooltip: React.FC<Props> = (props) => {
  return <Tooltip {...props} />
}

export { LYSTooltip }
