import cn from 'classnames'
import first from 'lodash/first'
import { ITaxonomy } from '@core/api/Categories/types'
import { LYSDivider } from '@core/components/Primitives'
import { MenuBehaviour } from '@core/components/shared/menu/MenuBehaviour'
import { MenuItem, MenuItemProps } from '@core/components/shared/menu/MenuItem'
import routes from '@core/config/routes'
import { template } from '@core/utils/templates'
import style from './index.module.less'
import React from 'react'

export interface CategoryMenuItemProps extends MenuItemProps {
  category: ITaxonomy
}

export const CategoryMenuItem: React.FC<CategoryMenuItemProps> = ({
  category,
  ...rest
}) => (
  <MenuItem route={routes.taxonomy(category)} {...rest}>
    {category.name}
  </MenuItem>
)

export interface ColumnCategoryNavigationProps {
  categoryRoot: ITaxonomy
  className?: string
  level1ListClassName?: string
  level2ListClassName?: string
}

export interface ColumnCategoryNavigationComponents {
  Level1MenuItem: typeof CategoryMenuItem
  Level2MenuItem: typeof CategoryMenuItem
}

export const ColumnCategoryNavigation = template<
  ColumnCategoryNavigationProps,
  // eslint-disable-next-line @typescript-eslint/ban-types
  {},
  ColumnCategoryNavigationComponents
>(
  ({
    categoryRoot,
    className,
    level1ListClassName,
    level2ListClassName,
    getComponents,
  }) => {
    const components = getComponents({
      Level1MenuItem: CategoryMenuItem,
      Level2MenuItem: CategoryMenuItem,
    })

    return (
      <MenuBehaviour
        menuId={'level1'}
        items={categoryRoot.children}
        keyboardNavigationDirection={'vertical'}
        initialSelectedItem={first(categoryRoot.children)}
        preventDeselection
        relayClose
      >
        <div className={cn(style.columnCategoryNavigation, className)}>
          {/* Left column */}
          <div className={style.column}>
            <div className={cn(style.itemList, level1ListClassName)}>
              {categoryRoot.children.map((level1Item) => (
                <MenuBehaviour.Trigger key={level1Item.id} item={level1Item}>
                  <components.Level1MenuItem
                    activatable
                    category={level1Item}
                  />
                </MenuBehaviour.Trigger>
              ))}
            </div>
          </div>
          <LYSDivider type="vertical" className={style.divider} />
          {/* Right column */}
          <div className={cn(style.column, style.right)}>
            {categoryRoot.children.map((level1Item) =>
              level1Item.children.length > 0 ? (
                <MenuBehaviour.Content key={level1Item.id} item={level1Item}>
                  <div className={cn(style.itemList, level2ListClassName)}>
                    <MenuBehaviour
                      menuId={'level2'}
                      items={level1Item.children}
                      keyboardNavigationDirection={'vertical'}
                      relayClose={true}
                    >
                      {level1Item.children.map((level2Item) => (
                        <MenuBehaviour.Trigger
                          key={level2Item.id}
                          item={level2Item}
                        >
                          <components.Level2MenuItem
                            activatable
                            category={level2Item}
                          />
                        </MenuBehaviour.Trigger>
                      ))}
                    </MenuBehaviour>
                  </div>
                </MenuBehaviour.Content>
              ) : null
            )}
          </div>
        </div>
      </MenuBehaviour>
    )
  }
)
