import BaseAPI from '@core/api/Platform/BaseAPI'
import { ICustomerInformation } from '@core/store/customer/types'
import { CustomerTypes } from '../Channel/types'
import {
  IAddCartItems,
  ICart,
  ICreateCart,
  IOrderBillingInformation,
  IOrderShippingAddress,
  IPaymentInformation,
  IPaymentMethodCollection,
  IShippingInformation,
  IShippingMethodCollection,
} from './types'

class Cart extends BaseAPI {
  public async createCart(params: ICreateCart): Promise<ICart> {
    const response = await this.api.post<ICart>('cart', params)
    const { data } = response
    return data
  }

  public async fetchCart(cartId: string): Promise<ICart> {
    const response = await this.api.get<ICart>(`cart/${cartId}`, {
      genericUrl: 'cart/{cartId}',
    })
    const { data } = response
    return data
  }

  public async deleteCart(cartId: string): Promise<void> {
    await this.api.delete<ICart>(`cart/${cartId}`, {
      genericUrl: 'cart/{cartId}',
    })
  }

  public async deleteAllCartItems(cartId: string): Promise<ICart> {
    const response = await this.api.delete<ICart>(`cart/${cartId}/items`, {
      genericUrl: 'cart/{cartId}/items',
    })
    const { data } = response
    return data
  }

  public async addToCart(
    cartId: string,
    addCartItems: IAddCartItems
  ): Promise<ICart> {
    const response = await this.api.post<ICart>(
      `cart/${cartId}/items`,
      addCartItems,
      {
        genericUrl: 'cart/{cartId}/items',
      }
    )
    const { data } = response
    return data
  }

  public async updateCartItemQuantity(
    cartId: string,
    productVariantId: string,
    quantity: number
  ): Promise<ICart> {
    const response = await this.api.put<ICart>(
      `cart/${cartId}/items/${productVariantId}`,
      {
        quantity,
      },
      {
        genericUrl: 'cart/{cartId}/items/{variantId}',
      }
    )
    const { data } = response
    return data
  }

  public async removeItemFromCart(
    cartId: string,
    productVariantId: string
  ): Promise<ICart> {
    const response = await this.api.delete<ICart>(
      `cart/${cartId}/items/${productVariantId}`,
      {
        genericUrl: 'cart/{cartId}/items/{variantId}',
      }
    )
    const { data } = response
    return data
  }

  public async setOrderShippingAddress(
    cartId: string,
    shippingAddress: IOrderShippingAddress
  ): Promise<ICart> {
    const response = await this.api.put(
      `cart/${cartId}/shipping-address`,
      {
        shippingAddress,
        customerType: 'personal',
      },
      {
        genericUrl: 'cart/{cartId}/shipping-address',
      }
    )
    const { data } = response
    return data
  }

  public async setOrderBillingInformation(
    cartId: string,
    billingInformation: IOrderBillingInformation,
    customerType: CustomerTypes
  ): Promise<ICart> {
    const response = await this.api.put(
      `cart/${cartId}/billing-information`,
      {
        billingInformation,
        customerType: customerType,
      },
      {
        genericUrl: 'cart/{cartId}/billing-information',
      }
    )
    const { data } = response
    return data
  }

  public async setShippingInformation(
    cartId: string,
    shippingInformation: IShippingInformation
  ): Promise<ICart> {
    const response = await this.api.put<ICart>(
      `cart/${cartId}/shipping-information`,
      {
        shippingInformation,
      },
      {
        genericUrl: 'cart/{cartId}/shipping-information',
      }
    )
    const { data } = response
    return data
  }

  public async setOrderCustomerInformation(
    cartId: string,
    customerInformation: ICustomerInformation
  ): Promise<ICart> {
    const response = await this.api.put<ICart>(
      `cart/${cartId}/customer-information`,
      {
        customerInformation,
      },
      {
        genericUrl: 'cart/{cartId}/customer-information',
      }
    )
    const { data } = response
    return data
  }

  public async setPaymentInformation(
    cartId: string,
    paymentInformation: IPaymentInformation
  ): Promise<ICart> {
    const response = await this.api.put(
      `cart/${cartId}/payment-information`,
      {
        paymentInformation,
      },
      {
        genericUrl: 'cart/{cartId}/payment-information',
      }
    )
    const { data } = response
    return data
  }

  public async fetchPaymentMethods(
    cartId: string
  ): Promise<IPaymentMethodCollection> {
    const response = await this.api.get<IPaymentMethodCollection>(
      `cart/${cartId}/payment-methods`,
      {
        genericUrl: 'cart/{cartId}/payment-methods',
      }
    )
    const { data } = response
    return data
  }

  public async fetchShippingMethods(
    cartId: string
  ): Promise<IShippingMethodCollection> {
    const response = await this.api.get<IShippingMethodCollection>(
      `cart/${cartId}/shipping-methods`,
      {
        genericUrl: 'cart/{cartId}/shipping-methods',
      }
    )
    const { data } = response
    return data
  }

  public async applyVoucherCode(cartId: string, code: string): Promise<ICart> {
    const response = await this.api.post<ICart>(
      `cart/${cartId}/voucher/${code}/apply`,
      undefined,
      {
        genericUrl: 'cart/${cartId}/voucher/{code}/apply',
      }
    )
    const { data } = response
    return data
  }

  public async mergeCart(
    cartId: string,
    cartIdToMerge: string
  ): Promise<ICart> {
    const response = await this.api.post<ICart>(
      `cart/${cartId}/merge/${cartIdToMerge}`,
      undefined,
      {
        genericUrl: 'cart/${cartId}/merge/{cartIdToMerge}',
      }
    )
    const { data } = response

    return data
  }

  public async createCartFromOrder(orderId: string) {
    const response = await this.api.post<ICart>(
      `cart/create-from-order/${orderId}`,
      undefined,
      {
        genericUrl: 'cart/create-from-order/{orderId}',
      }
    )
    const { data } = response

    return data
  }
}

export default Cart
