import { useEffect, useState } from 'react'
import {
  IProduct,
  IProductRelationType,
  IProductSearch,
} from '@core/api/Products/types'
import { useServices } from '@core/utils/ioc'
import { logging } from '@core/utils/logging'

export const useRelatedProducts = (
  product: IProduct | undefined,
  productRelationType: IProductRelationType
) => {
  const { api } = useServices()
  const [relatedProducts, setRelatedProducts] = useState<IProductSearch[]>([])
  const [isLoading, setIsLoading] = useState(false)

  useEffect(() => {
    let didCancel = false

    const relatedProductIds =
      (product?.relatedProducts &&
        product.relatedProducts[productRelationType]) ||
      []

    if (!relatedProductIds.length) {
      setRelatedProducts([])
      return
    }

    const fetchRelatedProducts = async () => {
      try {
        setIsLoading(true)
        const fetchedProducts = await api.products.fetchProducts({
          filter: { id: relatedProductIds },
          page: 1,
          requiresAggregations: 0,
        })
        if (!didCancel) {
          setRelatedProducts([...(fetchedProducts.hits || [])])
        }
      } catch (e) {
        logging.error('Error fetching related products', e)
      }
      setIsLoading(false)
    }

    fetchRelatedProducts()

    return () => {
      didCancel = true
    }
  }, [product, productRelationType, api?.products])

  return {
    relatedProducts,
    isLoading,
  }
}
