import React from 'react'
import { AutoComplete } from 'antd'
import { AutoCompleteProps } from 'antd/es/auto-complete'
import { RefSelectProps } from 'antd/es/select'

export type LYSAutoCompleteProps = AutoCompleteProps

const LYSAutoComplete = React.forwardRef<RefSelectProps, LYSAutoCompleteProps>(
  (props, ref?) => <AutoComplete ref={ref} {...props} />
)

export default LYSAutoComplete

export type LYSAutoCompleteInstance = RefSelectProps
