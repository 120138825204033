import React from 'react'
import { Form } from 'antd'
import { ICreateProductList, IProductList } from '@core/api/ProductLists/types'
import { LYSInput, LYSModal } from '@core/components/Primitives'
import FormButtons from '@core/components/shared/FormButtons'
import formConfig from '@core/config/formConfig'
import { useTranslation } from '@core/i18n/i18n'

interface Props {
  isVisible: boolean
  onSubmit: (productList: ICreateProductList) => void
  onCancel: () => void
  initialValues?: IProductList | ICreateProductList
}

const ProductListForm: React.FC<Props> = ({
  isVisible,
  onCancel,
  onSubmit,
  initialValues,
}) => {
  const { t } = useTranslation()

  const handleSubmit = (values: ICreateProductList) => {
    onSubmit(values)
  }

  return (
    <LYSModal
      title={t('wishlist.createProductList.title')}
      visible={isVisible}
      onCancel={onCancel}
      footer={null}
    >
      <Form
        initialValues={initialValues}
        layout="vertical"
        onFinish={handleSubmit}
      >
        <Form.Item
          name="label"
          label={t('wishlist.createProductList.nameListText')}
          {...formConfig.productListForm.label}
        >
          <LYSInput aria-required={true} type="text" />
        </Form.Item>
        <Form.Item
          name="description"
          label={t('wishlist.createProductList.listDescriptionText')}
          {...formConfig.productListForm.description}
        >
          <LYSInput
            placeholder={t(
              'wishlist.createProductList.listDescriptionPlaceholder'
            )}
            type="text"
          />
        </Form.Item>

        <FormButtons onCancel={onCancel} />
      </Form>
    </LYSModal>
  )
}

export default ProductListForm
