import React from 'react'
import { Form } from 'antd'
import { LYSInput, LYSModal, LYSTypography } from '@core/components/Primitives'
import LYSStaticImage from '@core/components/Primitives/LYSStaticImage'
import FormButtons from '@core/components/shared/FormButtons'
import config from '@core/config/config'
import formConfig from '@core/config/formConfig'
import usePriceRequests from '@core/hooks/priceRequest/usePriceRequests'
import { useTranslation } from '@core/i18n/i18n'
import styles from './index.module.less'

const PriceRequestModalPhoneNumber: React.FC = () => {
  const { t } = useTranslation()
  const { submitPhoneNumberAndCreateRequest, closePhoneNumberRequestModal } =
    usePriceRequests()

  const handleSubmit = (values: { phone: string }) => {
    submitPhoneNumberAndCreateRequest(values.phone)
  }

  return (
    <LYSModal
      visible={true}
      footer={null}
      onCancel={closePhoneNumberRequestModal}
      className={styles.modal}
    >
      <div className={styles.modal_header}>
        <LYSStaticImage
          src={config.assets.priceRequestModal}
          alt={t('alt.successPriceRequest')}
          width={80}
          height={80}
        />
      </div>
      <LYSTypography.Paragraph>
        {t`priceRequest.phoneNumberModal.info`}
      </LYSTypography.Paragraph>

      <LYSTypography.Text
        strong
      >{t`priceRequest.phoneNumberModal.label`}</LYSTypography.Text>
      <Form onFinish={handleSubmit} layout="vertical">
        <Form.Item
          name="phone"
          className={styles.price_request_phone_modal__input}
          {...formConfig.priceRequestModal.phone}
        >
          <LYSInput />
        </Form.Item>

        <FormButtons
          submitText={t`priceRequest.phoneNumberModal.submit`}
          submitOnlyButton={true}
        />
      </Form>
    </LYSModal>
  )
}

export default PriceRequestModalPhoneNumber
