import { Avatar } from 'antd'
import { AvatarProps } from 'antd/lib/avatar'

export type Props = AvatarProps

const LYSAvatar: React.FC<Props> = (props) => {
  return <Avatar {...props} />
}

export default LYSAvatar
