import coreRoutes, { Route as CoreRoute } from '@original/config/routes'

export type Route = CoreRoute

export default {
  ...coreRoutes,
  dataProtection: {
    href: '/page/[slug]',
    as: '/page/datenschutz',
  } as Route,
  termsAndConditions: {
    href: '/page/[slug]',
    as: `/page/allgemeine-verkaufsbedingungen`,
  } as Route,
  delivery: {
    href: '/page/[slug]',
    as: `/page/lieferung`,
  } as Route,
  impressum: {
    href: '/page/[slug]',
    as: `/page/impressum`,
  } as Route,
  editShippingAddress(id: string): Route {
    return {
      href: `/me/addresses/edit?id=${id}`,
      as: `/me/addresses/edit/${id}`,
    }
  },
  registerClosedShop: {
    href: `/auth/register/closed-shop`,
    as: `/auth/register/closed-shop`,
  },
}
