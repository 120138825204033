import { useCallback } from 'react'
import { useDispatch, useSelector } from 'react-redux'
import {
  IAddProductListItems,
  ICreateProductList,
  IProductListItems,
} from '@core/api/ProductLists/types'
import { addProductListToCart as addProductListToCartAction } from '@core/store/cart/actions'
import {
  addToProductList as dispatchAddToProductList,
  createProductList as dispatchCreateProductList,
  deleteItemsFromProductList as dispatchDeleteItemsFromProductList,
} from '@core/store/productList/actions'
import { getProductLists } from '@core/store/productList/selectors'

const useProductLists = () => {
  const productLists = useSelector(getProductLists)
  const dispatch = useDispatch()

  const addToProductList = useCallback(
    (productListId: string, variantId: string, quantity: number) => {
      const itemToAdd: IAddProductListItems = {
        productListItems: [{ quantity, variantId }],
      }
      dispatch(dispatchAddToProductList(productListId, itemToAdd))
    },
    [dispatch]
  )

  const deleteFromProductList = useCallback(
    (productListId: string, itemIds: string[]) => {
      dispatch(dispatchDeleteItemsFromProductList(productListId, itemIds))
    },
    [dispatch]
  )

  const createProductList = useCallback(
    (productList: ICreateProductList, items: IProductListItems[]) => {
      const itemToAdd: IAddProductListItems = {
        productListItems: items,
      }
      dispatch(dispatchCreateProductList(productList, itemToAdd))
    },
    [dispatch]
  )

  const addProductListToCart = useCallback(
    async (productListId: string) => {
      await dispatch(addProductListToCartAction(productListId))
    },
    [dispatch]
  )

  return {
    productLists,
    addToProductList,
    createProductList,
    addProductListToCart,
    deleteFromProductList,
  }
}

export default useProductLists
