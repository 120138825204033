const baseI18nextOptions = {
  defaultLanguage: 'de',
  returnEmptyString: false,
  defaultNS: 'translation',
  detection: {
    order: ['querystring', 'navigator'],
    lookupQuerystring: 'lng',
  },
  browserLanguageDetection: false,
  serverLanguageDetection: false,
  keySeparator: false,
}

export default function i18nextOptions(options: any) {
  return { ...baseI18nextOptions, ...options }
}
