import { createSelector } from 'reselect'
import { IAppState } from '@core/store/types'

export const getUser = (state: IAppState) => state.user

export const getAuthenticatedUser = (state: IAppState) => state.user.user

export const isAuthenticated = (state: IAppState) => !!state.user.user

export const getUserChannelId = createSelector(
  getUser,
  (user) => user.user?.channel.id || user.anonymous?.channel.id
)

export const getUserChannel = createSelector(
  getUser,
  (user) => user.user?.channel || user.anonymous?.channel
)

// TODO: connect to user store
export const getBranchOfficePrismicIndicator = () => 'branch-office-0218'

export const getHasPhoneNumber = createSelector(
  getUser,
  (user) => !!user?.user?.clientFields?.phone
)
