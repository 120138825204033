import { ICategoryState } from '@core/store/categories/types'
import { Action, ActionTypes } from './actions'

const initialState: ICategoryState = {
  navigation: undefined,
}

export default function categoriesReducer(
  state: ICategoryState = initialState,
  action: Action
) {
  switch (action.type) {
    case ActionTypes.SET_CATEGORY_NAVIGATION: {
      return {
        ...state,
        navigation: action.payload,
      }
    }
    default:
      return state
  }
}
