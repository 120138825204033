import { Environment } from '@core/utils/Environment'
import { consentChangedEvent } from '@core/utils/consent/events'
import { IConsentInfo } from '@core/utils/consent/types'
import EventBus from '@core/utils/eventbus'

const FALLBACK_CONSENT = {
  required: false,
  preferences: false,
  statistics: false,
  marketing: false,
}

/**
 * Facade for cookie consent
 * Currently simply used Cookiebot
 */
export class ConsentService {
  constructor(eventBus: EventBus) {
    // Only works in browser
    if (Environment.default.isServer) return

    // Relay consent change events from Cookiebot to event bus
    window.addEventListener('CookiebotOnAccept', () => {
      eventBus.publish(consentChangedEvent(this.consent))
    })
    window.addEventListener('CookiebotOnDecline', () => {
      eventBus.publish(consentChangedEvent(this.consent))
    })
  }

  get consent(): IConsentInfo {
    // Only works in browser
    if (Environment.default.isServer) return FALLBACK_CONSENT

    // Cookiebot might not be loaded or is not available in test environments
    return window.Cookiebot?.consent || FALLBACK_CONSENT
  }
}
