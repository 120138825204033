import React from 'react'
import { Steps } from 'antd'
import { StepsProps } from 'antd/lib/steps'

interface StaticExports {
  Step: typeof Steps.Step
}

const LYSSteps: React.FC<StepsProps> & StaticExports = (props) => {
  return <Steps {...props} />
}
LYSSteps.Step = Steps.Step

export default LYSSteps
