import { createContext } from 'react'
import { ProductPromotionType } from '@core/api/Products/types'

interface Context {
  isPromotion?: boolean
  promotionType?: ProductPromotionType
}

const PromotionContext = createContext<Context>({
  isPromotion: false,
})

export default PromotionContext
