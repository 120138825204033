import React, { useState } from 'react'
import {
  PlusOutlined,
  HeartOutlined,
  CheckOutlined,
  HeartFilled,
} from '@ant-design/icons'
import { ICreateProductList, IProductList } from '@core/api/ProductLists/types'
import ProductListForm from '@core/components/Me/ProductList/ProductListDetail/ProductListForm'
import {
  LYSButton,
  LYSDropdown,
  LYSMenu,
  LYSTooltip,
  LYSTypography,
} from '@core/components/Primitives'
import { useTranslation } from '@core/i18n/i18n'
import style from './index.module.less'
import useProductLists from '@core/hooks/productList/useProductLists'
import useCurrentUser from '@core/hooks/user/useCurrentUser'
import cn from 'classnames'
import { IProduct, IProductSearch } from '@core/api/Products/types'
import { IPriceRequestProduct } from '@core/api/PriceRequests/types'

interface OwnProps {
  onSelectProductList: (productListId: string) => void
  onCreateProductList: (productList: ICreateProductList) => void
  notAllowedToAddToProductList?: boolean
  product: IProduct | IProductSearch | IPriceRequestProduct
}

const createProductListOption = 'createProductList'

const AddToProductListSelect: React.FC<OwnProps> = (props) => {
  const {
    onSelectProductList,
    onCreateProductList,
    notAllowedToAddToProductList,
    product,
  } = props
  const { t } = useTranslation()
  const { deleteFromProductList, productLists } = useProductLists()
  const { isAuthenticated } = useCurrentUser()
  const [isModalVisible, setModalVisible] = useState<boolean>(false)

  const handleAddToProductList = (id: any) => {
    if (id === createProductListOption) {
      showCreateProductListForm()
    } else {
      onSelectProductList(id)
    }
  }

  const showCreateProductListForm = () => {
    setModalVisible(true)
  }

  const handleCreateProductList = async (productList: ICreateProductList) => {
    onCreateProductList(productList)
    setModalVisible(false)
  }

  const form = (
    <ProductListForm
      isVisible={isModalVisible}
      onSubmit={handleCreateProductList}
      onCancel={() => setModalVisible(false)}
    />
  )

  // checks if the product is on any productlist
  const isOnProductLists = productLists.filter((productList: IProductList) => {
    return productList.availableItems.find(
      (item) => item.variantId === product?.mainVariant.id
    )
  })

  const menu = (
    <LYSMenu>
      {productLists &&
        productLists.map((productList: IProductList) => {
          // checks if the product is on a specific productlist
          const productIsOnProductList = productList.availableItems.find(
            (item) => item.variantId === product?.mainVariant.id
          )
          return (
            <LYSMenu.Item
              icon={
                productIsOnProductList && (
                  <CheckOutlined className={style.checkIcon} rev={undefined} />
                )
              }
              onClick={() =>
                productIsOnProductList
                  ? deleteFromProductList(productList.id, [
                      product!.mainVariant.id,
                    ])
                  : handleAddToProductList(productList.id)
              }
              key={productList.id}
              className={productIsOnProductList && style.activeMenuItem}
            >
              <LYSTypography.Paragraph
                className={style.productListName}
                ellipsis
              >
                {productList.label} ({productList.itemsCount})
              </LYSTypography.Paragraph>
            </LYSMenu.Item>
          )
        })}
      <LYSMenu.Divider />
      <LYSMenu.Item
        className={style.createProductlist}
        onClick={() => showCreateProductListForm()}
        icon={<PlusOutlined rev={undefined} />}
      >
        {t('addToProductlist.createList')}
      </LYSMenu.Item>
    </LYSMenu>
  )

  const select = (
    <LYSTooltip title={t('addToProductlist.addToProductList')}>
      <LYSDropdown
        className={style.dropdown}
        trigger={['click']}
        overlay={menu}
      >
        <LYSButton
          type="text"
          size="large"
          data-testid="add-to-product-list-button"
        >
          {isOnProductLists.length > 0 ? (
            <HeartFilled
              className={cn(style.heartIcon, style.heartIconFilled)}
              rev={undefined}
            />
          ) : (
            <HeartOutlined className={style.heartIcon} rev={undefined} />
          )}
        </LYSButton>
      </LYSDropdown>
    </LYSTooltip>
  )

  if (!isAuthenticated || notAllowedToAddToProductList) return null

  return (
    <>
      {form}
      {select}
    </>
  )
}

export default AddToProductListSelect
