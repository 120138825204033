import { convertRichTextToHtml } from '@core/prismic/util'
import style from './index.module.less'

interface Props {
  element: PrismicTextElement[]
}

const RichTextElement: React.FC<Props> = ({ element }) => {
  const htmlString = convertRichTextToHtml(element)

  if (!htmlString) return null

  const html = { __html: htmlString }

  return (
    <div className={style.wrapper}>
      <div dangerouslySetInnerHTML={html} />
    </div>
  )
}

export default RichTextElement
