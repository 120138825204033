import { useDispatch, useSelector } from 'react-redux'
import { useDebounce } from 'use-debounce'
import {
  hideModal,
  ModalType,
  showModal,
  clearQueue as clearQueueAction,
} from '@core/store/modals/actions'
import { getActiveModal } from '@core/store/modals/selectors'

const useModals = () => {
  const dispatch = useDispatch()
  const activeModal = useSelector(getActiveModal)
  /**
   * to prevent flickering of activeModal appearing, we are debouncing the state change
   */
  const [debouncedActiveModal] = useDebounce(activeModal, 1000)

  const show = (modalType: ModalType) => {
    dispatch(showModal(modalType))
  }

  const hide = (modalType?: ModalType) => {
    dispatch(hideModal(modalType))
  }

  const clearQueue = () => {
    dispatch(clearQueueAction())
  }

  return {
    activeModal: debouncedActiveModal,
    show,
    hide,
    clearQueue,
  }
}

export default useModals
