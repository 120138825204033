import * as React from 'react'
import { CategoryNavBar } from '../../Navigation/CategoryNavBar'
import style from './index.module.less'

const MenuBar: React.FC = () => {
  return (
    <div className={style.menuBar}>
      <CategoryNavBar className={style.navigation} />
    </div>
  )
}

export default MenuBar
