import { ExtendedAxiosInstance } from '@core/api/Platform/types'
import CookiesFacade from '@core/utils/cookies'

export default class BaseAPI {
  public api: ExtendedAxiosInstance
  public cookies: CookiesFacade

  constructor(api: ExtendedAxiosInstance, cookies: CookiesFacade) {
    this.api = api
    this.cookies = cookies
  }
}
