import { QuickCart } from '@core/components/Cart/QuickCart'
import {
  LYSButton,
  LYSCol,
  LYSContainer,
  LYSRow,
  LYSTypography,
} from '@core/components/Primitives'
import { useTranslation } from '@core/i18n/i18n'
import { SearchOutlined, ReadOutlined, HeartOutlined } from '@ant-design/icons'
import styles from './index.module.less'
import { useAppBarContext } from '../../AppBar/Context/appBarContext'
import { useServices } from '@core/utils/ioc'
import useCurrentUser from '@core/hooks/user/useCurrentUser'
import routes from '@core/config/routes'
const MobileNavigation: React.FC = () => {
  const { t } = useTranslation()
  const {
    setAutoSuggestOpen,
    autoSuggestOpen,
    categoryMenuOpen,
    setCategoryMenuOpen,
  } = useAppBarContext()
  const { isAuthenticated } = useCurrentUser()

  const { router } = useServices()
  return (
    <LYSContainer className={styles.container}>
      <LYSRow justify="space-between">
        <LYSCol>
          <LYSButton
            icon={<ReadOutlined rev={undefined} />}
            type="link"
            ghost={true}
            className={styles.category}
            onClick={() => {
              if (setCategoryMenuOpen) setCategoryMenuOpen(!categoryMenuOpen)
            }}
          />
          <LYSTypography.Text className={styles.label}>
            {t('category')}
          </LYSTypography.Text>
        </LYSCol>
        <LYSCol>
          <LYSButton
            type="link"
            ghost={true}
            className={styles.search}
            icon={<SearchOutlined rev={undefined} />}
            onClick={() => {
              if (setAutoSuggestOpen) setAutoSuggestOpen(!autoSuggestOpen)
            }}
          />
          <LYSTypography.Text className={styles.label}>
            {t('search')}
          </LYSTypography.Text>
        </LYSCol>
        <LYSCol>
          <LYSButton
            className={styles.productLists}
            type="link"
            onClick={() =>
              !isAuthenticated
                ? router.pushAndScrollTop(
                    routes.login().href,
                    routes.login().as
                  )
                : router.pushAndScrollTop(
                    routes.productList().href,
                    routes.productList().as
                  )
            }
            ghost
            icon={<HeartOutlined rev={undefined} />}
          />
          <LYSTypography.Text className={styles.label}>
            {t('productLists')}
          </LYSTypography.Text>
        </LYSCol>
        <LYSCol
          style={{
            textAlign: 'center',
          }}
        >
          <QuickCart buttonClassName={styles.cart} />
          <LYSTypography.Text className={styles.label}>
            {t('cart')}
          </LYSTypography.Text>
        </LYSCol>
      </LYSRow>
    </LYSContainer>
  )
}

export default MobileNavigation
