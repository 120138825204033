import { ICustomerState } from '@core/store/customer/types'
import { Action, ActionTypes } from './actions'

const initialState: ICustomerState = {
  billingInformation: [],
  shippingAddresses: [],
  teamMembers: [],
  isOwner: false,
}

export default function customerReducer(
  state: ICustomerState = initialState,
  action: Action
) {
  switch (action.type) {
    case ActionTypes.SET_SHIPPING_ADDRESSES:
      return {
        ...state,
        shippingAddresses: action.payload.addresses,
      }
    case ActionTypes.SET_BILLING_INFORMATION:
      return {
        ...state,
        billingInformation: action.payload.addresses,
      }
    case ActionTypes.SET_TEAM_MEMBERS:
      return {
        ...state,
        teamMembers: action.payload.teamMembers,
      }
    case ActionTypes.SET_IS_ACCOUNT_OWNER:
      return {
        ...state,
        isOwner: action.payload.isOwner,
      }
    default:
      return state
  }
}
