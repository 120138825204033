import { logging } from './logging'

export interface IEventBusEvent {
  type: string
  payload: unknown
}

interface ISubscriptions {
  [id: string]: Array<(data: any) => void>
}

class EventBus {
  subscriptions: ISubscriptions = {}

  publish(event: IEventBusEvent) {
    if (!this.subscriptions[event.type]) {
      return
    }

    this.subscriptions[event.type].forEach((callback) => {
      try {
        callback(event)
      } catch (error) {
        logging.error(error, { event })
      }
    })
  }

  subscribe<EventType>(type: string, callback: (data: EventType) => void) {
    if (!this.subscriptions[type]) {
      this.subscriptions[type] = []
    }

    this.subscriptions[type].push(callback)

    return () => {
      const handlers = this.subscriptions[type] || []
      this.subscriptions[type] = handlers.filter(
        (handler) => handler !== callback
      )
    }
  }
}

/**
 * Use only for development purposes.
 * Hot reloading of app effectively subscribe the same handlers every time an app hot reloads.
 *
 * Not exported as part of the EventBus object to avoid confusion, prefixed with underscore to indicate internal use only.
 */
export const _removeAllSubscribers = (eventBus: EventBus) => {
  eventBus.subscriptions = {}
}

export default EventBus
