import React, { useContext, useEffect, useState } from 'react'
import {
  ProductDetailContext,
  ProductContext,
  ProductListItemContext,
  ProductSearchContext,
  ProductCartItemContext,
  ProductPriceRequestContext,
} from '@core/utils/models/product/types'
import { IPackagingUnit } from '@core/api/Products/types'
import {
  isProductCartItemContext,
  isProductDetailContext,
  isProductListItemContext,
  isProductPriceRequestContext,
  isProductSearchContext,
} from './helpers'
import { getDefaultRules } from './defaultRules'

const ReactProductContext = React.createContext<ProductContext | undefined>(
  undefined
)

export const ProductContextProvider: React.FC<{
  productContext: ProductContext
}> = ({ children, productContext }) => {
  const initialValue =
    productContext?.packagingUnitsInformation.packagingUnits[0]

  const [packagingUnit, setPackagingUnit] = useState(initialValue)

  useEffect(() => {
    if (productContext) {
      // Remove Flag when ready https://lyskahq.atlassian.net/browse/LCP-70
      setPackagingUnit(
        productContext.packagingUnitsInformation.packagingUnits[0]
      )
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [productContext?.packagingUnitsInformation])

  return (
    <ReactProductContext.Provider
      value={{
        ...productContext,
        rules: getDefaultRules({
          hasPrice: !!packagingUnit?.price,
          isOutOfStock: packagingUnit?.stockInformation?.isOutOfStock ?? false,
          isDiscontinued:
            packagingUnit?.stockInformation?.isDiscontinued ?? false,
          isPurchasable: packagingUnit?.purchasable ?? false,
        }),
        stock: packagingUnit?.stockInformation ?? productContext.stock,
        packagingUnitsInformation: {
          packagingUnit: packagingUnit,
          packagingUnits:
            productContext.packagingUnitsInformation?.packagingUnits || [],
          setPackagingUnit: (packagingUnit: IPackagingUnit) =>
            setPackagingUnit(packagingUnit),
        },
      }}
    >
      {children}
    </ReactProductContext.Provider>
  )
}

export const useProductContext = () => {
  return useContext(ReactProductContext)
}

export const useProductDetailContext = () => {
  const productContext = useContext(ReactProductContext)
  return isProductDetailContext(productContext)
    ? (productContext as ProductDetailContext)
    : undefined
}

export const useProductSearchContext = () => {
  const productContext = useContext(ReactProductContext)
  return isProductSearchContext(productContext)
    ? (productContext as ProductSearchContext)
    : undefined
}

export const useProductListItemContext = () => {
  const productContext = useContext(ReactProductContext)
  return isProductListItemContext(productContext)
    ? (productContext as ProductListItemContext)
    : undefined
}

export const useProductCartItemContext = () => {
  const productContext = useContext(ReactProductContext)
  return isProductCartItemContext(productContext)
    ? (productContext as ProductCartItemContext)
    : undefined
}
export const useProductPriceRequestContext = () => {
  const productContext = useContext(ReactProductContext)
  return isProductPriceRequestContext(productContext)
    ? (productContext as ProductPriceRequestContext)
    : undefined
}

export const getPackagingUnitsFromContext = (
  productContext: ProductContext
) => {
  const { packagingUnit, setPackagingUnit, packagingUnits } =
    productContext.packagingUnitsInformation
  return { packagingUnit, setPackagingUnit, packagingUnits }
}
