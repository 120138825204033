import React from 'react'
import { UserOutlined, DownOutlined } from '@ant-design/icons'
import LoginLink from '@core/components/Auth/LoginLink/LoginLink'
import { LYSDropdown, LYSButton, LYSIcon } from '@core/components/Primitives'
import UserMenuContent from '@core/components/header/AppBar/UserMenu/MenuContent'
import { i18n, useTranslation } from '@core/i18n/i18n'
import { usePageContext } from '@core/utils/pages/PageContext'
import { template, Slot, RenderProp } from '@core/utils/templates'
import useCurrentUser from '../../../../hooks/user/useCurrentUser'
import config from '@core/config/config'
import { ButtonType } from 'antd/lib/button'
import style from './index.module.less'

interface ButtonProps {
  className: string
}
interface UserMenuSlots {
  LoginButton: RenderProp<ButtonProps>
  MobileLoginButton: RenderProp<ButtonProps>
  UserMenuContent: RenderProp<void>
}
interface UserMenuProps {
  buttonClassName: string
}

export const UserMenu = template<UserMenuProps, UserMenuSlots>(
  ({ slots, buttonClassName }) => {
    const { isAuthenticated } = useCurrentUser()
    const { t } = useTranslation()

    const {
      props: { isMobile, isTablet },
    } = usePageContext()

    const isTouchDevice = isMobile || isTablet

    // the dropdown won't open if this is wrapped inside a slot,
    // since the dropdown does some element cloning and overrides
    // props in the cloned element
    // didn't come up with a straight forward solution in the first place
    // we should care about this if it is needed.

    return isAuthenticated ? (
      <LYSDropdown
        overlay={
          <Slot render={slots?.UserMenuContent}>
            <UserMenuContent />
          </Slot>
        }
      >
        <LYSButton
          type={config.features.header.buttonType as ButtonType}
          ghost
          icon={<UserOutlined rev={undefined} />}
          className={buttonClassName}
        >
          {!isTouchDevice && config.features.header.userMenuWithText && (
            <>
              {t('menu.myAccountText')}
              <LYSIcon className={style.icon} component={DownOutlined} />
            </>
          )}
        </LYSButton>
      </LYSDropdown>
    ) : (
      <LoginLink>
        {isMobile ? (
          <Slot<ButtonProps>
            render={slots?.MobileLoginButton}
            props={{ className: buttonClassName }}
          >
            <LYSButton
              type={config.features.header.buttonType as ButtonType}
              size="large"
              ghost
              icon={<UserOutlined rev={undefined} />}
              className={buttonClassName}
            />
          </Slot>
        ) : (
          <Slot render={slots?.LoginButton}>
            <LYSButton
              type={config.features.header.buttonType as ButtonType}
              size="large"
              ghost
              data-testid="loginbutton"
              className={buttonClassName}
            >
              {i18n.t('appBar.loginText')}
            </LYSButton>
          </Slot>
        )}
      </LoginLink>
    )
  }
)
