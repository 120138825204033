import NextI18Next from 'next-i18next'
import i18nConfig from '@core/config/i18n'
import i18nextOptions from './i18next-options'
const path = require('path') // eslint-disable-line @typescript-eslint/no-var-requires

const NextI18NextInstance = new NextI18Next(
  i18nextOptions({
    strictMode: false,
    defaultLanguage: i18nConfig.language,
    otherLanguages: i18nConfig.otherLanguages,
    localePath: path.resolve('./public/static/locales'),
  })
)

/* Optionally, export class methods as named exports */
export const { appWithTranslation, useTranslation, Trans, i18n } =
  NextI18NextInstance
