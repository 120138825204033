import qs from 'qs'
import BaseAPI from '@core/api/Platform/BaseAPI'
import {
  IExternalOrder,
  IExternalOrderCollection,
  IExternalOrdersQuery,
} from './types'

class ExternalOrders extends BaseAPI {
  public async fetchExternalOrders(
    filter?: IExternalOrdersQuery
  ): Promise<IExternalOrderCollection> {
    const response = await this.api.get<IExternalOrderCollection>(
      `external-orders?${qs.stringify(filter)}`,
      {
        genericUrl: 'external-orders',
      }
    )
    const { data } = response
    return data
  }
  public async fetchExternalOrderDetail(
    id: string
  ): Promise<IExternalOrder | undefined> {
    try {
      const response = await this.api.get<IExternalOrder>(
        `external-orders/${id}`,
        {
          genericUrl: 'external-orders/{id}',
        }
      )
      const { data } = response
      return data
    } catch (e) {
      if (e.response?.status === 404) return undefined
      throw e
    }
  }
}
export default ExternalOrders
