import * as React from 'react'
import { Drawer as BaseDrawer } from 'antd'
import cn from 'classnames'
import style from './index.module.less'

export interface OwnProps {
  open: boolean
  title?: JSX.Element
  position: 'right' | 'left'
  width: string
  persistOnClick?: boolean
  toggleDrawer?: () => void
  className?: string
}
type Props = OwnProps
const Drawer: React.FC<Props> = (props) => {
  const {
    children,
    position,
    width,
    toggleDrawer,
    title,
    persistOnClick,
    open,
    className,
  } = props

  return (
    <BaseDrawer
      width={width}
      placement={position}
      visible={open}
      title={title && title}
      onClose={toggleDrawer}
      // 100% - height of the drawer header
      bodyStyle={{ height: 'calc(100% - 55px)' }}
      className={cn(style.drawerStyle, className)}
    >
      <div
        className={style.onClickWrapper}
        onClick={() => (persistOnClick ? null : toggleDrawer)}
        onKeyDown={toggleDrawer}
      >
        {children}
      </div>
    </BaseDrawer>
  )
}

export default Drawer
