import React from 'react'
import { IProductSearch } from '@core/api/Products/types'
import {
  LYSCol,
  LYSDivider,
  LYSRow,
  LYSTypography,
} from '@core/components/Primitives'
// eslint-disable-next-line import/no-cycle
import ProductCollectionListItem from '@core/components/ProductCollection/ProductCollectionListItem'
import { template, RenderProp, Slot } from '@core/utils/templates'
import styles from './index.module.less'

interface RecommendationsBaseProps {
  title: string
  products: IProductSearch[]
}

interface RecommendationsBaseComponents {
  ProductItem: typeof ProductCollectionListItem
}

export interface RecommendationsBaseSlots {
  title: RenderProp<void>
  products: RenderProp<void>
}

const ProductRelationBase = template<
  RecommendationsBaseProps,
  RecommendationsBaseSlots,
  RecommendationsBaseComponents
>(({ slots, products, title, getComponents }) => {
  if (products.length === 0) {
    return null
  }

  const components = getComponents({
    ProductItem: ProductCollectionListItem,
  })

  return (
    <>
      <Slot render={slots?.title}>
        <LYSRow>
          <LYSDivider />
          <LYSCol md={24}>
            <LYSTypography.Title
              level={2}
              visualAppearance={'h3'}
              className={styles.title}
            >
              {title}
            </LYSTypography.Title>
          </LYSCol>
        </LYSRow>
      </Slot>
      <Slot render={slots?.products}>
        {products.map((product, index) => (
          <LYSRow key={`${product.id}-${index}`} gutter={'md'}>
            <LYSCol md={24}>
              <components.ProductItem product={product} />
            </LYSCol>
          </LYSRow>
        ))}
      </Slot>
    </>
  )
})

export default ProductRelationBase
