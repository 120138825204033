import { Collapse } from 'antd'
import { CollapseProps } from 'antd/lib/collapse'

interface StaticExports {
  Panel: typeof Collapse.Panel
}

export type Props = CollapseProps

const LYSCollapse: React.FC<Props> & StaticExports = (props) => {
  return <Collapse {...props} />
}

LYSCollapse.Panel = Collapse.Panel

export default LYSCollapse
