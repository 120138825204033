import { IOrderItem } from '@core/api/Orders/types'

import { IShopServices } from '@core/config/services'

import { IPurchaseEvent, purchaseEvent } from '@core/events/checkout'

export const setupIgefaTracking = (services: IShopServices) => {
  const { eventBus } = services

  eventBus.subscribe(purchaseEvent.type, (e: IPurchaseEvent) => {
    // Clear the previous ecommerce object.
    window.dataLayer.push({ ecommerce: null })

    // Google Universal Analytics ecommerce purchase event
    const analyticsPurchaseEvent = mapToAnalyticsEcommercePurchaseEvent(e)

    window.dataLayer.push(analyticsPurchaseEvent)
  })
}

const mapToAnalyticsEcommercePurchaseEvent = (
  purchaseEvent: IPurchaseEvent
) => {
  const { order } = purchaseEvent.payload

  const mapOrderItem = (orderItem: IOrderItem) => {
    return {
      item_id: orderItem.product.variant.sku,
      item_name: orderItem.product.name,
      item_variant: orderItem.product.variant.name,
      item_category: (orderItem.product as any).taxonomyBreadcrumb, // Workaround: taxonomyBreadcrumb not typed in Core
      quantity: orderItem.quantity,
      price: (orderItem.pricePerUnit.net || 0) / 100,
    }
  }

  return {
    event: 'purchase',
    ecommerce: {
      transaction_id: order.purchaseOrderNumber,
      affiliation: 'Idealclean',
      value: order.price.total.net / 100,
      tax: order.price.taxes.reduce((acc, tax) => acc + tax.amount, 0) / 100,
      shipping: order.price.shipping.net / 100,
      currency: order.price.total.currency,
      items: order.items.map(mapOrderItem),
    },
  }
}
