import { Breadcrumb } from 'antd'
import { BreadcrumbProps } from 'antd/lib/breadcrumb'

interface StaticExports {
  Item: typeof Breadcrumb.Item
}

export type Props = BreadcrumbProps

const LYSBreadcrumb: React.FC<Props> & StaticExports = (props) => (
  <Breadcrumb {...props} />
)

LYSBreadcrumb.Item = Breadcrumb.Item

export default LYSBreadcrumb
