import * as React from 'react'
import Link from 'next/link'
import {
  BookOutlined,
  EnvironmentOutlined,
  PoweroffOutlined,
  ShoppingOutlined,
  UserOutlined,
} from '@ant-design/icons'
import { LYSMenu, LYSTypography } from '@core/components/Primitives'
import { Props as MenuProps } from '@core/components/Primitives/LYSMenu'
import routes from '@core/config/routes'
import { useTranslation } from '@core/i18n/i18n'
import useCurrentUser from '../../../../../hooks/user/useCurrentUser'
import style from './index.module.less'
import config from '@core/config/config'

export type UserMenuProps = MenuProps

const UserMenuContent = (props: UserMenuProps) => {
  const { t } = useTranslation()
  const { logout } = useCurrentUser()

  return (
    <LYSMenu {...props}>
      <LYSMenu.Item
        key="me"
        icon={<UserOutlined className={style.menuItemText} rev={undefined} />}
        className={style.menuItem}
      >
        <Link href={'/me'}>
          <LYSTypography.Text className={style.menuItemText}>
            {t('menu.myAccountText')}
          </LYSTypography.Text>
        </Link>
      </LYSMenu.Item>
      {!config.features.header.productListDropdown && (
        <LYSMenu.Item
          key="productList"
          icon={<BookOutlined className={style.menuItemText} rev={undefined} />}
          className={style.menuItem}
        >
          <Link {...routes.productList()}>
            <LYSTypography.Text className={style.menuItemText}>
              {t('menu.orderListText')}
            </LYSTypography.Text>
          </Link>
        </LYSMenu.Item>
      )}
      <LYSMenu.Item
        key="addresses"
        icon={
          <EnvironmentOutlined className={style.menuItemText} rev={undefined} />
        }
        className={style.menuItem}
      >
        <Link {...routes.addresses}>
          <LYSTypography.Text className={style.menuItemText}>
            {t('menu.addressesText')}
          </LYSTypography.Text>
        </Link>
      </LYSMenu.Item>
      <LYSMenu.Item
        key="orders"
        icon={
          <ShoppingOutlined className={style.menuItemText} rev={undefined} />
        }
        className={style.menuItem}
      >
        <Link {...routes.orderOverview()}>
          <LYSTypography.Text className={style.menuItemText}>
            {t('menu.ordersText')}
          </LYSTypography.Text>
        </Link>
      </LYSMenu.Item>
      <LYSMenu.Item
        key="logout"
        icon={
          <PoweroffOutlined className={style.menuItemText} rev={undefined} />
        }
        className={style.menuItem}
      >
        <a type="link" onClick={logout}>
          <LYSTypography.Text className={style.menuItemText}>
            {t('menu.signoutText')}
          </LYSTypography.Text>
        </a>
      </LYSMenu.Item>
    </LYSMenu>
  )
}

export default UserMenuContent
