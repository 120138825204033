import { ICart } from '@core/api/Cart/types'

const getCartEventsHash = (cart: ICart) => {
  if (!cart || cart.items.length === 0) return null

  const tmpCart: ICart = JSON.parse(JSON.stringify(cart))

  const hashObject = tmpCart.items
    .slice()
    .sort((left, right) =>
      left.product.variant.id < right.product.variant.id ? -1 : 1
    )
    .map((cartItem) => ({
      id: cartItem.product.variant.id,
      events: cartItem.events
        ?.slice()
        .sort((left, right) => (left.name < right.name ? -1 : 1)),
    }))

  return JSON.stringify(hashObject)
}

export default getCartEventsHash
